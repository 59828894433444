import { AlertController, ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';

import { IapsPage } from '../molerts/iaps/iaps.page';
import { ToastsPage } from '../molerts/toasts/toasts.page';
import { EpisodePage } from '../molerts/episode/episode.page';
import { AlertsPage } from '../molerts/alerts/alerts.page';
import { AuthenticationPage } from '../molerts/authentication/authentication.page';
import { RadioPage } from '../molerts/radio/radio.page';
import { MusicPage } from '../molerts/music/music.page';

import { Events } from './events.service';
import { VariasService } from './varias.service';
import { LocalizationService } from './localization.service';
import { InstanceService } from './instance.service';

@Injectable({
  	providedIn: 'root'
})
export class MolertsService {

	queue: Array<any> = [];

	toastRadio: any = null;
	toastMusic: any = null;

  	constructor(
		private modalController: ModalController,
		private varias: VariasService,
		private localization: LocalizationService,
		private events: Events,
		private instance: InstanceService
	) {
		this.events.subscribe("purchaseMade", async (productId) => {
			if (productId === "membership" || productId === "membership2") {
				this.alert_membershipGained();
			} else if (productId === this.instance.iapPrefix+"10nutrition") {
				this.alert_nutritionGained(10);
			} else if (productId === this.instance.iapPrefix+"special2") {
				this.alert_nutritionGained(20);
			} else if (productId === this.instance.iapPrefix+"125nutrition") {
				this.alert_nutritionGained(125);
			} else if (productId === this.instance.iapPrefix+"100circuits") {
				this.alert_circuitsGained(100);
			} else if (productId === this.instance.iapPrefix+"box_single") {
				this.alert_ticketsGained(100);
				this.alert_nutritionGained(25);
			} else if (productId === this.instance.iapPrefix+"special6") {
				this.alert_ticketsGained(200);
				this.alert_nutritionGained(50);
			} else if (productId === this.instance.iapPrefix+"box_season") {
				this.alert_ticketsGained(500);
				this.alert_nutritionGained(125);
			}
			if (!await this.varias.getVariable("sys_asstUnl")) {
				if (!await this.varias.getVariable("conf_hasImageryAudio")) {
					this.alert_assetsGained();
				}
			}
		});

		this.events.subscribe("STB_unlocked", (contentId) => {
			this.alert_episodeUnlocked(contentId, "Episode");
		})
	}

	async toast_ticketsGained(amount) {
		let ticketsOverall = await this.varias.getVariable("sys_tickets");
		let molert = await this.modalController.create({
			component: ToastsPage,
			cssClass: "EON__MOLERT --TOAST --TOP",
			componentProps: {
				mode: "simple",
				animation: "ticketsGained",
				heading: amount + " " + this.localization.molerts_ticketsGained1,
				text: this.localization.molerts_ticketsGained2+ " " + ticketsOverall + " Tickets"
			}
		})
		this.queueUp(molert);
	}

	async toast_music(duration, permitFunction, dismissFunction) {
		this.toastMusic = await this.modalController.create({
			component: MusicPage,
			cssClass: "EON__MOLERT --TOAST --TOP --MUSIC",
			componentProps: {
				permitFunction: permitFunction,
				dismissFunction: dismissFunction
			}
		})
		this.toastMusic.present();
		this.toastMusic.onDidDismiss().then(() => {
			dismissFunction();
		});
		setTimeout(() => { this.toastMusic.dismiss(); }, duration);
	}

	async toast_radio(messageId, heading, pauseFunction, playing, playFunction) {
		let keepSame = false;
		if (this.toastRadio) { 
			if (this.toastRadio.messageId != messageId) {
				await this.toastRadio.dismiss();
				this.toastRadio = null;
			} else {
				keepSame = true;
			}
		}

		if (keepSame) { return; }

		this.toastRadio = await this.modalController.create({
			component: RadioPage,
			cssClass: "EON__MOLERT --TOAST --TOP --RADIO",
			componentProps: {
				mode: "simple",
				animation: "ticketsGained",
				heading: heading,
				text: "",
				pauseFunction: pauseFunction,
				playing: playing,
				playFunction: playFunction
			}
		})
		this.toastRadio.present();
		this.toastRadio.messageId = messageId;
		this.toastRadio.onDidDismiss().then(() => {
			this.toastRadio = null;
		});
	}

	async dismiss_radio(messageId) {
		if (this.toastRadio) { 
			if (this.toastRadio.messageId === messageId || messageId === "any") {
				this.toastRadio.dismiss();
			}
		}
	}

	async alert_episodeUnlocked(contentId, title) {
		let molert = await this.modalController.create({
			component: EpisodePage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				contentId: contentId,
				title: title
			}
		})
		this.queueUp(molert);
	}

	async alert_nutritionGained(amount) {
		let title = amount + " Nutrition";
		let text = this.localization.molerts_nutritionGained;
		text = text.replace("[x]", amount+"").replace("[x]", amount+"").replace("[y]", amount*2+"");
		this.alert_basic(title, "", text, "nutritionGained", "", "", "", []);
	}

	async alert_circuitsGained(amount) {
		console.log("ALERT CIRCUITS GAINED")
		let title = amount + " Circuits";
		let text = this.localization.molerts_circuitsGained;
		text = text.replace("[x]", amount);
		this.alert_basic(title, "", text, "circuitsGained", "", "", "", [])
	}

	async alert_ticketsGained(amount) {
		let title = amount + " Tickets";
		let text = this.localization.molerts_ticketsGained;
		text = text.replace("[x]", amount);
		this.alert_basic(title, "", text, "ticketsGained", "", "", "", [])
	}

	async alert_assetsGained() {
		let title = this.localization.advert_membership_assets_heading;
		let text = this.localization.molerts_assetsGained;
		this.alert_basic(title, "", text, "assetsGained", "", "", "", [])
	}

	async alert_boxGained(tickets, nutrition) {
		this.alert_ticketsGained(tickets);
		this.alert_nutritionGained(nutrition);
	}

	async alert_membershipGained() {
		if (await this.varias.getVariable("memberMolertAfterOrder")) {
			await this.varias.setVariable("memberMolertAfterOrder", false);
			let title = "Membership";
			let text = this.localization.molerts_membershipGained;
			this.alert_basic(title, "", text, "membershipGained", "", "", "", [])
		}
	}

	async alert_basic(title, subtitle, text, premadeAnimation: string = "", icon: string = "", iconAnimation: string = "", iconColor: string = "--WARNING", buttons: Array<any> = []) {
		let molert = await this.modalController.create({
			component: AlertsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				premadeAnimation: premadeAnimation,
				icon: icon,
				iconAnimation: iconAnimation,
				iconColor: iconColor,
				title: title,
				subtitle: subtitle,
				text: text,
				buttons: buttons
			}
		})
		this.queueUp(molert);
	}

	alert_error(code: number = 0, title: string = "", text: string = "") {
		if (!title) { title = this.localization.error; }
		if (!text) { text = this.localization.unknownError; }
		let message = text;
		if (code != 0) { message += " ["+code+"]"; }
		const buttons = [ { text: "Ok", color: "--WARNING", handler: () => {} } ]
		this.alert_basic(title, "", message, "", "triangle-exclamation", "shake", "--WARNING", buttons);
	}

	alert_errorFirebase(firebaseError) {
		let firstPart = firebaseError.message.split("(")[0];
		let message = firstPart.replace("Firebase: ", "");
		this.alert_error(0, "", message);
	}

	async alert_purchaseOptions(type, contentId, missingTickets, adCallback: any = () => {}) {
		let molert = await this.modalController.create({
			component: IapsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				variant: "purchaseOptions",
				subVariant: type,
				animation: "shake",
				icon: "lock",
				iconColor: "--DANGER",
				missingTickets: missingTickets,
				adCallback: adCallback
			}
		})
		this.queueUp(molert);
	}

	async alert_skipOptions(adAvailable, adCallback) {
		const molert = await this.modalController.create({
			component: IapsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				variant: "skipOptions",
				animation: "shake",
				icon: "clock",
				iconColor: "--DANGER",
				adAvailable: adAvailable,
				adCallback: adCallback
			}
		})
		this.queueUp(molert);
	}

	async alert_botOptions(adAvailable, adCallback) {
		const molert = await this.modalController.create({
			component: IapsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				variant: "botOptions",
				animation: "shake",
				icon: "brain-circuit",
				iconColor: "--DANGER",
				adAvailable: adAvailable,
				adCallback: adCallback
			}
		})
		this.queueUp(molert);
	}

	async alert_suggestAuthentication (callbackPassword, callbackGoogle, callbackApple, callbackDenied) {
		const molert = await this.modalController.create({
			component: AuthenticationPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				callbackPassword: callbackPassword,
				callbackGoogle: callbackGoogle,
				callbackApple: callbackApple,
				callbackDenied: callbackDenied
			}
		})
		this.queueUp(molert);
	}

	queueUp(molert) {
		if (this.showing) {
			this.queue.push(molert);
		} else {
			this.showing = true;
			molert.present();
			molert.onDidDismiss().then(() => {
				this.showing = false;
				this.workQueue();
			})
		}
	}


	showing: boolean = false;
	workQueue() {
		if (this.queue[0]) {
			this.showing = true;
			this.queue[0].present();
			this.queue[0].onDidDismiss().then(() => {
				this.queue.shift();
				this.showing = false;
				this.workQueue();
			})
		}
	}

}