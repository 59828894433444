import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

	episodes: Array<any> = [];
	userEpisodes: Array<any> = [];
	showEpisode: any = () => {}
	tapMenu: any = () => {}
	enabled: boolean = true;
	isFullscreen: boolean = false;

  	constructor() {}
}