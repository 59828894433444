import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { EpisodePage } from './episode.page';

import { ChatCardModule } from '../components/chat-card/chat-card.component.module'
import { ButtonModule } from '../components/button/button.component.module'
import { LasercutButtonModule } from '../components/lasercut-button/lasercut-button.component.module'
import { ImageModule } from '../components/image/image.component.module'

const routes: Routes = [
  {
    path: '',
    component: EpisodePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatCardModule,
    ButtonModule,
    LasercutButtonModule,
    ImageModule,
    RouterModule.forChild(routes)
  ],
  declarations: [EpisodePage]
})
export class EpisodePageModule {}
