import { Injectable } from '@angular/core';
//import { SignalService } from './signal.service';
import { UtilityService } from '../system/utility.service';
import { LocalizationService } from '../system/localization.service';
import { SignalService } from './signal.service';



@Injectable({
  	providedIn: 'root'
})
export class FirerrService {

	constructor(
		private util: UtilityService,
		private localization: LocalizationService,
		private signal: SignalService
	) { }
	  
	error(error, message) {
		if (error.code === "unavailable") { this.signal.alertNoConnection(message); }
		else { this.util.alert("Error", "Code="+error.code, this.localization.unknownError); }
	}
}
