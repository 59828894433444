import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.page.html',
  styleUrls: ['./toasts.page.scss'],
})
export class ToastsPage implements OnInit {
	@Input("mode") mode: string = "";
	@Input("animation") animation: string = "";
	@Input("heading") heading: string = "";
	@Input("text") text: string = "";

	anim1: boolean = false;
	anim2: boolean = false;
	anim3: boolean = false;
	anim4: boolean = false;
	anim5: boolean = false;
	anim6: boolean = false;
	anim7: boolean = false;

  	constructor(
		private modalController: ModalController
	) {
	}

  	ngOnInit() {
		if (this.animation === "ticketsGained") {
			this.anim1 = true;
			setTimeout(() => {
				this.anim2 = true;
				this.anim3 = false;
				this.anim4 = false;
				setTimeout(() => { this.anim1 = false; }, 500);
				setTimeout(() => { this.anim2 = false; }, 1000);
				setTimeout(() => { this.anim3 = true; }, 1000);
				setTimeout(() => { this.anim4 = true; }, 1500); 
				setTimeout(() => { this.dismiss(); }, 4000);
			}, 500)
		}		
	}

	dismiss() {
		this.modalController.dismiss();
	}

}