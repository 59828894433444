import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DataService } from '../system/data.service';
import { VariasService } from '../system/varias.service'
import { InstanceService } from '../system/instance.service'

import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config'
import { DeviceService } from '../system/device.service';

@Injectable({
  	providedIn: 'root'
})
export class ConfigService {

	pauseFactor: number = 1;
	fetched: boolean = false;

  	constructor(
		private remoteConfig: AngularFireRemoteConfig,
		private platform: Platform,
		private data: DataService,
		private varias: VariasService,
		private instance: InstanceService,
		private device: DeviceService
	) {}

	async getConfiguration() {

		if (!this.fetched) {
			await this.device.getPlatform();
			if (this.device.platform === "ios" || this.device.platform === "android") {
				this.remoteConfig.fetchAndActivate().then(() => {

					this.remoteConfig.getBoolean("pred_spend_lowest");
					this.remoteConfig.getBoolean("pred_spend_highest");
					this.remoteConfig.getBoolean("pred_churn_lowest");
					this.remoteConfig.getBoolean("pred_churn_highest");

					this.remoteConfig.getBoolean(this.instance.instance+"_f2pCovered")
					.then((result: any) => { this.varias.setVariable("conf_f2pCovered", result); })
					.catch(() => { this.varias.setVariable("conf_f2pCovered", true); })

					this.remoteConfig.getBoolean(this.instance.instance+"_adsImplemented")
					.then((result: any) => { this.varias.setVariable("conf_adsImplemented", result); })
					.catch(() => { this.varias.setVariable("conf_adsImplemented", true); })

					this.remoteConfig.getBoolean(this.instance.instance+"_adsCovered")
					.then((result: any) => { this.varias.setVariable("conf_adsCovered", result); })
					.catch(() => { this.varias.setVariable("conf_adsCovered", false); })				

					this.remoteConfig.getBoolean(this.instance.instance+"_adsForSkip")
					.then((result: any) => { this.varias.setVariable("conf_adsForSkip", result); })
					.catch(() => { this.varias.setVariable("conf_adsForSkip", true); })

					this.remoteConfig.getBoolean(this.instance.instance+"_shardsCovered")
					.then((result: any) => { this.varias.setVariable("conf_shardsCovered", result); })
					.catch(() => { this.varias.setVariable("conf_shardsCovered", true); })

					this.remoteConfig.getBoolean(this.instance.instance+"_nutritionAvailable")
					.then((result: any) => { this.varias.setVariable("conf_nutritionAvailable", result); })
					.catch(() => { this.varias.setVariable("conf_nutritionAvailable", true); })
					
					this.remoteConfig.getBoolean(this.instance.instance+"_hasImageryAudio")
					.then((result: any) => { this.varias.setVariable("conf_hasImageryAudio", result); })
					.catch(() => { this.varias.setVariable("conf_hasImageryAudio", false); })

					this.remoteConfig.getBoolean(this.instance.instance+"_hasCreateMode")
					.then((result: any) => { this.varias.setVariable("conf_hasCreateMode", result); })
					.catch(() => { this.varias.setVariable("conf_hasCreateMode", false); })

					this.remoteConfig.getBoolean(this.instance.instance+"_hasBots")
					.then((result: any) => { this.varias.setVariable("conf_hasBots", result); })
					.catch(() => { this.varias.setVariable("conf_hasBots", false); })

					this.remoteConfig.getNumber(this.instance.instance+"_videoCircuitsFactor")
					.then((result: any ) => { if (typeof result == 'number') { this.data.config_videoCircuitsFactor = result; } })
					.catch(() => { this.data.config_videoCircuitsFactor = 2; })

					this.remoteConfig.getNumber(this.instance.instance+"_pauseFactor")
					.then((result: any ) => { if (typeof result == 'number') { this.data.config_pauseFactor = result; } })
					.catch(() => { this.data.config_pauseFactor = 1; })

					this.remoteConfig.getString(this.instance.instance+"_purrSpecial2")
					.then((result: any) => { this.varias.setVariable("conf_purrSpecial2", result); })
					.catch(() => { this.varias.setVariable("conf_purrSpecial2", "hlp_purr_special2") })
					
					this.remoteConfig.getString(this.instance.instance+"_purrSpecial3")
					.then((result: any) => { this.varias.setVariable("conf_purrSpecial3", result); })
					.catch(() => { this.varias.setVariable("conf_purrSpecial3", "hlp_purr_special3") })
					
					this.remoteConfig.getString(this.instance.instance+"_purrSpecial4")
					.then((result: any) => { this.varias.setVariable("conf_purrSpecial4", result); })
					.catch(() => { this.varias.setVariable("conf_purrSpecial4", "hlp_purr_special4") })

					this.remoteConfig.getString(this.instance.instance+"_purrSpecial5")
					.then((result: any) => { this.varias.setVariable("conf_purrSpecial5", result); })
					.catch(() => { this.varias.setVariable("conf_purrSpecial5", "hlp_purr_special5") })

					this.remoteConfig.getString(this.instance.instance+"_hasSpecial5")
					.then((result: any) => { this.varias.setVariable("conf_canHaveSpecial5", result); })
					.catch(() => { this.varias.setVariable("conf_canHaveSpecial5", false) })

					this.fetched = true;

				}).catch((error) => { this.fetched = false; })
			} else {
				this.varias.setVariable("conf_f2pCovered", true);
				this.varias.setVariable("conf_adsImplemented", true);
				this.varias.setVariable("conf_adsCovered", false);
				this.varias.setVariable("conf_adsForSkip", true);
				this.varias.setVariable("conf_shardsCovered", true);
				this.varias.setVariable("conf_nutritionAvailable", true);
				this.varias.setVariable("conf_hasImageryAudio", true);
				this.varias.setVariable("conf_hasBots", true);
				if (this.instance.instance === "voidapp") {
					this.varias.setVariable("conf_hasCreateMode", true);
				} else {
					this.varias.setVariable("conf_hasCreateMode", false);
				}
				this.data.config_videoCircuitsFactor = 2;
				this.data.config_pauseFactor = 3;
				this.varias.setVariable("conf_purrSpecial2", "hlp_purr_special2");
				this.varias.setVariable("conf_purrSpecial3", "hlp_purr_special3");
				this.varias.setVariable("conf_purrSpecial4", "hlp_purr_special4");
				this.varias.setVariable("conf_purrSpecial5", "hlp_purr_special5");
				this.varias.setVariable("conf_canHaveSpecial5", false);
				this.varias.setVariable("conf_canHaveSpecial5", true);
				this.fetched = true;
			}
		}

	}

	setVariant(variant) {
		if (variant === "ads") {
			this.varias.setVariable("conf_adsImplemented", true);
			this.varias.setVariable("conf_adsCovered", false);
			this.varias.setVariable("conf_f2pCovered", true);
		} else if (variant === "premium") {
			this.varias.setVariable("conf_adsImplemented", false);
			this.varias.setVariable("conf_adsCovered", false);			
			this.varias.setVariable("conf_f2pCovered", true);
		} else if (variant === "fair2play") {
			this.varias.setVariable("conf_adsImplemented", false);
			this.varias.setVariable("conf_adsCovered", false);
			this.varias.setVariable("conf_f2pCovered", false);
		}
	}
	
}
