import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.page.html',
  styleUrls: ['./alerts.page.scss'],
})
export class AlertsPage implements OnInit {
	@Input("contentId") contentId: string = "";
	@Input("premadeAnimation") premadeAnimation: string = "";
	@Input("iconAnimation") iconAnimation: string = "";
	@Input("title") title: string = "";
	@Input("subtitle") subtitle: string = "";
	@Input("text") text: string = "";
	@Input("icon") icon: string = "";
	@Input("iconColor") iconColor: string = "";
	@Input("butons") buttons: Array<any> = [];

	anim1: boolean = false; anim1b: boolean = false; anim1c: boolean = false;
	anim2: boolean = false; anim2b: boolean = false; anim2c: boolean = false;
	anim3: boolean = false; anim3b: boolean = false; anim3c: boolean = false;
	anim4: boolean = false; anim4b: boolean = false; anim4c: boolean = false;
	anim5: boolean = false; anim5b: boolean = false; anim5c: boolean = false;

  	constructor(
		private modalController: ModalController
	) {}

  	ngOnInit() {
		if (this.premadeAnimation === "circuitsGained" || this.premadeAnimation === "nutritionGained" || this.premadeAnimation === "ticketsGained" || this.premadeAnimation === "boxGained" || this.premadeAnimation === "assetsGained") {
			this.anim1 = true; setTimeout(() => { this.anim1b = true; }, 750); setTimeout(() => { this.anim1c = true; }, 1000);
			setTimeout(() => { this.anim2 = true; setTimeout(() => { this.anim2b = true; }, 750); setTimeout(() => { this.anim2c = true; }, 1000); }, 250)
			setTimeout(() => { this.anim3 = true; setTimeout(() => { this.anim3b = true; }, 750); setTimeout(() => { this.anim3c = true; }, 1000); }, 500)
			setTimeout(() => { this.anim4 = true; setTimeout(() => { this.anim4b = true; }, 750); setTimeout(() => { this.anim4c = true; }, 1000); }, 750)
			setTimeout(() => { this.anim5 = true; setTimeout(() => { this.anim5b = true; }, 750); setTimeout(() => { this.anim5c = true; }, 1000); }, 1000)
		} else if (this.premadeAnimation === "membershipGained") {
			this.anim1 = true;
			setTimeout(() => {
				setInterval(() => {
					if (this.anim1) { this.anim1 = false; this.anim2 = true; }
					else if (this.anim2) { this.anim2 = false; this.anim3 = true; }
					else if (this.anim3) { this.anim3 = false; this.anim4 = true; }
					else if (this.anim4) { this.anim4 = false; this.anim5 = true; }
					else if (this.anim5) { this.anim5 = false; this.anim1 = true; }
				}, 1000);
			}, 500);
		}
	}

	clickContent() {
		this.modalController.dismiss();
	}

	clickButton(button) {
		button.handler();
	}

}