import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  	selector: 'app-seasonpass',
  	templateUrl: './seasonpass.page.html',
  	styleUrls: ['./seasonpass.page.scss'],
})
export class SeasonpassPage implements OnInit {

	asModal = true;

  	constructor(
		private modalController: ModalController
	) {}

  	ngOnInit() {}

	close() {
		this.modalController.dismiss();
	}

}