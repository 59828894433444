import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  	providedIn: 'root'
})
export class UtilityService {

	constructor(
		private alertController: AlertController
	) { }
	  
	sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

	async alert(header, subheader, message) {
		const alert = await this.alertController.create({
			subHeader: header,
			header: subheader,
			cssClass: 'eon__alert',
			message: message,
			buttons: ['Ok']
		});
		await alert.present();
	}
}
