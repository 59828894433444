import { Injectable } from '@angular/core';
import { LocalizationService } from './localization.service'

@Injectable({
  	providedIn: 'root'
})
export class DateformatService {

	constructor(
		private localization: LocalizationService
	) {}
	  
	format(time) {
		if (time === 9999999999999) { return ""; }
		else if (time <= 0) { return ""; }
		
		let now = Date.now();
		let past = Math.abs(time-now);

		//past = past + 60000*60*4;

		let minutesPast = Math.floor(past/60000);
		let hoursPast = Math.floor(minutesPast/60);
		minutesPast = minutesPast - (hoursPast*60);
		let daysPast = Math.floor(hoursPast/24);
		hoursPast = hoursPast - daysPast*24;
		let weeksPast = Math.floor(daysPast/7)
		daysPast = daysPast - weeksPast*7;

		let result: any = "";
		if (weeksPast > 1) {
			let timeDate = new Date(time);
			result = timeDate;
			result = timeDate.getDate() + "." + (timeDate.getMonth()+1) + "." + timeDate.getFullYear();
		} else if (weeksPast > 0) {
			if (this.localization.lang === "EN") { 
				result = new Intl.DateTimeFormat("en-US").format(time);
			} else { 
				result = new Intl.DateTimeFormat("de-DE").format(time);
			}
		}
		else if (daysPast > 0) {
			if (daysPast > 1) {
				if (this.localization.lang === "EN") { 
					result = new Intl.DateTimeFormat("en-US").format(time);
				} else { 
					result = new Intl.DateTimeFormat("de-DE").format(time);
				}
			} else {
				result = this.localization.dateformat_ereyesterday+"";
			}
		}
		else if (hoursPast > 0) {
			let nowDate = new Date(now);
			let pastDate = new Date(now-past);
			if (nowDate.getDate() == pastDate.getDate()) {
				if (hoursPast > 1) {
					result = this.localization.dateformat_hoursAgo.replace("[x]", hoursPast.toString());
				} else {
					result = this.localization.dateformat_oneHourAgo+"";
				}
			} else {
				result = this.localization.dateformat_yesterday+"";
			}
		}
		else if (minutesPast > 10) {
			if (minutesPast > 1) {
				result = this.localization.dateformat_minutesAgo.replace("[x]", minutesPast.toString());
			} else {
				result = this.localization.dateformat_oneMinuteAgo+"";
			}
		}
		else {
			result = this.localization.dateformat_momentsAgo+"";
		}

		return result;
	}
}
