import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController, NavController } from '@ionic/angular';

import { Storage } from '@ionic/storage-angular';

import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { CancelOptions, LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications'
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from '@capacitor/push-notifications';

import { AngularFireMessaging, AngularFireMessagingModule} from '@angular/fire/compat/messaging'

import { Events } from '../system/events.service'

import { AudioService } from '../system/audio.service'
import { DataService } from '../system/data.service'
import { VibrationService } from '../system/vibration.service'
import { UtilityService } from './utility.service';
import { LocalizationService } from './localization.service';
import { DeviceService } from '../system/device.service';
import { InstanceService } from './instance.service';
import { NavigationService } from './navigation.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StorageService } from './storage.service';

declare var cordova;

@Injectable({
  providedIn: 'root'  
})
export class NotificationsService {

	currentBadge: number = 0;
	funnel_assignBadges: any = undefined;
	hasPermission: boolean = false;
	foreground: boolean = true;
	pendingCancels: Array<any> = [];
	pendingCancelsGlobalQueue: any = null;

	constructor(
		private alertController: AlertController,
		private audio: AudioService,
		public badge: Badge,
		public data: DataService,
		private events: Events,
		public platform: Platform,
		public toastController: ToastController,
		private vibration: VibrationService,
		private util: UtilityService,
		private localization: LocalizationService,
		private device: DeviceService,
		private navController: NavController,
		private instance: InstanceService,
		private navigation: NavigationService,
		private fireAuth: AngularFireAuth,
		private storage: Storage,
		private storageService: StorageService
	) {
		this.platform.ready().then(async () => {
			await this.device.getPlatform();
			if (this.device.platform === "web") {
			} else if (this.device.platform === "android") {
				this.hasPermission = true;
				this.registerPushNotifications();
			} else {
				LocalNotifications.checkPermissions().then(async result => {
					if (result.display === "granted") {
						this.hasPermission = true;
						setTimeout(async () => {
							this.registerPushNotifications();
						}, 20000)
					} else {

					}
				})
			}

			var that = this;
			if (this.device.platform === "ios" || this.device.platform === "android") {
				document.addEventListener("pause", function() { that.pause(); }, false);
				document.addEventListener("resume", function() { that.resume(); }, false);
			} else {
				document.addEventListener("visibilitychange", (ev) => {
					if (document.hidden) { this.pause(); }
					else { this.resume(); }
				})
			}

			this.events.subscribe("askForNotifications", () => { this.requestPermission(); })
			this.events.subscribe("scheduleFair2PlayReminder", () => { this.scheduleFair2PlayReminder(); })
			this.events.subscribe("subscribeTopic", (topic) => { 
				FCM.subscribeTo({ topic: topic }).then(() => {
					this.registerPushNotifications();
				}).catch();
			})
			this.events.subscribe("unsubscribeTopic", (topic) => { 
				FCM.unsubscribeFrom({ topic: topic }).then(() => {
					this.registerPushNotifications();
				}).catch();
			})

			if (this.device.platform === "ios" || this.device.platform === "android") {
				PushNotifications.addListener('registration', (token) => { });
				PushNotifications.addListener("registrationError", () => {})
				PushNotifications.addListener('pushNotificationActionPerformed', async (notification: any) => {
					const data = notification.notification.data;
					if (data.routeLink) {
						setTimeout(() => {
							this.navigation.pushLink(data.routeLink);
						}, 3000);
					}
					if (data.tutoringId) {
						setTimeout(() => {
							this.events.publish("ECH_downloadAndStartTutoring", { sceneId: data.tutoringId, forceRestart: false, openOnStart: true });
						}, 3000);
					}
				});
				PushNotifications.addListener("pushNotificationReceived", (notifcation) => {})
			}

		}).catch(() => { this.hasPermission = true; })

	}

	async registerPushNotifications() {
		let permStatus = await PushNotifications.checkPermissions();
		if (permStatus.receive === 'prompt') {
		  	permStatus = await PushNotifications.requestPermissions();
		}
		if (permStatus.receive !== 'granted') {
		  	throw new Error('User denied permissions!');
		}
		await PushNotifications.register();

		await this.device.getPlatform();
		let topic = this.instance.instance+"";
		if (this.device.platform === "android") { topic = this.instance.instance+"_android_"+this.localization.lang; } 
		else if (this.device.platform === "ios") { topic = this.instance.instance+"_ios_"+this.localization.lang; }
		FCM.subscribeTo({ topic: topic })
		.then((r) => {})
		.catch((err) => {});

		FCM.subscribeTo({ topic: "test" })
		.then((r) => {})
		.catch((err) => {});

		this.fireAuth.onAuthStateChanged(async (currentUser) => {
			let uid = null;
			if (currentUser) { if (currentUser.uid) { uid = currentUser.uid; } }
			if (uid) {
				let uid_storage = null; await this.storage.get("indivFCM").then(result => { if (result) { uid_storage = result; } })
				if (uid_storage != uid) {
					if (uid_storage) { FCM.unsubscribeFrom({ topic: "usr_"+uid_storage }); }
					FCM.subscribeTo({ topic: "usr_"+uid });
					this.storage.set("indivFCM", uid);
				}
			} else {
				let uid_storage = null; await this.storage.get("indivFCM").then(result => { if (result) { uid_storage = result; } })
				if (uid_storage) {
					if (uid_storage) { FCM.unsubscribeFrom({ topic: "usr_"+uid_storage }); }
					this.storage.remove("indivFCM");
				}
			}
		});
	}

	pause() { this.foreground = false; }

	async resume() {
		this.foreground = true;
		for (let pending of this.pendingCancels) {
			this.cancel(pending.sceneId, pending.messageId, this.pendingCancelsGlobalQueue, pending.pause);
		}
		this.pendingCancels = [];
		this.clearBadge();
	}
	
	async convertToNumericId(sceneId, messageId) {
		let notificationNumber = 1;
		if (sceneId != undefined && messageId != undefined) {
			let sceneNumber = 0;
			let messageNumber = 0;
			messageId.split('').map(function(value) { messageNumber = messageNumber + value.charCodeAt(0); })
			notificationNumber = sceneNumber + messageNumber;
		}
		if (!notificationNumber) { notificationNumber = 7777; }
		if (isNaN(notificationNumber)) { notificationNumber = 7777; }
		return notificationNumber;
	}

	async requestPermission() {
		await this.device.getPlatform();
		if (this.device.platform === "ios" || this.device.platform === "android") {
			if (!this.data.simstats_simulationOn) {
				LocalNotifications.requestPermissions().then(async result => {
					if (result.display === "granted") { 
						this.hasPermission = true;
						await PushNotifications.requestPermissions();
						await PushNotifications.register();
					}
				})
			}
		}
	}

	async addMultiple(sceneInfo, messages, contentTitle, globalQueue) {
		let now = Date.now();

		if (!this.data.simstats_simulationOn) {
			if (this.hasPermission) {

				let notifications = [];

				let firstNotificationAt = null;
				let firstNotificationId = 1;
				let lastNotificationAt = null;
				let lastNotificationTitle = "---";
				let lastNotificationText = "...";
				let runningBadge = 1;
				for await (let message of messages) {
					if (!message.d && !message.end) {
						let text = message.t;
						if (message.usn) {
							let username; await this.data.getVariable("usr_name").then(result => username = result);
							if (username === null || username === undefined || username === "ask") { text = text.replace("[username]", "..."); }
							else { text = text.replace("[username]", username); }
						}
						
						let title = message.c.n;
	
						let id = 1; await this.convertToNumericId(message.scn, message.i).then(convertedId => id = convertedId);

						let at = parseInt(message.at + 60000);
						if (firstNotificationAt === null) { 
							firstNotificationAt = at; 
							firstNotificationId = id;
						}
						if (at < firstNotificationAt) {
							firstNotificationAt = at;
							firstNotificationId = id;
						}	
						if (lastNotificationAt === null) {
							lastNotificationAt = at;
							lastNotificationTitle = title;
							lastNotificationText = text;
						}
						if (at > lastNotificationAt) {
							lastNotificationAt = at;
							lastNotificationTitle = title;
							lastNotificationText = text;
						}
						
						let newNotification = { 
							id: id,
							title: title, 
							body: text, 
							schedule: { at: new Date(at) },
							sound: "message1.wav",
							foreground: true,
							group: contentTitle,
						}
						notifications.push(newNotification);

						runningBadge++;
					}
				}

				this.hasPermission = true;
				await this.device.getPlatform();
				if (this.device.platform === "android") {
					if (notifications.length > 0) {
						notifications.push({
							id: firstNotificationId+222,
							title: "", 
							body: "",
							schedule: { at: new Date(firstNotificationAt) },
							sound: "message1.wav",
							foreground: true,
							group: contentTitle,
							groupSummary: true,
							summary: ""
						})
					}
				}

				if (this.device.platform === "android") {
					for (let notification of notifications) {
						notification.smallIcon = 'ic_stat_notification_icon';
						notification.icon = 'ic_stat_notification_icon';
					}
				}

				if (notifications.length > 0) {
					this.scheduleNotifications(notifications);
					this.remindIncomingMessage(sceneInfo, lastNotificationAt, lastNotificationTitle, lastNotificationText, runningBadge, now);
				}

			}
		}
	}

	remindIncomingMessage(sceneInfo, messageAt, messageContactName, messageText, badge, now) {
		let items = [
			{ title: messageContactName, body: messageText },
			{ title: messageContactName, body: messageText },
			{ title: "Help-Bot:", body: this.localization.notification_helpi1 },
			{ title: messageContactName, body: messageText },
			{ title: "Help-Bot:", body: this.localization.notifcation_helpi2 }
		]

		if (this.platform.is("capacitor") || this.platform.is("cordova") || this.platform.is("android") || this.platform.is("ios")) {
			this.scheduleReminder(sceneInfo, items, messageAt);
		}
	}

	remindDecision(sceneInfo, lastMessage) {
		let contactName = "...";
		let messageText = this.localization.notification_newMessage;
		if (lastMessage) { 
			if (lastMessage.t) { if (!lastMessage.usn) { messageText = lastMessage.t; } }
			if (lastMessage.c) { contactName = lastMessage.c.n; }
		}

		let items = [
			{ title: contactName, body: messageText },
			{ title: contactName, body: messageText },
			{ title: "Help-Bot:", body: this.localization.notification_helpi3 },
			{ title: contactName, body: messageText },
			{ title: "Help-Bot:", body: this.localization.notifcation_helpi2 }
		]

		let now = Date.now();
		this.scheduleReminder(sceneInfo, items, now);
	}

	scheduleReminder(sceneInfo, items, fromTime) {
		let legit = true;
		if (sceneInfo) {
			if (sceneInfo.i) {
				if (sceneInfo.i.startsWith("hlp_sog")) { legit = false; }
				if (sceneInfo.ad) { legit = false; }
			}
		}
		if (legit) {
			let reminder =[];
			reminder[0] = new Date(fromTime);
			reminder[1] = new Date(fromTime);
			reminder[2] = new Date(fromTime);
			reminder[3] = new Date(fromTime);
			reminder[4] = new Date(fromTime);

			reminder[0].setHours(reminder[0].getHours()+3);
			reminder[1].setDate(reminder[1].getDate()+1);
			reminder[2].setDate(reminder[2].getDate()+3);
			reminder[3].setDate(reminder[3].getDate()+7);
			reminder[4].setDate(reminder[4].getDate()+14);

			let shiftedHours = reminder[0].getHours();
			if (shiftedHours >= 21) { reminder[0].setDate(reminder[0].getDate()+1); reminder[0].setHours(10); }
			if (shiftedHours < 10) { reminder[0].setHours(10); }

			let deferredHours = reminder[1].getHours();
			if (deferredHours >= 21) { reminder[1].setHours(19); reminder[2].setHours(20); reminder[3].setHours(18); reminder[4].setHours(19); }
			if (deferredHours < 10) { reminder[1].setHours(10); reminder[2].setHours(11); reminder[3].setHours(9); reminder[4].setHours(12); }		

			let notifications = [];

			let i = 0;
			for (let item of items) {
				if (reminder[i]) {
					let id = 11110 + i;
					notifications.push({
						id: id,
						title: item.title,
						body: item.body,
						schedule: { at: reminder[i] },
						sound: "message1.wav",
						foreground: true
					})
				}
				i++;
			}
			
			if (!this.data.simstats_simulationOn && this.hasPermission) {
				this.scheduleNotifications(notifications);
			}
		}
	}

	async scheduleFair2PlayReminder(silent = false) {
		let sunday = new Date(Date.now());
		let currentWeekday = sunday.getDay();
		let addDays = 7 - currentWeekday;
		sunday.setDate(sunday.getDate()+addDays);
		sunday.setHours(10);

		let notifications = [];
		notifications.push({
			id: 22220,
			title: "Help-Bot:",
			body: this.localization.fair2play_reminder1,
			schedule: { at: sunday },
			sound: "message1.wav",
			foreground: true
		})

		if (!this.data.simstats_simulationOn) {
			if (this.hasPermission) {
				this.scheduleNotifications(notifications);
			}
		}

		if (!silent) {
			const alert = await this.alertController.create({
				subHeader: "Reminder",
				cssClass: 'eon__alert',
				message: this.localization.fair2play_reminder2,
				buttons: ['OK']
			});
			await alert.present();
		}
	}

	async cancel(sceneId, messageId, globalQueue, pause) {

		if (messageId != undefined && messageId != null) {
			await this.device.getPlatform();
			if (this.device.platform === "ios" || this.device.platform === "android") {
				if (!this.data.simstats_simulationOn && this.foreground) {

					let convertedId = 0; await this.convertToNumericId(sceneId, messageId).then(result => convertedId = result);

					let notifications = [ { id: convertedId } ];
					if (this.device.platform === "android") {
						let summaryId = convertedId+222;
						notifications.push({ id: summaryId });
					}
					let options: CancelOptions = { notifications: notifications };
					LocalNotifications.cancel(options);

				}
			}
		}		

	}

	update(message, globalQueue) {}
	assignBadges(queue) {}
	doAssignBadges(globalQueue) {}

	setBadge(num, globalQueue) {
		if (this.device.platform === "ios") {
			if (!this.data.simstats_simulationOn) {
				if (this.hasPermission) {
					this.currentBadge = num;
					this.badge.set(num);
				}
			}
		}
	}

	clearBadge() {
		if (this.device.platform === "ios") {
			if (!this.data.simstats_simulationOn) {
				if (this.hasPermission) {
					this.badge.clear();
				}
			}
		}
	}

	async toast(scene, message) {
		if (scene.info) {
			if (scene.info.ad) {
				return;
			}
		}
		
		if (this.data.page_main == "chat") {
			if (this.data.page_sceneId == message.scn) {
				// in same chat
			} else {
				// in different chat
				this.doToast(scene, message, false);
				this.vibration.vibrate(100);
				this.audio.playSound("receiveMessage");
			}
		} else if (this.data.page_main == "list") {
			let storyboardId = message.scn;
			if (message.scn.indexOf("_") > -1) {
				storyboardId = message.scn.split("_")[0];
			} else {
				storyboardId = message.scn;
			}
			if (this.data.page_slide == storyboardId || this.data.page_slide == "") {
				// in same list
				this.vibration.vibrate(100);
				this.audio.playSound("receiveMessage");
			} else {
				// in different list
				this.doToast(scene, message, true,);
				this.vibration.vibrate(100);
				this.audio.playSound("receiveMessage");
			}
		} else if (this.data.page_main === "start") {
			let currentProperty = ""; await this.data.getVariable("boot_property").then(result => currentProperty = result);
			if (!message.scn.startsWith(currentProperty)) {
				this.doToast(scene, message, true);
				this.vibration.vibrate(100);
				this.audio.playSound("receiveMessage");
			}
		} else {
			if (this.data.page_main == "simulation") {
				// in simulation
			} else {
				// in other page
				this.doToast(scene, message, false);
				this.vibration.vibrate(100);
				this.audio.playSound("receiveMessage");
			}
		}
	}

	async doToast(scene, message, slideToStoryboardFirst) {
		var that = this;

		let text = message.t;
		if (text.length > 15) {
			text = text.substr(0, 15);
			text = text + "...";
		}
		if (message.usn) {
			let username; await this.data.getVariable("usr_name").then(result => username = result);
			if (username === null || username === undefined || username === "ask") { text = text.replace("[username]", "..."); }
			else { text = text.replace("[username]", username); }
		}

		let name = ""; if (message.sc) { if (message.sc.n) { name = message.sc.n; } }
		let storyboardId = undefined; if (scene.info) { if (scene.info.stb) { storyboardId = scene.info.stb; } }
		const toastItem = await this.toastController.create({
			animated: true,
			cssClass: 'EON__TOAST',
			header: name,
			message: text,
			position: "top",
			duration: 2000,
			buttons: [
				{
					icon: "close",
					side: "start"
				},{
					text: this.localization.open,
					side: "end",
					handler: async () => {
						await this.navController.navigateRoot("/start", { queryParams: { episode: storyboardId }});
						this.navController.navigateForward("/chat/", { queryParams: { sceneId: scene.info.i, progNext: true, viaHistory: false }});
					}
				}
			]
		})
		await toastItem.present();
	}

	scheduleNotifications(notifications) {
		if (this.device.platform === "ios" || this.device.platform === "android") {
			let options: ScheduleOptions = { notifications: notifications }
			LocalNotifications.schedule(options);
		}
	}

	async test() {
		LocalNotifications.schedule({
			notifications: [
				{
					id: 1,
					title: "Helpi:",
					body: "This is a test notification with 1000ms delay",
					schedule: { at: new Date(new Date().getTime() + 1000) },
					sound: "message1.wav"
				},{
					id: 2,
					title: "Helpi:",
					body: "This is a test notification with 5000ms delay",
					schedule: { at: new Date(new Date().getTime() + 5000) },
					sound: "message1.wav"
				},{
					id: 3,
					title: "Helpi:",
					body: "This is a test notification with 10000ms delay",
					schedule: { at: new Date(new Date().getTime() + 10000) },
					sound: "message1.wav"
				}
			]
		})
	}
	
}