import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Input, Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MetaService } from 'src/app/cloud/meta.service';
import { ProjectService } from '../project.service';
import { BootService } from 'src/app/system/boot.service';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.page.html',
  styleUrls: ['./metadata.page.scss'],
})
export class MetadataPage implements OnInit {
	@Input() projectId: string = "null";
	@Input() authorId: string = "null";
	@Input() asModal: any;

	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	cosmos: Array<any> = [];
	tags: Array<any> = [];
	spoilers: Array<any> = [];
	serials: Array<any> = [];
	mode: string = "cosmo";
	individualSpoiler: string = "";
	searchTerm: string = "";

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private alertController: AlertController,
		private modalController: ModalController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		private route: ActivatedRoute,
		private project: ProjectService,
		private meta: MetaService
	) { }

  	ngOnInit() {
		this.route.queryParamMap.forEach(async map => {
			this.projectId = map.get("projectId");
			if (!this.projectId) { this.projectId = "CCD9sQXTf8OxkOO8Tmn6"; }
			this.project.syncProject(this.projectId);
		})
	}

	ionViewDidEnter() {
		this.loadData();

		if (!this.project.projects[this.projectId].doc.meta) { this.project.projects[this.projectId].doc.meta = {}; }
		if (!this.project.projects[this.projectId].doc.meta.cos) { this.project.projects[this.projectId].doc.meta.cos = []; }
		if (!this.project.projects[this.projectId].doc.meta.tgs) { this.project.projects[this.projectId].doc.meta.tgs = []; }
		if (!this.project.projects[this.projectId].doc.meta.spo) { this.project.projects[this.projectId].doc.meta.spo = []; }
	}

	ionViewWillLeave() {
		this.close()
	}

	async loadData() {
		await this.meta.getCosmos().then(cosmos => {
			if (cosmos) {
				for (let cosmo of cosmos) {
					let active = false;
					for (let cosmoOfProject of this.project.projects[this.projectId].doc.meta.cos) {
						if (cosmoOfProject === cosmo.i) {
							active = true;
						}
					}
					this.cosmos.push({...cosmo, a: active });
				}
			}
		})

		await this.meta.getTags().then(tags => {
			if (tags) {
				for (let tag of tags) {
					let active = false;
					for (let tagOfProject of this.project.projects[this.projectId].doc.meta.tgs) {
						if (tagOfProject === tag) {
							active = true;
						}
					}
					this.tags.push({ t: tag, a: active })
				}
			}
		})

		await this.meta.getEpisodes().then(serials => {
			for (let serial of serials) {
				let items = [];
				for (let episode of serial.ep) {
					items.push({ ty: "ep", t: episode.t, i: episode.i, s: episode.s, e: episode.e, d: episode.d, ms: true })
				}
				this.spoilers.push({ ty: "serial", i: serial.i, items: items, t: serial.t, ms: true });
			}
		})
		await this.meta.getDiscoveries().then(discoveries => {
			let items = []
			let keys = Object.keys(discoveries);
			for (let key of keys) {
				let item = discoveries[key];
				if (item.t === "set" && item.id) { items.push({ ty: "set", i: item.id, t: item.h, d: item.d, ms: true  }); }
			}
			this.spoilers.push({ ty: "sets", i: "sets", items: items, t: "Echo Collections", ms: true });
		})
		for (let spoilerOfProject of this.project.projects[this.projectId].doc.meta.spo) {
			for (let spoilerSection of this.spoilers) {
				for (let spoilerItem of spoilerSection.items) {
					if (spoilerOfProject.i === spoilerItem.i) {
						spoilerItem.a = true;
					}
				}
			}
		}

		if (this.project.projects[this.projectId].doc.meta.ind) {
			this.individualSpoiler = this.project.projects[this.projectId].doc.meta.ind;
		}

	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	async close() {
		let message = "";
		let showAlert = false;
		if (this.project.projects[this.projectId].doc.meta.cos.length < 1) { 
			message = message + "Du hast keine Story-World ausgewählt. ";
			showAlert = true;
		}
		if (this.project.projects[this.projectId].doc.meta.tgs.length < 1) {
			message = message + "Es fehlen Tags. ";
			showAlert = true;
		}
		if (this.project.projects[this.projectId].doc.meta.spo.length < 1) { 
			message = message + "Eine Spoilerwarnung ist nicht vorhanden. ";
			showAlert = true;
		}

		if (showAlert) {
			const alert = await this.alertController.create({
				header: "Metadaten",
				subHeader: "Vollständig?",
				cssClass: 'eon__alert',
				message: message,
				buttons: [
					{
						text: "Schließen", handler: () => { this.doClose(); }
					},{ 
						text: 'Fortsetzen', handler: () => {
							this.navigation.pushCUGC_metadata(this.projectId);
						}
					}
				]
			})
			await alert.present();
		} else {
			this.doClose();
		}	
	}
	doClose() {
		this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).update({
			meta: this.project.projects[this.projectId].doc.meta
		})
	}

	toggleCosmo(cosmo) {
		if (cosmo.a) {
			cosmo.a = false;
			for (var i = 0; i < this.project.projects[this.projectId].doc.meta.cos.length; i++) {
				if (this.project.projects[this.projectId].doc.meta.cos[i] === cosmo.i) {
					this.project.projects[this.projectId].doc.meta.cos.splice(i,1);
				}
			}
		} else {
			cosmo.a = true;
			let exists = false;
			for (let cosmoOfProject of this.project.projects[this.projectId].doc.meta.cos) {
				if (cosmoOfProject === cosmo.i) {
					exists = true;
				}
			}
			if (!exists) {
				this.project.projects[this.projectId].doc.meta.cos.push(cosmo.i);
			}
		}
	}

	toggleTag(tag) {
		if (tag.a) {
			tag.a = false;
			for (var i = 0; i < this.project.projects[this.projectId].doc.meta.tgs.length; i++) {
				if (this.project.projects[this.projectId].doc.meta.tgs[i] === tag.t) {
					this.project.projects[this.projectId].doc.meta.tgs.splice(i,1);
				}
			}		
		} else {
			tag.a = true;
			let exists = false;
			for (let tagOfProject of this.project.projects[this.projectId].doc.meta.tgs) {
				if (tagOfProject.t === tag.t) {
					exists = true;
				}
			}
			if (!exists) { 
				this.project.projects[this.projectId].doc.meta.tgs.push(tag.t);
			}
		}
	}

	toggleSpoiler(spoilerSection, spoilerItem) {
		for (let section of this.spoilers) {
			if (spoilerSection.i === section.i) {
				for (let item of section.items) {
					if (item.i === spoilerItem.i) {
						if (item.a) {
							item.a = false;
							for (var i = 0; i < this.project.projects[this.projectId].doc.meta.spo.length; i++) {
								if (this.project.projects[this.projectId].doc.meta.spo[i].i === spoilerItem.i) {
									this.project.projects[this.projectId].doc.meta.spo.splice(i,1);
								}
							}
						} else {
							item.a = true;
							let exists = false;
							for (let spoilerItemOfProject of this.project.projects[this.projectId].doc.meta.spo) {
								if (spoilerItemOfProject.i === spoilerItem.i) { exists = true; }
							}
							if (!exists) { this.project.projects[this.projectId].doc.meta.spo.push({
								i: spoilerItem.i, t: spoilerItem.t, ty: spoilerItem.ty
							}); }
						}
					}
				}
			}
		}
	}

	goToPrevMode() {
		if (this.mode === "spoiler") { this.mode = "tags"; }
		else if (this.mode === "tags") { this.mode = "cosmo"; }
	}
	goToNextMode() {
		if (this.mode === "cosmo") { this.mode = "tags"; }
		else if (this.mode === "tags") { this.mode = "spoiler"; }
	}

	segmentChanged(ev) {
		this.mode = ev.detail.value;
	}

	next() {
		if (this.mode === "cosmo") { this.mode = "tags"; }
		else if (this.mode === "tags") { this.mode = "spoiler"; }
		setTimeout(() => {
			this.ionContent.scrollToTop(300);
		}, 400);
	}

	updateIndividualSpoiler() {
		this.project.projects[this.projectId].doc.meta.ind = this.individualSpoiler;		
	}

	async filterContacts() {
		let searchingFor = this.searchTerm.toLowerCase();
		let noInput = false; if (searchingFor.length <= 0) { noInput = true; }

		for (let section of this.spoilers) {
			let sectionMatchingSearch = false;
			for (let item of section.items) {
				let matching = false;
				if (noInput) { matching = true; }
				else if (item.t.toLowerCase().indexOf(searchingFor) > -1) { matching = true; }
				if (matching) { item.ms = true; sectionMatchingSearch = true; }
				else { item.ms = false; }
			}
			if (sectionMatchingSearch) { section.ms = true; }
			else { section.ms = false; }
		}
	}
	search(term) {
		this.searchTerm = term;
		this.filterContacts();
	}
	searching: boolean = false;
	minHeightSet: boolean = false;
	focusSearchbar() {
		this.searching = true;
		if (!this.minHeightSet) { this.domController.write(() => { this.renderer.setStyle(this.content.nativeElement, "minHeight", this.content.nativeElement.offsetHeight); }) }
	}
	blurSearchbar() { this.searching = false; }	

}