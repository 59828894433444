import { Injectable } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class BranchService {

	root: number = 7;
	pathway: any = null;

  	constructor() {
		setInterval(() => {
			console.log("BRANCH PATHWAY", this.pathway);
		}, 3000);
	}

	getRootItem() {
		if (this.pathway) {
			return this.pathway.find(el => el.frs)
		} else {
			return false;
		}
	}

	getItemById(itemId) {
		if (this.pathway) {
			return this.pathway.find(el => el.i === itemId)
		} else {
			return false;
		}
	}

}