import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Events } from '../../system/events.service'

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProjectService } from '../project.service';
import { BootService } from 'src/app/system/boot.service';

@Component({
	selector: 'app-scenesarchive',
	templateUrl: './scenesarchive.page.html',
	styleUrls: ['./scenesarchive.page.scss'],
  })
  export class ScenesarchivePage implements OnInit {
	@Input() asModal: boolean = false;
	@Input() projectId: string = "";

	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	usedScenes: Array<any> = [];
	archivedScenes: Array<any> = [];
	trashedScenes: Array<any> = [];
	sync_archive: any = false;
	loaded: boolean = false;
	showingTrashed: boolean = false;

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private modalController: ModalController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		private project: ProjectService,
		private events: Events,
		private route: ActivatedRoute
	) { }

  	ngOnInit() {}

	ionViewDidEnter() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			this.project.syncProject(this.projectId);
			this.project.syncScenes(this.projectId);
			this.events.subscribe(this.projectId+"_scenes", () => { this.findArchived(); })
			this.findArchived();
		})
	}

	timeout_findArchived: any = null;
	findArchived() {
		clearTimeout(this.timeout_findArchived);
		this.timeout_findArchived = setTimeout(async () => {

			if (!this.sync_archive) {
				this.usedScenes = [];
				for await (let step of this.project.projects[this.projectId].scenes) {
					if (step.s) {
						await this.addUsedScene(step.id);
					}
				}
	
				for (let step of this.project.projects[this.projectId].scenes) {
					if (step.sce) {
						let usedScene = this.usedScenes.find(element => element === step.id);
						if (!usedScene) { 
							if (step.trsh) {
								this.trashedScenes.push(step);
							} else {
								this.archivedScenes.push(step);
							}
						}
					}
				}
				this.loaded = true;
				this.sync_archive = true;
			}

		}, 1000);
	}

	async addUsedScene(stepId) {
		let step = await this.project.projects[this.projectId].scenes.find(element => element.id === stepId);
		if (step) {
			if (step.sce) {
				this.usedScenes.push(step.id);
				this.addUsedScene(step.n);
			} else {
				for (let option of step.o) {
					this.addUsedScene(option.n);
				}
			}
		}
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	close() { this.modalController.dismiss(); }

	chooseScene(scene) {
		if (this.asModal) {
			this.modalController.dismiss(scene);
		} else {
			this.navigation.pushCUGC_editor(this.projectId, scene.id);
		}
	}

	async trashScene(scene) {
		await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(scene.id).update({ trsh: true }).catch(() => {});
		let index = this.archivedScenes.findIndex(el => el.id === scene.id);
		if (index > -1) { this.archivedScenes.splice(index, 1); }
		this.trashedScenes.push(scene);
	}

	async untrashScene(scene) {
		await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(scene.id).update({ trsh: false }).catch(() => {});
		let index = this.trashedScenes.findIndex(el => el.id === scene.id);
		if (index > -1) { this.trashedScenes.splice(index, 1); }
		this.archivedScenes.push(scene);
	}

	showTrashed() {
		this.showingTrashed = true;
	}

}