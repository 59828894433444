import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonAccordionGroup } from '@ionic/angular';
import { PurchaseService } from 'src/app/cloud/purchase.service';
import { Events } from 'src/app/system/events.service';
import { InstanceService } from 'src/app/system/instance.service';
import { LocalizationService } from 'src/app/system/localization.service';
import { ModalsService } from 'src/app/system/modals.service';
import { VariasService } from 'src/app/system/varias.service';

@Component({
  selector: 'app-iaps',
  templateUrl: './iaps.page.html',
  styleUrls: ['./iaps.page.scss'],
})
export class IapsPage implements OnInit {
	@Input("variant") variant: string = "";
	@Input("subVariant") subVariant: string = "";
	@Input("contentId") contentId: string = "";
	@Input("animation") animation: string = "";
	@Input("title") title: string = "";
	@Input("subtitle") subtitle: string = "";
	@Input("text") text: string = "";
	@Input("icon") icon: string = "";
	@Input("iconColor") iconColor: string = "";
	@Input("missingTickets") missingTickets: number = 0;
	@Input("adAvailable") adAvailable: boolean = false;
	@Input("adCallback") adCallback: any = () => {}

	anim1_value: number = 0;
	anim1_buffer: number = 1;

	hasSpecial2: boolean = false;
	hasSpecial3: boolean = false;
	hasSpecial4: boolean = false;
	hasSpecial5: boolean = false;
	hasSpecial6: boolean = false;
	hasSpecial7: boolean = false;

	warnNoAnotherAd: boolean = false;

	accentUnlockNow: boolean = false;
	accentSkipNow: boolean = false;

	accordionTicketsOpen: boolean = false;
	accordionNutritionOpen: boolean = false;
	accordionTicketsValue: string = "closed";
	accordionNutritionValue: string = "closed";
	ticketsToggle: boolean = false;
	nutritionToggle: boolean = false;
	circuitsToggle: boolean = false;
	ticketsToggleLabel: string = "";
	ticketsToggleIcon: string = "unlock";

  	constructor(
		private modalController: ModalController,
		private varias: VariasService,
		public purchase: PurchaseService,
		public localization: LocalizationService,
		private events: Events,
		public instance: InstanceService
	) { }

  	ngOnInit() {
	}

	async ngAfterViewInit() {

		await this.checkSpecials();
		if (this.variant === "afterAd") {
			this.ticketsToggle = true;
			this.ticketsToggleLabel = this.localization.unlockNow;
		} else if (this.variant == "noAd") {
			if (this.subVariant === "episode") {
				this.ticketsToggle = true;
				this.ticketsToggleLabel = this.localization.unlockNow;
			} else if (this.subVariant === "bot") {
				this.circuitsToggle = true;
			}
			this.warnNoAnotherAd = true;
			this.icon = "ad";
			this.animation = "none";
			setTimeout(() => { this.animation = "shake"; }, 100)
			this.iconColor = "--DANGER";
			this.accentUnlockNow = true;
		} else if (this.variant === "purchaseOptions") { 
			setTimeout(() => {
				this.accordionTicketsValue = "open";
			}, 700);
		} else if (this.variant === "skipOptions") {
			setTimeout(() => {
				this.accordionNutritionValue = "open";
				this.ticketsToggle = true;
				this.ticketsToggleLabel = this.localization.moreOptions;
				this.ticketsToggleIcon = "box";
			}, 700)
		} else if (this.variant === "botOptions") {
			this.circuitsToggle = true;
		}
	}

	async checkSpecials() {
		if (await this.varias.getVariable("off_special2") && !await this.varias.getVariable("off_special2_wtd")) { this.hasSpecial2 = true; }
		if (await this.varias.getVariable("off_special3") && !await this.varias.getVariable("off_special3_wtd")) { this.hasSpecial3 = true; }
		if (await this.varias.getVariable("off_special4") && !await this.varias.getVariable("off_special4_wtd")) { this.hasSpecial4 = true; }
		if (await this.varias.getVariable("off_special5") && !await this.varias.getVariable("off_special5_wtd")) { this.hasSpecial5 = true; }
		if (await this.varias.getVariable("off_special6") && !await this.varias.getVariable("off_special6_wtd")) { this.hasSpecial6 = true; }
		if (await this.varias.getVariable("off_special7") && !await this.varias.getVariable("off_special7_wtd")) { this.hasSpecial7 = true; }
	}

	close() {
		this.modalController.dismiss();
	}
	
	dismissPrevented: boolean = false;
	preventDismiss() {
		this.dismissPrevented = true;
		setTimeout(() => { this.dismissPrevented = false; }, 700)
	}

	clickContent() {
		setTimeout(() => {
			if (!this.dismissPrevented) {
				this.modalController.dismiss();
			}
		})
	}

	clickCard() {
		this.preventDismiss();
	}

	watchAd() {
		this.preventDismiss();
		if (this.variant === "skipOptions") {
			if (this.adAvailable) {
				this.adCallback();
				this.modalController.dismiss({ method: "watchAd" });
			} else {
				this.warnNoAnotherAd = true;
				this.icon = "ad";
				this.animation = "none";
				setTimeout(() => { this.animation = "shake"; }, 100)
				this.iconColor = "--DANGER";
			}
		} else if (this.variant === "afterAd") {
			if (this.adAvailable) {
				this.modalController.dismiss({ method: "anotherAd" });
			} else {
				this.warnNoAnotherAd = true;
				this.accentUnlockNow = true;
				this.animation = "shake";
				this.iconColor = "--DANGER"
			}
		} else {
			this.adCallback();
			this.modalController.dismiss({ method: "watchAd" });
		}
		
	}

	order(productId) {
		this.preventDismiss();
		this.purchase.order(productId, this.variant);
		this.modalController.dismiss({ method: "order", productId: productId });
	}

	pushMembership() {
		this.preventDismiss();
		this.modalController.dismiss({ method: "membership" });
		this.events.publish("presentMembership");
	}

}