import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalizationService } from 'src/app/system/localization.service';

@Component({
  selector: 'app-episode',
  templateUrl: './episode.page.html',
  styleUrls: ['./episode.page.scss'],
})
export class EpisodePage implements OnInit {
	@Input("contentId") contentId: string = "";
	@Input("title") title: string = "";

	anim1: boolean = true;
	anim2: boolean = false;
	anim3: boolean = false;
	anim4: boolean = false;

  	constructor(
		private modalController: ModalController,
		public localization: LocalizationService
	) { }

  	ngOnInit() {
		this.anim1 = true;
		setTimeout(() => {
			this.anim2 = true;
			setTimeout(() => {
				this.anim4 = true;
			}, 250);
			setTimeout(() => {
				this.anim1 = false;
				this.anim4 = false;
			}, 750)
			setTimeout(() => {
				this.anim3 = true;
			}, 2000);
		}, 500);
	}

	clickContent() {
		this.modalController.dismiss();
	}

}