import { Component, OnInit, Input, ViewChild, Renderer2, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { DomController, } from '@ionic/angular';
import { Filesystem } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';

import { Events } from '../../system/events.service'

import { UtilityService } from 'src/app/system/utility.service';
import { DataService } from 'src/app/system/data.service';

@Component({
  selector: 'eon-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
	@Input('src') src: string;
	@Input('local') local: boolean = false;
	@Input('rectangle') rectangle: boolean = false;
	@Input('fadeIn') fadeIn: boolean = false;
	@Input('noBorderRadius') noBorderRadius: boolean = false;
	@Input('contain') contain: boolean = false;
	@Input('blur') blur: number = 0;
	@Input('translateY') translateY: number = 0;
	@Input('scale') scale: number = 1;
	@Input('scrollTop') scrollTop: number = 1;
	@Input("height") height: number = 100;
	@Input('neuo') neuo: boolean = false;
	@Input("useOuter") useOuter: boolean = true;
	@Input("invert") invert: boolean = false;
	@Input("insetshadow") insetshadow: boolean = false;
	@Input('transparent') transparent: boolean = false;
	@Input('flip') flip: boolean = false;
	@Input('contactId') contactId: string = "none";
	  
	@ViewChild('container', { static: false } ) container: any;
	@ViewChild("overlay", { static: false } ) overlay: any;
	@ViewChild("outer", { static: false } ) outer: any;

	@Output() eonLoad = new EventEmitter<any>();

	srcChanged: boolean = false;
	previousSrc: string = "";
	funnel_updateSrc: any;

  	constructor(
		private renderer: Renderer2,
		private domController: DomController,
		private util: UtilityService,
		private data: DataService,
		private events: Events,
		private domSanitizer: DomSanitizer
	) {}

	ngOnInit() {
		if (this.fadeIn) {
			this.domController.write(() => {
				this.renderer.setStyle(this.container.nativeElement, "opacity", "0");
			})
		}
	}
	  
	ngAfterViewInit() {
		if (this.local) { this.findImgLocal(); } 
		else { this.findImgServer(); }
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.src) {
			if (changes.src.currentValue != changes.src.previousValue) {
				clearTimeout(this.funnel_updateSrc);
				this.funnel_updateSrc = setTimeout(() => {
					if (this.local) { this.findImgLocal(); } 
					else { this.findImgServer(); }
				}, 400);
			}
		}

		if (changes.scrollTop) {
			let scrollTop = changes.scrollTop.currentValue;
			if (scrollTop < 0) {
				let scale = 1 + ( (0 - scrollTop) / this.height * 1.8 );
				this.domController.write(() => {
					this.renderer.setStyle(this.container.nativeElement, "transition", "0.1s");
					this.renderer.setStyle(this.container.nativeElement, "transform", "scale("+scale+")");
					this.renderer.setStyle(this.container.nativeElement, "filter", "none");
				})	
			} else {
				let percentage = scrollTop / this.height; if (percentage > 1) { percentage = 1; }
				let blur = percentage*5;
				let translateY = percentage*0.5*this.height;
				this.domController.write(() => {
					this.renderer.setStyle(this.container.nativeElement, "transition", "0.1s");
					this.renderer.setStyle(this.container.nativeElement, "transform", "translate3d(0,-"+translateY+"px,0)");
					this.renderer.setStyle(this.container.nativeElement, "filter", "blur("+blur+"px)");
				})
			}
		}
	}

	findImgLocal() {
		var imgOnServer = new Image();
		var that = this;
		imgOnServer.onload = function() { 
			that.setImg("../../assets/img/"+that.src);
		};
		imgOnServer.onerror = function(error) { that.findImgServer(); }
		try { imgOnServer.src = "../../assets/img/"+this.src; } catch {}
	}

	findImgServer() {
		var imgOnServer = new Image();
		var that = this;
		let src = this.src;
		imgOnServer.onload = function() {
			if (that.src === src) {
				that.setImg("https://avoidsociety.app/"+that.src);
			}
		};
		imgOnServer.onerror = function(error) { that.usePlaceholderImg(); }
		try { imgOnServer.src = "https://avoidsociety.app/"+this.src; } catch {}
	}

	usePlaceholderImg() {
		var imgOnServer = new Image();
		var that = this;
		imgOnServer.onload = function() {
			that.setImg("../../assets/img/misc/blanko.png");
		};
		imgOnServer.onerror = function(error) { that.usePlaceholderImg(); }
		try { imgOnServer.src = "../../assets/img/misc/blanko.png"; } catch {}
	}

	async setImg(src) {
		if (!this.srcChanged) {
			this.domController.write(() => {
				this.renderer.setStyle(this.container.nativeElement, "transition", ".4s");
				this.renderer.setStyle(this.container.nativeElement, "backgroundImage", "url('"+src+"')");
			})
			this.eonLoad.emit();
			this.srcChanged = true;
			this.previousSrc = src;
		} else {
			this.domController.write(() => {
				if (this.useOuter) {
					this.renderer.setStyle(this.outer.nativeElement, "backgroundImage", "url('"+this.previousSrc+"')");
				}
				this.renderer.setStyle(this.container.nativeElement, "opacity", "0");
			})
			this.domController.write(() => {
				this.renderer.setStyle(this.container.nativeElement, "transition", ".4s");
				this.renderer.setStyle(this.container.nativeElement, "backgroundImage", "url('"+src+"')");
				this.renderer.setStyle(this.container.nativeElement, "opacity", "1");
			})
		}
	}

}