import { Injectable } from '@angular/core';

import { Router, Event, NavigationStart, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { NavController, AlertController, ModalController, MenuController } from '@ionic/angular';
import { UtilityService } from './utility.service';
import { DataService } from './data.service';
import { MetaService } from '../cloud/meta.service';
import { LocalizationService } from '../system/localization.service'

import { Events } from '../system/events.service'
import { InstanceService } from './instance.service';
import { MenuService } from './menu.service';

import { EpisodePage } from '../episode/episode.page'
import { MembershipPage } from '../membership/membership.page'
import { Fair2playPage } from '../fair2play/fair2play.page';
import { AudioService } from './audio.service';
import { RateService } from './rate.service';
import { AdsService } from '../cloud/ads.service';
import { VariasService } from '../system/varias.service'
import { PurchaseService } from '../cloud/purchase.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

	transition: boolean = false;

	constructor(
		private router: Router,
		private navController: NavController,
		private alertController: AlertController,
		private util: UtilityService,
		private data: DataService,
		private route: ActivatedRoute,
		private meta: MetaService,
		private modalController: ModalController,
		private localization: LocalizationService,
		private events: Events,
		private instance: InstanceService,
		private menu: MenuService,
		private menuController: MenuController,
		private audio: AudioService,
		private rate: RateService,
		private ads: AdsService,
		private varias: VariasService,
		private purchase: PurchaseService
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd ) { this.transition = false; }
			if (event instanceof NavigationStart ) { this.transition = true; }
		});
		this.menu.tapMenu = (method, episodeId) => { this.tapMenu(method, episodeId); }
	}

	async tapMenu(method, contentId: string = "") {
		this.menuController.close();

		if (method === "episode") {
			this.navController.navigateRoot("/start", { queryParams: { episode: contentId } } );
		} else if (method === "story") {
			this.navController.navigateRoot("/start", { queryParams: { episode: contentId } });
		} else if (method === "blog") { this.pushBlog(); }
		else if (method === "shop") { this.pushShop(); }
		else if (method === "contacts") { this.pushContacts(); }
		else if (method === "cugc") { this.pushCUGC_catalogue("all"); }
		else if (method === "cugc_overview") { this.pushCUGC_overview(); }
		else if (method === "cugc_challenges") { this.pushCUGC_challenges(true); }
		else if (method === "cugc_friends") { this.pushCUGC_friends(); }
		else if (method === "glossary") { this.pushGlossary(); }
		else if (method === "restore") { this.pushRestore(); }
		else if (method === "settings") { this.pushSettings(); }
		else if (method === "support") { this.pushSupport(); }
		else if (method === "david") { this.pushSerial("pa"); }
		else if (method === "gamer") { await this.pushSerial("ga"); }
		else if (method === "echoes") { this.pushEchoes(); }
		else if (method === "feed") { this.navController.navigateRoot("/feed"); }
		// DEBUG
		else if (method === "play1") { this.audio.playSound("receiveMessage") }
		else if (method === "play2") { this.audio.playSound("logo") }
		else if (method === "pause") { this.audio.pauseAudio(); }
		else if (method === "resume") { this.audio.resumeAudio(); }
		else if (method === "rate") { this.rate.rate(); }
		else if (method === "sync") { this.events.publish("SYNC_findRunningStoryboards"); }
		else if (method === "showVideo") { this.ads.test(); }
		else if (method === "requestAd") { 
			this.ads.resetUsage();
			this.ads.requestAd("episode", "pa3");
		}
		else if (method === "cloudSettings") { this.events.publish("debug_cloudSettings"); }
		else if (method === "legacy") {
			this.varias.setVariable("sys_member", false);
			this.varias.setVariable("sys_wasMember", false);
			
			this.varias.setVariable("pa2_unl", false);
			this.varias.setVariable("pa2_std", false);
			this.varias.setVariable("pa2_cmp", false);

			this.varias.setVariable("pa3_unl", false);
			this.varias.setVariable("pa3_std", false);
			this.varias.setVariable("pa3_cmp", false);

			this.varias.setVariable("pa4_unl", false);
			this.varias.setVariable("pa4_std", false);
			this.varias.setVariable("pa4_cmp", false);

			this.varias.setVariable("off_special2", false);
			this.varias.setVariable("off_special2_wtd", false);
			this.varias.setVariable("off_special3", false);
			this.varias.setVariable("off_special3_wtd", false);
			this.varias.setVariable("off_special4", false);
			this.varias.setVariable("off_special4_wtd", false);
		} else if (method === "offer2") {
			this.purchase.order(this.instance.iapPrefix+"special2");
			this.varias.setVariable("off_special2", true);
			this.varias.setVariable("off_special2_wtd", false);
			this.varias.setVariable("sys_wasMember", false);
			this.varias.setVariable("sys_member", false);
			this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special2" });
		} else if (method === "offer3") {
			this.purchase.order("special3");
			this.varias.setVariable("off_special3", true);
			this.varias.setVariable("off_special3_wtd", false);
			this.varias.setVariable("sys_wasMember", false);
			this.varias.setVariable("sys_member", false);
			this.events.publish("offerSpecial", { specialId: "special3" });
		} else if (method === "offer4") {
			this.purchase.order("membership2");
			await this.varias.setVariable("off_special4", true);
			await this.varias.setVariable("off_special4_wtd", false);
			await this.varias.setVariable("sys_wasMember", false);
			await this.varias.setVariable("sys_member", false);
			if (this.purchase.products["membership2"]) {
				this.purchase.onMembershipUpdate(this.purchase.products["membership2"]);
			}
			this.events.publish("presentMembership");
			this.events.publish("offerSpecial", { specialId: "special4" });
		} else if (method === "offer5") {
			this.purchase.order("special5");
			this.varias.setVariable("off_special5", true);
			this.varias.setVariable("off_special5_wtd", false);
			this.varias.setVariable("sys_wasMember", false);
			this.varias.setVariable("sys_member", false);
			this.events.publish("offerSpecial", { specialId: "special5" });
		} else if (method === "membership2") {
			this.purchase.order("membership2");
		} else if (method === "membership3") {
			this.purchase.order("membership3");
		} else if (method === "information") {
			await this.varias.getVariable("off_special5");
			await this.varias.getVariable("off_special5_wtd");
			console.log("Special 5: " + this.varias.vars["off_special5"]);
			console.log("Special 5 WTD: " + this.varias.vars["off_special5_wtd"]);
		}
	}	

	async pushChat(sceneId, progNext, viaHistory = false, purrInfo = null) {
		if (this.data.page_main === "chat") {
			await this.navController.pop();
		}
		this.navController.navigateForward("/chat/", { queryParams: { sceneId: sceneId, progNext: progNext, viaHistory: viaHistory, purrInfo: purrInfo }});
	}

	async pushCUGC_applies() { this.navController.navigateForward("/quest_applies", {}); }
	async pushCUGC_author(authorId) { this.navController.navigateForward("/quest_author", { queryParams: { authorId: authorId } }); }
	async pushCUGC_chat(projectId) { this.navController.navigateForward("/quest_chat", { queryParams: { projectId: projectId } }); }
	async pushCUGC_catalogue(mode: string = "featured") { this.navController.navigateForward("/quest_catalogue", { queryParams: {} }); }
	async pushCUGC_challenge(challengeId) { this.navController.navigateForward("/quest_challenge", { queryParams: { challengeId: challengeId, feed: false } }); }
	async pushCUGC_challenges(collaborate) { this.navController.navigateForward("/quest_challenges", { queryParams: { collaborate: collaborate } }); }
	async pushCUGC_collabs(projectId) { this.navController.navigateForward("/quest_collabs", { queryParams: { projectId: projectId } }); }
	async pushCUGC_collection(collectionId) { this.navController.navigateForward("/quest_collection", { queryParams: { collectionId: collectionId } }) }
	async pushCUGC_collections() { this.navController.navigateForward("/quest_collections", {}); }
	async pushCUGC_collectionadmin(collectionId) { this.navController.navigateForward("/quest_collectionadmin", { queryParams: { collectionId: collectionId } }); }
	async pushCUGC_context(projectId) { this.navController.navigateForward("/context", { queryParams: { projectId: projectId } }); }
	async pushCUGC_comments(contentId) { this.navController.navigateForward("/quest_comments", { queryParams: { contentId: contentId } }); }
	async pushCUGC_contacts(projectId) { this.navController.navigateForward("/quest_contacts", { queryParams: { projectId: projectId } }); }
	async pushCUGC_content(projectId, contentId, type) { this.navController.navigateForward("/quest_content", { queryParams: { projectId: projectId, contentId: contentId, type: type } }); }
	async pushCUGC_covers(projectId) { this.navController.navigateForward("/quest_covers", { queryParams: { projectId: projectId } }); }
	async pushCUGC_drafting(projectId) { this.navController.navigateForward("/quest_drafting", { queryParams: { projectId: projectId } }); }
	async pushCUGC_editor(projectId, sceneId) { this.navController.navigateForward("/quest_editor", { queryParams: { projectId: projectId, sceneId: sceneId } }); }
	async pushCUGC_export(projectId) { this.navController.navigateForward("/quest_export", { queryParams: { projectId: projectId } }); }
	async pushCUGC_friends() { this.navController.navigateForward("/quest_friends", {}); }
	async pushCUGC_friendslist(projectId=null, mode="normal") { this.navController.navigateForward("/quest_friendslist", { queryParams: { projectId: projectId, mode: mode } }); }
	async pushCUGC_invites() { this.navController.navigateForward("/quest_invites", {}); }
	async pushCUGC_metadata(projectId) { this.navController.navigateForward("/quest_metadata", { queryParams: { projectId: projectId } }); }
	async pushCUGC_music(projectId, sceneId, previousTrackId) { this.navController.navigateForward("/quest_music", { queryParams: { projectId: projectId, sceneId: sceneId, previousTrackId: previousTrackId } }); }
	async pushCUGC_project(projectId) { this.navController.navigateForward("/quest_project", { queryParams: { projectId: projectId } }); }
	async pushCUGC_projects() { this.navController.navigateForward("/quest_projects", {}); }
	async pushCUGC_rights(projectId, coAuthorId) { this.navController.navigateForward("/quest_rights", { queryParams: { projectId: projectId, coAuthorId: coAuthorId } }); }
	async pushCUGC_scenes(projectId) { this.navController.navigateForward("/quest_scenes", { queryParams: { projectId: projectId } }); }
	async pushCUGC_scenesarchive(projectId) { this.navController.navigateForward("/quest_scenesarchive", { queryParams: { projectId: projectId } }); }
	async pushCUGC_sequencer(projectId, sceneId) { this.navController.navigateForward("/sequencer", { queryParams: { projectId: projectId, sceneId: sceneId } }); }
	async pushCUGC_overview() { this.navController.navigateForward("/quest_overview", {}); }

	async pushChall(challengeId, feed, feedItemId) {
		this.navController.navigateForward("/feed/chall", { queryParams: { challengeId: challengeId, feed: feed, feedItemId: feedItemId } });
	}

	async pushTruck() { this.navController.navigateForward("/truck", {}); }

	async pushSerials() { await this.navController.navigateRoot("/serials"); }
	async pushSerial(serialId, season: number = 0, episodeId: string = null) { await this.navController.navigateForward("/serial", { queryParams: { serialId: serialId, season: season, episodeId: episodeId }}); }

	pushMixin(contents, initialId) {}

	pushHistory(storyboardId, contactId) {
		this.navController.navigateForward("/history", { queryParams: { storyboardId: storyboardId, contactId: contactId }});
	}

	pushContact(contact) {
		if (contact.i.indexOf('alt') > -1) { contact.i = contact.i.replace("alt", ""); }
		this.navController.navigateForward("/contact2/", { queryParams: { contactId: contact.i, contact: contact } });
	}
	pushContactById(contactId) {
		if (contactId.indexOf('alt') > -1) { contactId = contactId.replace("alt", ""); }
		this.navController.navigateForward("/contact2/", { queryParams: { contactId: contactId } });
	}

	pushList() { this.navController.navigateRoot("/list"); }
	pushStart() { this.navController.navigateRoot("/start"); }
	pushBot() { this.navController.navigateForward("/bot"); }
	pushContacts() { this.navController.navigateForward("/contacts"); }
	pushBlog() { this.navController.navigateForward("/blog"); }
	pushQuestEditor() { this.navController.navigateBack("/quest_overview"); }
	pushShop() { this.navController.navigateForward("/shop"); }
	pushAccount() { this.navController.navigateForward("/account"); }
	pushSimulation(episodeId) { this.navController.navigateForward("/simulation", { queryParams: { episodeId: episodeId } }); }
	pushHelpi() { this.navController.navigateForward("/contact/", { queryParams: { contact: { i: "helpi", n: "Help-Bot", im: "helpi.jpg" } }}); }
	pushGlossary() { this.navController.navigateForward("/glossary"); }
	pushEchoes() { this.navController.navigateForward("/echoes"); }
	pushSettings() { this.navController.navigateForward("settings"); }
	pushSearch() { this.navController.navigateForward("/search"); }
	pushPrivacy() { this.navController.navigateForward("/privacy"); }
	pushLanguage() { this.navController.navigateForward("/language"); }

	pushSupport() { this.navController.navigateForward("/support", { queryParams: { category: "support" } }); }
	pushRestore() { this.navController.navigateForward("/support", { queryParams: { category: "restore" } }); }

	pushScheme() { this.navController.navigateForward("/scheme"); }

	pop(backToPage: string = "start") {
		if (this.data.navigationEndCount > 1) {
			this.navController.pop();
		} else {
			if (backToPage === "start") { this.navController.navigateRoot("/start");  }
			else if (backToPage === "catalogue") { this.navController.navigateRoot("/quest_catalogue") }
			else { this.navController.navigateRoot("/start"); }
		}
	}

	async presentAlert(header, message) {
		const alert = await this.alertController.create({
			subHeader: header,
			cssClass: 'eon__alert',
			message: message,
			buttons: ['OK']
		});
		await alert.present();
	}

	async pushLink(route) {
		if (this.data.page_main === "chat") {
			await this.navController.pop();
		}
		if (route.startsWith("/start")) {
			this.navController.navigateRoot(route);
		} else {
			this.navController.navigateForward(route);
		}
	}
}
