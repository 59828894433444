import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './button.component'

@NgModule({
    declarations: [ButtonComponent],
    exports: [ButtonComponent ],
    imports: [CommonModule, IonicModule]
})
export class ButtonModule{}