import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { DomController } from '@ionic/angular';
import { AudioService } from 'src/app/system/audio.service';
import { VibrationService } from 'src/app/system/vibration.service';
import { Platform } from '@ionic/angular';

@Component({
  	selector: 'eon-button',
  	templateUrl: './button.component.html',
  	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	@Input('title') title: any;
	@Input('full') full: any;
	@Input('size') size: any;
	@Input('smallFont') smallFont: any;
	@Input('color') color: any;
	@Input('noMargin') noMargin: boolean = false;
	@Input('disabled') disabled: boolean = false;
	@Input('icon') icon: string = "none";
	@Input('position') position: string = "center";

	@ViewChild('containerButton', { static: false } ) containerButton: ElementRef;

	@Output() eonClick = new EventEmitter<any>();

  	constructor(
		private audio: AudioService,
		private vibration: VibrationService,
		private platform: Platform,
		private domCtrl: DomController,
		private renderer: Renderer2
	) {}

  	ngOnInit() {
		if (this.smallFont === undefined) {
			if (this.size != "small") {
				if (this.title.length > 15) { this.smallFont = true; }
				else { this.smallFont = false; }
			}
		}
	}

	touchstart() { this.vibration.touchstart(); }
	touchend() { this.vibration.touchend(); }

	click() {
		this.eonClick.emit();
	}

}