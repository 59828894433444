import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';

import { SplashScreen } from '@capacitor/splash-screen';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';

import { BrowserService } from '../system/browser.service';
import { VariasService } from '../system/varias.service';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['./privacy.page.scss'],
})
export class PrivacyPage implements OnInit {
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	@Input("asModal") asModal: boolean = false;

	loaded: boolean = false;
	hasPermission: boolean = false;
	ageCheck: boolean = false;
	revokeOption: boolean = false;
	consentDate: any = false;

  	constructor(
		private domController: DomController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private iab: BrowserService,
		private varias: VariasService,
		private alertController: AlertController,
		private modalController: ModalController
	) { }

  	ngOnInit() {
		SplashScreen.hide();
	}

	async ionViewDidEnter() {
		await this.varias.getVariable("sys_adsGranted").then(result => { 
			if (result) { 
				this.hasPermission = true;
				this.ageCheck = true;
				this.revokeOption = true;
				this.consentDate = new Date(result);
			}
			this.loaded = true;
		})
	}

	async toggleAgeCheck() { 
		if (this.ageCheck) {
			if (this.hasPermission) {
				const alert = await this.alertController.create({
					subHeader: "Alter",
					message: "Möchtest du deine Zustimmung widerrufen?",
					cssClass: "eon__alert",
					buttons: [
						{ text: this.localization.cancel, handler: () => {} },
						{ text: this.localization.yes, handler: () => { this.revokeConsent(); }}
					]
				})
				await alert.present();
			} else {
				this.ageCheck = false;
			}
		} else {
			this.ageCheck = true;
		}
	}

	async grantConsent() {
		if (this.hasPermission) {
			this.revokeConsent();
		} else {
			if (this.ageCheck) {
				this.hasPermission = true;
				let now = Date.now();
				this.varias.setVariable("sys_adsGranted", now);
				this.close();
			} else {		
				const alert = await this.alertController.create({
					subHeader: "Alter",
					message: "Bitte bestätige zuerst, ob du 16 Jahre oder älter bist.",
					cssClass: 'eon__alert',
					buttons: [ { text: 'Ok', handler: (input) => {} } ]
				})
				alert.present();
			}
		}
	}

	async revokeConsent() {
		this.varias.setVariable("sys_adsGranted", false);
		this.hasPermission = false;
		this.ageCheck = false;
		this.revokeOption = false;
		this.consentDate = false;
	}

	showPrivacyPolicy() {
		if (this.localization.lang === "DE") { const browser = this.iab.create('https://www.iubenda.com/privacy-policy/95578647', '_blank', 'location=yes'); } 
		else { const browser = this.iab.create('https://www.iubenda.com/privacy-policy/60316547', '_blank', 'location=yes'); }
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	close() {
		if (this.asModal) { this.modalController.dismiss({ permission: this.hasPermission }); }
		else { this.navigation.pop(); }
	}

}