import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';
import { MetaService } from 'src/app/cloud/meta.service'

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BootService } from 'src/app/system/boot.service';

@Component({
	selector: 'app-canoncontacts',
	templateUrl: './canoncontacts.page.html',
	styleUrls: ['./canoncontacts.page.scss'],
  })
  export class CanoncontactsPage implements OnInit {
	@Input() asModal: boolean = true;
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	projectId: string = "";
	authorId: string = "";
	scenes: Array<any> = [];
	contacts: Array<any> = [];
	searchTerm: string = "";

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private modalController: ModalController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		private route: ActivatedRoute,
		private meta: MetaService
	) {}

  	ngOnInit() {}

	ionViewDidEnter() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			if (!this.projectId) { this.projectId = "CCD9sQXTf8OxkOO8Tmn6"; }
			this.authorId = map.get("authorId");
			if (!this.authorId) { this.authorId = "1234567"; }
		})

		this.loadContacts();
	}

	async loadContacts() {
		await this.meta.getContacts().then(contacts => {
			for (let contact of contacts) {
				if (contact.i === "helpBot") { contact.i = "helpBot_alt"; }
				contact.matchingSearch = true;
			}
			this.contacts = contacts;
		})
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	close() {
		if (this.asModal) { this.modalController.dismiss(); }
		else { this.navigation.pop(); }
	}

	async filterContacts() {
		let searchingFor = this.searchTerm.toLowerCase();
		let noInput = false; if (searchingFor.length <= 0) { noInput = true; }

		for (let contact of this.contacts) {
			let matching = false;
			if (noInput) { matching = true; }
			else if (contact.n.toLowerCase().indexOf(searchingFor) > -1) { matching = true; }
			if (matching) { contact.matchingSearch = true; }
			else { contact.matchingSearch = false; }
		}
	}
	search(term) {
		this.searchTerm = term;
		this.filterContacts();
	}
	searching: boolean = false;
	minHeightSet: boolean = false;
	focusSearchbar() {
		this.searching = true;
		if (!this.minHeightSet) { this.domController.write(() => { this.renderer.setStyle(this.content.nativeElement, "minHeight", this.content.nativeElement.offsetHeight); }) }
	}
	blurSearchbar() { this.searching = false; }	

	selectContact(contact) {
		if (contact.matchingSearch) {
			this.modalController.dismiss(contact);
		}
	}

}