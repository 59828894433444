import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { Platform, DomController, IonContent, LoadingController, IonSlides, ModalController, AlertController } from '@ionic/angular';
import { Route, ActivatedRoute, Router } from '@angular/router';

import { SplashScreen } from '@capacitor/splash-screen';

import { MetaService } from '../cloud/meta.service';
import { DataService } from '../system/data.service';
import { EngineService } from '../system/engine.service';
import { NavigationService } from '../system/navigation.service';
import { VariasService } from '../system/varias.service';
import { ThemeService } from '../system/theme.service';
import { UtilityService } from '../system/utility.service'
import { BrowserService } from '../system/browser.service';
import { LocalizationService } from '../system/localization.service';
import { AdsService } from '../cloud/ads.service';
import { PurchaseService } from '../cloud/purchase.service'

import { Events } from '../system/events.service'
import { InstanceService } from '../system/instance.service';
import { ModalsService } from '../system/modals.service';
import { PurrchaseService } from '../cloud/purrchase.service';
import { MolertsService } from '../system/molerts.service';

@Component({
selector: 'app-episode',
  templateUrl: './episode.page.html',
  styleUrls: ['./episode.page.scss'],
})
export class EpisodePage implements OnInit {
	@ViewChild('outerContent', { static: false } ) outerContent: any;
	@ViewChild('logoHeader', { static: false } ) logoHeader: ElementRef;
	@ViewChild('logoMain', { static: false } ) logoMain: ElementRef;
	@ViewChild('backdrop', { static: false } ) backdrop: ElementRef;
	@ViewChild('logo', { static: false } ) logo: ElementRef;
	@ViewChild('slides', { static: false } ) slides: IonSlides;
	@ViewChild(IonContent, { static: false } ) ionContent: IonContent;
	@ViewChild('backButton', { static: false } ) backButton: ElementRef;
	@ViewChild('downButton', { static: false } ) downButton: ElementRef;

	@Input('episode') episode: string = null;
	@Input('echo') echo: string = null;
	@Input('set') set: string = null;
	@Input('season') season: string = null;

	@Input('serialId') serialId: any = null;
	@Input('seasonNr') seasonNr: number = 1;
	@Input('episodeNr') episodeNr: number = 1;
	@Input('echoId') echoId: any = null;
	@Input('setId') setId: any = null;
	@Input('showProduct') showProduct: any = false;
	@Input('initialId') initialId: any = null;
	@Input('constellation') constellation: any = null;
	@Input('mode') mode: any = "episode";
	@Input('seasonPack') seasonPack: any = null;

	storyboard: any = {};
	allContacts: Array<any> = [];
	chats: Array<any> = [];
	completed: boolean = false;
	started: boolean = false;
	running: boolean = false;
	cover: any;
	title: string = "";
	logoMaxTranslateY: number = 0;
	setTitle: string = "Echoreihe";
	initialSlide: number = 0;
	asModal: boolean = false;

	episodeId = null;
	contents: Array<any> = []
	initialized: boolean = false;

	funnel_updateEpisodeChats: any = null;
	funnel_updateEchoChats: any = null;
	eventSubscribe_updateEpisodeChats: any = undefined;
	eventSubscribe_updateEchoChats: any = undefined;
	eventSubscribe_closeAllModals: any = undefined;

	wobble: boolean = true;

	event_updateStoryboardsOverview: any;
	event_updateEchoesOverview: any;
	event_unlockSet: any;
	event_unlockMembership: any;

	setPack: any = {};
	hasPurchasedSeason: boolean = false;
	hasPurchasedMembership: boolean = false;
	hasPurchasedSet: boolean = false;
	hasSetPack: boolean = false;

	hasSpecial2: boolean = false;
	hasSpecial3: boolean = false;
	hasSpecial4: boolean = false;
	hasSpecial5: boolean = false;
	hasSpecial6: boolean = false;
	hasSpecial7: boolean = false;

  	constructor(
		private platform: Platform,
		private route: ActivatedRoute,
		private meta: MetaService,
		public data: DataService,
		public engine: EngineService,
		public navigation: NavigationService,
		public varias: VariasService,
		private renderer: Renderer2,
		public theme: ThemeService,
		private loadingController: LoadingController,
		private events: Events,
		private util: UtilityService,
		private iab: BrowserService,
		public localization: LocalizationService,
		private modalController: ModalController,
		private alertController: AlertController,
		public ads: AdsService,
		public purchase: PurchaseService,
		public instance: InstanceService,
		private modals: ModalsService,
		private purrchase: PurrchaseService,
		private molerts: MolertsService
	) {
		this.logoMaxTranslateY = this.data.innerHeight/2-this.data.innerWidth*0.4;
	}

	ngOnInit() {
		SplashScreen.hide();
		
		this.route.queryParamMap.subscribe(param => {
			this.processRoute(param);
		})	

		this.varias.getVariable("sys_member");
		this.varias.getVariable("sys_tickets");

		//this.getSeasonPack();

		this.checkSpecials();

		this.events.subscribe("purchaseMade", (productId) => {
			if (productId.startsWith("ep_unlock_")) {
				for (let content of this.contents) {
					if (productId === "ep_unlock_"+content.i) {
						content.hasPacks = false;
						content.hasAds = false;
						content.hasPurchased = true;
					}
				}
			}
			if (this.seasonPack) {
				if (productId === this.seasonPack.id) {
					for (let content of this.contents) {
						content.hasPacks = false;
						content.hasAds = false;
						content.hasPurchased = true;
						this.hasPurchasedSeason = true;
					}				
				}
			}
			if (productId === "membership" || productId === "membership2") {
				for (let content of this.contents) {
					content.hasPacks = false;
					content.hasAds = false;
					content.hasPurchased = true;
					this.hasPurchasedMembership = true;
				}
			}
			if (this.setPack) {
				if (productId === this.setPack.id) {
					for (let content of this.contents) {
						this.hasPurchasedSet = true;
					}
				}
			}
		
		})
	}

	async checkSpecials() {
		if (await this.varias.getVariable("off_special2") && !await this.varias.getVariable("off_special2_wtd")) { this.hasSpecial2 = true; }
		if (await this.varias.getVariable("off_special3") && !await this.varias.getVariable("off_special3_wtd")) { this.hasSpecial3 = true; }
		if (await this.varias.getVariable("off_special4") && !await this.varias.getVariable("off_special4_wtd")) { this.hasSpecial4 = true; }
		if (await this.varias.getVariable("off_special5") && !await this.varias.getVariable("off_special5_wtd")) { this.hasSpecial5 = true; }
		if (await this.varias.getVariable("off_special6") && !await this.varias.getVariable("off_special6_wtd")) { this.hasSpecial6 = true; }
		if (await this.varias.getVariable("off_special7") && !await this.varias.getVariable("off_special7_wtd")) { this.hasSpecial7 = true; }
	}

	/*
	async getSeasonPack() {
		if (this.instance.instance === "gamerapp") {
			await this.varias.getVariable("off_special5");
			await this.varias.getVariable("off_special5_wtd");
			if (this.varias.vars["off_special5"] && !this.varias.vars["off_special5_wtd"]) {
				this.hasSpecial5 = true;
			}
		}
		if (this.hasSpecial5) {
			this.seasonPack = this.purchase.products["special5"];
		} else {
			if (!this.seasonPack) {
				let seasonId = "null";
				if (this.serialId === "pa") { seasonId = "par"+this.seasonNr; } 
				else if (this.serialId === "ga") { seasonId = "gam"+this.seasonNr; }
				this.purrchase.findSeasonPackProduct(seasonId).then(result => {
					if (result.productFound) {
						if (!result.seasonFull) {
							this.seasonPack = result.product;
						}
					}
				})
			}
		}
	}
	*/

	async processRoute(param) {
		if (this.serialId && this.seasonNr && this.episodeNr) {
			this.asModal = true;
			this.bootSeason(this.serialId, this.seasonNr, this.episodeNr, "season");
			return;
		}		

		if (!this.season) { this.season = param.get("season"); this.asModal = true; }
		if (this.season) {
			let serialId = this.season.substring(0, 2);
			let seasonNr = parseInt(this.season[3]);
			this.bootSeason(serialId, seasonNr, 1, "season");
			return
		}
		this.asModal = false;

		if (!this.episode) { 
			this.episode = param.get("episode"); this.asModal = true;
		}
		if (this.episode) {
			let allSeries = []; await this.meta.getEpisodes().then( result => allSeries = result );
			for (let series of allSeries) {
				if (this.episode.startsWith(series.i)) {
					for (let episode of series.ep) {
						if (episode.i === this.episode) {
							this.bootSeason(series.i, episode.s, episode.e, "season");
							break;
						}
					}
				}
			}
			return
		}

		if (!this.echo) { this.echo = param.get("echo"); this.asModal = true; }
		if (this.echo) {
			this.bootSetByEchoId(this.echo);
			return
		}
		this.asModal = false;

		if (!this.set) { this.set = param.get("set"); this.asModal = true; }
		if (this.set) {
			this.bootSet(this.set, 0);
			return
		}
		this.asModal = false;

		if (this.serialId) {
			this.asModal = true;
			this.bootSeason(this.serialId, this.seasonNr, this.episodeNr, "season");
			return
		}

		if (this.echoId) {
			this.asModal = true;
			if (this.constellation === "set") { 
				this.bootSetByEchoId(this.echoId);
				if (this.showProduct) {
					if (this.purchase.products["st_unlock_"+this.setId]) {
						this.setPack = this.purchase.products["st_unlock_"+this.setId];
						this.hasSetPack = true;
						
					}
				}
			} else {
				this.bootEchoUnsubmitted(this.echoId);
			}
			return
		}

		if (this.setId && this.mode === "set" && this.echoId) {
			this.asModal = true;
			this.bootSet(this.setId, this.echoId);
			return
		}
	}

	ionViewDidEnter() {
		this.subscribeOverviewsUpdates();
	}
	ionViewWillLeave() {
		this.unsubscribeOverviewsUpdates();
		if (this.event_unlockSet) { this.event_unlockSet.unsubscribe(); }
		if (this.event_unlockMembership) { this.event_unlockMembership.unsubscribe(); }
	}

	subscribeOverviewsUpdates() {
		this.event_updateStoryboardsOverview = this.events.subscribe("updateStoryboardsOverview", () => { this.handleEvent_updateEpisodeChats(); })
		this.willUpdateEpisodeChats();

		this.event_updateEchoesOverview = this.events.subscribe("updateEchoesOverview", () => { this.handleEvent_updateEchoChats(); })
		this.willUpdateEchoChats();		
	}
	unsubscribeOverviewsUpdates() {
		this.event_updateStoryboardsOverview.unsubscribe();
		this.eventSubscribe_updateEpisodeChats = undefined;

		this.event_updateEchoesOverview.unsubscribe();
		this.eventSubscribe_updateEchoChats = undefined;
	}
	handleEvent_updateEpisodeChats() { 
		this.willUpdateEpisodeChats();
	}
	handleEvent_updateEchoChats() { this.willUpdateEchoChats(); }
	willUpdateEpisodeChats() { clearTimeout(this.funnel_updateEpisodeChats); this.funnel_updateEpisodeChats = setTimeout(() => { this.doUpdateEpisodeChats(); }, 500); }
	willUpdateEchoChats() { clearTimeout(this.funnel_updateEchoChats); this.funnel_updateEchoChats = setTimeout(() => { this.doUpdateEchoChats(); }, 500); }
	async doUpdateEpisodeChats() {
		if (this.slides) {
			let activeIndex = 0; await this.slides.getActiveIndex().then(result => activeIndex = result);
			if (this.contents[activeIndex]) {
				await this.varias.getVariable(this.contents[activeIndex].i+"_std").then(result => this.contents[activeIndex].started = result);
				await this.varias.getVariable(this.contents[activeIndex].i+"_cmp").then(result => this.contents[activeIndex].completed = result);
				let storyboardId = this.contents[activeIndex].i;
				this.engine.createStoryboardsOverview(storyboardId).then(episodesOfOverview => {
					for (let episodeOfOverview of episodesOfOverview) {
						if (episodeOfOverview.i === storyboardId) {
	
							for (let updatedChat of episodeOfOverview.chats) {
								let chatAlreadyThere = false;
								if (this.contents[activeIndex].chats) {
									for (let existingChat of this.contents[activeIndex].chats) {
										if (existingChat.i == updatedChat.i) {
											chatAlreadyThere = true;
											if (updatedChat.touched >= existingChat.touched) {
												existingChat.hasSecondaryContact = updatedChat.hasSecondaryContact;
												existingChat.lastCompletedScene = updatedChat.lastCompletedScene;
												existingChat.lastScene = updatedChat.lastScene;
												existingChat.latestMessage = updatedChat.latestMessage;
												existingChat.secondaryContactId = updatedChat.secondaryContactId;
												existingChat.secondaryContactImage = updatedChat.secondaryContactImage;
												existingChat.secondaryContactName = updatedChat.secondaryContactName;
												existingChat.touched = updatedChat.touched;
												existingChat.unreadMessages = updatedChat.unreadMessages;
												existingChat.typing = updatedChat.typing;
												existingChat.startTyping = updatedChat.startTyping;
												existingChat.waiting = updatedChat.waiting;
											}
										}
									}
								}
								if (!chatAlreadyThere) {
									if (!this.contents[activeIndex].chats) { this.contents[activeIndex].chats = []; }
									this.contents[activeIndex].chats.unshift(updatedChat);
								}
							}
	
						}
					}
				})
			}
		}		
	}
	async doUpdateEchoChats() {
		if (this.slides) {
			let activeIndex = 0; await this.slides.getActiveIndex().then(result => activeIndex = result);
			let content = this.contents[activeIndex];
			if (content) {
				if (content.type === "echo") {
					await this.engine.getCachedScene(content.i).then(cachedScene => {
						if (cachedScene) {
							if (cachedScene.messages) {
								let latestMessage: any = { blank: true, at: 0 }
								for (let message of cachedScene.messages) {
									if (message.sent) {
										if (message.at > latestMessage.at) {
											latestMessage = message;
										}
									}
								}
								if (!latestMessage.blank) {
									content.latestMessage = {
										title: content.n,
										subtitle: latestMessage.c.n,
										message: latestMessage.t,
										pic: "cover/square/"+content.i+".jpg",
										minipic: "contacts/"+latestMessage.c.i+".jpg",
										at: latestMessage.at
									}
								}
							}
						}
					})
				}
			}
		}
	}

	async bootSet(setId, initialEchoId) {
		let set = await this.meta.getSet(setId);
		this.setTitle = set.n;
		let i = 0;
		let initialEchoIndex = 0;
		for (let echo of set.e) {
			this.contents.push({ ...echo, type: "echo" });
			if (echo.i === initialEchoId) { initialEchoIndex = i; }
			i++;
		}

		if (initialEchoId) { this.initialSlide = initialEchoIndex; }
		setTimeout(() => {
			this.slides.slideTo(this.initialSlide);
			if (this.initialSlide >= this.contents.length-1) { this.wobble = false; }
		}, 500)

		
		this.event_unlockSet = this.events.subscribe("unlockSet_"+setId, () => {
			if (this.contentTriedToStart) { this.start(this.contentTriedToStart); }
			this.event_unlockSet.unsubscribe();
			this.event_unlockMembership.unsubscribe();
		})
		this.event_unlockMembership = this.events.subscribe("unlockMembership", async () => {
			//if (this.contentTriedToStart) { this.start(this.contentTriedToStart); }
			this.event_unlockSet.unsubscribe();
			this.event_unlockMembership.unsubscribe();
			const alert = await this.alertController.create({
				header: this.localization.membership,
				subHeader: this.localization.congratulations,
				cssClass: 'eon__alert',
				message: this.localization.purchase_membership_congrats,
				buttons: [
					{ text: 'Awesome!', handler: (input) => {} }
				]
			})
			alert.present();			
		})
	}

	async bootSetByEchoId(echoId) {
		let sets = []; await this.meta.getSets().then(allSets => sets = allSets);
		let setFound = false;
		for (let set of sets) {
			for (let echo of set.e) {
				if (echo.i === echoId) {
					this.bootSet(set.i, echoId);
					setFound = true;
				}
			}
		}
		if (!setFound) { this.bootEchoUnsubmitted(echoId); }
	}

	async bootEchoSubmitted(echo) {
		this.setTitle = "Echo";
		this.contents.push({ ...echo, type: "echo" });
		this.slides.lockSwipes(true);
	}

	async bootEchoUnsubmitted(echoId) {
		this.setTitle = "Echo";
		let echoes = []; await this.meta.getEchoes_All().then(allEchoes => echoes = allEchoes);
		for (let echo of echoes) {
			if (echo.i === echoId) {
				this.contents.push({ ...echo, type: "echo" });
			}
		}
		this.slides.lockSwipes(true);


		let echoInfo: any = {}; await this.meta.getEchoInfo(echoId).then(result => echoInfo = result);
		this.contents.push({...echoInfo, type: "echo"});
	}

	async bootSeason(serialId, seasonNr, episodeNr, constellation) {
		let allSeries = []; await this.meta.getEpisodes().then( result => allSeries = result );
		for (let series of allSeries) {
			if (series.i === serialId) {
				for (let episode of series.ep) {
					if (episode.s === seasonNr) { 
						if (constellation === "single") {
							if (episode.e === episodeNr) {
								this.contents.push({ ...episode, type: "episode" });
							}
						} else {
							this.contents.push({ ...episode, type: "episode" });
						}
					}
				}
			}
		}

		if (constellation === "single") {
			this.slides.lockSwipes(true);
		} else {
			let initialIndex = 0;
			if (isNaN(episodeNr)) {
				let i = 0;
				for await (let episode of this.contents) {
					if (episode.i === episodeNr) {
						initialIndex = i;
					}
					i++;
				}
			} else {
				if (episodeNr != null) {
					initialIndex = episodeNr-1;
				} else {
					let i = 0;
					for await (let episode of this.contents) {
						await this.varias.getVariable(episode.i+"_cmp").then(result => {
							if (result) {  episode.completed = true; } 
							else { if (i < initialIndex) { initialIndex = i; } }
						})
						i++;
					}
				}
			}
			this.initialSlide = initialIndex;
			setTimeout(() => {
				this.slides.slideTo(this.initialSlide);
				if (this.initialSlide >= this.contents.length-1) { this.wobble = false; }
			}, 500)
		}
	}

	async bootMixin(contents, initialId) {}

	async setSlide(event) {
		this.wobble = false;
		this.doUpdateEchoChats();
		this.doUpdateEpisodeChats();

		if (this.slides) {
			let index = 0; await this.slides.getActiveIndex().then(result => index = result);
			if (this.contents[index]) {
				if (this.contents[index].scrollTop) {
					if (this.contents[index].scrollTop > this.data.innerHeight-100) {
						this.renderer.setStyle(this.backButton.nativeElement, "opacity", "0");
					} else { 
						this.renderer.setStyle(this.backButton.nativeElement, "opacity", "1")
					}
					if (this.contents[index].scrollTop > 0) {
						this.renderer.setStyle(this.downButton.nativeElement, "transform", "translateX(200px)");
					} else {
						this.renderer.setStyle(this.downButton.nativeElement, "transform", "translateX(0px)");
					}
				} else { 
					this.renderer.setStyle(this.backButton.nativeElement, "opacity", "1")
					this.renderer.setStyle(this.downButton.nativeElement, "transform", "translateX(0px)");
				}
			} else { 
				this.renderer.setStyle(this.backButton.nativeElement, "opacity", "1")
				this.renderer.setStyle(this.downButton.nativeElement, "transform", "translateX(0px)");
			}
		}
	}

	async scroll(content, event) {
		//this.wobble = false;
		let logo = document.getElementById("title_"+content.i);
		let limit = this.data.innerHeight/2-10;
		if (content.type === "episode") { limit = this.data.innerHeight*0.4; }
		if (event.detail.scrollTop > limit) {
			let translateY = event.detail.scrollTop - this.data.innerHeight/2+10;
			if (content.type === "episode") { translateY = event.detail.scrollTop - this.data.innerHeight*0.4; }
			logo.style.transform = "translateY(-"+translateY+"px)";
		} else { logo.style.transform = "none"; }

		this.renderer.setStyle(this.downButton.nativeElement, "transform", "translateX(200px)");

		content.scrollTop = event.detail.scrollTop;
		if (event.detail.scrollTop > this.data.innerHeight-100) { 
			this.renderer.setStyle(this.backButton.nativeElement, "opacity", "0");
		}
		else { this.renderer.setStyle(this.backButton.nativeElement, "opacity", "1"); }
		
		if (!content.loading) {
			await this.varias.getVariable(content.i+"_std").then(result => content.started = result);
			await this.varias.getVariable(content.i+"_cmp").then(result => content.completed = result);
			await this.varias.getVariable(content.i+"_unl").then(result => content.unlocked = result);
			if (content.type === "episode") {
				if (!content.storyboard) { await this.loadStoryboard(content); }
			} else if (content.type === "echo") {
				await this.loadEcho(content);
			}
		}

		let upperInfoEl = document.getElementById("upperInfo_"+content.i);
		if (upperInfoEl) { upperInfoEl.style.opacity = "0"; }
		let lowerInfoEl = document.getElementById("lowerInfo_"+content.i);
		if (lowerInfoEl) { lowerInfoEl.style.opacity = "0"; }
	}

	async loadEcho(content) {
		if (!content.loading && !content.loaded) {

			content.loading = true;

			if (content.i === "petesInterview") { content.hideEndings = true; }

			let echo = null; await this.meta.getEcho(content.i).then( result => echo = result );

			if (echo.info) {
				content.info = echo.info;
				content.summary = echo.info.d;
				content.alternativeEndings = [];
				content.matchingEnding = null;
				content.matchingEndingReached = false;
				content.endingsReachedCount = 0;
				content.endingsCount = echo.info.e.length;
				content.alternativeEndingsCount = 0;

				for (let ending of echo.info.e) {
					ending.reached = false; await await this.varias.getVariable("end_"+ending.i).then(result => {
						if (result) { 
							ending.reached = true;
							content.endingsReachedCount++;
							if (ending.mte) {
								content.matchingEndingReached = true;
								content.matchingEnding = ending;
							} else {
								content.alternativeEndings.push(ending)
							}
						}
						if (!ending.mte) { content.alternativeEndingsCount++; }
					})
				}

				if (content.matchingEnding != null) {
					let recap = echo.info.r.find(recap => recap.v === content.matchingEnding.i);
					content.summary = recap.t;
				} else {
					if (content.alternativeEndings.length > 0) {
						let recap = echo.info.r.find(recap => recap.v === content.alternativeEndings[0].i);
						content.alternativeEndings[0].usedInSummary = true;
						content.summary = recap.t;
					}
				}

				for (let ending of content.alternativeEndings) {
					let recap = echo.info.r.find(recap => recap.v === ending.i);
					ending.summary = recap.t;
				}

				content.unlocked = false; await this.varias.getVariable("ech_"+echo.info.i+"_unl").then(result => { if (result) { content.unlocked = true; } });
				content.started = false; await this.varias.getVariable("ech_"+echo.info.i+"_std").then(result => { if (result) { content.started = true; } });
				if (!await this.varias.getVariable("sys_member")) {
					content.subscribed = false; await this.varias.getVariable("ech_"+echo.info.i+"_sub").then(result => { if (result) { content.subscribed = true; } });
					if (content.subscribed && !content.unlocked) { 
						content.started = false;
						content.blocked = true;
					}					
				}

				if (content.started) { 
					content.started = true;
					content.showStartButton = false;
					content.showRestartCard = true;
				} else {
					content.showStartButton = true;
					content.showRestartCard = false;
					content.startButton = {
						title: echo.info.t,
						subtitle: this.localization.unlock,
						pic: "cover/square/"+content.i+".jpg",
						minipic: "contacts/"+echo.info.sc.i+".jpg",
						price: echo.info.p
					}
					if (this.showProduct && this.setPack && this.setPack) {
						if (content.info) {
							if (content.info.p > 0) {
								content.hasPacks = true;
							}
						}
					}
				}				
			}

			content.loaded = true;
		}
	}

	async loadStoryboard(episode) {
		if (!episode.loading && !episode.loaded) {
			episode.loading = true;

			if (this.instance.instance === "prevapp") {
				if (episode.i === "pa1" || episode.i === "ga1" || episode.i === "ha1") {
					episode.hasPreview = true;
				}
			}

			let storyboard = null;
			await this.engine.getCachedStoryboard(episode.i).then(result => { storyboard = result })
			if (storyboard === undefined || storyboard === null) {
				episode.loaded = true;
				episode.missing = true;
				return;
			}
			if (storyboard.info.p <= 0) { episode.freeEpisode = true; } else { episode.freeEpisode = false; }
			if (storyboard === null) {
				await this.meta.getStoryboardInfo(episode.i).then(result => {
					storyboard = { scenes: [], info: result }
				})
			}

			if (!await this.varias.getVariable("sys_member")) {
				await this.varias.getVariable(episode.i+"_sub").then(async subscribed => {
					if (subscribed) {
						await this.varias.getVariable(episode.i+"_unl").then(unlocked => {
							if (!unlocked) {
								episode.blocked = true;
							}
						})
					}
				})
			}
	
			if (storyboard) {
				if (storyboard.info) {
					if (storyboard.info.r) {
						for (let reaser of storyboard.info.r) {
							await this.data.checkConditions(reaser.conds).then(result => reaser.show = result )
						}
					}
	
					storyboard.aggregateContacts = [];
					try {
						if (storyboard.info.c) { for (let contact of storyboard.info.c) { storyboard.aggregateContacts.push(contact); } }
					} catch (error) {
						
					}
					if (storyboard.scenes) {
						for await (let scene of storyboard.scenes) {
							if (scene.started) {
								let contact = storyboard.aggregateContacts.find(item => item.i === scene.sc.i);
								if (!contact) { storyboard.aggregateContacts.push(scene.sc); }
							}
						}
					}
	
					if (storyboard.info.conds) {
						await this.varias.checkConditions(storyboard.info.conds).then(result => {
							if (!result) { episode.spoiler = true; }
						})
					}
	
					if (episode.started && !episode.blocked) {
						storyboard.chats = [];
						this.engine.createStoryboardsOverview(storyboard.info.i).then(episodesOfOverview => {
							for (let episodeOfOverview of episodesOfOverview) {
								if (episodeOfOverview.i === storyboard.info.i) {
									episode.chats = episodeOfOverview.chats;
								}
							}
						})
					}
	
					if (storyboard.info.lang === undefined || storyboard.info.lang === null) { storyboard.info.lang = "DE"; }
					if (storyboard.info.lang != this.localization.lang) {
						episode.langDiffers = true;
						this.meta.getStoryboardInfo(episode.i).then(stbCloudInfo => {
							if (stbCloudInfo.l) {
								episode.refreshLang = true;
							}
						})

					}

					let archived = false;
					if (this.engine.storyboardsRegistry.length === 0) { await this.engine.loadStoryboardsRegistry(); }
					for (let stbInfo of this.engine.storyboardsRegistry) {
						if (stbInfo.i === storyboard.info.i) {
							if (stbInfo.archived) {
								episode.archived = true;
							}
						}
					}
				}
			}
	
			if (storyboard) {
				if (storyboard.info) {
					if (storyboard.info.l) {
						episode.live = true;
						if ((episode.started || episode.completed) && !episode.blocked) {
							episode.showRestartButton = true;
						} else if (episode.unlocked) {
							episode.showStartButton = true;
							episode.startButton = {
								title: episode.t,
								subtitle: "Start",
								pic: "cover/square/"+episode.i+".jpg",
								minipic: "cover/square/"+episode.i+".jpg"
							}
						} else {
							let isMember = await this.varias.getVariable("sys_member");
							if (isMember) {
								episode.showStartButton = true;
								episode.startButton = {
									title: episode.t,
									subtitle: "Start",
									pic: "cover/square/"+episode.i+".jpg",
									minipic: "cover/square/"+episode.i+".jpg"
								}
							} else {
								let price = parseInt(storyboard.info.p);
								episode.showStartButton = true;
								episode.startButton = {
									title: episode.t,
									subtitle: this.localization.unlock,
									pic: "cover/square/"+episode.i+".jpg",
									minipic: "cover/square/"+episode.i+".jpg",
									price: price
								}
								let adsImplemented = false; await this.ads.checkImplementation().then(result => adsImplemented = result);
								if (adsImplemented) {
									let legitForAds = false; await this.ads.checkLegibility("episode", episode.i).then(result => legitForAds = result);
									if (legitForAds) {
										episode.hasAds = true;
										this.ads.getWatchCount("episode", episode.i);
									}
								}
	
								/*
								if (this.instance.instance === "voidapp" || this.instance.instance === "gamerapp") {
									if (await this.varias.getVariable("off_special3") && !await this.varias.getVariable("off_special3_wtd")) {
										if (this.instance.instance === "voidapp") {
											if (storyboard.info.i === "pa2" || storyboard.info.i === "pa3" || storyboard.info.i === "pa4") {
												let episodesOfSpecialMissing: number = 0;
												let ep2Missing = true; await this.varias.getVariable("pa2_unl").then(unlocked => { if (unlocked) { ep2Missing = false; } }); await this.varias.getVariable("pa2_std").then(started => { if (started) { ep2Missing = false; } }); if (ep2Missing) { episodesOfSpecialMissing++; }
												let ep3Missing = true; await this.varias.getVariable("pa3_unl").then(unlocked => { if (unlocked) { ep3Missing = false; } }); await this.varias.getVariable("pa3_std").then(started => { if (started) { ep3Missing = false; } }); if (ep3Missing) { episodesOfSpecialMissing++; }
												let ep4Missing = true; await this.varias.getVariable("pa4_unl").then(unlocked => { if (unlocked) { ep4Missing = false; } }); await this.varias.getVariable("pa4_std").then(started => { if (started) { ep4Missing = false; } }); if (ep4Missing) { episodesOfSpecialMissing++; }
												if (episodesOfSpecialMissing >= 2) { episode.specialOffer3 = true; }
											}
										} else if (this.instance.instance === "gamerapp") {
											if (storyboard.info.i === "ga2" || storyboard.info.i === "ga3" || storyboard.info.i === "ga4") {
												let episodesOfSpecialMissing: number = 0;
												let ep2Missing = true; await this.varias.getVariable("ga2_unl").then(unlocked => { if (unlocked) { ep2Missing = false; } }); await this.varias.getVariable("ga2_std").then(started => { if (started) { ep2Missing = false; } }); if (ep2Missing) { episodesOfSpecialMissing++; }
												let ep3Missing = true; await this.varias.getVariable("ga3_unl").then(unlocked => { if (unlocked) { ep3Missing = false; } }); await this.varias.getVariable("ga3_std").then(started => { if (started) { ep3Missing = false; } }); if (ep3Missing) { episodesOfSpecialMissing++; }
												let ep4Missing = true; await this.varias.getVariable("ga4_unl").then(unlocked => { if (unlocked) { ep4Missing = false; } }); await this.varias.getVariable("ga4_std").then(started => { if (started) { ep4Missing = false; } }); if (ep4Missing) { episodesOfSpecialMissing++; }
												if (episodesOfSpecialMissing >= 2) { episode.specialOffer3 = true; }
											}
										}
									}
								}
								*/
	
								episode.hasPacks = true;
								
								if (episode.specialOffer3) {
									if (this.purchase.products["special3"]) {
										episode.singlePack = this.purchase.products["special3"];
										episode.singlePackDescription = this.localization.purchase_special2;
										episode.singlePackSpecial = true;
									}
								} else {
									if (this.purchase.products["ep_unlock_"+episode.i]) {
										episode.singlePack = this.purchase.products["ep_unlock_"+episode.i];
										episode.singlePackDescription = this.localization.purchase_episode_1;
										episode.singlePackSpecial = false;
									}								
								}
							}
						}
					} else {					
						episode.live = false;
						episode.releaseInfo = storyboard.info.rl;
					}
				}
			}
	
			episode.storyboard = JSON.parse(JSON.stringify(storyboard));
			await this.util.sleep(1000);
			episode.loaded = true;
		}

		return;
	}

	pushChat(sceneId) { 
		this.navigation.pushChat(sceneId, true);
		this.close({ closeCatalogue: true, pushChat: true });
	}
	pushContact(contact) { this.navigation.pushContact(contact); }
	close(data: any = {}) { if (this.asModal) { this.dismiss(data); } else { this.pop(); } }
	pop() { this.navigation.pop(); }
	dismiss(data) { this.modalController.dismiss(data); }

	contentTriedToStart = null;
	async start(content) {
		let isParallax = false;
		if (this.instance.instance === "prllxapp") { isParallax = true; }
		else if (this.instance.instance === "prevapp") {
			if (this.instance.variant === "prllx") { isParallax = true; }
		}
		if (this.instance.liberty) {
			if (this.platform.is("ios")) {
				if (isParallax) {
					this.iab.create("https://apps.apple.com/de/app/apple-store/id1141858570",  '_blank', 'location=yes');
				} else {
					this.iab.create("https://apps.apple.com/de/app/a-void-society/id1471111858",  '_blank', 'location=yes');
				}
			} else if (this.platform.is("android")) {
				if (isParallax) {
					this.iab.create("https://play.google.com/store/apps/details?id=me.cloudtells.parallax",  '_blank', 'location=yes');
				} else {
					this.iab.create("https://play.google.com/store/apps/details?id=me.cloudtells.avoidsociety",  '_blank', 'location=yes');
				}
			} else {
				if (isParallax) {
					this.iab.create("https://prllx.app",  '_blank', 'location=yes');
				} else {
					this.iab.create("https://avoidsociety.app",  '_blank', 'location=yes');
				}
			}
		} else {
			this.contentTriedToStart = content;
			if (content.type === "episode") {
				await this.engine.STB_start(content.i, content.blocked).then(async result => {
					if (result.start) {
						content.blocked = false;
						content.started = true;
						content.showRestartButton = true;
						content.showStartButton = false;
						//this.close({ closeCatalogue: true, episodeStarted: content.i });
					} else {
						await this.varias.getVariable("conf_adsImplemented");
						if (this.varias.vars["conf_adsImplemented"]) {
							let missingTickets = 100 - await this.varias.getCount("sys_tickets") - this.ads.watchCountsPercentage[content.i];
							this.molerts.alert_purchaseOptions("episode", content.i, missingTickets, () => { this.requestAd("episode", content.i); });
						}						
					}
				});
			} else if (content.type === "echo") {
				if (content.info) {
					let success: any = false; await this.engine.ECH_unlock(content.info.i, content.info.p, content.n, content.blocked).then(result => success = result);
					if (success) {
						content.started = true;
						content.showStartButton = false;
						await this.engine.ECH_download(content.info.i, true);
						await this.engine.SCN_start(content.info.i, false, true);
						this.navigation.pushChat(content.info.i, false);
						this.close({ closeCatalogue: true, echoStarted: true });
					} else {
						let missingTickets = content.info.p - await this.varias.getVariable("sys_tickets");
						this.molerts.alert_purchaseOptions("echo", content.i, missingTickets, () => {});
					}
				}
			}
		}
	}

	aboutMultipleEndings() { this.iab.create("https://medium.com/the-parallax-app-developer-blog",  '_blank', 'location=yes'); }

	switchLanguage(content) {
		if (content.type === "episode") {
			if (content.storyboard) {
				content.langDiffers = false;
				content.refreshLang = false;
				this.engine.switchLanguage();
			}
		}
	}

	unarchive(content) {
		content.archived = false;
		this.engine.STB_unarchive(content.i);
		this.events.publish('updateStoryboardsOverview', true);
	}

	async pushHistory(content) {
		this.close({ closeCatalogue: true, pushHistory: true });
		this.navigation.pushHistory(content.i, "all");
	}

	async requestAd(mode, contentId) {
		let adIsThere = false; await this.ads.requestAd(mode, contentId).then(result => adIsThere = result);
		if (adIsThere) {
			const loading = await this.loadingController.create({
				spinner: "bubbles",
				message: this.localization.oneMomentPlease,
				translucent: true,
				duration: 5000,
				showBackdrop: true
			});
			loading.present();		
			setTimeout(() => {
				loading.dismiss();
			}, 3000);
		}
	}

	presentMembership() {
		this.modals.presentMembership();
	}

	orderPack(productId) {
		this.purchase.order(productId, "episodePage")
	}
	order(productId) {
		this.purchase.order(productId, "episodePage");
	}

	startPreview(content) {
		let previewId = "ta1";
		if (content.i === "ga1") { previewId = "ta2"; }
		else if (content.i === "ha1") { previewId = "ta3"; }
		this.engine.STB_downloadFirst(previewId).then(() => {})
	}

}