import { Injectable } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';

import { Storage } from '@ionic/storage-angular';

import { AdMob, RewardAdPluginEvents, AdLoadInfo, AdMobRewardItem, RewardAdOptions } from '@capacitor-community/admob';

import { MetaService } from './meta.service';
import { VariasService } from '../system/varias.service';
import { Events } from '../system/events.service'
import { DataService } from '../system/data.service';
import { LocalizationService } from '../system/localization.service';
import { TelemetryService } from './telemetry.service';
import { ConfigService } from './config.service';

import { PrivacyPage } from '../privacy/privacy.page'
import { DeviceService } from '../system/device.service';
import { InstanceService } from '../system/instance.service';
import { PurchaseService } from './purchase.service';
import { PurrchaseService } from './purrchase.service';
import { IapsPage } from '../molerts/iaps/iaps.page';
import { AlertsPage } from '../molerts/alerts/alerts.page';
import { MolertsService } from '../system/molerts.service';
import { StorageService } from '../system/storage.service';

@Injectable({
  	providedIn: 'root'
})
export class AdsService {

	adSettings: any = { 
		ep: [], 
		li: {
			ech: [ {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0} ],
			ep: [ {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0} ],
			skp: [ {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0}, {d:0,h:0} ]
		}, 
		sc: []
	};
	usedToday: number = 0;
	adUsage: any = false;
	currentContentId: any = null;
	currentMode: any = null;
	watchCounts: Array<any> = [];
	watchCountsPercentage: Array<any> = [];
	watchCountsColor: Array<any> = [];
	latestAd: any = null;
	lastAdHasBeenCredited: boolean = false;

  	constructor(
		private meta: MetaService,
		private storage: Storage,
		private varias: VariasService,
		private events: Events,
		private data: DataService,
		private localization: LocalizationService,
		private telemetry: TelemetryService,
		private config: ConfigService,
		private alertController: AlertController,
		private navController: NavController,
		private modalController: ModalController,
		private device: DeviceService,
		private instance: InstanceService,
		private purchase: PurchaseService,
		private molerts: MolertsService,
		private StorageSrv: StorageService
	) {
		this.events.subscribe("adComplete", () => { this.creditAd(); })
		this.events.subscribe("adDismissed", () => { this.onAdDismissed(); })
	}

	async loadAdSettings() {
		await this.meta.getAdSettings().then(result => { this.adSettings = result });
	}

	async getEpisodePresets(episodeId) {
		await this.loadAdSettings();
		for (let episode of this.adSettings.ep) {
			if (episodeId == episode.i) {
				return episode;
			}
		}
	}

	throttle_requestAd: boolean = false;
	async requestAd(mode, contentId) {
		if (this.throttle_requestAd) { return; }
		this.throttle_requestAd = true;
		setTimeout(() => { this.throttle_requestAd = false; }, 3000);

		this.currentMode = mode;
		this.currentContentId = contentId;
		this.lastAdHasBeenCredited = false;

		if (mode === "episode") {
			let episodePreset = { av: false, nc: 50, i: null }; await this.getEpisodePresets(contentId).then(result => episodePreset = result);
			if (!episodePreset) { 
				this.alertNoAvailability();
				return false;
			}
	
			if (!episodePreset.av) {
				this.alertNoLegibility();
				return false;
			}
		}

		let available = true; await this.checkAvailability(mode).then(result => available = result);
		if (!available) {
			this.alertNoAvailability();
			return false;
		}
		this.presentAd(mode, contentId);
		return true;
	}

	async presentAd(mode, contentId) {
		let adItem = null; await this.findAd(mode, contentId).then(result => adItem = result);
		if (!adItem) {
			this.alertNoAvailability();
			return false;
		}

		if (adItem.t === "rwv") {
			if (!this.instance.liberty) {
				this.requestRewardedVideo(mode);
			}
		} else {
			await this.varias.setVariable("mightSeeOffSpecial2", false);
			this.startAd(mode, contentId, adItem);
		}
	}

	async requestRewardedVideo(mode) {
		let hasPermission = false; await this.varias.getVariable("sys_adsGranted").then(result => { if (result) { hasPermission = true; } })
		if (hasPermission) { this.showRewardedVideo(); }
		else { this.requestPermission(); }
	}

	async requestPermission() {
		const modal = await this.modalController.create({
			component: PrivacyPage,
			componentProps: { "asModal": true }
		})
		await modal.present();
		modal.onDidDismiss().then(result => {
			if (result.data.permission) {
				this.showRewardedVideo();
			}
		})
		return;

		const alertAlert = await this.alertController.create({
			header: "Rewarded Video",
			subHeader: "Werbung",
			message: "Möchtest du Werbevideos von externen Anbietern aktivieren?",
			cssClass: 'eon__alert',
			buttons: [
				{
					text: this.localization.cancel, role: "cancel", 
					handler: () => {}
				},{
					text: this.localization.yes,
					handler: async () => { 
						const modal = await this.modalController.create({
							component: PrivacyPage,
							componentProps: { "asModal": true }
						})
						await modal.present();
						modal.onDidDismiss().then(result => {
							if (result.data.permission) {
								this.showRewardedVideo();
							}
						})
					}
				}
			]
		});
		await alertAlert.present();	
	}

	async prepareRewardedVideo() {
		let adId = "ca-app-pub-1221817806662469/6771611343";
		if (this.instance.instance === "voidapp") {
			if (this.device.platform === "ios") { adId = "ca-app-pub-1221817806662469/2968097703"; }
			else if (this.device.platform === "android") { adId = "ca-app-pub-1221817806662469/6771611343"; }
		} else if (this.instance.instance === "gamerapp") {
			if (this.device.platform === "ios") { adId = "ca-app-pub-1221817806662469/7540230086"; }
			else if (this.device.platform === "android") { adId = "ca-app-pub-1221817806662469/4426943597"; }
		} else if (this.instance.instance === "prllxapp") {
			if (this.device.platform === "ios") { adId = "ca-app-pub-1221817806662469/7296649515"; }
			else if (this.device.platform === "android") { adId = "ca-app-pub-1221817806662469/9709978297"; }
		}
		const options: RewardAdOptions = { adId: adId, isTesting: false };
		await AdMob.prepareRewardVideoAd(options);
	}

	async showRewardedVideo() {
		await this.initAdmob();
		AdMob.showRewardVideoAd();
	}

	admobInitialized: boolean = false;
	async initAdmob() {
		if (!this.admobInitialized) {
			const { status } = await AdMob.trackingAuthorizationStatus();
			await AdMob.initialize({
				requestTrackingAuthorization: false,
				//testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
			}).then(result => {}).catch((error) => {})
			AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) => {
				this.creditAd();
				this.prepareRewardedVideo();
			});
			AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
				this.onAdDismissed();
			})
			await this.prepareRewardedVideo();
			this.admobInitialized = true;
		}
		return;
	}

	async findAd(mode, contentId) {
		let latestAd = 0; await this.varias.getVariable("sys_latestAd").then(result => latestAd = result);
		let nextAd = latestAd + 1;

		await this.loadAdSettings();
		if (!this.adSettings.sc) { return false; }

		if (this.adSettings.sc[nextAd]) { 
			await this.varias.setVariable("sys_latestAd", nextAd);
			return this.adSettings.sc[nextAd];
		} else if (this.adSettings.sc[0]) {
			await this.varias.setVariable("sys_latestAd", 0);
			return this.adSettings.sc[0];
		}
		return false;
	}
	async startAd(mode, contentId, adItem) {
		this.telemetry.logEvent("startAd", { adItem: adItem });
		this.events.publish("ECH_downloadAndStartTutoring", { sceneId: adItem.i, forceRestart: true, openOnStart: true });
	}
	throttle_creditAd: boolean = false;
	async creditAd() {
		if (this.throttle_creditAd) { return; }
		this.throttle_creditAd = true;
		setTimeout(() => { this.throttle_creditAd = false; }, 10000);

		this.lastAdHasBeenCredited = true;
		if (this.currentMode && this.currentContentId) {
			this.incrementUsage(this.currentMode);
			if (this.currentMode === "skip") {
				this.events.publish("executeSkip", { sceneId: this.currentContentId });
			} else if (this.currentMode === "bot") {
				if (this.data.config_videoCircuitsFactor > 0) {
					this.varias.incrementCurrency("circuits", this.data.config_videoCircuitsFactor);
				}
			} else if (this.currentMode === "episode") {
				this.incrementWatchCount(this.currentMode, this.currentContentId);
			}
		}
	}
	async alertNoLegibility() {
		//let message = this.localization.ads_error_noLegibility;
		//this.events.publish("alertNoAds", { episodeId: this.currentContentId, message: message });
	}
	async alertNoAvailability() {
		let molert = await this.modalController.create({
			component: IapsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				variant: "noAd",
				subVariant: this.currentMode,
				adAvailable: false,
				animation: "shake",
				icon: "ad",
				iconColor: "--DANGER"
			}
		});
		molert.present();
	}

	async test_anotherAd() {
		this.resetUsage();
		this.currentMode = "episode";
		this.currentContentId = "pa7";
		let anotherAdAvailable = true;
		let missingTickets = 27;
		let molert = await this.modalController.create({
			component: IapsPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {
				variant: "afterAd",
				adAvailable: anotherAdAvailable,
				animation: "bounce",
				icon: "ad",
				missingTickets: missingTickets
			}
		});
		molert.present();
		molert.onDidDismiss().then((result) => {
			if (result) { if (result.data) { if (result.data.method) {
				if (result.data.method === "anotherAd") {
					this.requestAd(this.currentMode, this.currentContentId);
				}
			} } }
		})				
	}
	async test_unlockedForFree() {
		const buttons = [
			{
				text: this.localization.later,
				color: "--DANGER",
				handler: () => {}
			},{
				text: this.localization.unlockNow,
				color: "--WARNING",
				handler: () => {
					this.events.publish("STB_start", { episodeId: this.currentContentId });
				}
			}
		]
		this.molerts.alert_basic(this.localization.congratulations, "", this.localization.ads_enoughAdsCollected+" "+this.localization.ads_episodeIsFree+" "+this.localization.ads_episodeStartNow, null, "unlock", "bounce", "--WARNING", buttons);		
	}

	async onAdDismissed() {
		if (this.currentMode === "episode") {

			let mightSeeOffSpecial4 = false;
			let mightSeeOffSpecial6 = false;
			if (this.instance.instance === "voidapp") {
				if (this.currentContentId === "pa2" || this.currentContentId === "ga2" || this.currentContentId === "ha2") {
					mightSeeOffSpecial4 = true;
				} else if (this.currentContentId === "pa3" || this.currentContentId === "pa4" || this.currentContentId === "ga3" || this.currentContentId === "ga4" || this.currentContentId === "ha3" || this.currentContentId === "ha4") {
					mightSeeOffSpecial6 = true;
				}
			} else if (this.instance.instance === "prllxapp") {
				if (this.currentContentId === "pa2" || this.currentContentId === "pa3" || this.currentContentId === "pa4") {
					mightSeeOffSpecial6 = true;
				}
			} else if (this.instance.instance === "gamerapp") {
				if (this.currentContentId === "ga2" || this.currentContentId === "ga3") {
					mightSeeOffSpecial6 = true;	
				}
			} else if (this.instance.instance === "horrorapp") {
				if (this.currentContentId === "ha2" || this.currentContentId === "ha3") {
					mightSeeOffSpecial6 = true;	
				}
			}

			if (mightSeeOffSpecial4) {
				let newWatchCount = await this.getWatchCount(this.currentMode, this.currentContentId);
				if (newWatchCount.watchCountPercentage < 100) {
					await this.varias.getVariable("off_special4");
					if (!this.varias.vars["off_special4"]) {
						this.events.publish("offerSpecial", { specialId: "special4" });
						return;
					}
				}				
			} else if (mightSeeOffSpecial6) {
				let newWatchCount = await this.getWatchCount(this.currentMode, this.currentContentId);
				if (newWatchCount.watchCountPercentage < 100) {
					await this.varias.getVariable("off_special6");
					if (!this.varias.vars["off_special6"]) {
						this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special6" });
						return;
					}
				}
			}

			if (this.lastAdHasBeenCredited) {
				let newWatchCount = await this.getWatchCount(this.currentMode, this.currentContentId);
				let missingTickets = Math.round(100 - newWatchCount.watchCountPercentage - await this.varias.getVariable("sys_tickets"));
				if (missingTickets > 0) {
					let anotherAdAvailable = true; await this.checkAvailability(this.currentMode).then(result => anotherAdAvailable = result);
					let molert = await this.modalController.create({
						component: IapsPage,
						cssClass: "EON__MOLERT --ALERT",
						componentProps: {
							variant: "afterAd",
							adAvailable: anotherAdAvailable,
							animation: "bounce",
							icon: "ad",
							missingTickets: missingTickets
						}
					})
					molert.present();
					molert.onDidDismiss().then((result) => {
						if (result) { if (result.data) { if (result.data.method) {
							if (result.data.method === "anotherAd") {
								this.requestAd(this.currentMode, this.currentContentId);
							}
						} } }
					})
				} else {
					const buttons = [
						{
							text: this.localization.later,
							color: "--DANGER",
							handler: () => {}
						},{
							text: this.localization.unlockNow,
							color: "--WARNING",
							handler: () => {
								this.events.publish("STB_start", { episodeId: this.currentContentId });
							}
						}
					]
					this.molerts.alert_basic(this.localization.congratulations, "", this.localization.ads_enoughAdsCollected+" "+this.localization.ads_episodeIsFree+" "+this.localization.ads_episodeStartNow, null, "unlock", "bounce", "--WARNING", buttons);
				}
			}
		}
	}

	async checkImplementation() {
		await this.config.getConfiguration();
		let adsImplemented = false; await this.varias.getVariable("conf_adsImplemented").then(result => adsImplemented = result);
		if (!adsImplemented) { return false; }
		let hasMembership = false; await this.varias.getVariable("sys_member").then(result => { if (result) { hasMembership = true; } });
		if (hasMembership) { return false }
		//let hasTickets = false; await this.varias.getCount("sys_tickets").then(result => { if (result >= 100) { hasTickets = true; } })
		//if (hasTickets) { return false }
		return true;
	}

	async checkLegibility(mode, contentId) {
		let episodePreset = { av: false, nc: 50, i: null }; await this.getEpisodePresets(contentId).then(result => episodePreset = result);
		if (!episodePreset) { return false }
		if (!episodePreset.av) { return false }
		return true;
	}

	async checkAvailability(mode) {
		let usage = { withinDay: 0, withinHour: 0, skipsWithinDay: 0 }; await this.getUsage().then(result => usage = result);
		let limits = { d: 0, h: 0 }; await this.getLimits(mode).then(result => limits = result);
		if (usage.withinDay >= limits.d) {
			if (mode != "skip") { return false }
			if (usage.skipsWithinDay >= limits.d) { return false }
		}
		if (usage.withinHour >= limits.h) { return false; }
		return true;
	}

	async getLimits(mode) {
		await this.loadAdSettings();
		let limits = { d: 0, h: 0 };
		let now = new Date();
		let weekday = now.getDay();

		if (mode === "skip") { 
			if (this.adSettings.li.skp[weekday]) { limits = this.adSettings.li.skp[weekday]; }
		} else if (mode === "episode") {
			if (this.adSettings.li.ep[weekday]) { limits = this.adSettings.li.ep[weekday]; }
		} else if (mode === "echo") { 
			if (this.adSettings.li.ech[weekday]) { limits = this.adSettings.li.ech[weekday]; }
		} else if (mode === "bot") {
			if (this.adSettings.li.bot[weekday]) { limits = this.adSettings.li.bot[weekday]; }
		}
		return limits;
	}

	async loadUsage() {
		if (!this.adUsage) {
			await this.storage.get("adUsage").then(resultString => {
				if (resultString) {
					let parsed = JSON.parse(resultString);
					if (parsed) { this.adUsage = parsed; }
				}
			})
			if (!this.adUsage) { this.adUsage = []; }
		}
	}

	async getUsage() {
		await this.loadUsage();

		let withinDay = 0;
		let withinHour = 0;
		let skipsWithinDay = 0;
		let date = new Date();
		let now = date.getTime();
		let today = date.getDate();

		for (let i = 0; i < 10; i++) {
			if (this.adUsage[i]) {
				if (this.adUsage[i].t > now-3600000) { 
					withinHour++;
					withinDay++;
					if (this.adUsage[i].m === "skip") { skipsWithinDay++; }
				} else if (this.adUsage[i].d === today) { 
					withinDay++;
					if (this.adUsage[i].m === "skip") { skipsWithinDay++; }
				} else {
					this.adUsage[i] = false;
				}
			}
		}

		return { withinDay: withinDay, withinHour: withinHour, skipsWithinDay: skipsWithinDay };
	}	

	async incrementUsage(mode) {
		await this.loadUsage();

		let date = new Date();
		let now = date.getTime();
		let today = date.getDate();

		for (let i = 0; i < 10; i++) {
			if (!this.adUsage[i]) {
				this.adUsage[i] = { d: today, t: now, m: mode }
				break;
			}
		}

		this.storeUsage();
	}

	async resetUsage() {
		this.storage.remove("adUsage");
		this.adUsage = false;

		this.varias.setVariable("ads_wch_pa15", 0, true);
		this.watchCounts["pa15"] = 0;
		this.watchCountsPercentage["pa15"] = 0;
	}

	async storeUsage() {
		let stringifiedUsage = JSON.stringify(this.adUsage);
		if (stringifiedUsage) { 
			this.storage.set("adUsage", stringifiedUsage);
		}
	}

	async incrementWatchCount(mode, contentId) {
		let currentCount = await this.varias.getCount("ads_wch_"+contentId);
		if (currentCount <= 0) {
			await this.varias.incrementCount("ads_wch_"+contentId, 3);
		} else if (currentCount === 3) {
			await this.varias.incrementCount("ads_wch_"+contentId, 1);
		} else if (currentCount === 4) {
			await this.varias.incrementCount("ads_wch_"+contentId, 2);
		} else if (currentCount === 6) {
			await this.varias.incrementCount("ads_wch_"+contentId, 1);
		} else if (currentCount === 7) {
			await this.varias.incrementCount("ads_wch_"+contentId, 2);
		} else {
			await this.varias.incrementCount("ads_wch_"+contentId, 1);
		}
		this.getWatchCount(mode, contentId);
	}	

	async getWatchCount(mode, contentId) {
		await this.loadAdSettings();

		if (!this.watchCounts[contentId]) { this.watchCounts[contentId] = 0; }
		let watchCount = 0; await this.varias.getCount("ads_wch_"+contentId).then(result => {
			watchCount = result
			this.watchCounts[contentId] = result;
		});

		let necessaryCount = 50;
		if (mode === "episode") {
			for (let episode of this.adSettings.ep) {
				if (episode.i === contentId) {
					if (episode.nc) { necessaryCount = episode.nc; }
				}
			}
		} else if (mode === "echo") {
			necessaryCount = 7;
		}

		this.watchCountsPercentage[contentId] = Math.ceil((this.watchCounts[contentId] / necessaryCount) * 100);
		if (this.watchCountsPercentage[contentId] > 100) { this.watchCountsPercentage[contentId] = 100; }

		return { watchCount: this.watchCounts[contentId], watchCountPercentage: this.watchCountsPercentage[contentId] };
	}

	test() {
		this.requestRewardedVideo("skip");
	}

}