import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { AlertController } from '@ionic/angular';
import { LocalizationService } from '../system/localization.service';

@Injectable({
  	providedIn: 'root'
})
export class SignalService {

	type: string = "unknown";
	connected: boolean = true;
	funnel_alertNoConnection: any;
	message_alertNoConnection = "";

  	constructor(
		private alertController: AlertController,
		private localization: LocalizationService
	) {
		Network.getStatus().then(status => {
			this.connected = status.connected;
			this.type = status.connectionType;			
		})
		Network.addListener("networkStatusChange", (status) => {
			this.connected = status.connected;
			this.type = status.connectionType;
		})
	}

	async getType() { return this.type }

	async alertNoConnection(message) {
		var that = this;
		this.message_alertNoConnection = message;
		clearTimeout(this.funnel_alertNoConnection);
		this.funnel_alertNoConnection = setTimeout(function() {
			that.doAlertNoConnection();
		}, 1000);
	}

	async doAlertNoConnection() {
		const alert = await this.alertController.create({
			subHeader: 'Offline',
			header: this.localization.signal_connection,
			cssClass: 'eon__alert',
			message: this.message_alertNoConnection,
			buttons: ['Ok']
		});
		await alert.present();
	}
}
