import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { Auth, GoogleAuthProvider, OAuthProvider, AuthProvider, EmailAuthProvider } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'

import { AlertController, LoadingController, ActionSheetController, ModalController } from '@ionic/angular';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';

import { CloudSettings } from '@awesome-cordova-plugins/cloud-settings/ngx';

import { SyncPage } from '../molerts/sync/sync.page';

import { DeviceService } from '../system/device.service';
import { DataService } from '../system/data.service';
import { NavigationService } from '../system/navigation.service';
import { TelemetryService } from './telemetry.service';
import { VariasService } from '../system/varias.service';
import { LocalizationService } from '../system/localization.service'
import { InstanceService } from '../system/instance.service'


import { Events } from '../system/events.service'
import { StorageService } from '../system/storage.service';
import { UtilityService } from '../system/utility.service';
import { MolertsService } from '../system/molerts.service';

var shajs = require('sha.js')


@Injectable({
  	providedIn: 'root'
})
export class UserService {

	legacy_db: any;
	legacy_firebaseApp: any;

	currentUser: any = null;
	authenticated: boolean = false;

	hasProvider_cloud: boolean = false;
	hasProvider_password: boolean = false;
	hasProvider_google: boolean = false;
	hasProvider_apple: boolean = false;

	missingEmailVerification: boolean = false;

	cloudInformation: {};
	auid: any = undefined;

	signInWithApple_nonce: string = 'nonce';
	signInWithApple_hashedNonceHex: any = undefined;
	signInWithApple_options: SignInWithAppleOptions = undefined;

	unsubscribe_synchronousVariables: any = null;
	funnel_findRunningStoryboards: any = null;

  	constructor(
		private data: DataService,
		private fireAuth: AngularFireAuth,
		private fireStore: AngularFirestore,
		private storage: Storage,
		private alertController: AlertController,
		private loadingController: LoadingController,
		private navigation: NavigationService,
		public actionSheetController: ActionSheetController,
		private telemetry: TelemetryService,
		private events: Events,
		private platform: Platform,
		private varias: VariasService,
		private localization: LocalizationService,
		private modalController: ModalController,
		private cloudSettings: CloudSettings,
		private device: DeviceService,
		private instance: InstanceService,
		private storageSrv: StorageService,
		private util: UtilityService,
		private molerts: MolertsService
	) {
		this.subscribeToCurrentUser();

		this.signInWithApple_nonce = 'nonce';
		this.signInWithApple_hashedNonceHex = shajs('sha256').update(this.signInWithApple_nonce).digest('hex');

		/*
		let redirectURI = "https://interactivefiction.app/start";
		if (this.instance.instance === "gamerapp") { redirectURI = "https://buff.extraordinerdy.app/start"; } 
		else if (this.instance.instance === "prllxapp") { redirectURI = "https://parallax.extraordinerdy.app/start"; }
		*/
		this.signInWithApple_options = {
			clientId: 'me.cloudtells.extraordinerdyauth',
			redirectURI: "https://a-void-society.firebaseapp.com/__/auth/handler",
			scopes: 'email',
			state: '12345',
			nonce: this.signInWithApple_hashedNonceHex
		};		
	}

	/* *************************************************************************************************** */
	/* ** CURRENT USER *********************************************************************************** */
	/* *************************************************************************************************** */

	async logCurrentUser() {
		console.log(this.currentUser);
	}

	async subscribeToCurrentUser() {
		this.fireAuth.onAuthStateChanged(async (currentUser) => {
			this.bootUser(currentUser);
		})
	}

	async reloadUser() {
		this.fireAuth.currentUser.then(async (currentUser) => {
			this.bootUser(currentUser);
		});
	}

	async bootUser(currentUser) {
		this.currentUser = currentUser;

		let authenticated = false;
		let hasProvider_cloud = false;
		let hasProvider_password = false;
		let hasProvider_google = false;
		let hasProvider_apple = false;

		if (this.instance.instance === "prevapp" && this.instance.variant === "prllx") {
			return;
		}
		
		if (this.currentUser) {
			if (this.currentUser.providerData) {
				for (let provider of this.currentUser.providerData) {
					if (provider.providerId === "password") {
						if (provider.email.startsWith("eon_") && provider.email.endsWith("@textadventure.app")) {
							hasProvider_cloud = true;
						} else {
							hasProvider_password = true;
							authenticated = true;
							if (!this.currentUser.emailVerified) { this.missingEmailVerification = true; } else { this.missingEmailVerification = false; }
						}
					} else if (provider.providerId === "google.com") {
						hasProvider_google = true;
						authenticated = true;
					} else if (provider.providerId === "apple.com") {
						hasProvider_apple = true;
						authenticated = true;
					}
				}
			}
		}

		if (authenticated) { this.authenticated = true; } else { this.authenticated = false; }
		if (hasProvider_cloud) { this.hasProvider_cloud = true; } else { this.hasProvider_cloud = false; }
		if (hasProvider_password) { this.hasProvider_password = true; } else { this.hasProvider_password = false; }
		if (hasProvider_google) { this.hasProvider_google = true; } else { this.hasProvider_google = false; }
		if (hasProvider_apple) { this.hasProvider_apple = true; } else { this.hasProvider_apple = false; }
		

		if (this.currentUser) {

			if (authenticated || hasProvider_password || hasProvider_google || hasProvider_apple) {
				await this.unsubscribeSynchronousVariables();
				await this.subscribeSynchonousVariables(this.currentUser.uid);
				return;
			} else {
				await this.unsubscribeSynchronousVariables();
				await this.subscribeSynchonousVariables(this.currentUser.uid);
				this.promptAuthentication(false, () => {});
				return;
			}

		} else {

			let cloudSettings: any = await this.getCloudSettings();
			let wasAuthenticated = false; if (cloudSettings) { if (cloudSettings.wasAuthenticated) { wasAuthenticated = true; } }
			if (wasAuthenticated) {
				return this.promptAuthentication(true, () => {
					return this.connectWithCloudAccount(false);
				});
			} else {
				return this.connectWithCloudAccount(false);
			}

		}
	
	}

	async unsubscribeSynchronousVariables() {
		if (this.unsubscribe_synchronousVariables) { await this.unsubscribe_synchronousVariables(); }
	}

	async subscribeSynchonousVariables(uid) {
		await this.unsubscribeSynchronousVariables();

		this.unsubscribe_synchronousVariables = this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.onSnapshot((snapshot) => {
			snapshot.docChanges().forEach(async (change) => {
				if (change.type === "added" || change.type === "modified") {
					await this.varias.setVariable(change.doc.id, change.doc.data().v, true);
					if (change.doc.id.indexOf("_std") > -1) { 
						clearTimeout(this.funnel_findRunningStoryboards);
						this.funnel_findRunningStoryboards = setTimeout(() => {
							this.events.publish("SYNC_findRunningStoryboards");
						}, 1000);
					}
				}
			});
		});	
	}

	/* *************************************************************************************************** */
	/* ** PROMPT AUTHENTICATION  ************************************************************************* */
	/* *************************************************************************************************** */

	async promptAuthentication(ignoreBoot, onDenied) {
		if (!ignoreBoot) {
			let boot = 0; await this.varias.getCount("sys_boot").then(async value => boot = value);
			if (boot < 2) {
				return onDenied();
			}
		}

		let countAuthRequest = 0; await this.storage.get("countAuthRequest").then((val) => { if (val) { countAuthRequest = val; } });
		let lastRequest = 0; await this.storage.get("lastAuthRequest").then((val) => { if (val) { lastRequest = val; } });
		let now = new Date().getTime();

		if (now - lastRequest < 1000 * 60 * 60 * 24 * countAuthRequest * 1) {
			return onDenied();
		}

		countAuthRequest++;
		this.storage.set("countAuthRequest", countAuthRequest);
		this.storage.set("lastAuthRequest", now);

		const buttons = []
		buttons.push({ text: this.localization.account_signInWithGoogle, color: "--WARNING", handler: () => { this.authenticateWithExternalProvider("google.com", false, 0, false); } });
		await this.device.getPlatform();
		if (this.device.platform != "android") {
			buttons.push({ text: this.localization.account_signInWithApple, color: "--WARNING", handler: () => { this.authenticateWithExternalProvider("apple.com", false, 0, false); } });
		}
		buttons.push({ text: this.localization.user_methodEmailAndPassword, color: "--WARNING", handler: () => { this.navigation.pushAccount(); } });
		buttons.push({ text: this.localization.notNow, color: "--WARNING", class: "--LESS", handler: () => { onDenied(); } });

		let message = this.localization.account_secureProgress_1 + " " + this.localization.account_secureProgress_2 + " " + this.localization.account_secureProgress_4;
		this.molerts.alert_basic(this.localization.account_wannaSignIn, this.localization.account_singInFreeAndEasy, message, "", "cloud-xmark", "bounce", "--WARNING", buttons);
		return;
	}

	/* *************************************************************************************************** */
	/* ** AUTHENTICATE *********************************************************************************** */
	/* *************************************************************************************************** */

	async signInAnonymously() {
		if (this.instance.instance === "prevapp" && this.instance.variant === "prllx") {
			return;
		}
		if (!this.currentUser) {
			return this.fireAuth.signInAnonymously().then(async (newAnonymousUser) => {
				await this.unsubscribeSynchronousVariables();
				await this.sync(newAnonymousUser?.user?.uid, false, true, false, false, true, 0);
				await this.subscribeSynchonousVariables(newAnonymousUser.user.uid);
				return;
			}).catch((error) => {
				return;
			});
		}
		return;
	}

	async connectWithCloudAccount(ignoreSyncX) {
		if (this.currentUser) { if (!this.currentUser.isAnonymous) { return; } }
		if (this.authenticated) { return; }
		if (this.hasProvider_cloud) { return; }

		let preventCloudUser = false; await this.storage.get("preventCloudUser").then((result) => { if (result) { preventCloudUser = result; } });
		if (preventCloudUser) { return this.signInAnonymously(); }

		let boot = 0; await this.varias.getCount("sys_boot").then(async value => boot = value);
		if (boot < 3) { return this.signInAnonymously(); }

		let cloudSettings: any = await this.getCloudSettings().then(async cloudSettings => {});
		let auid = cloudSettings.auid;
		if (!auid) { await this.storage.get("auid").then(async value => auid = value); }

		if (auid) {

			let hasBinding = false;
			if (!ignoreSyncX) {
				await this.fireStore.collection("sync_x").doc(auid).ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						if (docData.u != auid) { hasBinding = true; }
					}
				}).catch(error => {});
			}

			if (hasBinding) {
				return this.promptAuthentication(true, () => {
					this.connectWithCloudAccount(true);
				});
			}

			return this.fireAuth.signInWithEmailAndPassword("eon_"+auid+"@textadventure.app", auid).then(async result => {
				await this.unsubscribeSynchronousVariables();
				await this.sync(result.user.uid, true, true, false, true, true, 0);
				return;
			}).catch(error => {
				if (error.code === "auth/user-not-found") {
					return this.promptAuthentication(true, () => {
						return this.signInAnonymously();
					});
				} else {
					return this.signInAnonymously();
				}
			});

		} else {

			return this.fireStore.collection("sync_auid").add({}).then(async result => {

				auid = result.id;

				if (this.currentUser) {
					let cloudCredential = EmailAuthProvider.credential("eon_"+auid+"@textadventure.app", auid);
					return this.currentUser.linkWithCredential(cloudCredential).then(async result_link => {
						await this.setCloudSettings("auid", auid);
						this.sync(result_link.user.uid, false, true, false, false, false, 0);
						await this.reloadUser();
						return;
					}).catch(error => {
						return this.fireAuth.createUserWithEmailAndPassword("eon_"+auid+"@textadventure.app", auid).then(async result_create => {
							await this.setCloudSettings("auid", auid);
							this.sync(result_create.user.uid, false, true, false, true, false, 0);
							return;
						}).catch(error => {
							return;
						});
					});
				} else {
					return this.fireAuth.createUserWithEmailAndPassword("eon_"+auid+"@textadventure.app", auid).then(async result_create => {
						await this.setCloudSettings("auid", auid);
						this.sync(result_create.user.uid, false, true, false, true, false, 0);
						return;
					}).catch(error => {
						return this.signInAnonymously();
					});					
				}

			}).catch(err => {
				return this.signInAnonymously();
			});

		}
	}

	async linkWithProvider(providerId, email, password, silent, tries) {
		if (tries > 3) {
			if (!silent) { this.molerts.alert_error(2019); }
			return;
		}
		if (!this.currentUser) { 
			if (!silent) { this.molerts.alert_error(2020); }
			return;
		}

		let credential = null;
		if (providerId === "password") {
			if (email && password) {
				credential = EmailAuthProvider.credential(email, password);
			}
		} else if (providerId === "google.com") {
			this.initializeGoogleAuth();
			await this.util.sleep(1000);
			await GoogleAuth.signIn().then((user) => {
				credential = GoogleAuthProvider.credential(user.authentication.idToken);
			}).catch((error) => {
				this.molerts.alert_error(2021);
				return;
			});
		} else if (providerId === "apple.com") {
			await SignInWithApple.authorize(this.signInWithApple_options).then((result: SignInWithAppleResponse) => {
				const provider = new OAuthProvider('apple.com');
				credential = provider.credential({ idToken: result.response.identityToken, rawNonce: this.signInWithApple_nonce });
			}).catch((error) => {
				this.molerts.alert_error(2022);
				return;
			});			
		}
		if (!credential) {
			this.molerts.alert_error(2023);
			return
		}

		return this.currentUser.linkWithCredential(credential).then(async result => {
			this.reloadUser();
			if (!silent) { 
				let buttons = [{ text: this.localization.ok, color: "--POSITIVE", handler: () => {} }];
				let message = this.localization.account_authenticatedViaPassword;
				if (providerId === "google.com") { message = this.localization.account_authenticatedViaGoogle; } 
				else if (providerId === "apple.com") { message = this.localization.account_authenticatedViaApple; }
				this.molerts.alert_basic(this.localization.success, "", message, "", "shield-check", "bounce", "--POSITIVE", buttons);
			}
			if (providerId === "password") { this.currentUser.sendEmailVerification(); }
			await this.setCloudSettings("provider_"+providerId, true);
			this.sync(result?.user?.uid, true, true, true, true, false, 0);
			return;
		}).catch(async error => {

			if (error.code === "auth/provider-already-linked") {

				if (providerId === "password") {
					let hasProvider_cloud = false;
					if (this.currentUser.providerData) {
						for (let provider of this.currentUser.providerData) {
							if (provider.providerId === "password") {
								if (provider.email.startsWith("eon_") && provider.email.endsWith("@textadventure.app")) {
									hasProvider_cloud = true;
									break;
								}
							}
						}
					}
					if (hasProvider_cloud) {
						return this.currentUser.unlinkProvider("password").then(async result => {
							return this.linkWithProvider(providerId, email, password, silent, tries+1);
						}).catch(error => {
							if (!silent) { this.molerts.alert_error(2001); }
							return;
						});
					} else {
						if (!silent) { this.molerts.alert_error(2002, null, this.localization.account_providerAlreadyLinked_password); }
						return;
					}
				}

				let message = this.localization.account_providerAlreadyLinked_google;
				if (providerId === "apple.com") { message = this.localization.account_providerAlreadyLinked_apple; }
				if (!silent) { this.molerts.alert_error(2003, null, message); }
				return;

			} else if (error.code === "auth/credential-already-in-use" || error.code === "auth/email-already-in-use") {

				if (!silent) { this.molerts.alert_error(2004, null, this.localization.account_authMethodAlreadyLinkedToAnotherAccount); }

			} else if (error.code === "auth/requires-recent-login") {

				let hasProvider_password;
				let hasProvider_google;
				let hasProvider_apple;
				let hasProvider_cloud;
				if (this.currentUser.providerData) {
					for (let provider of this.currentUser.providerData) {
						if (provider.providerId === "password") {
							if (provider.email.startsWith("eon_") && provider.email.endsWith("@textadventure.app")) {
								hasProvider_cloud = true;
							} else {
								hasProvider_password = true;
							}
						} else if (provider.providerId === "google.com") {
							hasProvider_google = true;
						} else if (provider.providerId === "apple.com") {
							hasProvider_apple = true;
						}
					}
				}
				if (hasProvider_password || hasProvider_google || hasProvider_apple) {
					return this.reAuthenticateAndTryAgain(this.localization.error, hasProvider_password, hasProvider_google, hasProvider_apple, () => {
						return this.linkWithProvider(providerId, email, password, silent, tries+1);
					});
				} else if (hasProvider_cloud) {
					let auid = await this.getAUID();
					let cloudCredential = EmailAuthProvider.credential(email, password);
					return this.currentUser.reauthenticateWithCredential(cloudCredential).then(async result => {
						return this.linkWithProvider(providerId, email, password, silent, tries+1);
					}).catch(async error => {
						if (!silent) { this.molerts.alert_error(2005); }
						return;
					});
				} else {
					return this.fireAuth.signOut().then(async result => {
						this.unsubscribeSynchronousVariables();
						if (!silent) { this.molerts.alert_error(2006); }
						return;						
					}).catch(async error => {
						if (!silent) { this.molerts.alert_error(2007); }
						return;
					});
				}

			}

		});
	}

	async authenticateWithAppleWeb(providerId, silent, tries, skipLink) {
		if (tries > 3) {
			if (!silent) { this.molerts.alert_error(2030); }
			return;
		}

		const provider = new OAuthProvider("apple.com");

		if (this.currentUser && !skipLink) {
			return this.currentUser.linkWithPopup(provider).then(async (result) => {
				this.molerts.alert_basic(this.localization.user_loginSuccess, "", "", "", "shield-check", "bounce", "--POSITIVE");
				await this.reloadUser();
				await this.setCloudSettings("provider_"+providerId, true);
				this.sync(result?.user?.uid, true, true, true, true, false, 0);
				return;
			}).catch(async error => {
				if (error.code === "auth/provider-already-linked") {
					let message = this.localization.account_providerAlreadyLinked_google;
					if (providerId === "apple.com") { message = this.localization.account_providerAlreadyLinked_apple; }
					if (!silent) { this.molerts.alert_error(2011, null, message); }
					return;
				} else if (error.code === "auth/credential-already-in-use" || error.code === "auth/email-already-in-use") {
					return this.authenticateWithAppleWeb(providerId, silent, tries, true);
				}
			});
		}
		
		return this.fireAuth.signInWithPopup(provider).then(async (result) => {
			await this.setCloudSettings("provider_"+providerId, true);
			await this.unsubscribeSynchronousVariables();
			this.sync(result?.user?.uid, true, true, true, true, false, 0);
			return;			
		}).catch(async error => {
			if (!silent) { this.molerts.alert_error(2012); }
			return;
		});

	}

	async authenticateWithExternalProvider(providerId, silent, tries, skipLink) {
		if (tries > 3) {
			if (!silent) { this.molerts.alert_error(2019); }
			return;
		}

		await this.device.getPlatform();
		if (providerId === "apple.com" && this.device.platform != "ios") {
			return this.authenticateWithAppleWeb(providerId, silent, tries, skipLink);
		}

		let credential = null;
		let authError: boolean = false;
		if (providerId === "google.com") {
			this.initializeGoogleAuth();
			await this.util.sleep(1000);
			await GoogleAuth.signIn().then((user) => {
				credential = GoogleAuthProvider.credential(user.authentication.idToken);
			}).catch((error) => {
				this.molerts.alert_error(2008);
				authError = true;
				return;
			});
		} else if (providerId === "apple.com") {
			await SignInWithApple.authorize(this.signInWithApple_options).then((result: SignInWithAppleResponse) => {
				const provider = new OAuthProvider('apple.com');
				credential = provider.credential({ idToken: result.response.identityToken, rawNonce: this.signInWithApple_nonce });
			}).catch((error) => {
				this.molerts.alert_error(2009);
				authError = true;
				return;
			});			
		}
		if (!credential && !authError) {
			this.molerts.alert_error(2010);
			return
		}

		if (this.currentUser && !skipLink) {
			return this.currentUser.linkWithCredential(credential).then(async result => {
				this.molerts.alert_basic(this.localization.user_loginSuccess, "", "", "", "shield-check", "bounce", "--POSITIVE");
				await this.reloadUser();
				await this.setCloudSettings("provider_"+providerId, true);
				this.sync(result?.user?.uid, true, true, true, true, false, 0);
				return;
			}).catch(async error => {
				if (error.code === "auth/provider-already-linked") {
					let message = this.localization.account_providerAlreadyLinked_google;
					if (providerId === "apple.com") { message = this.localization.account_providerAlreadyLinked_apple; }
					if (!silent) { this.molerts.alert_error(2011, null, message); }
					return;
				} else if (error.code === "auth/credential-already-in-use" || error.code === "auth/email-already-in-use") {
					return this.authenticateWithExternalProvider(providerId, silent, tries, true);
				}
			});
		}

		return this.fireAuth.signInWithCredential(credential).then(async result => {
			await this.setCloudSettings("provider_"+providerId, true);
			await this.unsubscribeSynchronousVariables();
			this.sync(result?.user?.uid, true, true, true, true, false, 0);
			return;
		}).catch(async error => {
			if (!silent) { this.molerts.alert_error(2012); }
			return;
		});

	}

	async signUpWithPassword(email, password, silent, tries) {
		if (tries > 3) {
			if (!silent) { this.molerts.alert_error(2025); }
			return;
		}

		if (this.currentUser) {
			let hasProvider_password = false;
			let hasProvider_cloud = false;
			let hasProvider_google = false;
			let hasProvider_apple = false;
			if (this.currentUser.providerData) {
				for (let provider of this.currentUser.providerData) {
					if (provider.providerId === "password") {
						if (provider.email.startsWith("eon_") && provider.email.endsWith("@textadventure.app")) {
							hasProvider_cloud = true;
						} else {
							hasProvider_password = true;
						}
					} else if (provider.providerId === "google.com") {
						hasProvider_google = true;
					} else if (provider.providerId === "apple.com") {
						hasProvider_apple = true;
					}
				}
			}

			if (hasProvider_password || hasProvider_google || hasProvider_apple) {
				this.molerts.alert_error(2026);
				return;
			} else {
				return this.linkWithProvider("password", email, password, silent, 0);
			}
		}

		return this.fireAuth.createUserWithEmailAndPassword(email, password).then(async result => {
			result?.user?.sendEmailVerification();
			await this.setCloudSettings("provider_password", true);
			await this.unsubscribeSynchronousVariables();
			this.sync(result?.user?.uid, false, true, true, true, false, 0);
			return;
		}).catch(async error => {

			if (error.code === "auth/email-already-in-use") {

				const buttons = [{ text: this.localization.ok, color: "--WARNING", handler: () => {} }];
				let message = this.localization.user_emailExists + " " + this.localization.user_emailExists_wantSignIn;
				if (!silent) { this.molerts.alert_basic(this.localization.account_signUp, this.localization.error, message, "", "envelope", "shake", "--WARNING", buttons); }
				return;

			} else if (error.code === "auth/invalid-email") {
				
				const buttons = [ { text: this.localization.ok, color: "--WARNING", handler: () => {} } ]
				if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, this.localization.user_invalidEmail, "", "envelope", "shake", "--DANGER", buttons); }
				return;

			} else if (error.code === "auth/weak-password") {

				const buttons = [ { text: this.localization.ok, color: "--WARNING", handler: () => {} } ]
				if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, this.localization.user_weakPassword, "", "key", "shake", "--DANGER", buttons); }
				return;
				
			} else {

				this.molerts.alert_error(2027);
				return;

			}

		});
	}

	async signInWithPassword(email, password, silent, tries) {
		if (tries > 3) {
			if (!silent) { this.molerts.alert_error(2013); }
			return;
		}

		return this.fireAuth.signInWithEmailAndPassword(email, password).then(async result => {
			await this.setCloudSettings("provider_password", true);
			await this.unsubscribeSynchronousVariables();
			this.sync(result?.user?.uid, true, true, true, true, false, 0);
			return;
		}).catch(async error => {			
			if (error.code === "auth/user-not-found") {

				if (email.indexOf("@gmail.") > -1 || email.indexOf("@googlemail.") > -1) {
					const buttons = [
						{ text: this.localization.account_signInWithGoogle, color: "--WARNING", handler: () => { this.authenticateWithExternalProvider("google.com", false, 0, false); } },
						{ text: this.localization.cancel, color: "--WARNING", class: "--LESS", handler: () => {} }
					]
					let message = this.localization.account_emailNotFound + " " + this.localization.account_emailNotFound_google;
					if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, message, "", "question", "shake", "--WARNING", buttons); }
				} else {
					const buttons = [
						{ text: this.localization.account_createAccount, color: "--WARNING", handler: () => {} },
						{ text: this.localization.cancel, color: "--WARNING", class: "--LESS", handler: () => {} }
					];
					let message = this.localization.account_emailNotFound + " " + this.localization.account_emailNotFound_wantCreateNow;
					if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, message, "", "question", "bounce", "--WARNING", buttons); }
				}
				return;

			} else if (error.code === "auth/wrong-password") {

				const buttons = [
					{ text: this.localization.user_forgotPassword, color: "--WARNING", handler: () => { this.sendPasswordResetEmail(email); } },
					{ text: this.localization.cancel, color: "--WARNING", handler: () => {} }
				]
				if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, this.localization.account_wrongPassword, "", "key", "shake", "--DANGER", buttons); }
				return;

			} else if (error.code === "auth/invalid-email") {
				
				const buttons = [ { text: this.localization.ok, color: "--WARNING", handler: () => {} } ]
				if (!silent) { this.molerts.alert_basic(this.localization.account_signIn, this.localization.error, this.localization.user_invalidEmail, "", "envelope", "shake", "--DANGER", buttons); }
				return;

			} else {

				if (!silent) { this.molerts.alert_error(2024); }
				return;

			}
		});
	}

	/* *************************************************************************************************** */
	/* ** AUTHENTICATE *********************************************************************************** */
	/* *************************************************************************************************** */

	async reAuthenticateAndTryAgain(header: string = "", hasProvider_password, hasProvider_google, hasProvider_apple, callback) {
		let buttons: Array<any> = [{ text: this.localization.cancel, role: "cancel", handler: () => { return; } }];
		if (hasProvider_password) {
			buttons.push({ text: "Email", handler: async () => {
				const alert = await this.alertController.create({
					subHeader: this.localization.settings_account_login,
					cssClass: 'eon__alert',
					message: this.localization.user_askForLogin,
					inputs: [
						{ name: "email", type: "email", placeholder: this.localization.email },
						{ name: "password", type: "password", placeholder: this.localization.password }
					],
					buttons: [
						{ text: this.localization.cancel, role: "cancel", handler: () => { return; } },
						{ text: this.localization.ok, handler: async (data) => {
							if (data.email && data.password) {
								await this.fireAuth.signInWithEmailAndPassword(data.email, data.password).then(async () => {
									callback();
								}).catch(async err => { this.molerts.alert_error(2013); })
							} else { this.molerts.alert_error(2014); }
						}}
					]

				});
				await alert.present();
			} });
		}
		if (hasProvider_google) {
			buttons.push({ text: "Google", handler: async () => {
				this.initializeGoogleAuth();
				await this.util.sleep(1000);
				await GoogleAuth.signIn().then(async (user) => {
					let credential = GoogleAuthProvider.credential(user.authentication.idToken);
					await this.currentUser.reauthenticateWithCredential(credential).then(() => {
						callback();
					}).catch((error) => {
						this.molerts.alert_error(2015);
						return;
					});
				}).catch((error) => {
					this.molerts.alert_error(2016);
					return;
				});
			} });
		}
		
		await this.device.getPlatform();
		if (hasProvider_apple && this.device.platform != "android") {
			buttons.push({ text: "Apple", handler: () => {
				SignInWithApple.authorize(this.signInWithApple_options).then((result: SignInWithAppleResponse) => {
					const provider = new OAuthProvider('apple.com');
					let credential = provider.credential({ idToken: result.response.identityToken, rawNonce: this.signInWithApple_nonce });
					this.currentUser.reauthenticateWithCredential(credential).then(() => {
						callback();
					}).catch((error) => {
						this.molerts.alert_error(2017);
						return;
					});
				}).catch(error => {
					this.molerts.alert_error(2018);
					return;
				});					
			} });
		}

		const alert = await this.alertController.create({
			subHeader: header,
			cssClass: 'eon__alert',
			message: this.localization.account_reauthNeeded,
			buttons: buttons
		});
		await alert.present();
	}

	initializeGoogleAuth() {
		if (this.instance.liberty) {
			GoogleAuth.initialize({ clientId: "866167593309-1jr298pa2i2ba9kcpufocr5oe6et3cap.apps.googleusercontent.com" })
		} else {
			GoogleAuth.initialize({});
		}
	}

	/* *************************************************************************************************** */
	/* ** MANAGE AUTHENTICATION ************************************************************************** */
	/* *************************************************************************************************** */	

	async unlinkProvider(providerId) {
		if (!this.currentUser) { return; }
		if (!this.currentUser.providerData) { return; }
		if (this.currentUser.providerData.length <= 1) {
			const alert = await this.alertController.create({
				subHeader: this.localization.error,
				cssClass: 'eon__alert',
				message: this.localization.account_unlinkProviderNotPossible,
				buttons: ['OK']
			});
			await alert.present();
			return;
		}

		this.currentUser.unlink(providerId).then(() => {
			this.reloadUser();
			this.setCloudSettings("provider_"+providerId, false);
		}).catch(error => {})
	}

	async updatePassword() {
		if (this.hasProvider_password) {
			const alert = await this.alertController.create({
				subHeader: this.localization.account_updatePassword_changePassword,
				cssClass: 'eon__alert',
				message: this.localization.account_updatePassword_enterNewPassword,
				inputs: [
					{ name: 'password', type: 'password', placeholder: this.localization.password },
					{ name: 'password2', type: 'password', placeholder: this.localization.account_repeatPassword }
				],
				buttons: [
					{ text: this.localization.cancel, role: 'cancel', cssClass: 'secondary', handler: () => {} },
					{ text: this.localization.confirm, handler: async (data) => {
							if (data.password === data.password2) {
								this.currentUser.updatePassword(data.password).then(async () => {
									const alert2 = await this.alertController.create({
										subHeader: this.localization.success,
										cssClass: 'eon__alert',
										message: this.localization.account_updatePassword_success,
										buttons: ['OK']
									})
									alert2.present();
									this.reloadUser();
								}).catch(async error => {
									if (error.code === "auth/requires-recent-login") {
										this.reAuthenticateAndTryAgain(this.localization.account_updatePassword_changePassword, this.hasProvider_password, this.hasProvider_google, this.hasProvider_apple, () => {
											this.currentUser.updatePassword(data.password).then(async () => {
												const alert2 = await this.alertController.create({
													subHeader: this.localization.success,
													cssClass: 'eon__alert',
													message: this.localization.account_updatePassword_success,
													buttons: ['OK']
												})
												alert2.present();
												this.reloadUser();											
											}).catch(async error => {
												this.molerts.alert_errorFirebase(error);
											});
										})
									} else {
										this.molerts.alert_errorFirebase(error);
									}
								})
							} else {
								const alert2 = await this.alertController.create({
									subHeader: this.localization.error,
									cssClass: 'eon__alert',
									message: this.localization.account_passwordsDontMatch,
									buttons: ['OK']
								})
								alert2.present();
							}
						}
					}
				]
			});
			await alert.present();
		}
	}

	sendPasswordResetEmail(email) {
		email = email.replace(/\s/g, "");

		return this.fireAuth.sendPasswordResetEmail(email).then( async () => {
			const alert = await this.alertController.create({
				subHeader: this.localization.account_passwordReset,
				cssClass: 'eon__alert',
				message: this.localization.account_passwordResetEmailSent,
				buttons: ['OK']
			})
			await alert.present();
		}).catch(async (error) => {

			if (error.code === "auth/invalid-email") {

				this.molerts.alert_error(2028, this.localization.error, this.localization.user_invalidEmail);
				return;

			} else if (error.code === "auth/user-not-found") {

				if (email.indexOf("@gmail.") > -1 || email.indexOf("@googlemail.")) {
					const buttons = [
						{ text: this.localization.account_signInWithGoogle, color: "--WARNING", handler: () => { this.authenticateWithExternalProvider("google.com", false, 0, false); } },
						{ text: this.localization.cancel, color: "--WARNING", class: "--LESS", handler: () => {} }
					]
					let message = this.localization.account_emailNotFound + " " + this.localization.account_emailNotFound_google;
					this.molerts.alert_basic(this.localization.account_passwordReset, this.localization.error, message, "", "envelope", "shake", "--DANGER", buttons);
					return;
				} else {
					const buttons = [
						{ text: this.localization.ok, color: "--WARNING", handler: () => {} }
					]
					let message = this.localization.account_emailNotFound;
					this.molerts.alert_basic(this.localization.account_passwordReset, this.localization.error, message, "", "envelope", "shake", "--DANGER", buttons);
					return;
				}

			} else {

				const buttons = [{ text: this.localization.ok, color: "--WARNING", handler: () => {} }]
				this.molerts.alert_basic(this.localization.account_passwordReset, this.localization.error, this.localization.account_passwordResetEmailError, "", "envelope", "shake", "--DANGER", buttons);
				return;

			}


		});
	}

	sendEmailVerification() {
		this.fireAuth.currentUser.then(async (currentUser) => {
			if (currentUser) {
				currentUser.sendEmailVerification().then(async () => {
					let buttons = [{ text: this.localization.ok, color: "--POSITIVE", handler: () => {} }];
					let message = this.localization.user_verificationEmail_2 + currentUser.email + this.localization.user_verificationEmail_3;
					this.molerts.alert_basic(this.localization.success, "", message, "", "envelope", "bounce", "--POSITIVE", buttons);
				}).catch(async (error) => {
					this.molerts.alert_error(2029);
				});
			}
		});
	}

	deleteAccount(warnAuthor: boolean = true) {
		const buttons = [
			{
				text: this.localization.cancel,
				color: "--WARNING",
				handler: () => {}
			},{
				text: this.localization.account_delete_irrevocably,
				class: "--LESS",
				color: "--DANGER",
				handler: () => {

					const buttons2 = [
						{
							text: this.localization.cancel,
							color: "--WARNING",
							handler: () => {}
						},{
							text: this.localization.confirm,
							class: "--LESS",
							color: "--DANGER",
							handler: () => {
								
								const buttons3 = [
									{
										text: this.localization.cancel,
										color: "--WARNING",
										handler: () => {}
									},{
										text: this.localization.account_delete,
										class: "--LESS",
										color: "--DANGER",
										handler: async () => {

											let uid = this.currentUser.uid+"";
											await this.fireStore.collection("sync").doc(uid).collection("vars_a").doc("usr_name").set({ v: "Unknown" }).then(() => {}).catch(() => {});
											await this.fireStore.collection("sync").doc(uid).collection("vars_a").doc("deleted").set({ v: true }).then(() => {}).catch(() => {});

											this.currentUser.delete().then(async () => {

												await this.storage.set("preventCloudUser", true);

												const buttons4 = [
													{
														text: this.localization.ok,
														color: "--WARNING",
														handler: () => {
															window.location.href = "index.html";
														}
													}
												]
												this.molerts.alert_basic(this.localization.account_deleted, "", this.localization.account_deleted_explanation, "", "door-open", "bounce", "--WARNING", buttons4);

												setTimeout(() => { window.location.href = "index.html"; }, 5000);

											}).catch(async (error) => {
												if (error.code === "auth/requires-recent-login") {
													this.reAuthenticateAndTryAgain(this.localization.account_delete, this.hasProvider_password, this.hasProvider_google, this.hasProvider_apple, async () => {
														const alert = await this.alertController.create({
															subHeader: this.localization.success,
															cssClass: 'eon__alert',
															message: this.localization.thanks_canContinue,
															buttons: ['OK']
														});
														await alert.present();
													})
												}
											});

										}
									}
								]
								this.molerts.alert_basic(this.localization.account_delete, "", this.localization.account_delete_deviceRemains, "", "skull", "fade", "--DANGER", buttons3);

							}
						}
					]
					this.molerts.alert_basic(this.localization.account_delete, "", this.localization.account_delete_author_explanation, "", "skull", "bounce", "--WARNING", buttons2);

				}
			}
		]
		this.molerts.alert_basic(this.localization.account_delete, "", this.localization.account_delete_explanation, "", "skull", "fade", "--DANGER", buttons);
	}

	/* *************************************************************************************************** */
	/* ** LOGOUT ***************************************************************************************** */
	/* *************************************************************************************************** */	

	async logout() {
		const buttons = [
			{
				text: this.localization.cancel,
				color: "--WARNING",
				handler: () => {}
			},{
				text: this.localization.confirm,
				class: "--LESS",
				color: "--DANGER",
				handler: async () => {
					const molert = await this.modalController.create({
						component: SyncPage,
						cssClass: "EON__MOLERT --ALERT",
						componentProps: {}
					})
					molert.present();
					await this.storage.set("preventCloudUser", true);
					await this.fireAuth.signOut().then(async () => {
						this.unsubscribeSynchronousVariables();
						window.location.href = "index.html";
					});
				}
			}
		]
		this.molerts.alert_basic(this.localization.account_signoutConfirmation, "", this.localization.account_signoutWarning, "", "door-open", "fade", "--DANGER", buttons);
	}

	/* *************************************************************************************************** */
	/* ** CLOUD SETTINGS ********************************************************************************* */
	/* *************************************************************************************************** */

	async getCloudSettings() {
		await this.device.getPlatform();
		let cloudSettingsExist = false; await this.cloudSettings.exists().then(result => { 
			if (result) { cloudSettingsExist = true; } 
		}).catch(async error => {
			return {};
		});

		if (cloudSettingsExist) { 
			await this.cloudSettings.load().then(cloudSettings => { 
				return cloudSettings;
			}).catch(async error => {
				return {};
			});
		}

		return {};
	}

	async getAUID() {
		let cloudSettings: any = await this.getCloudSettings();
		if (cloudSettings.auid) { return cloudSettings.auid; }
		let storage_auid = null; await this.storage.get("auid").then(async result => { storage_auid = result; });
		if (storage_auid) { return storage_auid; }
		return null;
	}

	async setCloudSettings(key, value) {
		if (key === "auid") { await this.storage.set("auid", value); }

		await this.device.getPlatform();
		if (this.device.platform === "ios" || this.device.platform === "android") {
			let cloudSettingsExist = false; await this.cloudSettings.exists().then(result => { if (result) { cloudSettingsExist = true; } } )
			let cloudSettings: any = {}; if (cloudSettingsExist) { await this.cloudSettings.load().then(settings => { cloudSettings = settings; }) }
	
			cloudSettings[key] = value;
	
			if (key.startsWith("provider_") && value === true) { cloudSettings.wasAuthenticated = true; }
	
			await this.cloudSettings.save(cloudSettings, true).then(() => {}).catch(error => {});			
		}
	}

	async checkCloudSettings() {
		let cloudSettingsExist = false; await this.cloudSettings.exists().then(result => { if (result) { cloudSettingsExist = true; } })
		if (cloudSettingsExist) {
			await this.cloudSettings.load().then(async settings => {});
		}
	}

	async deleteCloudSettings() {
		await this.cloudSettings.save({}, true).then(() => {})
	}

	/* *************************************************************************************************** */
	/* ** SYNC ******************************************************************************************* */
	/* *************************************************************************************************** */	

	async sync(forceUID, pull: boolean, push: boolean, binding: boolean, reload: boolean, silent: boolean, tries: number = 0) {

		if (tries > 10) { return; }
		
		if (!this.currentUser) { 
			this.unsubscribe_synchronousVariables();
			return;
		}

		if (this.currentUser.uid != forceUID) {
			return setTimeout(() => {
				return this.sync(forceUID, pull, push, binding, reload, silent, tries+1);
			}, 1000);
		}

		let uid = this.currentUser.uid;

		const molert = await this.modalController.create({
			component: SyncPage,
			cssClass: "EON__MOLERT --ALERT",
			componentProps: {}
		})
		if ((pull || push) && !silent) { molert.present(); }

		let pulledKeys = [];

		if (binding) {
			let cloudSettings: any = await this.getCloudSettings();
			if (cloudSettings.auid) {
				await this.fireStore.collection("sync_x").doc(cloudSettings.auid).set({ u: uid }).then(() => {}).catch(() => {})
			}
		}		

		if (pull) {
			let varsToBeSet = [];
			await this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.get().then(async querySnapshot_s => {
				querySnapshot_s.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
			await this.fireStore.collection("sync").doc(uid).collection("vars_a").ref.get().then(querySnapshot_a => {
				querySnapshot_a.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
	
			for await (let item of varsToBeSet) {
				if (item.key === "sys_tickets" || item.key === "sys_nutrition" || item.key === "sys_shards") {
					let currentValue = 0; await this.varias.getVariable(item.key).then(result => { currentValue = result; })
					if (item.val > currentValue) { await this.varias.setVariable(item.key, item.val, true, true); }
				} else if (item.key.endsWith("_ign")) {
				} else {
					pulledKeys[item.key] = true;
					await this.varias.setVariable(item.key, item.val, true, true);
				}			
			}
		}

		if (push) {
			let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })	
			let varsToBePushed = [];
			for await (let fullKey of keys) {
				if (fullKey.indexOf("var_") > -1) {
					let value; await this.storage.get(fullKey).then((returnedValue) => { value = returnedValue; })
					let key = fullKey.replace("var_", "");
					if (!pulledKeys[key]) {
						varsToBePushed.push({ key: key, value: value });
					}
				}
			}
			for await (let item of varsToBePushed) {
				let value = item.value;
				if (value === "true") { value = true; }
				else if (value === "false") { value = false; }
				else if (!isNaN(value)) { value = parseInt(value); }
				await this.varias.syncVariable(item.key, value);
			}	
		}

		if ((pull || push) && !silent) { molert.dismiss(); }

		if (reload) {
			window.location.href = "index.html";
		}

		return;
	}

	async pullAllFromSync() {
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			let varsToBeSet = [];
	
			await this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.get().then(async querySnapshot_s => {
				querySnapshot_s.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
	
			await this.fireStore.collection("sync").doc(uid).collection("vars_a").ref.get().then(querySnapshot_a => {
				querySnapshot_a.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
	
			for await (let item of varsToBeSet) {
				if (item.key === "sys_tickets" || item.key === "sys_nutrition" || item.key === "sys_shards") {
					let currentValue = 0; await this.varias.getVariable(item.key).then(result => { currentValue = result; })
					if (item.val > currentValue) { await this.varias.setVariable(item.key, item.val, true, true); }
				} else if (item.key.endsWith("_ign")) {
				} else {
					await this.varias.setVariable(item.key, item.val, true, true);
					console.log("-> pull");
				}
			}
		}
	}

	async pushAllToSync() {
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })
			let vars = [];
			for await (let fullKey of keys) {
				if (fullKey.indexOf("var_") > -1) {
					let value; await this.storage.get(fullKey).then((returnedValue) => { value = returnedValue; })
					let key = fullKey.replace("var_", "");
					vars.push({ k: key, v: value })
				}
			}
			for await (let item of vars) {
				let value = item.v;
				if (value === "true") { value = true; }
				else if (value === "false") { value = false; }
				else if (!isNaN(value)) { value = parseInt(value); }
				await this.varias.syncVariable(item.k, value);
				console.log("-> push");
			}
		}
	}	

	/* *************************************************************************************************** */
	/* ** LEGACY ***************************************************************************************** */
	/* *************************************************************************************************** */

	async checkSyncAfterUpdate3() {
		let checked = false; await this.varias.getVariable("syncedAfterUpdate3").then(result => { if (result) { checked = true; }});
		if (!checked) {
			if (this.currentUser.uid) {
				this.fireStore.collection("sync").doc(this.currentUser.uid).ref.get().then(doc => { 
					if (!doc.exists) {
						this.fireStore.collection("sync").doc(this.currentUser.uid).collection("vars_s").ref.get().then(async snapshot => {
							if (snapshot.size <= 1) {
								await this.varias.pushAllToSync();
								this.varias.setVariable("syncedAfterUpdate3", true);
							}
						})
					}
				})
			}
		}
	}	

	async promptUploadDownload() {
		if (!this.currentUser) {
			const alert = await this.alertController.create({
				subHeader: this.localization.user_loginNecessary,
				cssClass: 'eon__alert',
				message: this.localization.user_needToLoginForMigration,
				buttons: [
				  	{
						text: this.localization.no,
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => { return; }
				  	}, {
						text: 'Login',
						handler: () => {
							this.navigation.pushAccount();
							return;  
						}
				  	}
				]
			});
			await alert.present();
		} else {
			const actionSheet = await this.alertController.create({
				subHeader: this.localization.settings_account_changeDevice,
				buttons: [
					{
						text: this.localization.user_isOldDevice,
						handler: () => { this.uploadData(); }
					},{
						text: this.localization.user_isNewDevice,
						handler: () => { this.downloadData(); }
					},{
						text: this.localization.cancel,
						role: "cancel",
						handler: () => {}
					}
				]
			});
			await actionSheet.present();
		}		
	}

	async uploadData() {
		console.log("UPLOAD DATA");
		if (this.currentUser === null) {
			const alert = await this.alertController.create({
				subHeader: this.localization.user_loginNecessary,
				cssClass: 'eon__alert',
				message: this.localization.user_needToLoginForUpload,
				buttons: [
				  	{
						text: this.localization.no,
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => { return; }
				  	}, {
						text: 'Login',
						handler: () => {
							this.navigation.pushAccount();
							return;  
						}
				  	}
				]
			  });
			  await alert.present();
		} else {
			await this.currentUser.reload();

			let providerNeedsEmailVerification = true;
			this.currentUser.providerData.forEach(provider => {
				if (provider.providerId === "github.com" || provider.providerId === "google.com" || provider.providerId === "twitter.com") {
					providerNeedsEmailVerification = false;
				}
			})

			if (providerNeedsEmailVerification && !this.currentUser.emailVerified) {
				const alert = await this.alertController.create({
					subHeader: this.localization.user_emailNotVerified_1,
					cssClass: 'eon__alert',
					message: this.localization.user_emailNotVerified_2,
					buttons: ['OK']
				});
				await alert.present();
			} else {
				let uid = this.currentUser.then(result => uid = result.uid);

				let uploadLimitReached = false;
				let migrationLimitReached = false;
				let now = Date.now();
				let markerDoc: any = {}; await this.fireStore.collection("users").doc(uid).ref.get().then(doc => { if (doc.exists) { markerDoc = doc.data(); } })
				if (!markerDoc.uM) { markerDoc.uM = now; }
				if (!markerDoc.uC) { markerDoc.uC = 0; }
				if (now-markerDoc.uM < 1000*60*60*24) { if (markerDoc.uC >= 3) { uploadLimitReached = true; } }
				if (markerDoc.dM) { if (now-markerDoc.dM < 1000*60*60*24*30*6) { migrationLimitReached = true; } }

				if (uploadLimitReached) {
					const alert = await this.alertController.create({
						subHeader: this.localization.problem,
						cssClass: 'eon__alert',
						message: this.localization.user_migrationEvery24Hours,
						buttons: ['OK']
					});
					await alert.present();			
				} else if (migrationLimitReached) {
					const alert = await this.alertController.create({
						subHeader: this.localization.problem,
						cssClass: 'eon__alert',
						message: this.localization.user_migrationEvery180Days,
						buttons: ['OK']
					});
					await alert.present();
				} else {
					markerDoc.uC++;
					const loading = await this.loadingController.create({
						message: this.localization.user_uploadingData
					});
					await loading.present();
		
					let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })
					let vars = [];
					for await (let fullKey of keys) {
						if (fullKey.indexOf("var_") > -1) {
							let value; await this.storage.get(fullKey).then((returnedValue) => { value = returnedValue; })
							let key = fullKey.replace("var_", "");
							vars.push({ k: key, v: value })
							//await this.fireStore.collection("users").doc(uid).collection("uvars").doc(key).set({v: value});
						}
					}
					markerDoc.vars = vars;
		
					await this.fireStore.collection("users").doc(uid).set(markerDoc);
					await this.storage.set("uploadMarker", markerDoc.uM);
					await this.storage.set("uploadCount", markerDoc.uC);
		
					loading.dismiss();
		
					const alert = await this.alertController.create({
						subHeader: this.localization.user_uploadFinished_1,
						cssClass: 'eon__alert',
						message: this.localization.user_uploadFinished_2,
						buttons: ['OK']
					});
					await alert.present();
				}
			}
		}
	}

	async downloadData() {
		if (this.currentUser === null) {
			const alert = await this.alertController.create({
				subHeader: this.localization.user_loginNecessary,
				cssClass: 'eon__alert',
				message: this.localization.user_needToLoginForDownload,
				buttons: [
				  	{
						text: this.localization.no,
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => { return; }
				  	}, {
						text: 'Login',
						handler: () => {
							this.navigation.pushAccount();
							return;  
						}
				  	}
				]
			  });
			  await alert.present();
		} else {
			await this.currentUser.reload();

			let providerNeedsEmailVerification = true;
			this.currentUser.providerData.forEach(provider => {
				if (provider.providerId === "github.com" || provider.providerId === "google.com" || provider.providerId === "twitter.com") {
					providerNeedsEmailVerification = false;
				}
			})

			if (providerNeedsEmailVerification && !this.currentUser.emailVerified) {
				const alert = await this.alertController.create({
					subHeader: this.localization.user_emailNotVerified_1,
					cssClass: 'eon__alert',
					message: this.localization.user_emailNotVerified_2,
					buttons: ['OK']
				});
				await alert.present();
			} else {
				let uid = this.currentUser.then(result => uid = result.uid);

				let now = Date.now();
				let uploadExists = false;
				let downloadLimitReached = false;
				let uploadMarkerOnDevice; await this.storage.get("uploadMarker").then(value => { uploadMarkerOnDevice = value; } );
				let markerDoc: any = {}; await this.fireStore.collection("users").doc(uid).ref.get().then(doc => { if (doc.exists) {
					markerDoc = doc.data();
					uploadExists = true;
				} })
				if (!markerDoc.dM) { markerDoc.dM = now;}
				if (!markerDoc.dC) { markerDoc.dC = 0; }
				if (markerDoc.dC > 0) { if (now-markerDoc.dM < 1000*60*60*24*30*6) { downloadLimitReached = true; } }
			
				if (!uploadExists) {
					const alert = await this.alertController.create({
						subHeader: this.localization.problem,
						cssClass: 'eon__alert',
						message: this.localization.user_noUploadedData,
						buttons: ['OK']
					});
					await alert.present();
				} else if (markerDoc.uM === uploadMarkerOnDevice) {
					const alert = await this.alertController.create({
						subHeader: this.localization.problem,
						cssClass: 'eon__alert',
						message: this.localization.user_noDownloadOnSameDevice,
						buttons: ['OK']
					});
					await alert.present();
				} else if (downloadLimitReached) {
					const alert = await this.alertController.create({
						subHeader: this.localization.problem,
						cssClass: 'eon__alert',
						message: this.localization.user_migrationEvery180Days,
						buttons: ['OK']
					});
					await alert.present();					
				} else {
					const loading = await this.loadingController.create({
						message: this.localization.user_downloadingData
					});
					await loading.present();

					for await (let aVariable of markerDoc.vars) {
						if (aVariable.k != "scene" && aVariable.k != "rating" && aVariable.k != "nutrition" && aVariable.k != "tickets" && aVariable.k != "username" && aVariable.k != "echo" && aVariable.k != "modal") {
							this.varias.setVariable(aVariable.k, aVariable.v, true);
						}
					}

					this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.get().then(querySnapshot => {
						querySnapshot.forEach(doc => { this.varias.setVariable(doc.id, doc.data().v, true); })
					})
					this.fireStore.collection("sync").doc(uid).collection("vars_a").ref.get().then(querySnapshot => {
						querySnapshot.forEach(doc => { this.varias.setVariable(doc.id, doc.data().v, true); })
					})


					markerDoc.dC++;
					this.fireStore.collection("users").doc(uid).ref.update({
						uM: markerDoc.uM,
						uC: markerDoc.uC,
						dM: markerDoc.dM,
						dC: markerDoc.dC
					});

					loading.dismiss();

					const alert = await this.alertController.create({
						subHeader: this.localization.downloadComplete,
						cssClass: 'eon__alert',
						message: this.localization.user_downloadComplete,
						buttons: ['OK']
					});
					await alert.present();
				}				
			}
		}
	}

	async resetMigrationLimits() {
		if (this.currentUser) {
			let uid = this.currentUser.then(result => uid = result.uid);
			this.fireStore.collection("users").doc(uid.toString()).update({ dC: null, dM: null, uC: null, uM: null });
			await this.storage.set("uploadMarker", null);
			await this.storage.set("uploadCount", null);
		}
	}

}