import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  	providedIn: 'root'
})
export class LibertyService {
	
	beforeEvent: any = null;
	added: boolean = false;

  	constructor(
		private platform: Platform
	) {

		if (this.platform.is("android")) { this.added = true; }

		window.addEventListener('beforeinstallprompt', (e) => {
			this.beforeEvent = e;
			if (this.platform.is("android")) { this.added = false; }
		});

		if (this.platform.is("ios") && this.platform.is("pwa")) { this.added = true; }

		this.platform.ready().then(() => {
			setTimeout(() => { if (this.platform.is("android")) { screen.orientation.lock("portrait"); } }, 5000);
		})
	}

	install() {
		this.beforeEvent.prompt(); 
    	this.beforeEvent.userChoice.then((response) => {
     		if (response.outcome === 'accepted') {
       			console.log('yes, please install');
     		} else {
       			console.log('No thanks, I am good!');
     		}
  		});
	}

	reload() {
		/*
		let wind: any = window;
		wind.location.reload(true);
		*/
		const even = new Event("updatepwa");
		window.dispatchEvent(even);
	}
}