import { IonContent, DomController, AlertController, ActionSheetController, ModalController, GestureController, IonTextarea } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrowserService } from '../../system/browser.service';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';
import { UserService } from 'src/app/cloud/user.service'

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ProjectService } from '../project.service';
import { AuthorService } from 'src/app/cloud/author.service';
import { BootService } from 'src/app/system/boot.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {
	@Input() projectId: string = "null";
	@Input() sceneId: string = "null";
	@Input() item: any = { i: "null" };
	@Input() asModal: any;
	@Input() mode: any;
	@Input() pushStep: any = () => {};

	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("inputArea", { static: false } ) inputArea: IonTextarea;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	dbPath: AngularFirestoreCollection = null;
	comments: Array<any> = [];
	autoScrollBlocked: boolean = false;
	typing: boolean = false;
	inputText: string = "";
	gst_blockAutoScroll: any = null;
	sync_comments: any = null;
	i: number = 0;

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private actionSheetController: ActionSheetController,
		private modalController: ModalController,
		private gestureController: GestureController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		public project: ProjectService,
		private user: UserService,
		public author: AuthorService,
		private iab: BrowserService,
		private route: ActivatedRoute
	) { }

  	ngOnInit() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			if (!this.projectId) { this.projectId = "CCD9sQXTf8OxkOO8Tmn6"; }
		})
		this.project.syncChat(this.projectId);
		this.project.syncAuthors(this.projectId);
	}

	ionViewDidEnter() {
		this.dbPath = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("chat");
		if (this.mode === "comment") { 
			this.dbPath = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("items").doc(this.item.i).collection("comments");
			this.syncComments();
		}
		setTimeout(() => {
			if (this.inputText === "") {
				this.inputText = " ";
				setTimeout(() => {
					if (this.inputText === " ") {
						this.inputText = "";
					}
				}, 100);
			}
		}, 100);
	}

	updateMessage(changedMessage) {
		let exists = false;
		for (let message of this.comments) {
			if (message.id === changedMessage.id) {
				message = changedMessage;
				exists = true;
			}
		}
		if (!exists) {
			this.comments.push(changedMessage);
		}
	}

	syncComments() {
		if (!this.sync_comments) {
			this.sync_comments = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("items").doc(this.item.i).collection("comments").ref.onSnapshot( snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					if (docData.t.startsWith("https://") || docData.t.startsWith("www.")) { docData.l = true; }
					let index = this.comments.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { this.comments[index] = docData; } 
						else { this.comments.push(docData); }
					} else if (change.type === "removed") {
						if (index > -1) { this.comments.splice(index,1); }
					}
				})
			})
		}
	}	

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	close() {
		if (this.asModal) { this.modalController.dismiss(); }
		else { this.navigation.pop(); }
	}

	async initGestures() {
		this.gst_blockAutoScroll = this.gestureController.create({
			el: this.content.nativeElement,
			gestureName: "blockAutoScroll",
			direction: "x",
			maxAngle: 100,
			disableScroll: false,
			onStart: () => {
				this.autoScrollBlocked = true;
			},
			onMove: (ev) => {
				if (ev.deltaY > 10 || ev.deltaY < -10) {
					this.contextMenuBlocked = true;
				}
			},
			onEnd: (ev) => {
				if (ev.deltaY > 0) {
					this.autoScrollBlocked = true;
				} else {
					this.ionContent.getScrollElement().then(scrollEl => {
						let fromBottom = scrollEl.scrollHeight - window.innerHeight - scrollEl.scrollTop;
						if (fromBottom <= 80) { this.autoScrollBlocked = false; } 
						else { this.autoScrollBlocked = true; }
					})
				}
			}
		})
		this.gst_blockAutoScroll.enable(true);
	}	

	async scrollToBottom(force) {
		if (!this.autoScrollBlocked || force) {
			await this.ionContent.scrollToBottom(400);
		}
		return;
	}	

	timeout_typing: any;
	startTyping() {
		clearTimeout(this.timeout_typing);
		if (!this.typing) { this.scrollToBottom(false); }
		this.typing = true;
	}
	stopTyping() {
		this.timeout_typing = setTimeout(() => { this.typing = false; }, 400);
	}

	async focusInputArea(force) {
		if (this.typing || force) {
			this.inputArea.setFocus();
			/*
			let textArea = await this.inputArea.getInputElement();
			textArea.focus();
			*/
		}
	}

	timeout_touch: any = null;
	time_touchstart: number = 0;
	touch_waitingForMenu: boolean = false;
	contextMenuBlocked: boolean = false;
	touchstart(ev, message) {
		this.contextMenuBlocked = false;
		this.touch_waitingForMenu = true;
		this.time_touchstart = Date.now();
		this.timeout_touch = setTimeout(() => {
			if (this.touch_waitingForMenu && !this.contextMenuBlocked) {
				this.contextMenu(ev, message);
				this.touch_waitingForMenu = false;
			}
		}, 1000);
	}
	touchend(ev, message) {
		clearTimeout(this.timeout_touch);
		let now = Date.now();
		if (now - this.time_touchstart > 300) {
			if (this.touch_waitingForMenu && !this.contextMenuBlocked) {
				this.contextMenu(ev, message);
				this.touch_waitingForMenu = false;
			}
		}
	}
	
	async contextMenu(ev, message) {
		if (this.user.currentUser) {
			if (message.aid === this.user.currentUser.uid) {
				const actionSheet = await this.actionSheetController.create({
					header: "Modifications",
					buttons: [
						{
							text: "Löschen",
							handler: () => { this.deleteMessage(message); }
						},{
							text: 'Cancel',
							icon: 'close',
							handler: () => {}					
						}
					]
				})
				actionSheet.present();
			}
		}
	}

	sendMessage(ev) {
		ev.preventDefault();
		if (this.inputText === "") {
			this.focusInputArea(true);
		} else {
			if (this.user.currentUser) {
				let now = Date.now();
				this.dbPath.add({
					t: this.inputText,
					uid: this.user.currentUser.uid,
					at: now
				})
				this.inputText = "";
				this.focusInputArea(true);
				if (this.mode === "comment") {
					this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("items").doc(this.item.i).update({ com: true });
				}
				this.scrollToBottom(false);
			}
		}
	}

	deleteMessage(message) {
		this.dbPath.doc(message.id).delete().then(() => {}).catch(() => {});
	}

	updateItem() {
		if (this.item && this.pushStep) {
			this.pushStep(this.item);
		}
	}

	clickItem(item) {
		if (item.l) {
			if (item.t.startsWith("https://")) {
				this.iab.create(item.t, '_system', 'location=yes');
			} else if (item.t.startsWith("www.")) {
				this.iab.create("https://"+item.t, '_system', 'location=yes');
			}
		}
	}

}