import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Injectable({
  	providedIn: 'root'
})
export class SyncService {

	constructor(
		private fireAuth: AngularFireAuth,
		private fireStore: AngularFirestore
	) {
	}
	  
	setVariable(key, value) {
	}
}
