import { AlertController, Platform, LoadingController } from '@ionic/angular'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Events } from '../system/events.service'
import { VariasService } from '../system/varias.service'
import { PurchaseService } from '../cloud/purchase.service'
import { EngineService } from '../system/engine.service'
import { NavigationService } from '../system/navigation.service'
import { SignalService } from '../cloud/signal.service'
import { LocalizationService } from '../system/localization.service'
import { TelemetryService } from '../cloud/telemetry.service'
import { MetaService } from './meta.service';
import { AdsService } from '../cloud/ads.service'
import { ModalsService } from '../system/modals.service';
import { InstanceService } from '../system/instance.service';

@Injectable({
  	providedIn: 'root'
})
export class PurrchaseService {

	requestedStoryboards: Array<string> = [];

	event_membershipOwned: any;
	event_seasonOwned: any;
	event_episodeOwned: any;
	event_fair2playUsed: any;
	loadingElement: any;
	availableForBonusAfterShards: Array<any> = [];

	baseSunday = 0;
	isSunday: boolean = false;
	nextSunday: string = "---";
	now: any;
	sundayPeriod: any = 0;

	constructor(
		private http: HttpClient,
		private varias: VariasService,
		private events: Events,
		private purchase: PurchaseService,
		private engine: EngineService,
		private navgiation: NavigationService,
		private signal: SignalService,
		private localization: LocalizationService,
		private telemetry: TelemetryService,
		private meta: MetaService,
		private firestore: AngularFirestore,
		private ads: AdsService,
		private alertController: AlertController,
		private modals: ModalsService,
		private instance: InstanceService
	) {

		this.events.subscribe("purrchaseEpisode", (data) => {
			this.requestEpisode(data.storyboardInfo, data.storyboardsRegistry);
		})
		this.events.subscribe("alertNoAds", (data) => {
			this.showPurchaseOptions(data.episodeId, data.message, "adsRejected");
		})
		this.events.subscribe("offerSpecial", (data) => {
			this.offerSpecial(data.specialId);
		})
		this.events.subscribe("showPurchaseOptions", (data) => {
			this.showPurchaseOptions(data.episodeId, this.localization.purrchase_options, "other");
		})
	}

	async checkF2P() {
		if (this.signal.connected) {
			this.http.get("https://avoidsociety.app/f2p").subscribe(async response => {
				if (response) {
					let result: any = JSON.parse(JSON.stringify(response));
					if (result.r) { this.isSunday = true; } 
					else { this.isSunday = false; }
					if (this.localization.lang === "DE") { this.nextSunday = result.de; } 
					else { this.nextSunday = result.en; }
				} else { this.isSunday = false; }
			})
		} else { this.signal.alertNoConnection(this.localization.signal_blank); }
	}

	async requestEpisode(storyboardInfo, storyboardsRegistry) {
		if (this.signal.connected) {
			this.http.get('https://avoidsociety.app/fair2play?episode='+storyboardInfo.i).subscribe(async response => {
				if (response) {
					let result: any = JSON.parse(JSON.stringify(response));
					this.selectDialog(storyboardInfo, storyboardsRegistry, result);
				} else {
					let result = { r: false, m: "error-global", no: 0 };
					this.selectDialog(storyboardInfo, storyboardsRegistry, result);
					this.signal.alertNoConnection(this.localization.signal_blank);
				}
				if (this.engine.loadingElement) { this.engine.loadingElement.dismiss(); }
			});
		} else { this.signal.alertNoConnection(this.localization.signal_blank); }
	}
	  
	async selectDialog(storyboardInfo, storyboardsRegistry, f2pCall) {
		if (f2pCall.no) { this.sundayPeriod = f2pCall.no; }
		let f2pAnswer = f2pCall.m;

		this.purchase.requestProduct("ep_unlock_"+storyboardInfo.i).then( owned => { if (owned) { episodeOwned = true; } })
		
		let episodeOwned = false; await this.purchase.requestProduct("ep_unlock_"+storyboardInfo.i).then( owned => { if (owned) { episodeOwned = true; } })
		if (episodeOwned) { this.startEpisode(storyboardInfo, "owned"); return; }

		let episodeUnlocked = false; await this.varias.getVariable(storyboardInfo.i+"_unl").then(result => { if (result) { episodeUnlocked = true; } });
		let episodeSkippable = false; await this.varias.getVariable(storyboardInfo.i+"_skp").then(result => { if (result) { episodeSkippable = true; } });
		let episodeSubscribed = false; await this.varias.getVariable(storyboardInfo.i+"_sub").then(result => { if (result) { episodeSubscribed = true; } });
		if (episodeUnlocked) { this.startEpisode(storyboardInfo, "owned"); return; }

		let isMember = false; await this.varias.getVariable("sys_member").then(result => { if (result) { isMember = true; } });
		let wasMember = false; await this.varias.getVariable("sys_wasMember").then(result => { if (result) { wasMember = true; } });
		if (isMember && episodeSubscribed) { this.startEpisode(storyboardInfo, "membership"); return; }

		// FAIR2PLAY
		// fair2play, goodwill-episode, limit-episode, not-available, error-episode
		// goodwill-global, limit-global, not-sunday, error-global

		let isWareEpisode = false; if (storyboardInfo.i.indexOf("pa") > -1) { isWareEpisode = true; }
		let isStrayEpisode = false; if (storyboardInfo.i.indexOf("ga") > -1) { isStrayEpisode = true; }
		if (isWareEpisode) { this.varias.setVariable("purr_isWare", true); } else { this.varias.setVariable("purr_isWare", false); }
		if (isStrayEpisode) { this.varias.setVariable("purr_isStray", true); } else { this.varias.setVariable("purr_isStray", false); }

		let hasShardsAdv = false; await this.varias.getVariable("hasShardsAdv").then(result => hasShardsAdv = result );
		this.varias.setVariable("purr_hasShardsAdv", hasShardsAdv);

		if (f2pAnswer === "fair2play") {

			let greed = false;
			let latestUsedSundayPeriod = 0; await this.varias.getVariable("sys_latestUsedSundayPeriod").then(result => latestUsedSundayPeriod = result);
			if (latestUsedSundayPeriod === this.sundayPeriod) { greed = true; }
			if (greed) {
				// --> GREED
				this.startDialog(storyboardInfo, "hlp_purr_greed2");
				return
			}

			let stockpile = false;
			let hasRunningEpisode = false;
			let hasRunningWareEpisode = false;
			let hasRunningStrayEpisode = false;
			for (let stb of storyboardsRegistry) {
				if (stb.started && stb.running && !stb.completed && !stb.archived) {
					hasRunningEpisode = true;
					if (stb.i.indexOf("pa") > -1) { hasRunningWareEpisode = true; }
					else if (stb.i.indexOf("ga") > -1) { hasRunningStrayEpisode = true; }
				}
			}
			if (isWareEpisode && hasRunningWareEpisode) { stockpile = true; }
			if (isStrayEpisode && hasRunningStrayEpisode) { stockpile = true; }
			if (stockpile) {
				// --> STOCKPILE
				this.startDialog(storyboardInfo, "hlp_purr_stockpile2");
				return
			}

			// --> FAIR2PLAY
			this.startDialog(storyboardInfo, "hlp_purr_fair2play2");
			return
		}

		if (f2pAnswer === "hot-stuff") {

			if (storyboardInfo.lst) { this.varias.setVariable("purr_isLatest", true); }
			else { this.varias.setVariable("purr_isLatest", false); }

			// --> HOT
			this.startDialog(storyboardInfo, "hlp_purr_hot2");
			return
		}

		if (f2pAnswer === "limit-episode" || f2pAnswer === "limit-global") {

			let requestedStockpile = false; await this.varias.getVariable("purr_requestedStockpile").then(result => { if (result) { requestedStockpile = true } });
			if (requestedStockpile) {
				// --> SPEEDRUN
				this.startDialog(storyboardInfo, "hlp_purr_speedrun2");
				return
			}

			// --> LIMIT
			this.startDialog(storyboardInfo, "hlp_purr_limit2");
			return
		}

		//let paidEpisodes = 0; await this.varias.getVariable("sys_paidEpisodes").then(result => paidEpisodes = result);
		//let freeEpisodes = 0; await this.varias.getVariable("sys_freeEpisodes").then(result => freeEpisodes = result);
		//if (freeEpisodes < 1 && paidEpisodes < 1) {
			// --> FIRST
			//this.startDialog(storyboardInfo, "hlp_purr_first2");
			//this.startDialog(storyboardInfo, "hlp_purr_default");
			//return
		//}

		let defaultId = "hlp_purr_default2";
		let adsImplemented = false; await this.varias.getVariable("conf_adsImplemented").then(result => adsImplemented = result);
		if (adsImplemented) { defaultId = "hlp_purr_default4"; }

		let isAmongIntroductoryEpisodes = false; if (storyboardInfo.i === "pa1" || storyboardInfo.i === "pa2" || storyboardInfo.i === "pa3" || storyboardInfo.i === "pa4") { isAmongIntroductoryEpisodes = true; }
		if (isAmongIntroductoryEpisodes) {
			// --> INTRODUCTORY
			//this.startDialog(storyboardInfo, "hlp_purr_introductory2");
			this.startDialog(storyboardInfo, defaultId);
			return
		}

		if (!this.varias.vars['sys_member'] && this.varias.vars['sys_wasMember']) {
			// --> WINBACK
			let winback3 = false; await this.varias.getVariable("purr_winback3").then(result => { if (result) { winback3 = true; } })
			if (!winback3) {
				//this.startDialog(storyboardInfo, "hlp_purr_winback2");
				this.startDialog(storyboardInfo, defaultId);
				return
			}
		}

		// --> DEFAULT
		this.startDialog(storyboardInfo, defaultId);
		return

		// ########################################################################################################################
		// ########################################################################################################################

		//let hadEpisodeAsMember = false; if (!isMember && episodeSubscribed && wasMember) { hadEpisodeAsMember = true; }
		//let daysOfPlaying = 0; await this.varias.getVariable("usr_init").then(initTime => { daysOfPlaying = Math.floor((this.now - initTime) / (1000*60*60*24)); });

	}

	async startDialog(storyboardInfo, purrchaseSceneId) {
		let seasonPack: any = {};
		let seasonTitle = this.localization.season;
		let seasonId = null; await this.findClosedSeason(storyboardInfo.i).then(result => seasonId = result);
		let seasonLength = 0;
		if (seasonId) {
			this.varias.setVariable("purr_isSeason", true);
			await this.findSeasonPackProduct(seasonId).then(result => {
				if (result) {
					if (result.product && !result.seasonFull) { seasonPack = result.product; }
					if (result.seasonLength) { seasonLength = result.seasonLength; }
				}
			});
			if (seasonPack) {
				if (seasonPack.id) {
					if (seasonPack.id.startsWith("se_reduced_")) { await this.varias.setVariable("purr_isDiscount", true); } else { await this.varias.setVariable("purr_isDiscount", false); }
				}
				await this.varias.setVariable("purr_seasonAvailable", true);
				seasonTitle = seasonPack.title;
			} else {
				await this.varias.setVariable("purr_seasonAvailable", false);
			}
		} else {
			this.varias.setVariable("purr_isSeason", false);
			this.varias.setVariable("purr_isDiscount", false);
		}

		let now = Date.now();
		let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
		this.telemetry.logEvent("purrchase_request_"+purrchaseSceneId, { scene: purrchaseSceneId, day: day, ep: storyboardInfo.i });

		if (this.event_membershipOwned) { await this.event_membershipOwned.unsubscribe(); }
		this.event_membershipOwned = this.events.subscribe("productOwned_membership", async () => {
			this.startEpisode(storyboardInfo, "membership");
			this.varias.incrementCount("sys_subbedEpisodes", 1);
			this.varias.setVariable("sys_latestSubbedEpisode", now);
			if (this.event_membershipOwned) { await this.event_membershipOwned.unsubscribe(); }
			if (this.event_seasonOwned) { await this.event_seasonOwned.unsubscribe(); }
			if (this.event_episodeOwned) { await this.event_episodeOwned.unsubscribe(); }
			if (this.event_fair2playUsed) { await this.event_fair2playUsed.unsubscribe(); }
			this.telemetry.logEvent("purrchase_resolve_"+purrchaseSceneId, { scene: purrchaseSceneId, day: day, ep: storyboardInfo.i, method: "membership" });
		});

		if (this.event_seasonOwned) { await this.event_seasonOwned.unsubscribe(); }
		if (seasonPack) {
			this.event_seasonOwned = this.events.subscribe("productOwned_"+seasonPack.id, async() => {
				this.startEpisode(storyboardInfo, "purchase");
				this.varias.incrementCount("sys_paidEpisodes", 7);
				this.varias.setVariable("sys_latestPaidEpisode", now);
				if (this.event_membershipOwned) { await this.event_membershipOwned.unsubscribe(); }
				if (this.event_seasonOwned) { await this.event_seasonOwned.unsubscribe(); }
				if (this.event_episodeOwned) { await this.event_episodeOwned.unsubscribe(); }
				if (this.event_fair2playUsed) { await this.event_fair2playUsed.unsubscribe(); }
				this.telemetry.logEvent("purrchase_resolve_"+purrchaseSceneId, { scene: purrchaseSceneId, day: day, ep: storyboardInfo.i, method: "season" });				
			})
		}

		if (this.event_episodeOwned) { await this.event_episodeOwned.unsubscribe(); }
		this.event_episodeOwned = this.events.subscribe("productOwned_ep_unlock_"+storyboardInfo.i, async () => {
			this.startEpisode(storyboardInfo, "purchase");
			this.varias.incrementCount("sys_paidEpisodes", 1);
			this.varias.setVariable("sys_latestPaidEpisode", now);
			if (this.event_membershipOwned) { await this.event_membershipOwned.unsubscribe(); }
			if (this.event_seasonOwned) { await this.event_seasonOwned.unsubscribe(); }
			if (this.event_episodeOwned) { await this.event_episodeOwned.unsubscribe(); }
			if (this.event_fair2playUsed) { await this.event_fair2playUsed.unsubscribe(); }
			this.telemetry.logEvent("purrchase_resolve_"+purrchaseSceneId, { scene: purrchaseSceneId, day: day, ep: storyboardInfo.i, method: "unlock" });
		});

		if (this.event_fair2playUsed) { await this.event_fair2playUsed.unsubscribe(); }
		this.event_fair2playUsed = this.events.subscribe("fair2playUsed", async () => {
			this.startEpisode(storyboardInfo, "fair2play");
			this.varias.setVariable("sys_latestUsedSundayPeriod", this.sundayPeriod);
			this.varias.incrementCount("sys_freeEpisodes", 1);
			this.varias.setVariable("sys_latestFreeEpisode", now);
			if (this.event_membershipOwned) { await this.event_membershipOwned.unsubscribe(); }
			if (this.event_seasonOwned) { await this.event_seasonOwned.unsubscribe(); }
			if (this.event_episodeOwned) { await this.event_episodeOwned.unsubscribe(); }			
			if (this.event_fair2playUsed) { await this.event_fair2playUsed.unsubscribe(); }
			this.telemetry.logEvent("purrchase_resolve_"+purrchaseSceneId, { scene: purrchaseSceneId, day: day, ep: storyboardInfo.i, method: "fair2play" });
		})
			
		await this.engine.ECH_downloadAndStartTutoring(purrchaseSceneId, true);

		let purrInfo = {
			episodeId: storyboardInfo.i,
			singleId: "ep_unlock_"+storyboardInfo.i,
			singleTitle: storyboardInfo.n,
			seasonId: seasonId,
			seasonLength: seasonLength,
			packId: seasonPack.id,
			packTitle: seasonPack.title,
			description: storyboardInfo.d
		}
		this.navgiation.pushChat(purrchaseSceneId, false, false, purrInfo);
	}

	async startEpisode(storyboardInfo, method) {
		this.engine.STB_downloadNewAndStart(storyboardInfo, method).then(() => {})
	}

	async findClosedSeason(episodeId) {
		if (episodeId === "pa1" || episodeId === "pa2" || episodeId === "pa3" || episodeId === "pa4" || episodeId === "pa5" || episodeId === "pa6" || episodeId === "pa7") {
			return "par1";
		} else if (episodeId === "pa8" || episodeId === "pa9" || episodeId === "pa10" || episodeId === "pa11" || episodeId === "pa12" || episodeId === "pa13" || episodeId === "pa14") {
			return "par2";
		} else if (episodeId === "pa15" || episodeId === "pa16" || episodeId === "pa17" || episodeId === "pa18" || episodeId === "pa19" || episodeId === "pa20" || episodeId === "pa21") {
			return "par3";
		} else if (episodeId === "pa22" || episodeId === "pa23" || episodeId === "pa24" || episodeId === "pa25" || episodeId === "pa26" || episodeId === "pa27" || episodeId === "pa28") {
			return "par4";
		} else if (episodeId === "ga1" || episodeId === "ga2" || episodeId === "ga3" || episodeId === "ga4" || episodeId === "ga5" || episodeId === "ga6" || episodeId === "ga7") {
			return "gam1";
		} else if (episodeId === "ga8" || episodeId === "ga9" || episodeId === "ga10" || episodeId === "ga11" || episodeId === "ga12" || episodeId === "ga13" || episodeId === "ga14") {
			return "gam2";
		} else if (episodeId === "ga15" || episodeId === "ga16" || episodeId === "ga17" || episodeId === "ga18" || episodeId === "ga19" || episodeId === "ga20" || episodeId === "ga21") {
			return "gam3";
		}
		return null;
	}

	async findSeasonPackProduct(seasonId) {
		await this.varias.getVariable("sys_member").then(hasMembership => { if (hasMembership) { return null; } })

		let episodesInSeason = [];
		if (seasonId === "par1") { episodesInSeason = ["pa1", "pa2", "pa3", "pa4", "pa5", "pa6", "pa7"]; }
		else if (seasonId === "par2") { episodesInSeason = ["pa8", "pa9", "pa10", "pa11", "pa12", "pa13", "pa14"]; }
		else if (seasonId === "par3") { episodesInSeason = ["pa15", "pa16", "pa17", "pa18", "pa19", "pa20", "pa21"]; }
		else if (seasonId === "par4") { episodesInSeason = ["pa22", "pa23", "pa24", "pa25", "pa26", "pa27", "pa28"]; }
		else if (seasonId === "gam1") { episodesInSeason = ["ga1", "ga2", "ga3", "ga4", "ga5", "ga6", "ga7"]; }
		else if (seasonId === "gam2") { episodesInSeason = ["ga8", "ga9", "ga10", "ga11", "ga12", "ga13", "ga14"]; }
		else if (seasonId === "gam3") { episodesInSeason = ["ga15", "ga16", "ga17", "ga18", "ga19", "ga20", "ga21"]; }
		else { return null; }

		let hasEpisodes = 0;
		for (let episodeId of episodesInSeason) {
			let unlocked = false; await this.varias.getVariable(episodeId+"_unl").then(result => { if (result) { unlocked = true; } })
			if (unlocked) { hasEpisodes++; continue }
			/*
			let started = false; await this.varias.getVariable(episodeId+"_std").then(result => { if (result) { started = true; } })
			if (started) { hasEpisodes++; continue }
			*/
			let completed = false; await this.varias.getVariable(episodeId+"_cmp").then(result => { if (result) { completed = true; } })
			if (completed) { hasEpisodes++; continue }
		}

		let seasonPackProduct = null;
		let productFound: boolean = false;
		if (hasEpisodes <= 3) {
			if (this.purchase.products["se_unlock_"+seasonId]) { 
				seasonPackProduct = this.purchase.products["se_unlock_"+seasonId];
				productFound = true;
			}
		} else if (hasEpisodes <= 5) {
			if (this.purchase.products["se_reduced_"+seasonId]) { 
				seasonPackProduct = this.purchase.products["se_reduced_"+seasonId];
				productFound = true;
			}
		} else if (hasEpisodes <= 6) {
			if (this.purchase.products["se_single_"+seasonId]) {
				seasonPackProduct = this.purchase.products["se_single_"+seasonId];
				productFound = true;
			}
		}

		let seasonFull: boolean = false;
		if (hasEpisodes >= episodesInSeason.length) { seasonFull = true; }

		return {
			product: seasonPackProduct,
			productFound: productFound,
			seasonLength: episodesInSeason.length,
			hasEpisodes: hasEpisodes,
			seasonFull: seasonFull,
			episodesInSeason: episodesInSeason
		}

	}

	async grantFreeEpisodeAfterShardsPurchase() {
		this.varias.setVariable("hasShardsAdv", true);

		let purchOrigin = "none"; await this.varias.getVariable("purchOrigin").then(result => purchOrigin = result);
		let serials = []; await this.meta.getEpisodes().then(resultSerials => serials = resultSerials);

		let freeEpisode = null; await this.checkIfEpisodeIsAvailableForBonusAfterShardsPurchase(purchOrigin).then(result => freeEpisode = result);
		if (freeEpisode) {
			if (freeEpisode.avl) {
				this.varias.setVariable(freeEpisode.i+"_unl", true);
			}
		}
		
	}

	async checkIfEpisodeIsAvailableForBonusAfterShardsPurchase(episodeId) {
		let tickets = await this.varias.getVariable("sys_tickets");
		if (tickets >= 100) { return false; }

		let serials = []; await this.meta.getEpisodes().then(resultSerials => serials = resultSerials);
		if (this.availableForBonusAfterShards[episodeId]) {
			if (this.availableForBonusAfterShards[episodeId].avl) {
				return this.availableForBonusAfterShards[episodeId];
			} else {
				return false;
			}
		} else {
			this.availableForBonusAfterShards[episodeId] = { avl: false };
			for await (let serial of serials) {
				if (episodeId.startsWith(serial.i)) {
					let i = 0;
					for await (let episode of serial.ep) {
						if (episode.i === episodeId) {
							let nextEpisode = serial.ep[i+1];
							if (nextEpisode) {
								let started = false; await this.varias.getVariable(serial.ep[i+1].i+"_std").then(result => started = result);
								if (started) { return false; }
								let unlocked = false; await this.varias.getVariable(serial.ep[i+1].i+"_unl").then(result => unlocked = result);
								if (unlocked) { return false; }
								let completed = false; await this.varias.getVariable(serial.ep[i+1].i+"_cmp").then(result => completed = result);
								if (completed) { return false; }
	
								let availableInF2P = false;
								await this.firestore.collection("fair2play").doc(episodeId).ref.get().then(doc => {
									if (doc.exists) {
										let docData: any = doc.data();
										if (docData.avl) {
											availableInF2P = true;
										}
									}
								}).catch(err => {});

								if (!availableInF2P) { return false; } 
								this.availableForBonusAfterShards[episodeId] = { ...nextEpisode, avl: true };
								return { ...nextEpisode, avl: true };

							}
						}
						i++;
					}
				}
			}	
		}	
	}

	async showPurchaseOptions(episodeId, message, purchaseReason) {
		this.ads.currentMode = null;
		this.ads.currentContentId = null;

		let buttons = [{ text: this.localization.cancel, handler: () => {} }];

		let singlePack = null;
		if (this.purchase.products["ep_unlock_"+episodeId]) { singlePack = this.purchase.products["ep_unlock_"+episodeId]; }
		if (await this.varias.getVariable("off_special3") && !await this.varias.getVariable("off_special3_wtd")) {
			if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
				if (episodeId === "pa2" || episodeId === "pa3" || episodeId === "pa4") {
					let episodesOfSpecialMissing: number = 0;
					let ep2Missing = true; await this.varias.getVariable("pa2_unl").then(unlocked => { if (unlocked) { ep2Missing = false; } }); await this.varias.getVariable("pa2_std").then(started => { if (started) { ep2Missing = false; } }); if (ep2Missing) { episodesOfSpecialMissing++; }
					let ep3Missing = true; await this.varias.getVariable("pa3_unl").then(unlocked => { if (unlocked) { ep3Missing = false; } }); await this.varias.getVariable("pa3_std").then(started => { if (started) { ep3Missing = false; } }); if (ep3Missing) { episodesOfSpecialMissing++; }
					let ep4Missing = true; await this.varias.getVariable("pa4_unl").then(unlocked => { if (unlocked) { ep4Missing = false; } }); await this.varias.getVariable("pa4_std").then(started => { if (started) { ep4Missing = false; } }); if (ep4Missing) { episodesOfSpecialMissing++; }
					if (episodesOfSpecialMissing >= 2) {
						if (this.purchase.products["special3"]) { singlePack = this.purchase.products["special3"]; }
					}
				}
			} else if (this.instance.instance === "gamerapp") {
				if (episodeId === "ga2" || episodeId === "ga3" || episodeId === "ga4") {
					let episodesOfSpecialMissing: number = 0;
					let ep2Missing = true; await this.varias.getVariable("ga2_unl").then(unlocked => { if (unlocked) { ep2Missing = false; } }); await this.varias.getVariable("ga2_std").then(started => { if (started) { ep2Missing = false; } }); if (ep2Missing) { episodesOfSpecialMissing++; }
					let ep3Missing = true; await this.varias.getVariable("ga3_unl").then(unlocked => { if (unlocked) { ep3Missing = false; } }); await this.varias.getVariable("ga3_std").then(started => { if (started) { ep3Missing = false; } }); if (ep3Missing) { episodesOfSpecialMissing++; }
					let ep4Missing = true; await this.varias.getVariable("ga4_unl").then(unlocked => { if (unlocked) { ep4Missing = false; } }); await this.varias.getVariable("ga4_std").then(started => { if (started) { ep4Missing = false; } }); if (ep4Missing) { episodesOfSpecialMissing++; }
					if (episodesOfSpecialMissing >= 2) {
						if (this.purchase.products["special3"]) { singlePack = this.purchase.products["special3"]; }
					}
				}
			}
		}
		if (singlePack) {
			buttons.push({ text: singlePack.title+" - "+singlePack.price, handler: () => { this.purchase.order(singlePack.id, purchaseReason); } })
		}

		let seasonId = await this.meta.getSeasonId(episodeId);
		let seasonPackProduct = await this.findSeasonPackProduct(seasonId);
		if (seasonPackProduct) {
			if (seasonPackProduct.productFound) {
				if (seasonPackProduct.product) {
					buttons.push({ text: seasonPackProduct.product.title+" - "+seasonPackProduct.product.price, handler: () => { this.purchase.order(seasonPackProduct.product.id, purchaseReason); } })
				}
			}
		}

		if (this.instance.instance === "gamerapp") {
			if (await this.varias.getVariable("off_special5") && !await this.varias.getVariable("off_special5_wtd")) {
				let special5Pack = this.purchase.products["special5"];
				buttons.push({ text: special5Pack.title+" - "+special5Pack.price, handler: () => { this.purchase.order(special5Pack.id, purchaseReason); } })
			}
		}

		if (this.instance.instance === "voidapp") {
			buttons.push({ text: this.purchase.membershipTitle+" "+this.purchase.membershipPrice, handler: () => { this.modals.presentMembership(); } });
		}

		const alert = await this.alertController.create({
			header: this.localization.unlock,
			subHeader: "Episode",
			cssClass: 'eon__alert__warning',
			message: message,
			buttons: buttons
		})
		alert.present();
	}

	async offerSpecial(specialId) {
		if (await this.varias.getVariable("sys_member")) { return }
		if (await this.varias.getVariable("sys_wasMember")) { return }

		// Special 2 = 20 Nutrition
		// Special 3 = drei Episoden für drei
		// Special 4 = Membership
		// Special 5 = zwei Staffeln
		// Special 6 = 200 Tickets + 50 Nutrition

		if (specialId === this.instance.iapPrefix+"special2") {
			let hasOffer = await this.varias.getVariable("off_special2");
			let offerWithdrawn = await this.varias.getVariable("off_special2_wtd");
			let canSkip = false; if (this.varias.vars["sys_nutrition"] > 0 || this.varias.vars["sys_shards"] > 0) { canSkip = true; }
			if (!hasOffer && !offerWithdrawn && !canSkip) {
				this.varias.setVariable("off_special2", true);
				let sceneId = "hlp_purr_special2"; await this.varias.getVariable("conf_purrSpecial2").then(result => { if (result) { sceneId = result; } })
				await this.engine.ECH_downloadAndStartTutoring(sceneId, true);
				let purrInfo = {
					episodeId: "pa2",
					singleId: "null",
					singleTitle: "20 Nutrition",
					seasonId: "par1",
					seasonLength: 7,
					packId: this.instance.iapPrefix+"special2",
					packTitle: "20 Nutrition",
					description: "20 Nutrition"
				}
				this.navgiation.pushChat(sceneId, false, false, purrInfo);
			}
		} else if (specialId === "special3") {
			return;
			if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp" || this.instance.instance === "gamerapp") {
				let hasOffer3 = await this.varias.getVariable("off_special3");
				let offer3Withdrawn = await this.varias.getVariable("off_special3_wtd");
				if (hasOffer3 || offer3Withdrawn) { return }

				if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
					let hasEP2 = false; 
					await this.varias.getVariable("pa2_unl").then(unlocked => { if (unlocked) { hasEP2 = true; } }); 
					await this.varias.getVariable("pa2_std").then(started => { if (started) { hasEP2 = true; } })
					if (hasEP2) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
		
					let hasEP3 = false; 
					await this.varias.getVariable("pa3_unl").then(unlocked => { if (unlocked) { hasEP3 = true; } }); 
					await this.varias.getVariable("pa3_std").then(started => { if (started) { hasEP3 = true; } })
					if (hasEP3) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
		
					let hasEP4 = false; 
					await this.varias.getVariable("pa4_unl").then(unlocked => { if (unlocked) { hasEP4 = true; } }); 
					await this.varias.getVariable("pa4_std").then(started => { if (started) { hasEP4 = true; } })
					if (hasEP4) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
				} else if (this.instance.instance === "gamerapp") {
					let hasEP2 = false; 
					await this.varias.getVariable("ga2_unl").then(unlocked => { if (unlocked) { hasEP2 = true; } }); 
					await this.varias.getVariable("ga2_std").then(started => { if (started) { hasEP2 = true; } })
					if (hasEP2) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
		
					let hasEP3 = false; 
					await this.varias.getVariable("ga3_unl").then(unlocked => { if (unlocked) { hasEP3 = true; } }); 
					await this.varias.getVariable("ga3_std").then(started => { if (started) { hasEP3 = true; } })
					if (hasEP3) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
		
					let hasEP4 = false; 
					await this.varias.getVariable("ga4_unl").then(unlocked => { if (unlocked) { hasEP4 = true; } }); 
					await this.varias.getVariable("ga4_std").then(started => { if (started) { hasEP4 = true; } })
					if (hasEP4) {
						this.varias.setVariable("off_special3", true);
						this.varias.setVariable("off_special3_wtd", true);
						return;
					}
				}
	
				let sceneId = "hlp_purr_special3"; await this.varias.getVariable("conf_purrSpecial3").then(result => { if (result) { sceneId = result; } })
				await this.engine.ECH_downloadAndStartTutoring(sceneId, true);
				let purrInfo = {
					episodeId: "pa3",
					singleId: "ep_unlock_pa3",
					singleTitle: "Episode 2-4",
					seasonId: "par1",
					seasonLength: 7,
					packId: "special3",
					packTitle: "Episode 2-4",
					description: "Episode 2-4"
				}
				this.navgiation.pushChat(sceneId, false, false, purrInfo);
				this.varias.setVariable("off_special3", true);
			}
		} else if (specialId === "special4") {
			if (this.instance.instance === "voidapp") {
				await this.varias.getVariable("off_special4");
				await this.varias.getVariable("off_special4_wtd");
				if (!this.varias.vars["off_special4"] && !this.varias.vars["off_special4_wtd"]) {
					let sceneId = "hlp_purr_special4"; await this.varias.getVariable("conf_purrSpecial4").then(result => { if (result) { sceneId = result; } })
					await this.varias.setVariable("off_special4", true);
					if (this.purchase.products["membership2"]) {
						await this.purchase.onMembershipUpdate(this.purchase.products["membership2"]);
					}					
					await this.engine.ECH_downloadAndStartTutoring(sceneId, true);
					let purrInfo = {
						episodeId: "null",
						singleId: "null",
						singleTitle: "Membership",
						seasonId: "null",
						seasonLength: 7,
						packId: "null",
						packTitle: "Membership",
						description: "Membership"
					}
					this.navgiation.pushChat(sceneId, false, false, purrInfo);
				}
			}
		} else if (specialId === "special5") {
			return;
			if (this.instance.instance === "gamerapp") {
				await this.varias.getVariable("off_special5");
				await this.varias.getVariable("off_special5_wtd");
				if (!this.varias.vars["off_special5"] && !this.varias.vars["off_special5_wtd"]) {
					let sceneId = "hlp_purr_special5"; await this.varias.getVariable("conf_purrSpecial5").then(result => { if (result) { sceneId = result; } })
					await this.engine.ECH_downloadAndStartTutoring(sceneId, true);
					let purrInfo = {
						episodeId: "ga3",
						singleId: "ep_unlock_pa3",
						singleTitle: "Season 1 & 2",
						seasonId: "gam1",
						seasonLength: 7,
						packId: "special5",
						packTitle: "Season 1 & 2",
						description: "Season 1 & 2"
					}
					this.navgiation.pushChat(sceneId, false, false, purrInfo);
					await this.varias.setVariable("off_special5", true);
				}
			}
		} else if (specialId === this.instance.iapPrefix+"special6") {
			let hasOffer = await this.varias.getVariable("off_special6");
			let offerWithdrawn = await this.varias.getVariable("off_special6_wtd");
			if (!hasOffer && !offerWithdrawn) {
				this.varias.setVariable("off_special6", true);
				let sceneId = "hlp_purr_special6"; await this.varias.getVariable("conf_purrSpecial6").then(result => { if (result) { sceneId = result; } })
				await this.engine.ECH_downloadAndStartTutoring(sceneId, true);
				let purrInfo = {
					episodeId: "pa3",
					singleId: "null",
					singleTitle: "200 Tickets & 50 Nutrition",
					seasonId: "par1",
					seasonLength: 7,
					packId: this.instance.iapPrefix+"special6",
					packTitle: "200 Tickets & 50 Nutrition",
					description: "200 Tickets & 50 Nutrition"
				}
				this.navgiation.pushChat(sceneId, false, false, purrInfo);
			}			
		}
	}

}