import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalizationService } from 'src/app/system/localization.service';

@Component({
  	selector: 'app-authentication',
  	templateUrl: './authentication.page.html',
  	styleUrls: ['./authentication.page.scss'],
})
export class AuthenticationPage implements OnInit {

	@Input("callbackPassword") callbackPassword: any = () => {}
	@Input("callbackGoogle") callbackGoogle: any = () => {}
	@Input("callbackApple") callbackApple: any = () => {}
	@Input("callbackDenied") callbackDenied: any = () => {}

	anim1: boolean = true;
	anim2: boolean = false;
	anim3: boolean = false;
	anim4: boolean = false;

  	constructor(
		public localization: LocalizationService,
		private modalController: ModalController
	) { }

  	ngOnInit() {
		this.anim1 = true;
		setTimeout(() => {
			this.anim1 = false;
			this.anim2 = true;
			setTimeout(() => {
				this.anim3 = true;
				setTimeout(() => {
					this.anim4 = true;
				}, 1000);
			}, 750);
		}, 3000);
	}

	usePassword() { 
		this.modalController.dismiss();
		this.callbackPassword();
	}
	useGoogle() {
		this.modalController.dismiss();
		this.callbackGoogle();
	}
	useApple() { 
		this.modalController.dismiss();
		this.callbackApple();
	}

	cancel() {
		this.modalController.dismiss();
		this.callbackDenied();		
	}

}