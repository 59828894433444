import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { BranchComponent } from './branch.component'

@NgModule({
    declarations: [BranchComponent],
    exports: [BranchComponent ],
    imports: [CommonModule, IonicModule]
})
export class BranchModule{}