import { DomController } from '@ionic/angular'
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

import { UtilityService } from 'src/app/system/utility.service';
import { ThemeService } from 'src/app/system/theme.service';

@Component({
  	selector: 'eon-searchbar',
  	templateUrl: './searchbar.component.html',
	styleUrls: ['./searchbar.component.scss']  
})
export class SearchbarComponent implements OnInit {
	@Input('placeholder') placeholder = "...";
	@Input('autofocus') autofocus: boolean = false;
	@Output() changed = new EventEmitter<String>();
	@Output() eonFocus = new EventEmitter<String>();
	@Output() eonBlur = new EventEmitter<String>();
	@ViewChild('inputfield', { static: false } ) inputfield:ElementRef;

	searchterm: string = "Suchen!";
	state: string = "inactive";
	resetState: string = "inactive"

  	constructor(
		private util: UtilityService,
		public theme: ThemeService,
		private domController: DomController
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
		if (this.autofocus) {
			this.domController.read(() => {
				this.domController.write(() => {
					setTimeout(() => {
						this.inputfield.nativeElement.focus();
					}, 300)
				})
			})
		}
	}

	async focus(event) {
		this.state = "active";
		this.eonFocus.emit(event.srcElement.value);
	}

	async blur(event) {
		if (event.srcElement.value === "") {
			this.state = "inactive";
			this.resetState = "inactive";
		}
		this.eonBlur.emit();
	}

	async change(event) {
		await this.util.sleep(100);
		this.changed.emit(event.srcElement.value);
		if (event.srcElement.value != "") { this.resetState = "active"; } else { this.resetState = "inactive"; }
	}

	async reset() {
		let inputfieldEL = document.getElementById("inputfield");
		this.inputfield.nativeElement.value = "";
		this.state = "inactive";
		this.resetState = "inactive";
		this.changed.emit("");
		this.inputfield.nativeElement.focus();
	}

	focusInput() {
		this.inputfield.nativeElement.focus();
	}

	key: any = null;
	keyCode: any = null;
	onKeyPressed(event){
		this.key = event.key;
		this.keyCode = event.keyCode;
		return true
	}	

}