import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AudioService } from 'src/app/system/audio.service';
import { VariasService } from 'src/app/system/varias.service';
import { MetaService } from 'src/app/cloud/meta.service';
import { FilterService } from 'src/app/system/filter.service';
import { BootService } from 'src/app/system/boot.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.page.html',
  styleUrls: ['./music.page.scss'],
})
export class MusicPage implements OnInit {
	@Input() asModal: any;
	@Input() currentTrack: any;
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	projectId: string = "";
	sceneId: string = "";
	authorId: string = "";
	scenes: Array<any> = [];
	activeTrack: any = null;
	searchTerm: string = "";
	tracks: Array<any> = [];
	filteredTracks: Array<any> = [];
	membertracks: Array<any> = [];
	filteredMembertracks: Array<any> = [];
	previousTrackId: string = "";

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		private route: ActivatedRoute,
		private audio: AudioService,
		private modalController: ModalController,
		public varias: VariasService,
		private meta: MetaService,
		private filter: FilterService,
		private alertController: AlertController
	) { }

  	ngOnInit() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			this.sceneId = map.get("sceneId");
			this.previousTrackId = map.get("previousTrackId");
		});

		this.varias.getVariable("sys_member");
		this.meta.getTracks().then(result => {
			this.tracks = result.tracks;
			this.membertracks = result.membertracks;

			for (let section of this.tracks) {
				for (let track of section.t) { track.matchingSearch = true; }
			}
			for (let section of this.membertracks) {
				for (let track of section.t) { track.matchingSearch = true; }
			}

			this.audio.pauseAudio();

			let found = false;
			for (let section of this.tracks) {
				for (let track of section.t) {
					if (track.i === this.previousTrackId) {
						this.toggleTrack(track, false, true);
						found = true;
						break;
					}
				}
			}
			if (!found) {
				for (let section of this.membertracks) {
					for (let track of section.t) {
						if (track.i === this.previousTrackId) {
							this.toggleTrack(track, true, true);
							break;
						}
					}
				}
			}

		})
	}

	ionViewDidEnter() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			if (!this.projectId) { this.projectId = "CCD9sQXTf8OxkOO8Tmn6"; }
			this.authorId = map.get("authorId");
			if (!this.authorId) { this.authorId = "1234567"; }
		})
	}

	async ionViewWillLeave() {
		if (this.activeTrack) {
			let allowed = true;
			if (this.activeTrack.member) {
				let hasMembership = await this.varias.getVariable("sys_member");
				if (!hasMembership) {
					allowed = false;
					const alert = await this.alertController.create({
						header: "Exklusiv",
						subHeader: "Membership",
						cssClass: 'eon__alert',
						message: "Den ausgewählten Track kannst du exklusiv mit einer Membership verwenden.",
						buttons: [
							{
								text: 'Ok', handler: () => {}
							},{
								text: "Neu wählen", handler: () => {
									this.navigation.pushCUGC_music(this.projectId, this.sceneId, this.previousTrackId);
								}
							}
						]
					})
					await alert.present();
				}
			}
			if (allowed) {
				this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).update({
					mu: {
						i: this.activeTrack.i,
						t: this.activeTrack.t
					}
				})
			}
		}		
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}	
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	close() {
		if (this.asModal) { this.modalController.dismiss(this.activeTrack); }
		else { this.navigation.pop(); }
		this.audio.stopEditorTrack();
	}

	async filterContacts() {
		let searchingFor = this.searchTerm.toLowerCase();
		let noInput = false; if (searchingFor.length <= 0) { noInput = true; }
		await this.searchArray(this.tracks, searchingFor, noInput);
		await this.searchArray(this.membertracks, searchingFor, noInput);
	}
	async searchArray(array, searchingFor, noInput) {
		for (let section of array) {
			let sectionMatching = false;
			if (section.s.toLowerCase().indexOf(searchingFor) > -1) { sectionMatching = true; }
			for (let track of section.t) {
				let matchingSearch = false;
				if (noInput) {
					matchingSearch = true;
				} else if (sectionMatching) {
					matchingSearch = true;
				} else if (track.t.toLowerCase().indexOf(searchingFor) > -1) {
					matchingSearch = true;
				} else if (track.d.toLowerCase().indexOf(searchingFor) > -1) {
					matchingSearch = true;
				}
				if (matchingSearch) { track.matchingSearch = true; } else { track.matchingSearch = false; }
			}
		}
	}
	search(term) {
		this.searchTerm = term;
		this.filterContacts();
	}
	searching: boolean = false;
	minHeightSet: boolean = false;
	focusSearchbar() {
		this.searching = true;
		if (!this.minHeightSet) { this.domController.write(() => { this.renderer.setStyle(this.content.nativeElement, "minHeight", this.content.nativeElement.offsetHeight); }) }
	}
	blurSearchbar() { this.searching = false; }	

	playTrack(track) {
		this.audio.tryEditorTrack(track);
	}

	toggleTrack(track, membertrack, forceTrue) {
		this.activeTrack = track;

		if (!track.a || forceTrue) {
			track.a = true;
			this.audio.tryEditorTrack(track.i);
		} else {
			track.a = false;
			this.audio.stopEditorTrack();	
		}

		let found = false;
		for (let section of this.tracks) {
			for (let aTrack of section.t) {
				if (aTrack.i != track.i) {
					aTrack.a = false;
				}
			}
		}
		for (let section of this.membertracks) {
			for (let aTrack of section.t) {
				if (aTrack.i != track.i) {
					aTrack.a = false;
				}
			}
		}
	}

}