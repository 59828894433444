import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { InstanceService } from './instance.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  	constructor(
		private storage: Storage,
		private instance: InstanceService
	) {
		if (this.instance.instance === "prevapp" && this.instance.variant === "prllx") {
			this.storage.set = async (key, value) => {}
			setTimeout(()=>{
				this.storage.clear();
			}, 1000);
		}

		this.storage.create();
	}
}
