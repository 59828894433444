import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { Events } from '../system/events.service'
import { InstanceService } from './instance.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

	lang: string = "EN";
	langInfo: any = {};
	languages: Array<any> = [
		{ code: "AR", name: "العربية", englishName: "Arabic", you: "أنت", unknownWord: "غير معروف" },
		{ code: "BN", name: "বাংলা", englishName: "Bengali", you: "তুমি", unknownWord: "অজানা" },
		{ code: "ZH", name: "中文", englishName: "Chinese", you: "你", unknownWord: "未知" },
		{ code: "CS", name: "Čeština", englishName: "Czech", you: "Ty", unknownWord: "Neznámý" },
		{ code: "DA", name: "Dansk", englishName: "Danish", you: "Dig", unknownWord: "Ukendt" },
		{ code: "NL", name: "Nederlands", englishName: "Dutch", you: "Jij", unknownWord: "Onbekend" },
		{ code: "EN", name: "English", englishName: "English", you: "You", unknownWord: "Unknown" },
		{ code: "FI", name: "Suomi", englishName: "Finnish", you: "Sinä", unknownWord: "Tuntematon" },
		{ code: "FR", name: "Français", englishName: "French", you: "Tu", unknownWord: "Inconnu" },
		{ code: "DE", name: "Deutsch", englishName: "German", you: "Du", unknownWord: "Unbekannt" },
		{ code: "EL", name: "Ελληνικά", englishName: "Greek", you: "Εσύ", unknownWord: "Άγνωστος" },
		{ code: "HI", name: "हिन्दी", englishName: "Hindi", you: "आप", unknownWord: "अज्ञात" },
		{ code: "IS", name: "Íslenska", englishName: "Icelandic", you: "Þú", unknownWord: "Óþekktur" },
		{ code: "IT", name: "Italiano", englishName: "Italian", you: "Tu", unknownWord: "Sconosciuto" },
		{ code: "JA", name: "日本語", englishName: "Japanese", you: "あなた", unknownWord: "不明" },
		{ code: "KO", name: "한국어", englishName: "Korean", you: "너", unknownWord: "알 수 없는" },
		{ code: "MS", name: "Bahasa Melayu", englishName: "Malay", you: "Kamu", unknownWord: "Tidak diketahui" },
		{ code: "NO", name: "Norsk", englishName: "Norwegian", you: "Du", unknownWord: "Ukjent" },
		{ code: "PL", name: "Polski", englishName: "Polish", you: "Ty", unknownWord: "Nieznany" },
		{ code: "PT", name: "Português", englishName: "Portuguese", you: "Tu", unknownWord: "Desconhecido" },
		{ code: "RU", name: "Русский", englishName: "Russian", you: "Ты", unknownWord: "Неизвестный" },
		{ code: "ES", name: "Español", englishName: "Spanish", you: "Tú", unknownWord: "Desconocido" },
		{ code: "SV", name: "Svenska", englishName: "Swedish", you: "Du", unknownWord: "Okänd" },
		{ code: "TR", name: "Türkçe", englishName: "Turkish", you: "Sen", unknownWord: "Bilinmeyen" },
		{ code: "UK", name: "Українська", englishName: "Ukrainian", you: "Ти", unknownWord: "Невідомий" },
		{ code: "UR", name: "اردو", englishName: "Urdu", you: "آپ", unknownWord: "نامعلوم" }
	]	
	DB_contacts: string = "contacts_EN";
	DB_echoes: string = "echoes_EN";
	DB_echoesInfo: string = "echoesInfo_EN";
	DB_episodes: string = "episodes_EN";
	DB_meta: string = "meta_EN";
	DB_notifications: string = "notifications_EN";

	list_initialChat_incomingMessage: string =  "Incoming message";
	list_initialChat_accept: string =  "Accept request?";
	list_search_1: string =  "Search for episodes, Echoes, series, serials, contacts or other functions.";
	list_search_2: string =  "(You might have to narrow down your search.)";
	list_search_3: string =  "What are you looking for?";
	
	fictionDisclaimer_header: string =  "Work of Fiction";
	fictionDisclaimer: string =  "This app is a work of fiction. Names, characters, businesses, places, events, locales, and incidents are the products of the authors' imagination. Any resemblance to actual persons, living or dead, or actual events is purely coincidental.";
	fictionDisclaimer_button: string =  "Got it";
	settings_primarySettings: string =  "Basic Settings";
	settings_darkMode: string =  "Dark mode";
	settings_darkMode_alwaysOn: string =  "Always on";
	settings_darkMode_alwaysOff: string =  "Always off";
	settings_darkMode_range: string =  "Between 22:00 and 06:00";
	settings_darkMode_system: string =  "Depending on system settings";
	
	settings_profile_heading: string =  "Profile";
	settings_profile_name: string =  "Name";
	settings_profile_yourName: string =  "Your name:";
	settings_profile_gender: string =  "Gender";
	
	settings_privacy_consent_3: string =  "You agreed to our terms.";
	settings_privacy_consent_4: string =  "You did not agree to our terms.";
		
	settings_privacy_privacy: string =  "Privacy Policy";
	settings_privacy_privacyOfThis: string =  "App Privacy Policy";
	settings_privacy_read: string =  "I have read the privacy policy and agree.";
	settings_signup: string =  "Sign Up";
	settings_signin: string =  "Sign In";
	
	settings_account: string =  "Account";
	settings_account_login: string =  "Sign in";
	settings_account_notLoggedIn: string =  "You are not signed in.";
	settings_account_loggedIn: string =  "You are signed in.";
	settings_account_changeDevice: string =  "Migrate device";
	settings_account_changeDevice_message: string =  "By using this function, you can transfer your balance and progress to a new device. If you want to import data from Version 1.0 of the Parallax, please select the option “The Parallax 1.0”.";
	settings_account_logInFirst: string =  "Please sign in first.";
	settings_account_instruction: string =  "Manual";
	settings_account_loginNeeded: string =  "Sign in required";
	settings_account_instruction_1: string =  "You need to sign in to save and/or restore your data.";
	settings_account_instruction_2: string =  "If you want to transfer your balance and progress from The Parallax 1.0 to the NEW app, you will first need to upload this data from the OLD app to the cloud.";
	settings_account_instruction_3: string =  "Please do this now. Open the OLD app, go to settings, save your data to the cloud, and then return to this screen.";
	settings_account_moveProgress: string =  "Transfer saved progress";
	settings_account_notYet: string =  "No, not yet";
	settings_account_didThat: string =  "Yes, all done";
	settings_account_consentNeeded: string =  "Please agree to the privacy policy to create an account.";
	settings_termsAndConditions: string =  "Terms & Conditions";
	
	settings_info_factory: string =  "Factory reset";
	settings_info_delete: string =  "Delete all progress and settings?";
	
	settings_reset_header: string =  "Erase all data (factory reset)";
	settings_reset_subheader: string =  "Are you sure?";
	settings_reset_message: string =  "Are you sure you want to erase all progress and do a factory reset?";
	
	settings_restorePurchases: string =  "Restore purchases";
	settings_purchasesRestored: string =  "Purchases restored";
	settings_noPurchasesRestored: string =  "No purchases found";
	
	contact_support_helpi: string =  "Helpy is always happy to receive feedback, praise and constructive criticism. And he’s also happy to help.";
	contact_cv: string =  "Characteristics";
	contact_stats: string =  "Stats";
	contact_relationships: string =  "Relationships";
	contact_story: string =  "Story";
	contact_character: string =  "Character";
	contacts_loading: string =  "Loading contacts...";
	contacts_noneYet: string =  "No contacts available yet. Come back later to learn more about the people you're talking to.";
	contacts_noneFilter: string =  "No contacts found.";
	
	menu_contacts: string =  "Contacts";
	menu_contact: string =  "Contact";
	menu_glossary: string =  "Glossary";
	menu_search: string =  "Search";
	menu_welcome: string =  "Welcome";
	menu_catalogue: string =  "Catalog";
	menu_help: string =  "Help";
	menu_settings: string =  "Settings";
	menu_history: string =  "Show History";
		
	menu_recaps: string =  "Recaps";
	menu_profile: string =  "Profile";
	
	menu_menu: string =  "Menu";
	menu_signup: string =  "Sign Up";
	menu_friends: string =  "Friends";
	menu_desk: string =  "Writer's Desk";
	menu_runningEpisodes: string =  "Open Episodes";
	menu_cugcEpisodes: string =  "CUGC-Episodes";
	menu_cugc: string =  "CUGC";
	menu_other: string =  "Other";
	
	unlock: string =  "Unlock";
	unlockForFree: string =  "Unlock for free";
	unlockEcho: string =  "Unlock Echo";
	unlockEpisode: string =  "Unlock Episode";
	open: string =  "Open";
	openBlog: string =  "Go to blog";
	turnOn: string =  "TURN ON";
	bestWithMusic: string =  "Best with Music...";
	history: string =  "History";
	sendEmail: string =  "Send an email";
	openMenu: string =  "Open menu";
	nextEpisode: string =  "Next Episode";
	episodeComplete: string =  "Episode complete";
	oneMomentPlease: string =  "Please hold...";
	coupon: string =  "Debug-Code";
	enterCoupon: string =  "Enter code";
	music: string =  "Music";
	sound: string =  "Sound";
	vibration: string =  "Vibration";
	cancel: string =  "Cancel";
	yes: string =  "Yes";
	pleaseConnectToInternet: string =  "Please connect to the Internet.";
	continue: string =  "Continue";
	moreInBlog: string =  "See our blog for more info...";
	recent: string =  "Latest";
	introductory: string =  "Introductory";
	free: string =  "free";
	noCost: string =  "Free";
	watchVideo: string =  "Watch a video";
	refresh: string =  "Refresh";
	more: string =  "More...";
	
	search: string =  "Search";
	olderPosts: string =  "Older posts";
	privacyPolicy: string =  "Privacy Policy";
	hello: string =  "Hello";
	help: string =  "Help";
	close: string =  "Close";
	end: string =  "End";
	gameOver: string =  "Game Over";
	problem: string =  "Problem";
		
	password: string =  "Password";
	no: string =  "No";
	noThanks: string =  "No thanks";
	yesPlease: string =  "Yes please";
	downloadComplete: string =  "Download complete";
	season: string =  "Season";
	continuedEnding: string =  "True Ending";
	alternativeEnding: string =  "Alternate Ending";
	
	later: string =  "Not now";
	notNow: string =  "Not now";
	sorry: string =  "Sorry!";
	wait: string =  "Waiting";
	success: string =  "Success!";
	show: string =  "Show";
	restart: string =  "Restart";
	language: string =  "Language";
	hours: string =  "hours";
	minutes: string =  "minutes";
	seconds: string =  "seconds";
	serial: string =  "serial";
	restore: string =  "Restore";
	redeem: string =  "Redeem";
	learnMore: string =  "Learn more...";
	openHomepage: string =  "Open homepage";
	showEpisodes: string =  "View episodes";
	installFullVersion: string =  "Install full version";
	playPreview: string =  "Play preview";
	save: string =  "Save";
	chooseEpisode: string =  "Select an episode";
	showInCatalogue: string =  "View in catalogue";
	demoLoading: string =  "Demo is loading...";
	get: string =  "Get";
	unlockNow: string =  "Unlock now";
	skipNow: string =  "Skip now";
	moreOptions: string =  "More Options";
	unlocked: string =  "Unlocked";
	
	unknownError: string =  "Sorry. An unknown error has occurred.";
	error_legacyLogin: string =  "Please contact us via email so that we can transfer your progress manually.";
	renewMembership: string =  "Renew";
	
	chat_redirected: string =  "redirected";
	chat_previousChatWith: string =  "Previous chat with ";
	chat_previousChatInEpisode: string =  "Previous chat in episode";
	chat_state_waiting: string =  "Waiting...";
	chat_state_chooseMessage: string =  "Select a message...";
	chat_noNextChat: string =  "Next chat not available (yet).";
	chat_noPreviousChat_3: string =  "You have no chats.";
	chat_ends_3: string =  " Some paths lead to Game Over; others are part of the ongoing story.";
	chat_ends_4: string =  " You have not yet chosen the “correct” ending in this case.";
	chat_ends_5: string =  " You may restart the echo now to collect all possible endings.";
	chat_startChat: string =  "Start Chat";
	chat_pause: string =  "Pause";
	chat_watchForSkip: string =  "Watch an ad to skip this pause.";
	chat_membershipExpired_episode: string =  "Unfortunately, you cannot continue with the episode.";
	chat_membershipExpired_echo: string =  "Unfortunately, you cannot continue with the echo.";
	chat_purrchase_episode_1: string =  "Buy the ";
	chat_purrchase_episode_2: string =  "episode for ";
	purrchase_options: string =  "You can watch ads to unlock this episode for free or choose one of the offers to play it right now and even skip all pauses.";
	
	user_verificationEmail_1: string =  "Complete registration";
	user_verificationEmail_2: string =  "A verification link has been sent to ";
	user_verificationEmail_3: string =  " . Please click on the link that has just been sent to your email account to verify your email and complete the registration process.";
	user_invalidEmail: string =  "Please enter a valid email address!";
	user_weakPassword: string =  "Please use a stronger password. Your password must contain at least 6 characters.";
	user_emailExists: string =  "An account already exists for this email address.";
	user_emailExists_wantSignIn: string = "Do you want to sign in?";
	user_alreadyLoggedIn: string =  "You are already signed in.";
	user_loginMethod: string =  "Sign in with...";
	user_methodEmailAndPassword: string =  "Email & password";
	user_hasAccount_ask: string =  "Do you already have an account?";
	user_hasAccount_yes: string =  "I already have an account.";
	user_hasAccount_no: string =  "I do not have an account yet.";
	user_enterValidData: string =  "Please enter valid login details.";
	user_forgotPassword: string =  "Forgot password";
	user_loginSuccess: string =  "Sign in successful";
	user_loginFailed: string =  "Sign in failed";
	user_loginNecessary: string =  "Login required";
	user_needToLoginForMigration: string =  "You need to be signed in to transfer your game data to a new device. Do you want to sign in now?";
	user_needToLoginForUpload: string =  "You need to be signed in to upload your game data before transferring it to a new device. Do you want to sign in now?";
	user_needToLoginForDownload: string =  "You need to be signed in to download your game data. Do you want to sign in now?";
	user_needToLoginForVote: string =  "You need to be signed in to vote. Do you want to sign in now?";
	user_isOldDevice: string =  "This is your old device.";
	user_isNewDevice: string =  "This is your new device.";
	user_emailNotVerified_1: string =  "Email not verified.";
	user_emailNotVerified_2: string =  "Please verify your email address first, by clicking on the link that has just been sent to your email account.";
	user_migrationEvery24Hours: string =  "To avoid misuse, you may upload your game data a maximum of three times in any 24-hour period. Please use device migration only once to transfer data from an old device to a new device.";
	user_migrationEvery180Days: string =  "To avoid misuse, you may migrate your data only once in any 180-day period. Please use device migration only once to transfer data from an old device to a new device. If you need to migrate your data again before the end of this period, please contact support at support@avoidsociety.app!";
	user_downloadingData: string =  "Downloading data...";
	user_uploadingData: string =  "Uploading data...";
	user_uploadFinished_1: string =  "Upload complete";
	user_uploadFinished_2: string =  "Your data has been uploaded successfully. Please take your new device and go to “Migrate device > This is my new device”.";
	user_noUploadedData: string =  "We could not find any uploaded game data for this account. Please take your old device first and select “Migrate device > This is my old device”. Please make sure you are signed in using the same login details on both devices.";
	user_noDownloadOnSameDevice: string =  "You cannot download your game data to the same device from which it was uploaded. Please take your new device and go to “Migrate device > This is my new device”.";
	user_downloadComplete: string =  "Your data has been downloaded successfully.";
	user_resetPassword_1: string =  "Reset password";
	user_resetPassword_2: string =  "Please enter your email address. We will send you an email to reset your password.";
	user_askForLogin: string =  "Would you like to log in now to restore your progress and continue playing where you left off?";
	
	content_spoiler: string =  "Spoiler alert! Before playing this episode, we recommend you play the following content first:";
	content_whyMultipleEndings: string =  "Why are there several endings?";
	content_endingsFound: string =  "Found endings";
	content_restart_1: string =  "Restart?";
	content_restart_2: string =  "Are you sure you want to restart this episode from the beginning?";
	content_update_1: string =  "Update?";
	content_update_2: string =  "A newer version of this episode is available. Would you like to download it?";
	content_updatingEpisode: string =  "Updating episode...";
	content_loadingEpisode: string =  "Loading episode...";
	content_connecting: string =  "Connecting...";
	content_restartEpisode: string =  "Restart episode";
	content_episodeInArchive: string =  "You have archived this episode. It will no longer be displayed on your home screen.";
	content_episodeInArchive_unarchive: string =  "Restore episode";
	content_episodeInArchive_archive: string =  "Archive Episode";
	content_episodeInArchive_notAllowed: string =  "You cannot archive the only episode on your home screen.";
	
	date_yesterday: string =  "Yesterday";
	
	legacy_moveData: string =  "Data transferred";
	legacy_alreadyMoved: string =  "You have already transferred data from Version 1.0.";
	legacy_howLoggedIn: string =  "How did you sign in to Version 1.0 of THE PARALLAX?";
	legacy_moveSuccess: string =  "Your credit and progress have been transferred from Version 1.0.";
	legacy_moveData_2: string =  "Transfer progress and credit";
	legacy_noFake: string =  "We will not use social media integration (e.g. Facebook and Twitter) in Version 2.0. Please return to Version 1.0 and sign in using your Google account to save your data. Then come back here again, go to “Transfer progress and credit” and select sign in via Google. If you no longer have access to Version 1.0 or if there are any other problems, please contact our support team.";
	legacy_noIOS: string =  "Unfortunately, transferring data from THE PARALLAX to an iOS device is no longer possible. Please send us an email so that we can manually restore your progress.";
	
	backButton_instruction: string =  "Tap the back button again to close the app.";
	
	rate_title: string =  "Would you like to rate this app?";
	rate_message: string =  "It only takes a minute and would help us enormously. Thank you for your support!";
	rate_cancelButtonLabel: string =  "Nope";
	rate_rateNow: string =  "Rate now";
	rate_appRatePromptTitle: string =  "Do you like this app?";
	rate_feedbackPromptTitle: string =  "Would you like to leave us some feedback?";
	
	signal_connection: string =  "Internet connection";
	signal_blank: string =  "Please connect to the Internet.";
	signal_videos: string =  "Please connect to the Internet to watch ads.";
	signal_contact: string =  "Please connect to the Internet to load contact info.";
	signal_contacts: string =  "Please connect to the Internet to load contact index.";
	signal_blog: string =  "Please connect to the Internet to load blog index.";
	
	coupon_invalidCode: string =  "Invalid code";
	coupon_success: string =  "Your code has been redeemed. Enjoy!";
	coupon_error: string =  "Your code could not be redeemed.";
	coupon_usedUser: string =  "You have already redeemed this code.";
	coupon_usedGlobal: string =  "This code has already been redeemed.";
	
	notFound: string =  "Not found";
	episodeNotFound: string =  "This episode could not be found.";
	content_loadingEcho: string =  "Loading echo...";
	moreStuff: string =  "More...";
	echoesAndMore: string =  "Echoes & more";
	browse: string =  "Browse...";
	
	repair_heading: string =  "Automatic repair";
	repair_episode_subheading: string =  "Repair scene";
	repair_episode_instruction: string =  "Each episode consists of several scenes (chats). If ever the app gets stuck, we recommend restarting the scene you last played.";
	repair_episode_restart: string =  "Restart scene";
	repair_echo_subheading: string =  "Repair Echo";
	repair_echo_instruction: string =  "If ever an echo gets stuck, we recommend restarting the echo.";
	repair_echo_restart: string =  "Restart Echo";
	
	share_select: string =  "Choose a message:";
	share_message_own: string =  "Individual message text";
	share_message1: string =  "Hey, I’d rather get back to chatting with David if you don’t mind!";
	share_message2: string =  "The app is great!";
	share_message3: string =  "Store! Now!";
	
	live_subheading: string =  "News";
	
	recommended: string =  "Recommended";
	
	userPic_header: string =  "Profile picture";
	userPic_message: string =  "You can add a profile picture. It will be displayed with your messages. (The picture will only be stored locally on your device.)";
	userPic_delete: string =  "Delete profile picture";
	userPic_retake: string =  "Change profile picture";
	userPic_take: string =  "Add profile picture";
	
	glossary_notFoundSimple: string =  "(Unfortunately, it looks like the Parallax has swallowed this item. Sorry!)";
	glossary_notFoundConnection: string =  "Unfortunately, it looks like the Parallax has swallowed this item. Please reconnect to the internet to display it.";
	glossary_loading: string =  "Loading...";
	glossary_noneYet: string =  "No entries available yet. Come back later.";
	glossary_noneFilter: string =  "No entries found.";
	
	echoCollection: string =  "Echo Collection";
	
	advert_membership_heading: string = "Membership has many benefits..."
	advert_membership_episodes_heading: string =  "All Episodes";
	advert_membership_episodes_text: string =  "Get access to all episodes from all series.";
	advert_membership_pauses_heading: string =  "No Pauses";
	advert_membership_pauses_text: string =  "Skip waiting times as often as you want.";
	advert_membership_bonus_heading: string =  "Bonus Content";
	advert_membership_community_heading: string =  "Community";
	advert_membership_community_text: string =  "Only with the help of the best community in the world this project can survive. Thank you very much!";
	advert_membership_offer_heading: string = "Special Offer";
	advert_membership_offer_text: string =  "You can test the membership for 2 months at a reduced price.";
	advert_membership_assets_heading: string = "Pictures & Audio";
	advert_membership_assets_text: string = "Unlock exclusive photos, images, voice recordings, radio and broadcast features.";
	advert_membership_disclaimer_text_offer_1: string =  "Special Deal: ";
	advert_membership_disclaimer_text_offer_2: string =  "for the first two months, then";
	advert_membership_disclaimer_tcs: string =  "T&Cs";
	advert_membership_perMonth: string =  "a month";
	advert_membership_firstMonths: string =  "during the first two months";
	advert_membership_action: string =  "Buy now";
	advert_membership_short: string =  "Unlock all content now + skip all pauses + support the project.";

	advert_assets_heading: string = "Unlock Pictures & Audio";
	advert_assets_instruction: string = "Every package from the store unlocks all Pictures & Audio at the same time. Forever.";
	advert_assets_info: string = "Even if you have used up the Nutrition and Tickets or terminate the membership, Pictures and Audio remain unlocked.";
	
	notification_newMessage: string =  "New Message";
	notification_helpi1: string =  "Hey, someone is waiting for an answer from you. You know, people are entirely lost if you don't tell them what to do...";
	notifcation_helpi2: string =  "How's it going?";
	notification_helpi3: string =  "Helpi here. Long time no see. I think there is still a message unanswered ...";
	
	moreInfo: string =  "Show details";
	letsgo: string =  "Let's go";
	setReminder: string =  "Set Reminder";
	congratulations: string =  "Congratulations!";
	
	purchase_oneTimeFee: string =  "one-time";
	purchase_perMonth: string =  "per Month";
	
	purchase_season_4: string =  "You play them completely without any pauses.";
	purchase_season_5: string =  "Go back and play again as much as you want to find alternative storylines.";
	purchase_season_6: string =  "The Season Pack is available as a one-off purchase, after which the entire Season is yours to keep.";
	
	purchase_season_notFound: string =  "Sorry, we couldn’t find a Season Pack for this Season.";
	purchase_season_inProduction: string =  "This Season is still in production and is not yet available as a Season Pack.";
	
	purchase_membership_community: string =  "Werde Teil der fabelhaften Community, die dieses Projekt unterstützt.";
	purchase_membership_1: string =  "Unlock all content in this app: ";
	purchase_membership_2: string =  " episodes so far & more. And play";
	purchase_membership_3: string =  "completely without pauses at any time.";
	purchase_membership_offer_1: string =  "Special offer: 40% discount during the first two months.";
	
	purchase_membership_yourPersonal: string =  "Your personal";
	purchase_membership_getAllSupportProject: string =  "Support the project!";
	purchase_membership_congrats: string =  "You can now play all Echoes, all Episodes and all bonus content! All with zero pauses! Thank you so much for your support!";
	
	purchase_shards_0: string =  "Quick skip";
	purchase_shards_1: string =  "Get";
	purchase_shards_2: string =  " to skip pauses.";
	purchase_shards_3: string =  "BONUS: You’ll also get the next Episode, ";
	purchase_shards_4: string =  "";
	purchase_shards_5: string =  "for free on top!";
	
	purchase_episode_1: string =  "Unlock this episode and remove all pauses from the chats.";
	
	purchase_special2: string =  "Unlock episodes 2-4 and remove all pauses from the chats.";
	
	purchase_episode_B_1: string =  "Remove all pauses from this episode.";
	
	playNow: string =  "Play now";
	
	purchase_fair2play_1: string =  "Unlock one free Episode per week and play ";
	purchase_fair2play_2: string =  "with pauses.";
	purchase_fair2play_freeWith: string =  "For free with";
	
	fair2play_oneliner: string =  "You can always unlock the next Episode for free during our regular Fair2Play events. You'll be playing this free episode ";
	fair2play_oneliner2: string =  "with pauses.";
	fair2play_nexttime: string =  "The next Fair2Play event will be on:";
	fair2play_today: string =  "Today is Fair2Play:";
	fair2play_reminder1: string =  "It's Sunday! The Fair2Play event is on! Time for a new episode!";
	fair2play_reminder2: string =  "You will be notified when the Fair2Play event starts.";
	
	fair2play_whatIs_1: string =  "What's";
	fair2play_whatIs_2: string =  "Fair2Play?";
	fair2play_whatIs_3: string =  "Once a week, when the strata of the Parallax align, the Fair2Play event opens for business. During this period, you can unlock the next episode for free.";
	fair2play_when_1: string =  "When’s the";
	fair2play_when_2: string =  "next one?";
	fair2play_whatRules_1: string =  "Are there any";
	fair2play_whatRules_2: string =  "conditions?";
	fair2play_whatRules_3: string =  "Yes, you must follow these rules:";
	fair2play_rules_1: string =  " The three latest Episodes in a Season are not included.";
	fair2play_rules_2: string =  "You can unlock a maximum of 1 free Episode per week.";
	fair2play_rules_3: string =  "You must have finished all previous Episodes.";
	fair2play_rules_4: string =  "There are pauses in the chats.";
	fair2play_running_1: string =  "The Fair2Play event is";
	fair2play_running_2: string =  "on now!";
	fair2play_running_3: string =  "You can now unlock the next Episode for free. This Episode is WITH pauses.";
	fair2play_noWait_1: string =  "But I";
	fair2play_noWait_2: string =  "can’t wait!";
	fair2play_noWait_3: string =  "You can unlock the next Episode straight away and support the project at the same time. This will also allow you to play through the entire Episode with zero pauses. ";
	fair2play_why_1: string =  "Why? Just";
	fair2play_why_2: string =  "why?!?";
	fair2play_why_3: string =  "We’d like to tell you a bit about why we think Fair2Play is a good compromise. Go to our blogpost to learn more.";
	
	thanks_episode: string =  "Thank you for purchasing this episode. You can start it now.";
	thanks_season: string =  "Thank you for purchasing this season. You can start the episodes now.";
	thanks_set: string =  "Thank you for purchasing this collection. You can now launch the Echoes.";
	
	ads_advertisement: string =  "Advertisement";
	ads_leaveAnyway: string =  "Leave";
	ads_stay: string =  "Stay";
	ads_instruction_3: string =  "Please select an episode you would like to unlock for free.";
	ads_showProduct: string =  "View product";
	ads_disclaimer_a: string =  "Disclaimer: This is an affiliate link. If you open this link and make a purchase within 24 hours, we will receive compensation.(This also applies to products other than the one advertised here.) This will not increase the retail price for you.";
	ads_disclaimer_b: string =  "Disclaimer: This is an advertising link to an external store where you can purchase merchandising products related to this app. We receive a revenue share.";
	ads_error_noLegibility: string =  "This episode cannot yet be unlocked with Ads.";
	ads_error_noAvailability: string =  "Sorry, there are no more Rewarded Video Ads available at the moment. Please try again later.";
	ads_offer_season: string =  "Want to fully unlock the season and remove all pauses?";
	ads_offer_episode: string =  "Do you want to unlock the episode immediately and remove all pauses?";
	ads_adsCollected: string =  "Ads collected";
	ads_freeWithPauses: string =  "Free of charge. With pauses.";
	ads_unlockedViaAds: string =  "Free";
	ads_watchAd: string =  "watch Ad";
	ads_enoughAdsCollected: string =  "You've watched enough ads.";
	ads_episodeIsFree: string =  "This episode is free for you. You'll play with pauses in the chats.";
	ads_episodeStartNow: string =  "Want to start right away?";
	ads_instructionC_1: string =  "You can collect advertisements to unlock this episode for free. You then play it with pauses in the chats.";
	ads_leaveEarly: string =  "Stay until the end to get your reward.";
	startEpisode: string =  "Start Episode";
	
	troubleShootingSupport: string =  "For troubleshooting, please contact us via email.";
	
	preview_skip: string =  "In the full version of this app you are able to skip pauses in chats. (You can install the full version on your smartphone now).";
	
	synopsis_david: string =  "The story of the last survivor of the apocalypse, a cheeky cat and their adventures.";
	synopsis_gamer: string =  "A gamer guild almost misses the end of the world and anyway they are only moderately well prepared.";
	synopsis_horror: string =  "Your new phones includes some extra features you weren't expecting.";
	synopsis_cugc: string =  "The community creates its own stories in the familiar story world.";
	synopsis_echoes: string =  "Echoes hailing from before the catastrophe shed a different light on some of the goings-on.";
	
	catalogue_cugc: string =  "Browse through an ever-growing selection of stories from the community";
	catalogue_echoes: string =  "Browse through all Echoes and Specials";
	
	unlockEpisodeIndividually: string =  "You can unlock this episode individually.";
	unlockEchoIndividually: string =  "You can unlock this echo individually.";
	
	molerts_ticketsGained2: string =  "You have";
	molerts_membershipGained: string =  "Thank you for supporting this project! You now have access to all content and can skip pauses at any time.";
	
	molerts_waitNextMessage: string =  "You have to wait until the next message arrives.";
	molerts_waitNextMessage_2: string =  "You can watch a Rewarded Video or use 1 Nutrition to skip the pause. For this you will get 2 tickets.";

	molerts_nextAd: string =  "Next Rewarded Ad";
	molerts_watchAd: string =  "Watch Rewarded Ad";

	molerts_missingCircuits: string = "No Circuits";
	molerts_missingCircuits_2_b: string = "You need Circuits to send messages to Help-Bot (and other AI Bots).";
	molerts_missingCircuits_2: string = "You can watch a Rewarded Video or buy Circuits in the shop.";
	molerts_missingCircuits_switchToStoryMode_b: string = "Switch to Story Mode";
	
	ads_needTickets: string =  "You're missing";
	
	settings_profile_howAdress_1: string =  "How should we address you?";
	settings_profile_howAdress_2: string =  "I want to be addressed as ";
	settings_profile_howAdress_3: string =  "HE.";
	settings_profile_howAdress_4: string =  "SHE.";
	settings_profile_howAdress_5: string =  "";
	settings_profile_howAdress_6: string =  "I want to be addressed as HE.";
	settings_profile_howAdress_7: string =  "I want to be addressed as SHE.";
	settings_profile_howAdress_8: string =  "as HE";
	settings_profile_howAdress_9: string =  "as SHE";
	settings_privacy_consent_1: string =  "You agreed to our terms on ";
	settings_privacy_consent_2: string =  ".";
	chat_noPreviousChat_1: string =  "You have not chatted to ";
	chat_noPreviousChat_2: string =  " yet.";
	chat_ends_1: string =  "You have played ";
	chat_ends_2: string =  " endings of this echo.";
	content_notEnoughTickets_2: string =  "You still need ";
	content_notEnoughTickets_3: string =  " tickets to unlock this echo. Would you like to buy tickets in our shop now?";
	content_notEnoughTickets_4: string =  " tickets to unlock this episode. Would you like to buy tickets in our shop now?";
	localization_change_message_1_DE: string =  "Die Sprache wird nun von ";
	localization_change_message_2_DE: string =  " zu ";
	localization_change_message_3_DE: string =  " geändert";
	localization_change_message_1_EN: string =  "Language will be changed from ";
	localization_change_message_2_EN: string =  " to ";
	localization_change_message_3_EN: string =  " .";
	purchase_season_1: string =  "Unlock all";
	purchase_season_2: string =  "Episodes from";
	purchase_season_3: string =  "and remove all pauses from all episodes.";
	purchase_set_1: string =  "Unlock all";
	purchase_set_2: string =  "Echoes of the Collection and remove all pauses from the chats.";
	purchase_set_3: string =  "You can repeat them at will to find alternative endings.";
	ads_chat_header_1: string =  "";
	ads_chat_header_2: string =  "seconds left";
	ads_chat_header_3: string =  "until you can leave this Ad...";
	ads_chat_header_4: string =  "Done!";
	ads_chat_header_5: string =  "You can now leave this chat.";
	ads_instruction_1: string =  "You can watch Ads to unlock episodes for free. You then play them ";
	ads_instruction_2: string =  "with pauses.";
	ads_instructionB_1: string =  "You can";
	ads_instructionB_2: string =  "watch Ads";
	ads_instructionB_3: string =  "to unlock this episode for free and play with breaks.";
	molerts_adWatched_1: string =  "You are already one step closer to the free episode. Only";
	molerts_adWatched_2: string =  "left.";
	molerts_ticketsMissing_1: string =  "You're missing";
	molerts_ticketsMissing_2_episode: string =  "for this episode.";
	molerts_ticketsMissing_2_echo: string =  "for this echo.";
	ads_discount_1: string =  "Since you've been watching Rewarded Ads, you can get this episode with discount for as low as";
	ads_discount_2: string =  ". Collect more ads to unlock the episode for free.";
	ads_discount_3: string =  "You can earn a discount for this episode by watching Rewarded Ads.";
	
	language_name: string =  "English";
	localization_missingLocalizations_b: string =  "The below episodes or echoes are not available in the chosen language yet. Only the original language version is available for:";
	localization_missingLanguageVersion_b: string =  "Not available in the chosen language yet";
	localization_missingLanguageVersion_explanation_b: string =  "This episode is not available in the chosen language yet. Would you like to play the original language version?";
	localization_missingLanguageVersion_refresh_b: string =  "This episode is now available in the chosen language. Do you want to change language now?";
	localization_missingLanguageVersion_instruction_b: string =  "(Tap here to find out if content has been published in the chosen language yet.)";
	localization_missingLanguageVersion_switch_b: string =  "Change language version";
	
	purchase_ticketsForNutrition: string =  "If you use 10 Nutrition, you will collect 20 Tickets.";
	purchase_ticketsForNutrition_special2: string =  "If you use 20 Nutrition, you will collect 40 Tickets.";
	purchase_collect2Tickets: string =  "You receive 2 Tickets.";
	purchase_offer_special6: string =  "Helpi has arranged a special deal for you. Get enough Tickets & Nutrition to unlock multiple episodes and skip 50 pauses.";
	shop_125nutrition_description: string =  "Skip 125 pauses and collect 250 Tickets in the process.";
	shop_100circuits_description_b: string = "Send 100 messages to Help-Bot (and other AI chatbots.)";
	shop_box_single_description: string =  "Enough Tickets & Nutrition to unlock an episode and skip all the pauses.";
	shop_special6_description: string =  "Enough Tickets & Nutrition to unlock three episodes and skip most of the pauses.";
	shop_box_season_description: string =  "Unlock a complete season and skip most of the pauses.";
	molerts_nutritionGained: string =  "You have received [x] Nutrition. With it, you can skip [x] pauses and collect [y] Tickets along the way.";
	molerts_circuitsGained: string = "You have received [x] Circuits. With it, you can send messages to AI Chatbots.";
	molerts_ticketsGained: string =  "You have received [x] tickets. You can use them to unlock episodes, specials & echoes.";

	molerts_assetsGained: string = "You have unlocked pictures and audio (voice messages, calls, radio).";
	
	purrchase_options_echo: string =  "You can wait until you have collected enough tickets, or choose one of the offers to play it immediately and skip all the pauses.";
	content_notEnoughTickets_1: string =  "Not enough tickets";
	molerts_ticketsGained1: string =  "Tickets collected";
	
	settings_hasMembership: string =  "You’re a Member.";
	settings_membershipExpired: string =  "Your Membership has expired.";
	settings_hasNoMembership: string =  "You are not a Member.";
	settings_freeAccess: string =  "That means you have unrestricted access to all episodes and all echoes.";
	settings_canRenewMembership: string =  "You can unlock individual episodes or buy a Membership.";
	settings_canGetMembership: string =  "You can unlock individual episodes or renew your Membership now.";
	reactivateMembership: string =  "Reactivate Membership";
	chat_membershipExpired: string =  "Your Membership has expired.";
	chat_purrchase_membership_1: string =  "Access to all episodes & echoes";
	chat_purrchase_membership_2: string =  "a month - Rolling 1-month membership - renews every month until cancelled";
	advert_membership_bonus_text: string =  "Echoes and Minisodes are free with a Membership.";
	advert_membership_disclaimer_text: string =  "Rolling 1-month membership. You can access content for the duration of your Membership. You can cancel any time up to 24 hours before the end of each billing period. Renews every month until cancelled";
	purchase_membership_long: string =  "This project can exist mainly thanks to the great community that supports it for many years. Members have access to all the content and skip all the pause if they want. But above all, they make sure that this adventure can continue.";
	purchase_offer_deal: string =  "Special Deal";
	purchase_offer_special3: string =  "Helpi has arranged a special deal for you. His offer includes episodes 2 to 4 - complete without pauses - for the special price of ";
	purchase_offer_special4_1_a: string =  "Helpi has a special deal for you. He offers you a";
	purchase_offer_special4_1_b: string =  "membership, which gives you access to all content and allows you to play completely without breaks,";
	purchase_offer_special4_1_c: string =  "for 2 months at an introductory price. Instead of the regular";
	purchase_offer_special4_2: string =  "per month for only";
	purchase_offer_special4_3: string =  "per month.";
	purchase_offer_special5: string =  "Helpi has arranged a special deal for you. His offer includes season 1 & 2 - complete without pauses - for the special price of ";
	purchase_offer_getItNow: string =  "Now only";
	thanks_membership: string =  "Thank you for supporting the project with a membership. You can now start all episodes, echoes and specials and play anytime without pauses.";
	ads_offer_membership: string =  "Do you want to get access to all episodes with a membership and play them at any time without pauses?";

	mail: string =  "Email";
	support_mail_1: string = "There is almost no problem that we cannot solve by email. In any case you will receive a friendly reply.";
	support_mail_2: string = "We try to reply within 3 business days.";
	support_migrate_0: string = "Migrate device";
	support_migrate_1: string = "If you have changed your device and want to transfer your full progress, select this option.";
	support_migrate_2: string = "Your decisions and settings will be transferred as well.";
	support_migrate_3: string = "migrate";
	support_restore_0: string = "Restore purchases";
	support_restore_1: string = "If you want to restore only your purchases, choose this option.";
	support_restore_2: string = "Once you have purchased content, you can unlock it again for free. Your exact progress within the story as well as your decisions and settings will not be taken over.";
	support_restore_3: string = "restore";
	support_restoreManually: string = "If none of the above options are possible, please send us a short email. We will restore your progress and credit manually.";
	support_discord_1: string = "While you wait for our response to your email, you can also turn to the helpful and friendly community on Discord. Many questions are answered there immediately.";
    support_discord_2: string = "Be nice to them!";

	// UNIVERSAL
	list_randomA_1: string = "This is a series.";
	list_randomA_2: string = "To be continued.";
	list_randomB_1: string = "This app is a work of fiction. Names, characters, businesses, places, events, locales, and incidents are the products of the authors' imagination. Any resemblance to actual persons, living or dead, or actual events is purely coincidental.";
	list_randomC_1: string = "Nice to see you!";
	list_randomC_2: string = "Nice to see you!";
	list_randomD_1: string = "You are standing in an open field.";
	settings_privacy_heading: string = "Privacy";
	settings_usage_heading: string = "Usage";
	settings_privacy_videos: string = "Ads";
	settings_privacy_analytics: string = "Analytics";
	settings_info: string = "App-Info";
	settings_info_build: string = "Build 5.0.0";
	settings_info_configuration: string = "Configuration";
	menu_showcase: string = "Discover";
	menu_echoes: string = "Echoes";
	menu_episodes: string = "Episodes";
	menu_chats: string = "Chats";
	menu_more: string = "More";
	menu_support: string = "Support";
	menu_stories: string = "Stories";
	menu_david: string = "David Series";
	menu_gamer: string = "Gamer Series";
	email: string = "Email";
	error: string = "Error";
	membership: string = "Membership";
	shop_10nutrition_alias: string = "Snack";
	shop_special2_alias: string = "Buff Food";

	settings_account_moveFromVersion1: string =  "Learn how to restore your progress and balance from Version 1.0.";
	account_emailEmpty: string = "Please enter a email address.";
	account_passwordEmpty: string = "Please enter a password.";
	account_passwordTooShort: string = "Password too short.";
	account_passwordTooLong: string = "Password too long.";
	account_emailInvalid: string = "Please enter a valid email address.";
	account_passwordInvalid: string = "Please enter a valid password.";
	account_privacyEmpty: string = "Please agree to the privacy policy to create an account.";
	account_unlinkProviderNotPossible: string = "You cannot unlink the only login method. Please add another login method first.";
	account_passwordReset: string = "Password reset";
	account_resetPassword: string = "Reset password";
	account_passwordResetEmailSent: string = "Password reset email sent. Please check your inbox.";
	account_emailNotFound: string = "No account found for this email address.";
	account_emailNotFound_google: string = "Have you possibly signed in with Google directly without creating a password?";
	account_emailNotFound_wantCreateNow: string = "Do you want to create an account now?";
	account_createAccount: string = "Create account";
	account_passwordResetEmailError: string = "Error sending password reset email.";
	account_signInWithGoogle: string = "Sign in with Google";
	account_signInWithApple: string = "Sign in with Apple";
	account_deleteAuthMethod: string = "Remove";
	account_authenticatedViaPassword: string = "Login with email & password is set up.";
	account_authenticatedViaGoogle: string = "Sign in with Google is connected.";
	account_authenticatedViaApple: string ="Sign in with Apple is connected.";
	account_emailAndPassword: string = "Email & password";
	account_addAuthMethod_google: string = "Add Google as an additional login method.";
	account_addAuthMethod_apple: string = "Add Apple as an additional login method.";
	account_addAuthMethod_password: string = "Add email & password as an additional login method.";
	account_repeatPassword: string = "Repeat password";
	account_passwordsDontMatch: string = "Passwords don't match.";
	account_alreadyHaveAnAccount: string = "Already have an account?";
	account_dontHaveAnAccountYet: string = "Don't have an account yet?";
	account_logout: string = "Sign out";
	account_logoutSub: string = "Stay signed in to keep your progress and balance safe.";
	account_signIn: string = "Sign in";
	account_signUp: string = "Sign up";
	account_copiedToClipboard: string = "User-ID copied to clipboard";
	account_wannaSignIn: string = "Would you like to sign in?";
	account_singInFreeAndEasy: string = "It's free and easy.";
	account_secureProgress_1: string = "Sign in to make sure your progress and balance won't be lost.";
	account_secureProgress_2: string = "Play accross multiple devices and keep your data safe.";
	account_secureProgress_3: string = "It's easy and free.";
	account_secureProgress_4: string = "You can sign in with your email address or with Google or Apple.";
	account_signoutConfirmation: string = "Are you sure you want to sign out?";
	account_signoutWarning: string = "If you sign out, you are in danger of losing your progress and balance as long as you don't sign in again. Are you sure you want to sign out?";
	confirm: string = "Confirm";
	account_updatePassword_changePassword: string = "Change password";
	account_updatePassword_enterNewPassword: string = "Please enter a new password.";
	account_updatePassword_success: string = "Password changed successfully.";
	ok: string = "OK";
	account_reauthNeeded: string = "Please authenticate again to perform this action.";
	account_addLoginMethod: string = "Add login method";
	account_syncInProgress: string = "Important updates are being applied. Please do not close the app.";
	account_syncProgress: string = "Sync my data & progress";
	account_syncProgress_explanation: string = "This happens automatically. You can kick off the process manually at your own risk.";
	account_syncProgress_warning: string = "Please do not close the app while the sync is in progress.";
	account_syncProgress_actionButton: string = "Sync now";
	account_delete: string = "Delete account";
	account_delete_explanation: string = "This will delete your account and all data associated with it. This cannot be undone.";
	account_delete_irrevocably: string = "Delete irrevocably";
	account_delete_author_explanation: string = "If you have created and/or published any projects as an author of CUGC-Stories, you will lose access to these projects. You will NOT be able to edit or delete them anymore. The stories will remain in the app, but you will NOT be able to edit them. If you want to remove them please unpublish them BEFORE you delete your account.";
	account_delete_deviceRemains: string = "The progress on your device will not be deleted by now. You can still play the game. But you might lose your progress if you delete the app or change your device.";
	thanks_canContinue: string = "Thank you. You can continue now.";
	account_deleted: string = "Account deleted.";
	account_deleted_explanation: string ="Your account has been deleted. The progress on your device was not deleted. You can still play the game. But you might lose your progress if you delete the app or change your device.";
	account_providerAlreadyLinked_password: string = "Email & password is already set up.";
	account_providerAlreadyLinked_google: string = "Sign in with Google is already connected.";
	account_providerAlreadyLinked_apple: string = "Sign in with Apple is already connected.";
	account_authMethodAlreadyLinkedToAnotherAccount: string = "Login data already linked to another account. You can sign out and sign in with the other account.";
	account_wrongPassword: string = "Wrong password";
	account_wantToRestorePassword: string = "Forgot your password? Do you want to reset it?";
	account_restorePassword: string = "Reset password";
	account_accountNotFound: string = "For this email address no account was found. You can create an account by signing up.";
	account_resendVerificationEmail: string = "Resend verification email";
	account_resendVerificationEmail_explanation: string = "If you have not received a verification email, you can request a new one.";
	echoes_subtitle: string = "of a lost world";
	stories_subtitle: string = "written by the community"

	images: string = "Images";
	immerch_disclaimer = "(Affiliate-Link)";
	authForCreateMode: string = "Bitte logge dich ein oder erstelle einen Account, um den Create-Mode benutzen zu können.";
	comingSoon: string = "Coming soon";
	createModeComingSoon_1_b: string = "Bald wirst du eigene Geschichten schreiben können. Wir haben einen praktischen Editor für dich vorbereitet, mit dem du deine eigenen Echos erstellen kannst.";
	createModeComingSoon_2_b: string = "Anschließend kannst du deine Geschichten mit Freunden teilen oder diejenigen von anderen spielen und bewerten.";
	createModeComingSoon_3_b: string = "Wir arbeiten hart daran, dass du bald deine eigenen Stories verfassen kannst. Wir halten dich auf dem Laufenden.";
	menu_projects: string = "Projects";
	menu_challenges: string = "Challenges";
	menu_serials: string = "Serials";
	placeholderProfileDescription: string = "Erzähl der Welt etwas über dich, wenn du willst. Oder bleib anonym, wenn du es vorziehst.";

	buyCircuits: string = "Buy Circuits";
	flushConversation: string = "Flush conversation";
	resumeConversation: string = "Resume conversation";
	flushMemories: string = "Flush memories";
	confirmFlushConversation: string = "Are you sure you want to flush the conversation?";
	confirmFlushMemories: string = "Are you sure you want to flush the memories of this bot? It will forget everything you have told it so far.";
	reset: string = "Reset";
	watchAdForCircuit: string = "Watch Rewarded Video Ad for [x] Circuit";
	authenticateForDeleteAccount: string = "Please authenticate if you want to delete your account.";
	messageTooLong: string = "Message too long";
	messageTooLong_2: string = "Your message can be a maximum of [x] characters long."
	enterStoryMode: string = "Enter Story Mode";
	botNotAvailable: string = "This bot is not available at the moment. Please try again later.";

	privacy_needConsent: string = "Um dir Werbevideos von externen Anbietern zeigen zu können, benötigen wir deine Zustimmung. Bitte lies dazu zunächst unsere Datenschutzerklärung durch.";
	privacy_details: string = "Bitte bestätige, dass du die Datenschutzerklärung verstanden hast und einverstanden bist. Wenn du nicht zustimmen möchtest, kannst du das Spiel trotzdem ungehindert fortsetzen. Du musst außerdem 16 Jahre oder älter sein, um deine Zustimmung erteilen zu können. Wenn du nicht 16 Jahre oder älter bist, muss ein Erziehungsberechtiger die Zustimmung für dich erteilen.";
	privacy_confirmAge: string = "Ich bin 16 Jahre oder älter";
	privacy_agree: string = "Ich stimme zu";
	privacy_revoke: string = "Zustimmung widerrufen";
	privacy_consentGive: string = "Zustimmung erteilt:";
	
	dateformat_ereyesterday: string = "two days ago";
	dateformat_hoursAgo: string = "[x] hours ago";
	dateformat_oneHourAgo: string = "one hour ago";
	dateformat_yesterday: string = "yesterday";
	dateformat_minutesAgo: string = "[x] minutes ago";
	dateformat_oneMinuteAgo: string = "one minute ago";
	dateformat_momentsAgo: string = "just now";

	constructor(
		private events: Events,
		private storage: Storage,
		private storageSrv: StorageService,
		private alertController: AlertController,
		private platform: Platform,
		private http: HttpClient,
		private instance: InstanceService
	) {}

	async setLanguage(langCode, changed) {
		let langCodeLegit = false;
		for (let language of this.languages) {
			if (language.code === langCode) {
				langCodeLegit = true;
			}
		}
		if (!langCodeLegit) { langCode = "EN"; }

		let DB_contacts = "contacts_"+langCode;
		let DB_echoes = "echoes_"+langCode;
		let DB_echoesInfo = "echoesInfo_"+langCode;
		let DB_episodes = "episodes_"+langCode;
		let DB_meta = "meta_"+langCode;
		let DB_notifications = "notifications_"+langCode;
		if (langCode === "DE") {
			DB_contacts = "contacts";
			DB_echoes = "echoes";
			DB_echoesInfo = "echoesInfo";
			DB_episodes = "episodes";
			DB_meta = "meta";
			DB_notifications = "notifications";
		}
		this.DB_contacts = DB_contacts;
		this.DB_echoes = DB_echoes;
		this.DB_echoesInfo = DB_echoesInfo;
		this.DB_episodes = DB_episodes;
		this.DB_meta = DB_meta;
		this.DB_notifications = DB_notifications;

		this.lang = langCode;
		this.langInfo = this.languages.find(x => x.code === langCode);

		await this.setDictionary(langCode);

		if (changed) { this.events.publish("languageChanged"); }
	}

	async setDictionary(language) {
		this.fetchDictionary(language);
	}

	fetchDictionary(language) {
		fetch("assets/json/dictionary_"+language+".json").then(res => res.json()).then(dictionary => {
			if (dictionary) {
				for (let value of dictionary) {
					this[value.key] = value.value;
				}
			}
		});
	}

}