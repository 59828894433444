import { Injectable } from '@angular/core';
import { BrowserService } from '../system/browser.service';
import { MetaService } from './meta.service';
import { Platform, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { NavigationService } from '../system/navigation.service';
import { LocalizationService } from '../system/localization.service';
import { InstanceService } from '../system/instance.service'
import { DeviceService } from '../system/device.service';
import { ModalsService } from '../system/modals.service';
import { StorageService } from '../system/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {

	notifications: Array<any> = [];

  	constructor(
		private meta: MetaService,
		private platform: Platform,
		private storage: Storage,
		private alertController: AlertController,
		private iab: BrowserService,
		private navigation: NavigationService,
		private localization: LocalizationService,
		private instance: InstanceService,
		private device: DeviceService,
		private modals: ModalsService,
		private storageSrv: StorageService
	) {
		document.addEventListener("resume", () => { this.checkNotifications(); }, false);
	}

	requestPermission() {}

	subscribe() {}

	async checkNotifications() {
		if (this.instance.instance != "prevapp") {
			let now = Date.now();
			let notif: any = []; await this.meta.getNotifications().then(result => { notif = result } );
	
			await this.device.getPlatform();

			if (this.device.platform === "ios") {
				if (notif.ios) {
					if (notif.ios.f && notif.ios.t) {
						if (now > notif.ios.f.seconds*1000 && now < notif.ios.t.seconds*1000) { this.notifications.push(notif.ios); }
					}
				}
			} else if (this.device.platform === "android") {
				if (notif.android) {
					if (notif.android.f && notif.android.t) {
						if (now > notif.android.f.seconds*1000 && now < notif.android.t.seconds*1000) { this.notifications.push(notif.android); }
					}
				}
			}	
			if (notif) {
				if (notif.general) {
					if (notif.general.f && notif.general.t) {
						if (now > notif.general.f.seconds*1000 && now < notif.general.t.seconds*1000) { this.notifications.push(notif.general); }
					}
				}
			}
	
			for (let notification of this.notifications) {
				let resolved = false; await this.storage.get("noti_"+notification.i).then((value) => {
					if (value != null) { resolved = JSON.parse(value); }
				})
				if (!resolved) {
					if (notification.h != "The Parallax" || !this.instance.liberty) {
						this.presentNotification(notification);
					}
				}
			}
		}
	}

	async presentNotification(notification) {
		const alert = await this.alertController.create({
			subHeader: notification.h,
			cssClass: 'eon__alert',
			message: notification.m,
			buttons: [
				{
					text: this.localization.notNow,
					handler: () => { this.resolveNotification(notification); }
				},{
					text: this.localization.show,
					handler: () => { this.executeNotificationn(notification) }
				}
			]
		});
	  
		await alert.present();
	}

	async executeNotificationn(notification) {
		if (notification.a === "link") { this.iab.create(notification.d, '_blank', 'location=yes'); }
		else if (notification.a === "echo") {
			let echo = await this.meta.getEchoInfo(notification.d);
			this.modals.pushEcho(echo);
		}
		else if (notification.a === "episode") {
			this.modals.pushEpisodeById(notification.d, "season");
		}
		this.resolveNotification(notification);
	}

	resolveNotification(notification) {
		this.storage.set("noti_"+notification.i, "true");
		this.notifications = this.notifications.filter((item) => { return item.i != notification.i; })
	}
}
