import { Injectable } from '@angular/core';

import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

	platform: string = "unknown";
	operatingSystem: string = "unknown";
	native: boolean = false;
	id: string = "unknown";

	constructor() { 
		this.getPlatform();
	}

	async getPlatform() {
		if (this.platform === "unknown") {
			const info = await Device.getInfo();
			this.platform = info.platform; // 'ios' / 'android' / 'web'
			if (info.platform === "ios" || info.platform === "android") { this.native = true; }
			this.operatingSystem = info.operatingSystem; // 'ios' | 'android' | 'windows' | 'mac' | 'unknown'
		}
		return this.platform;
	}

	async getId() {
		if (this.id === "unknown") {
			const id = await Device.getId();
			this.id = id.uuid;
			console.log("ID", JSON.stringify(this.id));
			alert(this.id);
		}
	}
}