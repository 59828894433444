import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ImageModule } from '../components/image/image.component.module'
import { Fair2playPage } from './fair2play.page';
import { ChatCardModule } from '../components/chat-card/chat-card.component.module';

const routes: Routes = [
  {
    path: '',
    component: Fair2playPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImageModule,
    ChatCardModule,
    RouterModule.forChild(routes)
  ],
  declarations: [Fair2playPage]
})
export class Fair2playPageModule {}
