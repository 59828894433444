import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { HttpClient } from '@angular/common/http';

import { SplashScreen } from '@capacitor/splash-screen';

import { BrowserService } from '../system/browser.service';

import { PurchaseService } from '../cloud/purchase.service'
import { LocalizationService } from '../system/localization.service'
import { EngineService } from '../system/engine.service'
import { MetaService } from '../cloud/meta.service'
import { VariasService } from '../system/varias.service'
import { SignalService } from '../cloud/signal.service';
import { NotificationsService } from '../system/notifications.service';
import { InstanceService } from '../system/instance.service'

@Component({
  	selector: 'app-fair2play',
  	templateUrl: './fair2play.page.html',
  	styleUrls: ['./fair2play.page.scss'],
})
export class Fair2playPage implements OnInit {

	asModal = true;
	nextEpisodes: Array<any> = [];
	hasNextEpisodes: boolean = false;
	callToAction: string = "Sofort freischalten";
	isSunday: boolean = false;
	nextSunday: string = "---";
	purchaseMode: string = "season";

  	constructor(
		private modalController: ModalController,
		public purchase: PurchaseService,
		private iab: BrowserService,
		public localization: LocalizationService,
		public engine: EngineService,
		private meta: MetaService,
		public varias: VariasService,
		private http: HttpClient,
		private signal: SignalService,
		private notifications: NotificationsService,
		public instance: InstanceService
	) { }

  	ngOnInit() {
		this.findNextEpisode();
		this.checkF2P();
		SplashScreen.hide();
	}

	checkF2P() {
		if (this.signal.connected) {
			this.http.get("https://avoidsociety.app/f2p").subscribe(async response => {
				if (response) {
					let result: any = JSON.parse(JSON.stringify(response));
					if (result.r) {
						this.isSunday = true;
						this.callToAction = "Kostenlos freischalten";
						if (this.localization.lang === "DE") { this.nextSunday = result.de; } 
						else { this.nextSunday = result.en; }						
					} else {
						if (this.localization.lang === "DE") { this.nextSunday = result.de; } 
						else { this.nextSunday = result.en; }
					}
				} else { 
					this.isSunday = false;
				}
			})
		} else { this.signal.alertNoConnection(this.localization.signal_blank); }
	}

	async findNextEpisode() {
		await this.engine.loadStoryboardsRegistry();

		let serials = []; await this.meta.getEpisodes().then(result => serials = result)


		let nextEpisodes = [];

		for (let serial of serials) {
			let serialIsRunning = false;
			let nextEpisodeOfSerial:any = {};
			let i = 0;
			for (let episodeInSerial of serial.ep) {
				for (let episodeInRegistry of this.engine.storyboardsRegistry) {
					if (episodeInRegistry.i === episodeInSerial.i) {
						if (this.instance.instance === "voidapp") {
							serialIsRunning = true;
						} else if (this.instance.instance === "gamerapp") {
							if (serial.i === "ga") { serialIsRunning = true; }
						} else if (this.instance.instance === "horrorapp") {
							if (serial.i === "ma") { serialIsRunning = true; }
						} else if (this.instance.instance === "redfirapp") {
							if (serial.i === "da") { serialIsRunning = true; }
						}
						if (serial.ep[i+1]) {
							nextEpisodeOfSerial = serial.ep[i+1];
						}
					}
				}
				i++;
			}
			if (serialIsRunning && nextEpisodeOfSerial) {
				let unlocked = false; await this.varias.getVariable(nextEpisodeOfSerial.i+"_unl").then(result => unlocked = result);
				if (!unlocked) {
					let storyboardInfo: any = {};
					await this.meta.getStoryboardInfo(nextEpisodeOfSerial.i).then(result => { storyboardInfo = result; })
					if (storyboardInfo.l) {
						let serialName = "";
						if (serial.i === "pa") { serialName = " (David)"; }
						else if (serial.i === "ga") { serialName = " (Gamer)" }
						nextEpisodes.push({...nextEpisodeOfSerial, serialName: serialName});
						this.hasNextEpisodes = true;
					}					
				}
			}
		}

		this.nextEpisodes = nextEpisodes;

		if (this.nextEpisodes.length > 0) { this.notifications.scheduleFair2PlayReminder(true); }
	}

  	close(data: any = {}) { this.modalController.dismiss(data); }

	async requestEpisode(episodeId) {
		this.close();
		this.engine.STB_start(episodeId);
	}

	setReminder() {
		this.notifications.scheduleFair2PlayReminder();
	}

	openBlogpost() {
		if (this.instance.instance === "prllxapp") {
			this.iab.create("https://prllx.app/blog?article=fair2play", '_blank', 'location=yes');
		} else {
			this.iab.create("https://avoidsociety.app/blog?article=fair2play", '_blank', 'location=yes');
		}
	}
	
}