import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController, Platform } from '@ionic/angular';

import { ThemeDetection, ThemeDetectionResponse } from '@awesome-cordova-plugins/theme-detection/ngx';

import { VariasService } from "./varias.service"
import { InstanceService } from './instance.service';

interface Theme {
  	name: string;
  	styles: ThemeStyle[];
}

interface ThemeStyle {
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

	vars: Array<any> = [];
  	private themes: Theme[] = [];
	private instanceThemes: Array<any> = [];
  	private currentTheme: number = 0;

  	constructor(
		private platform: Platform,
		private domCtrl: DomController,
		private varias: VariasService,
		private themeDetection: ThemeDetection,
		private instance: InstanceService,
		@Inject(DOCUMENT) private document
	) {

		this.vars = [];

		this.instanceThemes = [
			{
				instance: "redfirapp",
				styles: [
					{ themeVariable: '--eon-color-positive-1', value: '#668e9a' },
					{ themeVariable: '--eon-color-positive-2', value: '#326978' },
					{ themeVariable: '--eon-color-positive-3', value: '#668e9a' },
					{ themeVariable: '--eon-color-positive-4', value: '#003645' },
					{ themeVariable: '--eon-color-danger-1', value: '#BF8BBA' },
					{ themeVariable: '--eon-color-danger-2', value: '#d2adce' },
					{ themeVariable: '--eon-color-danger-3', value: '#e5d0e3' },
					{ themeVariable: '--eon-color-warning-1', value: '#F9F871' },
					{ themeVariable: '--eon-color-warning-2', value: '#adb480' },
					{ themeVariable: '--eon-color-warning-3', value: '#F6F2CB' },
					{ themeVariable: '--eon-color-neutral-1', value: '#668e9a' },
					{ themeVariable: '--eon-color-neutral-2', value: '#326978' },
					{ themeVariable: '--eon-color-neutral-3', value: '#668e9a' },
					{ themeVariable: '--eon-bubble-bg', value: '#326978'},
					{ themeVariable: '--eon-box-contrast', value: '#e0e8ea'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#668e9a'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#A9C0B8'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#e9d6e6'}
				]
			},{
				instance: "horrorapp",
				styles: [
					{ themeVariable: '--eon-color-positive-1', value: '#6676BA' },
					{ themeVariable: '--eon-color-positive-2', value: '#9DAAF2' },
					{ themeVariable: '--eon-color-positive-3', value: '#EAEEFF' },
					{ themeVariable: '--eon-color-positive-4', value: '#003645' },
					{ themeVariable: '--eon-color-danger-1', value: '#FF6A3D' },
					{ themeVariable: '--eon-color-danger-2', value: '#FF9EA2' },
					{ themeVariable: '--eon-color-danger-3', value: '#FFBDD0' },
					{ themeVariable: '--eon-color-warning-1', value: '#F4DB7D' },
					{ themeVariable: '--eon-color-warning-2', value: '#BAA449' },
					{ themeVariable: '--eon-color-warning-3', value: '#FEF0CA' },
					{ themeVariable: '--eon-color-neutral-1', value: '#668e9a' },
					{ themeVariable: '--eon-color-neutral-2', value: '#326978' },
					{ themeVariable: '--eon-color-neutral-3', value: '#668e9a' },

					{ themeVariable: '--eon-bubble-bg', value: '#6676BA'},
					{ themeVariable: '--eon-box-contrast', value: '#e0e8ea'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#EAEEFF'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#9DAAF2'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#9DAAF2'}					
				]				
			},{
				instance: "gamerapp",
				styles: [
					/*
					{ themeVariable: '--eon-color-positive-1', value: '#688274' },
					{ themeVariable: '--eon-color-positive-2', value: '#9BB7A8' },
					{ themeVariable: '--eon-color-positive-3', value: '#E7F1E1' },
					*/
					{ themeVariable: '--eon-color-positive-1', value: '#315561' },
					{ themeVariable: '--eon-color-positive-2', value: '#96AFB9' },
					{ themeVariable: '--eon-color-positive-3', value: '#E7F1E1' },					
					{ themeVariable: '--eon-color-positive-4', value: '#1D333A' },
					{ themeVariable: '--eon-color-danger-1', value: '#C4462B' },
					{ themeVariable: '--eon-color-danger-2', value: '#603540' },
					{ themeVariable: '--eon-color-danger-3', value: '#D69482' },
					{ themeVariable: '--eon-color-warning-1', value: '#EBE4A5' },
					{ themeVariable: '--eon-color-warning-2', value: '#B2AD71' },
					{ themeVariable: '--eon-color-warning-3', value: '#F7F2CB' },
					{ themeVariable: '--eon-color-neutral-1', value: '#19424E' },
					{ themeVariable: '--eon-color-neutral-2', value: '#315561' },
					{ themeVariable: '--eon-color-neutral-3', value: '#96AFB9' },

					{ themeVariable: '--eon-bubble-bg', value: '#315561'},
					{ themeVariable: '--eon-box-contrast', value: '#e0e8ea'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#96AFB9'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#9BB7A8'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#9BB7A8'}					
				]				
			},{
				instance: "prllxapp?",
				styles: [
					{ themeVariable: '--eon-color-positive-1', value: '#2DA172' },
					{ themeVariable: '--eon-color-positive-2', value: '#96AFB9' },
					{ themeVariable: '--eon-color-positive-3', value: '#E7F1E1' },					
					{ themeVariable: '--eon-color-positive-4', value: '#1D333A' },
					{ themeVariable: '--eon-color-danger-1', value: '#C4462B' },
					{ themeVariable: '--eon-color-danger-2', value: '#603540' },
					{ themeVariable: '--eon-color-danger-3', value: '#D69482' },
					{ themeVariable: '--eon-color-warning-1', value: '#ECE684' },
					{ themeVariable: '--eon-color-warning-2', value: '#B2AD71' },
					{ themeVariable: '--eon-color-warning-3', value: '#F7F2CB' },
					{ themeVariable: '--eon-color-neutral-1', value: '#19424E' },
					{ themeVariable: '--eon-color-neutral-2', value: '#315561' },
					{ themeVariable: '--eon-color-neutral-3', value: '#96AFB9' },

					{ themeVariable: '--eon-bubble-bg', value: '#2F4858'},
					{ themeVariable: '--eon-box-contrast', value: '#e0e8ea'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#6490ac'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#9BB7A8'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#9BB7A8'}					
				]
			}
		]

		this.themes = [
			{
				name: 'day',
				styles: [

					{ themeVariable: '--eon-bg', value: '#f7f6f6'},
					{ themeVariable: '--eon-bg-morish', value: '#fbfafa'},
					{ themeVariable: '--eon-bg-more', value: '#FFFFFF' },
					{ themeVariable: '--eon-bg-less', value: '#FFFFFF' },

					{ themeVariable: '--eon-background-active', value: 'rgba(255,255,255,1)'},
					{ themeVariable: '--eon-card-bg', value: 'white'},
					{ themeVariable: '--eon-card-shadow', value: '2px 2px 3px rgba(0,0,0,.05)'},
					{ themeVariable: '--eon-card-shadow-less', value: '1px 1px 2px rgba(0,0,0,.07)'},
					{ themeVariable: '--eon-bubble-shadow', value: '2px 2px 3px rgba(0,0,0,.03)'},
					{ themeVariable: '--eon-bubble-shadow-right', value: '2px 2px 3px rgba(0,0,0,.15)'},
					{ themeVariable: '--eon-button-bg', value: 'rgba(255, 255, 255, .3)'},
					{ themeVariable: '--eon-button-shadow-inset-bottom', value: 'white'},
					{ themeVariable: '--eon-searchbar-shadow-inset-top', value: 'rgba(0,0,0,.07)'},
					{ themeVariable: '--eon-searchbar-shadow-inset-bottom', value: 'rgba(255,255,255,.5)'},
					{ themeVariable: '--eon-lasercut', value: 'rgba(0,0,0,.05)'},
					{ themeVariable: '--eon-filtercontact-shadow', value: '1px 1px 2px rgba(0,0,0,.07), -2px -2px 3px rgba(255,255,255,.8)'},
					{ themeVariable: '--eon-inset-bottom', value: '#f6f5f5'},
					{ themeVariable: '--eon-image-invert', value: 'invert(100%)'},
					{ themeVariable: '--eon-sendbutton-shadow-low', value: '#dedddd'},
					{ themeVariable: '--eon-sendbutton-shadow-top', value: 'white'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#73998D'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#A9C0B8'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#FAB09A'},
					{ themeVariable: '--eon-shadow-03-low', value: '#f0efef'},
					{ themeVariable: '--eon-shadow-03-top', value: '#fefdfd'},
					{ themeVariable: '--eon-shadow-04-low', value: '#eaecec'},
					{ themeVariable: '--eon-shadow-04-top', value: 'white'},
					{ themeVariable: '--eon-shadow-07-low', value: '#e6e5e5'},					
					{ themeVariable: '--eon-shadow-07-top', value: 'white'},
					{ themeVariable: '--eon-shadow-10-low', value: '#dedddd'},					
					{ themeVariable: '--eon-shadow-10-top', value: 'white'},					
					{ themeVariable: '--eon-shadow-14-low', value: '#d4d4d4'},					
					{ themeVariable: '--eon-shadow-14-top', value: 'white'},	
					{ themeVariable: '--eon-shadow-inset-glance', value: '#fbfdfd'},
					{ themeVariable: '--eon-boxbutton-shadow', value: 'inset 3px 3px 3px #fbfdfd'},
					{ themeVariable: '--eon-shadow-footer-barrier-inner-background', value: 'linear-gradient(to bottom, rgba(247, 246, 246, 0), rgba(247, 246, 246, 1), rgba(247, 246, 246, 1))'},
					{ themeVariable: '--eon-bg-20', value: 'rgba(247, 246, 246, .2)'},
					{ themeVariable: '--eon-bg-50', value: 'rgba(247, 246, 246, .5)'},
					{ themeVariable: '--eon-bg-50-invert', value: 'rgba($color: #101010, $alpha: .2)'},
					{ themeVariable: '--eon-bg-60', value: 'rgba(247, 246, 246, .6)'},
					{ themeVariable: '--eon-bg-more-60', value: 'rgba(247, 246, 246, .6)'},
					{ themeVariable: '--eon-bg-70', value: 'rgba(0,0,0,.7)'},
					{ themeVariable: '--eon-contrast-50', value: 'rgba(0,0,0,.5)'},
					{ themeVariable: '--eon-contrast-100', value: 'rgba(0,0,0,1)'},
					{ themeVariable: '--eon-contrast-text-inactive', value: 'rgba(0,0,0,.2)'},
					{ themeVariable: '--eon-contrast-text-high', value: 'rgba(0,0,0,1)'},
					{ themeVariable: '--eon-contrast-text', value: 'rgba(0,0,0,.7)'},
					{ themeVariable: '--eon-contrast-text-color', value: 'rgba(255,255,255,.7)'},
					{ themeVariable: '--eon-contrast-text-less', value: 'rgba(0,0,0,.5)'},
					{ themeVariable: '--eon-contrast-text-least', value: 'rgba(0,0,0,.3)'},
					{ themeVariable: '--eon-neuo-footer-outer-background', value: 'rgba(247, 246, 246, 0.7)'},

					{ themeVariable: '--eon-neuo-dots', value: "url('../../assets/img/misc/dots2.png')" },
					{ themeVariable: '--eon-maincard-shadow', value: '0 0 10px rgba(0, 0, 0, 0.5)' },

					{ themeVariable: '--eon-menu-background', value: 'rgba(0,0,0,.7)' },
					{ themeVariable: '--eon-molert-backdrop', value: 'rgba(255,255,255,.4)' },
					{ themeVariable: '--eon-molert-backdrop-more', value: 'rgba(255,255,255,.8)' },
					{ themeVariable: '--eon-menu-color', value: 'rgba(255,255,255,.8)' },

					{ themeVariable: '--eon-separation-line', value: 'rgba(0,0,0,.05)' },
					{ themeVariable: '--eon-item-active', value: 'rgba(0,0,0,.05)' },
					{ themeVariable: '--eon-invert-logo', value: 'invert(100%)' },
					{ themeVariable: '--eon-segment-bg', value: 'rgba(120,120,120,0.065)' },
					{ themeVariable: '--eon-segment-purchase', value: 'rgb(249,248,113,.5)' },
					{ themeVariable: '--eon-segment-bg-inverse', value: 'rgba(255,255,255,.065)' },

					{ themeVariable: '--eon-bubble-bg', value: '#518070'},
					{ themeVariable: '--eon-box-contrast', value: '#C9FCE9'},
					{ themeVariable: '--eon-color-positive-1', value: '#518070' },
					{ themeVariable: '--eon-color-positive-2', value: '#84B5A4' },
					{ themeVariable: '--eon-color-positive-3', value: '#C9FCE9' },
					{ themeVariable: '--eon-color-positive-4', value: '#38594E' },
					{ themeVariable: '--eon-color-danger-1', value: '#AD644D' },
					{ themeVariable: '--eon-color-danger-2', value: '#E7987E' },
					{ themeVariable: '--eon-color-danger-3', value: '#FFE6D7' },
					{ themeVariable: '--eon-color-warning-1', value: '#F9F871' },
					{ themeVariable: '--eon-color-warning-2', value: '#C0BC84' },
					{ themeVariable: '--eon-color-warning-3', value: '#F6F2CB' },
					{ themeVariable: '--eon-color-neutral-1', value: '#597A98' },
					{ themeVariable: '--eon-color-neutral-2', value: '#8EAECE' },
					{ themeVariable: '--eon-color-neutral-3', value: '#ECFBFF' },

					{ themeVariable: '--eon-color-gradient-1', value: '#c0bc84' },
					{ themeVariable: '--eon-color-gradient-2', value: '#adb480' },
					{ themeVariable: '--eon-color-gradient-3', value: '#9bac7c' },
					{ themeVariable: '--eon-color-gradient-4', value: '#8aa47a' },
					{ themeVariable: '--eon-color-gradient-5', value: '#799b77' },
					{ themeVariable: '--eon-color-gradient-6', value: '#6b9375' },
					{ themeVariable: '--eon-color-gradient-7', value: '#5d8973' },
					{ themeVariable: '--eon-color-gradient-8', value: '#518070' },
					{ themeVariable: '--eon-color-gradient-9', value: '#43756F' },
					{ themeVariable: '--eon-color-gradient-10', value: '#386A6C' },
					{ themeVariable: '--eon-color-gradient-11', value: '#325F68' },
					{ themeVariable: '--eon-color-gradient-12', value: '#305361' },
					{ themeVariable: '--eon-color-gradient-13', value: '#26424D' }			
				]
			},{
				name: 'green',
				styles: [
					{ themeVariable: '--eon-bg', value: '#101010'},
					{ themeVariable: '--eon-bg-morish', value: '#0C0C0C'},
					{ themeVariable: '--eon-bg-more', value: '#090909' },
					{ themeVariable: '--eon-bg-less', value: '#272727' },

					{ themeVariable: '--eon-background-active', value: 'rgba(0,0,0,.3)'},
					{ themeVariable: '--eon-card-bg', value: '#1d2326'},
					{ themeVariable: '--eon-card-shadow', value: '2px 2px 3px rgba(0,0,0,.5)'},
					{ themeVariable: '--eon-card-shadow-less', value: '1px 1px 2px rgba(0,0,0,.7)'},
					{ themeVariable: '--eon-bubble-shadow', value: '2px 2px 3px rgba(0,0,0,1)'},
					{ themeVariable: '--eon-bubble-shadow-right', value: '2px 2px 3px rgba(0,0,0,1)'},
					{ themeVariable: '--eon-button-bg', value: 'rgba(0, 0, 0, .05)'},
					{ themeVariable: '--eon-button-shadow-inset-bottom', value: '#262a2c'}, //
					{ themeVariable: '--eon-searchbar-shadow-inset-top', value: 'rgba(0,0,0,.15)'},
					{ themeVariable: '--eon-searchbar-shadow-inset-bottom', value: 'rgba(255,255,255,.05)'},
					{ themeVariable: '--eon-lasercut', value: 'rgba(0,0,0,.2)'},
					{ themeVariable: '--eon-filtercontact-shadow', value: '1px 1px 2px rgba(0,0,0,.3), -2px -2px 3px rgba(255,255,255,.07)'},
					{ themeVariable: '--eon-inset-bottom', value: 'rgba(255,255,255,.05'},
					{ themeVariable: '--eon-image-invert', value: 'none'},
					{ themeVariable: '--eon-sendbutton-shadow-low', value: 'rgba(0,0,0,1)'},
					{ themeVariable: '--eon-sendbutton-shadow-top', value: 'rgba(255,255,255,.03)'},
					{ themeVariable: '--eon-sendbutton-glance-positive', value: '#73998D'},
					{ themeVariable: '--eon-sendbutton-glance-warning', value: '#A9C0B8'},
					{ themeVariable: '--eon-sendbutton-glance-danger', value: '#FAB09A'},
					{ themeVariable: '--eon-shadow-03-low', value: 'rgba(0,0,0,1)'},
					{ themeVariable: '--eon-shadow-03-top', value: 'rgba(255,255,255,.1)'},
					{ themeVariable: '--eon-shadow-04-low', value: 'rgba(0,0,0,1)'},
					{ themeVariable: '--eon-shadow-04-top', value: 'rgba(255,255,255,.1)'},
					{ themeVariable: '--eon-shadow-07-low', value: 'rgba(0,0,0,1)'},					
					{ themeVariable: '--eon-shadow-07-top', value: 'rgba(255,255,255,.03)'},
					{ themeVariable: '--eon-shadow-10-low', value: 'rgba(0,0,0,1)'},					
					{ themeVariable: '--eon-shadow-10-top', value: 'rgba(255,255,255,.1)'},
					{ themeVariable: '--eon-shadow-14-low', value: 'rgba(0,0,0,1)'},					
					{ themeVariable: '--eon-shadow-14-top', value: 'rgba(255,255,255,.1)'},
					{ themeVariable: '--eon-shadow-inset-glance', value: 'rgba(255,255,255,.02)'},
					{ themeVariable: '--eon-boxbutton-shadow', value: 'inset 3px 3px 3px rgba(255,255,255,.02), inset -2px -2px 2px rgba(0,0,0,.2)'},
					{ themeVariable: '--eon-shadow-footer-barrier-inner-background', value: 'linear-gradient(to bottom, rgba(33, 39, 43, 0), rgba(33, 39, 43, 1), rgba(33, 39, 43, 1))'},
					{ themeVariable: '--eon-bg-20', value: 'rgba($color: #101010, $alpha: .2)'},
					{ themeVariable: '--eon-bg-50', value: 'rgba($color: #101010, $alpha: .2)'},
					{ themeVariable: '--eon-bg-50-invert', value: 'rgba(247, 246, 246, .5)'},
					{ themeVariable: '--eon-bg-60', value: 'rgba($color: #101010, $alpha: .6)'},
					{ themeVariable: '--eon-bg-more-60', value: 'rgba($color: #090909, $alpha: .8)'},
					{ themeVariable: '--eon-bg-70', value: 'rgba($color: #101010, $alpha: .7)'},
					{ themeVariable: '--eon-contrast-50', value: 'rgba(0,0,0,.5)'},
					{ themeVariable: '--eon-contrast-100', value: 'rgba(255,255,255,1)'},
					{ themeVariable: '--eon-contrast-text-inactive', value: 'rgba(255,255,255,.2)'},
					{ themeVariable: '--eon-contrast-text-high', value: 'rgba(255,255,255,1)'},
					{ themeVariable: '--eon-contrast-text', value: 'rgba(255,255,255,.7)'},
					{ themeVariable: '--eon-contrast-text-color', value: 'rgba(255,255,255,.7)'},
					{ themeVariable: '--eon-contrast-text-less', value: 'rgba(255,255,255,.5)'},
					{ themeVariable: '--eon-contrast-text-least', value: 'rgba(255,255,255,.3)'},
					{ themeVariable: '--eon-neuo-footer-outer-background', value: 'rgba(33, 39, 43, 0.7)'},
		
					{ themeVariable: '--eon-neuo-dots', value: "url('../../assets/img/misc/dots_dark.png')" },
					{ themeVariable: '--eon-maincard-shadow', value: '0 0 10px rgba(0, 0, 0, 0.9)'},

					{ themeVariable: '--eon-menu-background', value: 'rgba(255,255,255,.7)' },
					{ themeVariable: '--eon-molert-backdrop', value: 'rgba(0,0,0,.4)' },
					{ themeVariable: '--eon-molert-backdrop-more', value: 'rgba(0,0,0,.8)' },
					{ themeVariable: '--eon-menu-color', value: 'rgba(0,0,0,.9)' },

					{ themeVariable: '--eon-separation-line', value: 'rgba(255,255,255,.05)' },
					{ themeVariable: '--eon-item-active', value: 'rgba(255,255,255,.05)' },
					{ themeVariable: '--eon-invert-logo', value: 'none' },
					{ themeVariable: '--eon-segment-bg', value: 'rgba(255,255,255,.065)' },
					{ themeVariable: '--eon-segment-purchase', value: 'rgb(249,248,113,.5)' },
					{ themeVariable: '--eon-segment-bg-inverse', value: 'rgba(120,120,120,0.065)' },

					{ themeVariable: '--eon-bubble-bg', value: '#518070'},
					{ themeVariable: '--eon-box-contrast', value: '#C9FCE9'},
					{ themeVariable: '--eon-color-positive-1', value: '#518070' },
					{ themeVariable: '--eon-color-positive-2', value: '#84B5A4' },
					{ themeVariable: '--eon-color-positive-3', value: '#C9FCE9' },
					{ themeVariable: '--eon-color-positive-4', value: '#38594E' },
					{ themeVariable: '--eon-color-danger-1', value: '#AD644D' },
					{ themeVariable: '--eon-color-danger-2', value: '#E7987E' },
					{ themeVariable: '--eon-color-danger-3', value: '#FFE6D7' },
					{ themeVariable: '--eon-color-warning-1', value: '#F9F871' },
					{ themeVariable: '--eon-color-warning-2', value: '#C0BC84' },
					{ themeVariable: '--eon-color-warning-3', value: '#F6F2CB' },
					{ themeVariable: '--eon-color-neutral-1', value: '#597A98' },
					{ themeVariable: '--eon-color-neutral-2', value: '#8EAECE' },
					{ themeVariable: '--eon-color-neutral-3', value: '#ECFBFF' },

					{ themeVariable: '--eon-color-gradient-1', value: '#c0bc84' },
					{ themeVariable: '--eon-color-gradient-2', value: '#adb480' },
					{ themeVariable: '--eon-color-gradient-3', value: '#9bac7c' },
					{ themeVariable: '--eon-color-gradient-4', value: '#8aa47a' },
					{ themeVariable: '--eon-color-gradient-5', value: '#799b77' },
					{ themeVariable: '--eon-color-gradient-6', value: '#6b9375' },
					{ themeVariable: '--eon-color-gradient-7', value: '#5d8973' },
					{ themeVariable: '--eon-color-gradient-8', value: '#518070' },
					{ themeVariable: '--eon-color-gradient-9', value: '#43756F' },
					{ themeVariable: '--eon-color-gradient-10', value: '#386A6C' },
					{ themeVariable: '--eon-color-gradient-11', value: '#325F68' },
					{ themeVariable: '--eon-color-gradient-12', value: '#305361' },
					{ themeVariable: '--eon-color-gradient-13', value: '#26424D' }
				]
			}
		]

		var that = this;
		this.varias.getVariable("stt_darkmode").then(darkmode => {
			if (darkmode === "always") { this.setTheme("green"); }
			else if (darkmode === "range") { this.setThemeRange(); }
			else if (darkmode === "dependent") { this.setThemeDependent(); }
			else if (darkmode === "never") { this.setTheme("day"); }
			else { 
				this.setTheme("green");
			}
		})
		
		if (document.body) {
			document.body.classList.add("eon-no-move");
		}
	}


	isScrolling = false;
	isTouching = false;
	preventTouchAfterScroll = false;
	funnel_isScrolling: any;
	funnel_touchstart: any;
	globalTouchStart() {
		this.isTouching = true;
		if (!this.isScrolling) {
			this.funnel_touchstart = setTimeout(() => {
				if (document.body) {
					document.body.classList.add("eon-no-move");
					document.body.classList.remove("eon-move");
				}
			}, 300)
		}
	}
	globalScroll() {
		clearTimeout(this.funnel_touchstart);
		this.isScrolling = true;
		clearTimeout(this.funnel_isScrolling);
		this.funnel_isScrolling = setTimeout(() => {
			this.isScrolling = false;
			if (this.isTouching) {
				this.preventTouchAfterScroll = true;
			} else {
				if (document.body) {
					document.body.classList.add("eon-no-move");
					document.body.classList.remove("eon-move");
				}
			}
		}, 66);
		if (document.body) {
			document.body.classList.remove("eon-no-move");
			document.body.classList.add("eon-move");
		}
	}
	globalTouchEnd() {
		this.isTouching = false;
		if (this.preventTouchAfterScroll) {
			if (document.body) {
				document.body.classList.add("eon-no-move");
				document.body.classList.remove("eon-move");
			}
		}
	}

	lightenDarkenColor(col,amt) {
		var usePound = false;
		if ( col[0] == "#" ) {
			col = col.slice(1);
			usePound = true;
		}

		var num = parseInt(col,16);

		var r = (num >> 16) + amt;

		if ( r > 255 ) r = 255;
		else if  (r < 0) r = 0;

		var b = ((num >> 8) & 0x00FF) + amt;

		if ( b > 255 ) b = 255;
		else if  (b < 0) b = 0;

		var g = (num & 0x0000FF) + amt;

		if ( g > 255 ) g = 255;
		else if  ( g < 0 ) g = 0;

		return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
	}

	cycleTheme(): void {
    	if(this.themes.length > this.currentTheme + 1){
      		this.currentTheme++;
    	} else {
      		this.currentTheme = 0;
    	}
		this.setTheme(this.themes[this.currentTheme].name);
  	}

  	setTheme(name): void {
    	let theme = this.themes.find(theme => theme.name === name);
    	this.domCtrl.write(() => {
      		theme.styles.forEach(style => {
				document.documentElement.style.setProperty(style.themeVariable, style.value);
				this.vars[style.themeVariable] = style.value;
      		});
		});
		let instanceTheme = this.instanceThemes.find(theme => theme.instance === this.instance.instance);
		if (instanceTheme) {
			this.domCtrl.write(() => {
				instanceTheme.styles.forEach(style => {
					document.documentElement.style.setProperty(style.themeVariable, style.value);
					this.vars[style.themeVariable] = style.value;
				})
			})
		}
		this.varias.setVariable("stt_theme", name);
		clearTimeout(this.timeout_switchMode);
	}
	  
	setThemeDependent() {
		this.themeDetection.isAvailable()
		.then((res: ThemeDetectionResponse) => {
		   	if(res.value) {
			 	this.themeDetection.isDarkModeEnabled().then((res: ThemeDetectionResponse) => {
					if (res.value) { this.setTheme("green"); } 
					else { this.setTheme("day"); }
			 	}).catch((error: any) => console.error(error));
		   	}
		}).catch((error: any) => console.error(error));
		clearTimeout(this.timeout_switchMode);
	}

	timeout_switchMode;
	setThemeRange() {
		let now = new Date;
		let hours = now.getHours();
		let minutes = now.getMinutes();
		if (hours >= 6 && hours < 22) { 
			this.setTheme("day"); 
			let switchInMinutes = 60-minutes;
			let switchInHours = 22 - hours - 1;
			let switchInTimeout = ((switchInHours*60)+switchInMinutes)*60*1000;
			clearTimeout(this.timeout_switchMode);
			this.timeout_switchMode = setTimeout(() => {
				this.setTheme("green");
			}, switchInTimeout);
		} else { 
			this.setTheme("green");
			if (hours >= 22) {
				let switchInHours = 6; if (hours === 22) { switchInHours = 7; }
				let switchInMinutes = 60-minutes;
				let switchInTimeout = ((switchInHours*60)+switchInMinutes)*60*1000;
				clearTimeout(this.timeout_switchMode);
				this.timeout_switchMode = setTimeout(() => {
					this.setTheme("day");
				}, switchInTimeout);
			} else {
				let switchInHours = 5 - hours;
				let switchInMinutes = 60-minutes;
				let switchInTimeout = ((switchInHours*60)+switchInMinutes)*60*1000;
				clearTimeout(this.timeout_switchMode);
				this.timeout_switchMode = setTimeout(() => {
					this.setTheme("day");
				}, switchInTimeout);
			}
		}
	}

	startWatchingRange() {
		var that = this;
		document.addEventListener("resume", function() { that.watchRange(); }, false);
	}
	watchRange() {
		setTimeout(() => {
			this.varias.getVariable("stt_darkmode").then(darkmode => {
				if (darkmode === "range") { this.setThemeRange(); }
			});
		}, 10000);
	}

	async toggleTheme() {
		let currentTheme = ""; await this.varias.getVariable("stt_theme").then( result => currentTheme = result );
		let currentDarkmode = ""; await this.varias.getVariable("stt_darkmode").then( result => currentDarkmode = result );

		if (currentTheme === "day") {
			if (currentDarkmode === "never" || currentDarkmode === null) { this.varias.setVariable("stt_darkmode", "always"); }
			this.setTheme("green");
		} else if (currentTheme === "green") {
			if (currentDarkmode === "always" || currentDarkmode === null) { this.varias.setVariable("stt_darkmode", "never"); }
			this.setTheme("day");
		}
	}

}