import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { ImageComponent } from './image.component'

@NgModule({
    declarations: [ImageComponent],
    exports: [ImageComponent ],
    imports: [CommonModule, IonicModule]
})
export class ImageModule{}