import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, Platform } from '@ionic/angular';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { LocalizationService } from './localization.service';
import { DeviceService } from '../system/device.service';
import { InstanceService } from './instance.service';

@Injectable({
  	providedIn: 'root'
})
export class ShareService {

	constructor(
		private socialSharing: SocialSharing,
		private loadingController: LoadingController,
		private localization: LocalizationService,
		private actionSheetController: ActionSheetController,
		private alertController: AlertController,
		private device: DeviceService,
		private platform: Platform,
		private instance: InstanceService
	) {}
	  
	async prompt() {
		const actionSheet = await this.alertController.create({
			subHeader: this.localization.share_select,
			cssClass: "eon__alert",
			buttons: [
				{
					text: '"'+this.localization.share_message1+'"',
					handler: () => { this.shareMessage(this.localization.share_message1); }
				},{
					text: '"'+this.localization.share_message2+'"',
					handler: () => { this.shareMessage(this.localization.share_message2); }
				},{
					text: '"'+this.localization.share_message3+'"',
					handler: () => { this.shareMessage(this.localization.share_message3); }
				},{
					text: this.localization.share_message_own,
					handler: () => { this.shareMessage(""); }
				},{					
					text: this.localization.cancel,
					role: "cancel",
					handler: () => {}
				}
			]
		})
		await actionSheet.present();
	}

	async shareMessage(message, url = 'https://www.avoidsociety.app', subject = 'A Void Society - Interactive Fiction App') {

		if (this.instance.instance === "prllxapp") {
			url = 'https://www.prllx.app';
			subject = 'PARALLAX - Interactive Fiction App';
		}

		if (this.platform.is("cordova")) {
			if (this.device.platform === "ios" || this.device.platform === "android") {
				const loading = await this.loadingController.create({
					spinner: "bubbles",
					message: this.localization.oneMomentPlease,
					translucent: true,
					duration: 5000,
					showBackdrop: true
				});
				await loading.present();
		
				var options = {
					message: message,
					subject: subject,
					files: ['https://avoidsociety.app/icon/'+this.instance.instance+'.png'],
					url: url
				};
				await this.socialSharing.shareWithOptions(options).then((result) => {
					console.log("SHARNOF", JSON.stringify(result));
					loading.dismiss();
				})			
			}
		} else {
			const shareData = {
				title: subject,
				text: message,
				url: url
			}
			navigator.share(shareData).catch((er) => {});
		}

	}

}
