import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { LocalizationService } from 'src/app/system/localization.service';

@Component({
  	selector: 'app-music',
  	templateUrl: './music.page.html',
  	styleUrls: ['./music.page.scss'],
})
export class MusicPage implements OnInit {
	@Input("permitFunction") permitFunction: any = null;
	@Input("dismissFunction") dismissFunction: any = null;

  	constructor(
		public localization: LocalizationService,
		private modalController: ModalController
	) { }

  	ngOnInit() {}

	permit() {
		this.permitFunction();
		this.modalController.dismiss();	
	}

}