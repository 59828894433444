import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { DeviceService } from '../system/device.service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

	funnel_message_received = [];
	messages_skipped = [];

	constructor(
		private angularFireAnalytics: AngularFireAnalytics,
		private platform: Platform,
		private http: HttpClient,
		private fireStore: AngularFirestore,
		private device: DeviceService
	) {
		FirebaseAnalytics.setCollectionEnabled({ enabled: true });
	}

	setUserProperty(name: string, value: string) {
		if (this.device.platform === "ios" || this.device.platform === "android") {
			FirebaseAnalytics.setUserProperty({ name: name, value: value });
		} else {
			this.angularFireAnalytics.setUserProperties({ [name]: value });
		}
	}
	  
	logEvent(event, params) {
		var that = this;

		if (event == "message_received") {
			let message = params.message;
			clearTimeout(this.funnel_message_received[message.i]);
			this.funnel_message_received[message.i] = setTimeout(function() {
				let skipped = true;
				if (message.p > 0) {
					if (!that.messages_skipped[message.i]) {
						that.executeLogEvent("pause_awaited", { messageId: message.i, pause: message.p });
						skipped = false;
					}
				} else { skipped = false; }
				that.executeLogEvent("message_received", { messageId: message.i, pause: message.p, skipped: skipped })
			}, 1000);
		} else if (event == "pause_skipped") {
			this.messages_skipped[params.messageId] = true;
			this.executeLogEvent(event, params);
		} else {
			this.executeLogEvent(event, params);
		}
	}

	cugc_upvote(contentId, uid) { this.http.get('https://avoidsociety.app/cugc_upvote?id='+contentId+'&uid='+uid).subscribe(async (response: any) => {}) };
	cugc_downvote(contentId, uid) { this.http.get('https://avoidsociety.app/cugc_downvote?id='+contentId+'&uid='+uid).subscribe(async (response: any) => {}) };
	cugc_start(contentId) { this.http.get('https://avoidsociety.app/cugc_start?id='+contentId).subscribe(async (response: any) => {} )};
	cugc_complete(contentId) { this.http.get('https://avoidsociety.app/cugc_complete?id='+contentId).subscribe(async (response: any) => {} )};
	cugc_skip(contentId) { this.http.get('https://avoidsociety.app/cugc_skip?id='+contentId).subscribe(async (response: any) => {} )};

	async executeLogEvent(event, params) {
		await this.device.getPlatform();
		if (this.device.platform === "ios" || this.device.platform === "android") {
			FirebaseAnalytics.logEvent({
				name: event,
				params: params
			})
		} else {
			this.angularFireAnalytics.logEvent(event, params);
		}
	}
}
