import { AlertController, ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { MetaService } from '../cloud/meta.service';
import { LocalizationService } from './localization.service';

import { EpisodePage } from '../episode/episode.page'
import { MembershipPage } from '../membership/membership.page'
import { Fair2playPage } from '../fair2play/fair2play.page';
import { SeasonpassPage } from '../rfh/seasonpass/seasonpass.page';

import { InstanceService } from './instance.service';
import { Events } from './events.service';
import { VariasService } from './varias.service';

@Injectable({
  	providedIn: 'root'
})
export class ModalsService {

  	constructor(
		private localization: LocalizationService,
		private meta: MetaService,
		private alertController: AlertController,
		private modalController: ModalController,
		private instance: InstanceService,
		private events: Events,
		private varias: VariasService
	) {
		this.events.subscribe("presentMembership", () => { this.presentMembership(); })
		this.events.subscribe("presentFair2play", () => { this.presentFair2play(); })
	}

	pushEpisode(serialId, season, episode, constellation) {
		this.presentSeason(serialId, season, episode);
	}

	async pushEpisodeById(episodeId, constellation) {
		this.meta.getEpisodes().then(async serials => {
			let found = false;
			for (let serial of serials) { for (let episode of serial.ep) { 
				if (episode.i === episodeId) {
					found = true;
					this.presentSeason(serial.i, episode.s, episode.e);
				}
			} }
			if (!found) {
				const alert = await this.alertController.create({
					subHeader: this.localization.notFound,
					cssClass: 'eon__alert',
					message: this.localization.episodeNotFound,
					buttons: ['OK']
				});
				await alert.present();
			}
		})
	}
	
	async presentSeason(serialId, seasonNumber, episodeNumber) {
		let modal = await this.modalController.create({
			component: EpisodePage,
			componentProps: {
				mode: "episode", 
				constellation: "season", 
				serialId: serialId, 
				seasonNr: seasonNumber, 
				episodeNr: episodeNumber
			}
		}).then(async (modal) => {
			modal.onDidDismiss().then(async result => {
				if (!result.data.pushChat && !result.data.episodeStarted && !result.data.echoStarted && !result.data.pushHistory && !result.data.adRequested) {
					
					if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
						if (serialId === "pa" || serialId === "ga" || serialId === "ha") {
							if (seasonNumber === 1) {
								if (episodeNumber === 2) {
									if (this.instance.instance === "voidapp") {
										this.events.publish("offerSpecial", { specialId: "special4" });
									} else if (this.instance.instance === "prllxapp") {
										this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special6" });	
									}
								} else if (episodeNumber === 3 || episodeNumber === 4) {
									this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special6" });
								}
							}
						}
					} else if (this.instance.instance === "gamerapp") {
						if (serialId === "ga" && seasonNumber === 1 && (episodeNumber === 2 || episodeNumber === 3)) {
							this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special6" });
						}
					} else if (this.instance.instance === "horrorapp") {
						if (serialId === "ha" && seasonNumber === 1 && (episodeNumber === 2 || episodeNumber === 3)) {
							this.events.publish("offerSpecial", { specialId: this.instance.iapPrefix+"special6" });
						}
					}

				}
			})
			setTimeout(async () => { await modal.present(); }, 100)
		})
	}

	pushSeason(serialId, season, episode) { this.pushEpisode(serialId, season, episode, "season"); }

	pushEcho(echo) { this.presentEcho(echo.i, "single"); }
	pushEchoById(echoId) { this.presentEcho(echoId, "single"); }
	pushSetByEchoId(echoId, setId = "", showProduct = false, membershipPack: any = null) { this.presentEcho(echoId, "set", setId, showProduct, membershipPack); }
	async pushEchoFromChat(echo) { this.presentEcho(echo.i, "single"); }	
	async pushSet(setId, echoId = undefined) {
		if (echoId) {
			this.presentEcho(echoId, "set");
		} else {
			let sets = []; await this.meta.getSets().then(allSets => { sets = allSets; })
			for (let set of sets) {
				if (set.i === setId) {
					if (set.e.length > 0) {
						this.presentEcho(set.e[0].i, "set");
					}
				}
			}
		}
	}	
	async presentEcho(echoId, constellation, setId = "", showProduct = false, membershipPack: any = null) {	
		let modal = await this.modalController.create({
			component: EpisodePage,
			componentProps: {
				mode: "echo-unsubmitted", 
				constellation: constellation, // single | set
				echoId: echoId,
				setId: setId,
				showProduct: showProduct,
				membershipPack: membershipPack
			}
		}).then(async (modal) => {
			setTimeout(async () => { await modal.present(); }, 100);
		})
	}

	async presentMembership(reduced: boolean = false) {
		if (this.instance.instance === "voidapp") {
			let modal = await this.modalController.create({
				component: MembershipPage,
				componentProps: { "asModal": true, reduced: reduced }
			})
			await modal.present();
		}
	}

	async presentFair2play() {
		let modal = await this.modalController.create({
			component: Fair2playPage,
			componentProps: { "asModal": true }
		})
		await modal.present();
	}

	async presentSeasonPass() {
		let modal = await this.modalController.create({
			component: SeasonpassPage,
			componentProps: { "asModal": true }
		})
		await modal.present();
	}

}