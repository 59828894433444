import { Injectable } from '@angular/core';
import { VariasService } from './varias.service';

@Injectable({
  	providedIn: 'root'
})
export class AssetsService {

	imagery: boolean = false;
	imageryChecked: boolean = false;

	radio: boolean = false;

  	constructor(
		private varias: VariasService
	) {}

	funnel_unlockAssets: any = null;
	async unlockAssets() {
		this.imagery = true;
		this.radio = true;

		clearTimeout(this.funnel_unlockAssets);
		this.funnel_unlockAssets = setTimeout(async () => {
			this.varias.setVariable("sys_asstUnl", true);
		}, 1000);
	}

	async requestRadio(message, storyboardId) {
		if (this.radio) { return true; }

		if (message.asstFree) { return true; }

		if (await this.varias.getVariable("sys_asstUnl")) { 
			this.radio = true;
			return true;
		}

		if (await this.varias.getVariable("sys_member")) { 
			this.radio = true;
			return true;
		}
		if (await this.varias.getVariable("sys_wasMember")) {
			this.radio = true;
			return true;
		}

		if (await this.varias.getVariable("sys_asstUnl_" + storyboardId)) { return true; }

		return false;
	}

	async requestImagery(storyboardId) {
		if (!this.imageryChecked) {
			let hasGeneralImagery: boolean = false;
			if (await this.varias.getVariable("sys_asstUnl")) { hasGeneralImagery = true; }
			else if (await this.varias.getVariable("sys_member")) { hasGeneralImagery = true; }
			else if (await this.varias.getVariable("sys_wasMember")) { hasGeneralImagery = true; }
			this.imageryChecked = true;
			if (hasGeneralImagery) {
				this.imagery = true;
				return true;
			}
		}
		if (await this.varias.getVariable("sys_asstUnl_" + storyboardId)) { return true; }
		
		return false;
	}
}