import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { LasercutButtonComponent } from './lasercut-button.component'

import { ImageModule } from '../image/image.component.module'

@NgModule({
    declarations: [LasercutButtonComponent],
    exports: [LasercutButtonComponent ],
    imports: [
        CommonModule,
        IonicModule,
        ImageModule
    ]
})
export class LasercutButtonModule{}