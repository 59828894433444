import { Injectable } from '@angular/core';

import { Browser } from '@capacitor/browser';

@Injectable({
  	providedIn: 'root'
})
export class BrowserService {

  	constructor() { }

	create(url, target, location) {
		Browser.open({ url: url, windowName: target });
	}

	openSystem(url) {
		window.open(url);
	}
}
