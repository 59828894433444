import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';

import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'

import { CloudSettings } from '@awesome-cordova-plugins/cloud-settings/ngx';

import { InstanceService } from './instance.service';
import { DeviceService } from '../system/device.service';
import { TelemetryService } from '../cloud/telemetry.service'
import { Events } from '../system/events.service'
import { MetaService } from '../cloud/meta.service';
import { LocalizationService } from './localization.service';
import { ShareService } from './share.service'
import { MailService } from './mail.service';
import { SyncService } from '../cloud/sync.service'
import { LibertyService } from './liberty.service';

import { PrivacyPage } from '../privacy/privacy.page'
import { StorageService } from './storage.service';
//import { MembershipPage } from '../membership/membership.page';
//import { Fair2playPage } from '../fair2play/fair2play.page';

@Injectable({
  	providedIn: 'root'
})
export class VariasService {

	vars: Array<any> = [];
	syncVars: Array<any> = [];
	unsubscribe_synchronousVariables: any = undefined;
	auid: any = undefined;

	constructor(
		private storage: Storage,
		private storageSrv: StorageService,
		private events: Events,
		private modalController: ModalController,
		private meta: MetaService,
		private alertController: AlertController,
		private telemetry: TelemetryService,
		private localization: LocalizationService,
		private share: ShareService,
		private mail: MailService,
		private sync: SyncService,
		private fireAuth: AngularFireAuth,
		private fireStore: AngularFirestore,
		private cloudSettings: CloudSettings,
		private instance: InstanceService,
		private platform: Platform,
		private liberty: LibertyService,
		private device: DeviceService,
		private loadingController: LoadingController
	) {
		this.loadVariables();

		this.events.subscribe("languageChanged", () => {
			this.setVariable("stt_lang", this.localization.lang);
		})

		this.fireAuth.onAuthStateChanged((currentUser) => {
			this.on_authStateChanged(currentUser)
		})
	}

	async loadVariables() {
		var that = this;
		let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })
		for await (let fullKey of keys) {
			if (fullKey.indexOf("var_stt_") > -1 || fullKey.indexOf("var_sys_") > -1 || fullKey.indexOf("var_usr_") > -1) {
				let key = fullKey.replace("var_", "");
				let value; that.getVariable(key).then((returnedValue) => { value = returnedValue; })
			}
		}
		return;
	}	

	async returnVariables() {
		let keys; await this.storage.keys().then(storedKeys => { keys = storedKeys })
		return keys;
	}

	async setArray(key) {
		if (this.instance.instance != "demoapp") {
			if (this.vars[key]) {
				if (this.vars[key].length > 0) {  this.storage.set("var_"+key, JSON.stringify(this.vars[key])); } 
				else { this.storage.set("var_"+key, null); }
			}
		}
	}

	async getArray(key) {
		if (!this.vars[key]) {
			let valueString; await this.storage.get("var_"+key).then((returnedString) => { valueString = returnedString; })
			if (valueString === null || valueString === undefined || valueString === "NaN") { this.vars[key] = []; }
			else {
				try {
					this.vars[key] = JSON.parse(valueString);	
				} catch (error) {
					this.vars[key] = [];
				}
			}
		}
		return this.vars[key];
	}
	  
	async setVariable(key, value, preventSync=false, afterPull=false) {
		if (value === "[timestamp]") { value = Date.now(); }

		let modifiedValue = undefined;
		if (value) {
			if (value.indexOf) {
				if (value.indexOf("+") > -1) {
					await this.getVariable(key);
					let addedValue = value.replace("+","");
					if (!isNaN(parseInt(addedValue))) {
						if (!isNaN(parseInt(this.vars[key]))) { modifiedValue = parseInt(this.vars[key]) + parseInt(addedValue); } 
						else { modifiedValue = addedValue; }
					}
				} else if (value.indexOf("-") > -1) {
					await this.getVariable(key);
					let subtractedValue = value.replace("-","");
					if (!isNaN(parseInt(subtractedValue))) {
						if (!isNaN(parseInt(this.vars[key]))) { modifiedValue = parseInt(this.vars[key]) - parseInt(subtractedValue); } 
						else { modifiedValue = subtractedValue; }
					}

				}
			}
		}

		if (
			key.indexOf("cnt_") > -1 || 
			key === "stt_theme" || key === "stt_track" ||
			key === "event" || key === "tracking"
		) { preventSync = true; }

		let stringifiedValue = "";
		if (modifiedValue != undefined) {
			stringifiedValue = modifiedValue.toString().split('"').join('').split("'").join("");
			if (this.instance.instance != "demoapp") {
				this.storage.set("var_"+key, modifiedValue);
			}
		} else {
			if (value === null) { stringifiedValue = "none"; }
			else if (value === undefined) { stringifiedValue = value; }
			else { stringifiedValue = value.toString().split('"').join('').split("'").join(""); }
			if (this.instance.instance != "demoapp") {
				this.storage.set("var_"+key, stringifiedValue);
			}
		}

		if (key === "ending") { await this.setVariable("end_"+value, true); }
		else if (key === "contact") { this.setVariable("cnt_"+value, true); }
		else if (key === "stt_consent") { let now = Date.now(); this.setVariable("stt_consentGivenAt", now); }
		else if (key.startsWith("topic_")) { 
			let topic = key.replace("topic_","");
			if (value === "true" || value === true) {
				this.events.publish("subscribeTopic", {topic: topic});
			} else if (value === "false" || value === false) {
				this.events.publish("unsubscribeTopic", {topic: topic});
			}
		} 

		if (!afterPull) {
			if (key === "scene") { this.events.publish("ECH_downloadAndStartTutoring", {sceneId: stringifiedValue}); }
			else if (key === "notifications") { this.events.publish("askForNotifications"); }
			else if (key === "rating") { this.events.publish("askForRating"); }
			else if (key === "nutrition") { this.incrementCurrency("nutrition", parseInt(value)); }
			else if (key === "tickets") { this.incrementCurrency("tickets", parseInt(value)); }
			else if (key === "shards") { this.incrementCurrency("shards", parseInt(value)); }
			else if (key === "startEpisode") { this.events.publish("STB_start", {episodeId: stringifiedValue}); }
			else if (key === "restoreEpisode") { this.restoreEpisode(stringifiedValue); }
			else if (key === "startEcho") { this.events.publish("ECH_downloadAndStart", {sceneId: stringifiedValue}); }
			else if (key === "restartEcho") { this.events.publish("ECH_downloadAndStart", {sceneId: stringifiedValue}); }
			else if (key === "modal") { 
				if (value === "privacy") { const modal = await this.modalController.create({ component: PrivacyPage }); await modal.present(); } 
				else if (value === "membership") { this.events.publish("presentMembership"); }
				else if (value === "fair2play") { this.events.publish("presentFair2play"); }
			} 
			else if (key === "share") { this.share.prompt(); }
			else if (key === "mail") { 
				if (value === "restore") { this.mail.support(this.vars['sys_member'], ""); }
				else { this.mail.support(this.vars['sys_member'], "plain"); }
			}
			else if (key === "fair2play") { this.events.publish("fair2playUsed"); }
			else if (key === "tracking") { this.telemetry.logEvent(value, {v: 1}); }
			else if (key === "f2pReminder") { this.events.publish("scheduleFair2PlayReminder"); }
			else if (key === "iap") { this.events.publish("purchaseIAP", {id: stringifiedValue}); }
			else if (key === "usergender") {
				if (value === "input") {
					this.getVariable("usr_gender").then(async currentValue => {
						if (currentValue === "male" || currentValue === "female" || currentValue === "other") {
							this.setVariable("usergender", currentValue);
						} else {
							const alert = await this.alertController.create({
								subHeader: this.localization.settings_profile_howAdress_1,
								cssClass: 'eon__alert',
								buttons: [
									{
										text: this.localization.settings_profile_howAdress_8,
										role: undefined,
										handler: () => { 
											this.setVariable("usr_gender", "male");
											this.setVariable("usergender", "male");
										}
									},{
										text: this.localization.settings_profile_howAdress_9,
										role: undefined,
										handler: () => {
											this.setVariable("usr_gender", "female");
											this.setVariable("usergender", "female");
										}
									}
								]
							})
							await alert.present();
						}
					})
				} else {
					this.setVariable("usr_gender", value);
				}
			}
			else if (key === "username") {
				let username; await this.getVariable("usr_name").then(result => username = result);
				if (username === null || username === "ask" || value === "force") {
					const alert = await this.alertController.create({
						subHeader: this.localization.settings_profile_yourName,
						cssClass: 'eon__alert',
						inputs: [ { name: 'name', type: 'text', placeholder: 'Name' } ],
						buttons: [
							{ text: this.localization.cancel, role: 'cancel', handler: () => {} },
							{ text: 'Ok', handler: (data) => { 
									this.setVariable("usr_name", data.name);
								}
							}
						]
					})
					alert.present();
				}
			}
			else if (key === "echo") {
				await this.getArray("arr_recech");
				let alreadyThere = false; for (let item of this.vars["arr_recech"]) { if (item.i === value) { alreadyThere = true } }
				if (!alreadyThere) {
					let started = false; await this.getVariable("ech_"+value+"_std").then(result => { started = result })
					if (!started) {
						let echoInfo = null; await this.meta.getEchoInfo(value).then(result => echoInfo = result);
						if (echoInfo) { this.vars["arr_recech"].push({ i: value, t: echoInfo.t, sc: echoInfo.sc }) }
					}
				}
				this.setArray("arr_recech");
			} else if (key === "image") {
				this.setVariable("image_"+value, true, preventSync, afterPull);
			} else if (key === "audio") {
				this.setVariable("audio_"+value, true, preventSync, afterPull);
			} else if (key === "immerch") {
				this.setVariable("immerch_"+value, true, preventSync, afterPull);
			}
		}

		await this.convertWithType(stringifiedValue).then((convertedValue) => { 
			this.vars[key] = convertedValue;
			
			let redundantSync = false; if (this.syncVars[key] === convertedValue) { redundantSync = true; }
			if (!preventSync && !redundantSync) { this.syncVariable(key, convertedValue); }
		})
		this.telemetry.logEvent("variable_set", { key: key, value: value });
		return;
	}

	async getVariable(key) {
		if (key === "sys_instance") {
			if (this.instance.instance === "gamerapp") { return "gamerapp"; }
			else if (this.instance.instance === "prllxapp") { return "prllxapp"; }
			else if (this.instance.instance === "horrorapp") { return "horrorapp"; }
			else if (this.instance.instance === "redfirapp") { return "redfirapp"; }
			else { return "voidapp"; }
		} else if (key.startsWith("plat_")) {
			if (key === "plat_pwa") {
				if (this.platform.is("pwa")) { return true } 
				else if (this.liberty.added) { return true }
				else { return false }
			}
			else if (key === "plat_cordova") { if (this.device.platform === "ios" || this.device.platform === "android") { return true } else { return false } }
			else if (key === "plat_android") { if (this.device.platform === "android") { return true } else { return false } }
			else if (key === "plat_ios") { if (this.device.platform === "ios") { return true } else { return false } }
			else if (key === "plat_desktop") { if (this.device.platform === "web" && this.device.operatingSystem != "ios" && this.device.operatingSystem != "android") { return true } else { return false } }

		}
		if (!this.vars[key]) {
			let valueString; await this.storage.get("var_"+key)
			.then((returnedString) => { valueString = returnedString; }).catch((error) => {})
			await this.convertWithType(valueString).then((convertedValue) => { this.vars[key] = convertedValue; })
		}
		if (this.vars[key] === null) {
			if (key === "stt_music") { await this.setVariable("stt_music", true); }
			else if (key === "stt_sound") { await this.setVariable("stt_sound", true); }
			else if (key === "stt_track") { await this.setVariable("stt_track", "wasteland1"); }
			else if (key === "stt_vibration") { await this.setVariable("stt_vibration", true); }
			else if (key === "stt_images") { await this.setVariable("stt_images", true); this.vars["stt_images"] = true; }
			else if (key === "sys_tickets") { await this.setVariable("sys_tickets", 0); }
			else if (key === "sys_nutrition") { await this.setVariable("sys_nutrition", 0); }
			else if (key === "sys_shards") { await this.setVariable("sys_shards", 0); }
			else if (key === "sys_circuits") { await this.setVariable("sys_circuits", 0); }
		}
		return this.vars[key];
	}

	async getCount(key) {
		let count = 10; await this.getVariable(key).then(result => { count = Number.parseInt(result) });
		if (Number.isNaN(count)) { count = 0; }
		if (count === undefined || count === null) { count = 0; }
		return count;
	}

	async incrementCount(key, amount) {
		let count = 0; await this.getCount(key).then(result => count = result);
		if (Number.isNaN(count)) { count = 0; }
		count = count + amount;
		this.setVariable(key, count);
		return count;
	}

	async decrementCount(key, amount) {
		let count = 0; await this.getCount(key).then(result => count = result);
		if (Number.isNaN(count)) { count = 0; }
		count = count - amount;
		this.setVariable(key, count);
		return count;
	}	

	async checkConditions(conditions) {
		let allGood = true;
		for (let condition of conditions) {
			let isEqual = false;
			let storedValue; await this.getVariable(condition.key).then(result => storedValue = result);
			if (storedValue === null) { storedValue = "null"; }
			if (condition.key === "nutrition") { storedValue = this.vars["sys_nutrition"]; }
			if (condition.key === "tickets") { storedValue = this.vars["sys_tickets"]; }
			if (condition.key === "shards") { storedValue = this.vars["sys_shards"]; }
			let relativeComparison = false;
			if (condition.val.indexOf) {
				if (condition.equ) {
					if (condition.val.indexOf(">=") > -1) { relativeComparison = true; if (parseInt(storedValue) >= parseInt(condition.val.replace(">=",""))) {} else { allGood = false; } }
					else if (condition.val.indexOf("<=") > -1) { relativeComparison = true; if (parseInt(storedValue) <= parseInt(condition.val.replace("<=",""))) {} else {allGood = false;} }
					else if (condition.val.indexOf(">") > -1) { relativeComparison = true; if (parseInt(storedValue) > parseInt(condition.val.replace(">",""))) {} else {allGood = false;} }
					else if (condition.val.indexOf("<") > -1) { relativeComparison = true; if (parseInt(storedValue) < parseInt(condition.val.replace("<",""))) {} else {allGood = false;} }
				} else {
					if (condition.val.indexOf(">=") > -1) { relativeComparison = true; if (parseInt(storedValue) >= parseInt(condition.val.replace(">=",""))) {allGood = false;} else {} }
					else if (condition.val.indexOf("<=") > -1) { relativeComparison = true; if (parseInt(storedValue) <= parseInt(condition.val.replace("<=",""))) {allGood = false;} else {} }
					else if (condition.val.indexOf(">") > -1) { relativeComparison = true; if (parseInt(storedValue) > parseInt(condition.val.replace(">",""))) {allGood = false;} else {} }
					else if (condition.val.indexOf("<") > -1) { relativeComparison = true; if (parseInt(storedValue) < parseInt(condition.val.replace("<",""))) {allGood = false;} else {} }
				}
			}
			if (!relativeComparison) {
				if (condition.equ) { if (storedValue.toString() === condition.val.toString()) {} else { allGood = false; } } 
				else { if (storedValue.toString() === condition.val.toString()) { allGood = false; } else {} }
			}
		}
		return allGood;
	}
	
	async convertWithType(value) {
		if (value === null) { return null; }
		else if (value === "true") { return true; }
		else if (value === "false") { return false; }
		else if (!Number.isNaN(Number.parseFloat(value))) {
			let valueAsFloat = Number.parseFloat(value);
			if (Number.isInteger(valueAsFloat)) { let valueAsInteger: number = Number.parseInt(value); return valueAsInteger;
			} else { return valueAsFloat; }
		} else { return value; }
	}

	async getRewardVariables() {
		let rewardVariables = [];
		let storageKeys; await this.storage.keys().then(result => storageKeys = result);
		for await (let key of storageKeys) {
			if (key.indexOf("var_rwd_") > -1) {
				rewardVariables.push(key.replace("var_rwd_",""));
			}
		}
		return rewardVariables;
	}

	async incrementCurrency(currency, amount) {
		if (this.vars["sys_"+currency] === undefined || this.vars["sys_"+currency] === null) { this.vars["sys_"+currency] = 0; }
		await this.setVariable("sys_"+currency, this.vars["sys_"+currency]+parseInt(amount));
		this.trackCurrency(currency, this.vars["sys_"+currency]+parseInt(amount));
		return true;
	}
	
	async decrementCurrency(currency, amount) {
		if (this.vars["sys_"+currency] === undefined || this.vars["sys_"+currency] === null) { this.vars["sys_"+currency] = 0; }
		if (this.vars["sys_"+currency] >= amount) {
			let newAmount = this.vars["sys_"+currency]-amount;
			await this.setVariable("sys_"+currency, newAmount);
			this.trackCurrency(currency, this.vars["sys_"+currency]-amount);
			return true;
		} else {
			return false;
		}
	}

	async trackCurrency(currency, value) {
		if (currency === "nutrition") {
			if (value >= 25) { this.telemetry.setUserProperty("has25Nutrition", "true"); }
			else { this.telemetry.setUserProperty("has25Nutrition", "false"); }
		} else if (currency === "tickets") {
			if (value >= 100) { this.telemetry.setUserProperty("has100Tickets", "true"); } 
			else { this.telemetry.setUserProperty("has100Tickets", "false"); }
		}
	}
	
	async checkCurrency(currency, amount) {
		if (this.vars["sys_"+currency] >= amount) {
			return true;
		} else {
			return false;
		}
	}
	
	async incrementXP(amount) {
		await this.setVariable("sys_xp", this.vars["sys_xp"]+parseInt(amount));
		return true;
	}

	async removeFromArray(array, id) {
		await this.getArray[array];

		if (this.vars[array]) {
			this.vars[array] = this.vars[array].filter(element => { return element.i != id; })
		}
		this.setArray(array);
	}	

	async syncVariable(key, value) {
		if (!key) { 
			console.log("NO KEY");
			return false;
		}
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			if (uid != "none") {
				let varsCollectionName = "vars_a";
				if ( (key.indexOf("_cmp") > -1) || (key.indexOf("_std") > -1) || key === "stt_lang" || key === "sys_shards" || key === "sys_tickets" || key === "sys_nutrition" || key === "usr_init" || key === "usr_mature") { varsCollectionName = "vars_s" };
				if (key.indexOf("scn_") > -1) {
					let storyboardId = key.replace("scn_","").replace("_std","").replace("_cmp","").split("_")[0];
					varsCollectionName = storyboardId;
				}
				await this.fireStore.collection("sync").doc(uid).ref.collection(varsCollectionName).doc(key).set({ v: value }).then(() => {}).catch(error => {
					console.log("ERROR 1: " + key + " -> " + value, error);
				}).catch(() => {})
				this.syncVars[key] = value;
			}
		}
	}

	signedInWithRealAccount: boolean = false;
	initialAuthStateChange: boolean = true;
	async on_authStateChanged(currentUser) {
		return;
		if (currentUser) {

			let now_signedInWithRealAccount = false;
			if (currentUser.email) {
				if ((currentUser.email.indexOf("eon_") > -1) && (currentUser.email.indexOf("@textadventure.app") > -1)) { 
					now_signedInWithRealAccount = false;
				} else {
					now_signedInWithRealAccount = true;
				}
			} else {
				now_signedInWithRealAccount = true;
			}

			if (!this.initialAuthStateChange) {
				if (!this.signedInWithRealAccount && now_signedInWithRealAccount) {
					this.tryToBindUIDtoAUID();
				}

				if (this.signedInWithRealAccount && !now_signedInWithRealAccount) {
					await this.pushAllToSync();
				} else {
					let loadingElement = await this.loadingController.create({
						spinner: "bubbles",
						cssClass: "eon__alert",
						message: this.localization.oneMomentPlease,
						showBackdrop: true
					});
					setTimeout(() => { loadingElement.present(); }, 3000);
					if (this.instance.liberty) {
						setTimeout(() => { loadingElement.dismiss(); }, 8000);
					}
					await this.pullAllFromSync();
					await this.pushAllToSync();
					loadingElement.dismiss();
					if (!this.instance.liberty) { 
						window.location.href = "index.html";
					}
				}
			}

			if (now_signedInWithRealAccount) { this.signedInWithRealAccount = true; } else { this.signedInWithRealAccount = false; }			

			this.unsubscribe_synchronousVariables = this.fireStore.collection("sync").doc(currentUser.uid).collection("vars_s").ref.onSnapshot((snapshot) => {
				snapshot.docChanges().forEach(async (change) => {
					if (change.type === "added" || change.type === "modified") {
						await this.setVariable(change.doc.id, change.doc.data().v, true);
						if (change.doc.id.indexOf("_std") > -1) { 
							this.events.publish("SYNC_findRunningStoryboards"); 
						}
					}
				});
			});

		} else {
			if (this.unsubscribe_synchronousVariables != undefined) { this.unsubscribe_synchronousVariables(); }
			this.signedInWithRealAccount = false;
		}

		this.initialAuthStateChange = false;
	}

	async tryToBindUIDtoAUID() {
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			let cloudSettingsExist = false; await this.cloudSettings.exists().then(result => { if (result) { cloudSettingsExist = true; } })
			if (cloudSettingsExist) {
				let auid = false;
				await this.cloudSettings.load().then(async settings => { if (settings.auid) { auid = settings.auid; } });
				if (auid) {
					await this.fireStore.collection("sync_x").doc(auid).set({ u: uid }).then(() => {}).catch(() => {})
				}
			}
		}
	}

	async pullAllFromSync() {
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			let varsToBeSet = [];
	
			await this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.get().then(async querySnapshot_s => {
				querySnapshot_s.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
	
			await this.fireStore.collection("sync").doc(uid).collection("vars_a").ref.get().then(querySnapshot_a => {
				querySnapshot_a.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
			})
	
			for await (let item of varsToBeSet) {
				if (item.key === "sys_tickets" || item.key === "sys_nutrition" || item.key === "sys_shards") {
					let currentValue = 0; await this.getVariable(item.key).then(result => { currentValue = result; })
					if (item.val > currentValue) { await this.setVariable(item.key, item.val, true, true); }
				} else if (item.key.endsWith("_ign")) {
				} else {
					await this.setVariable(item.key, item.val, true, true);
					console.log("-> pull");
				}
			}
		}
	}

	async pushAllToSync() {
		if (this.fireAuth.currentUser) {
			let uid = "none"; await this.fireAuth.currentUser.then(result => { if (result) { uid = result.uid } });
			let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })
			let vars = [];
			for await (let fullKey of keys) {
				if (fullKey.indexOf("var_") > -1) {
					let value; await this.storage.get(fullKey).then((returnedValue) => { value = returnedValue; })
					let key = fullKey.replace("var_", "");
					vars.push({ k: key, v: value })
				}
			}
			for await (let item of vars) {
				let value = item.v;
				if (value === "true") { value = true; }
				else if (value === "false") { value = false; }
				else if (!isNaN(value)) { value = parseInt(value); }
				await this.syncVariable(item.k, value);
				console.log("-> push");
			}
		}
	}

	async pullAllFromUID(uid) {
		let varsToBeSet = [];

		await this.fireStore.collection("sync").doc(uid).collection("vars_s").ref.get().then(async querySnapshot_s => {
			querySnapshot_s.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
		})

		await this.fireStore.collection("sync").doc(uid).collection("vars_a").ref.get().then(querySnapshot_a => {
			querySnapshot_a.forEach(doc => { varsToBeSet.push({ key: doc.id, val: doc.data().v }); })
		})

		for await (let item of varsToBeSet) {
			if (item.key.startsWith("conf_")) {
				// do nothing
			} else if (item.key === "sys_tickets" || item.key === "sys_nutrition" || item.key === "sys_shards") {
				let currentValue = 0; await this.getVariable(item.key).then(result => { currentValue = result; })
				if (item.val > currentValue) { this.setVariable(item.key, item.val, true, true); }
			} else {
				this.setVariable(item.key, item.val, true, true);
			}
		}		
	}

	test_firebaseAnalytics() {
		this.telemetry.logEvent("test", {});
		this.telemetry.logEvent("caway", {});
	}

	async restoreEpisode(episodeId) {
		await this.setVariable(episodeId+"_unl", true);

		if (episodeId === "pa1") {
		} else if (episodeId === "pa2") {
			await this.setVariable("pa1_cmp", true);
		} else if (episodeId === "pa3") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);			
		} else if (episodeId === "pa4") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);			
		} else if (episodeId === "pa5") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);			
		} else if (episodeId === "pa6") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);			
		} else if (episodeId === "pa7") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
		} else if (episodeId === "pa8") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
		} else if (episodeId === "pa9") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
		} else if (episodeId === "pa10") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);		
		} else if (episodeId === "pa11") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);			
		} else if (episodeId === "pa12") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);		
		} else if (episodeId === "pa13") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);		
		} else if (episodeId === "pa14") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);		
		} else if (episodeId === "pa15") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);		
		} else if (episodeId === "pa16") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);
			await this.setVariable("pa15_cmp", true);		
		} else if (episodeId === "pa17") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);
			await this.setVariable("pa15_cmp", true);
			await this.setVariable("pa16_cmp", true);		
		} else if (episodeId === "pa18") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);
			await this.setVariable("pa15_cmp", true);
			await this.setVariable("pa16_cmp", true);
			await this.setVariable("pa17_cmp", true);		
		} else if (episodeId === "pa19") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);
			await this.setVariable("pa15_cmp", true);
			await this.setVariable("pa16_cmp", true);
			await this.setVariable("pa17_cmp", true);
			await this.setVariable("pa18_cmp", true);			
		} else if (episodeId === "pa20") {
			await this.setVariable("pa1_cmp", true);
			await this.setVariable("pa2_cmp", true);
			await this.setVariable("pa3_cmp", true);
			await this.setVariable("pa4_cmp", true);
			await this.setVariable("pa5_cmp", true);
			await this.setVariable("pa6_cmp", true);
			await this.setVariable("pa7_cmp", true);
			await this.setVariable("pa8_cmp", true);
			await this.setVariable("pa9_cmp", true);
			await this.setVariable("pa10_cmp", true);

			await this.setVariable("pa11_cmp", true);
			await this.setVariable("pa12_cmp", true);
			await this.setVariable("pa13_cmp", true);
			await this.setVariable("pa14_cmp", true);
			await this.setVariable("pa15_cmp", true);
			await this.setVariable("pa16_cmp", true);
			await this.setVariable("pa17_cmp", true);
			await this.setVariable("pa18_cmp", true);
			await this.setVariable("pa19_cmp", true);
		}

		this.events.publish("STB_start", {episodeId: episodeId});

		this.telemetry.logEvent("restore_"+episodeId, {});
	}

}