import { IonContent, DomController, IonSlides, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MetaService } from '../cloud/meta.service';
import { NavigationService } from '../system/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '../system/theme.service';

import { LocalizationService } from '../system/localization.service'
import { DataService } from '../system/data.service';
import { EngineService } from '../system/engine.service';
import { VariasService } from '../system/varias.service';

import { EpisodePage } from '../episode/episode.page'
import { Events } from '../system/events.service';
import { PurchaseService } from '../cloud/purchase.service';
import { PurrchaseService } from '../cloud/purrchase.service';
import { AdsService } from '../cloud/ads.service'
import { InstanceService } from '../system/instance.service';
import { ModalsService } from '../system/modals.service';
import { BootService } from '../system/boot.service';

@Component({
  selector: 'app-serial',
  templateUrl: './serial.page.html',
  styleUrls: ['./serial.page.scss'],
})
export class SerialPage implements OnInit {
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("ionSlides", { static: false } ) ionSlides: IonSlides;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	serialId: any;
	seasons: any = [];
	loaded: boolean = false;
	activeSeason: number = 0;
	lastActiveSeason: any = null;
	serialTitle = "";
	scrollTops: Array<any> = [];
	seasonPacks: Array<any> = [];
	purchaseMode: string = "season";
	activeSeasonPack: any = undefined;

	param_season: number = null;
	param_episodeId: string = null;

	param_autoOpen: string = "";

  	constructor(
		private boot: BootService,
		public meta: MetaService,
		public navigation: NavigationService,
		private route: ActivatedRoute,
		public theme: ThemeService,
		private domController: DomController,
		private renderer: Renderer2,
		public localization: LocalizationService,
		public data: DataService,
		private engine: EngineService,
		public varias: VariasService,
		private modalController: ModalController,
		private events: Events,
		public purchase: PurchaseService,
		public purrchase: PurrchaseService,
		public ads: AdsService,
		public instance: InstanceService,
		private modals: ModalsService
	) {
		this.route.queryParamMap.subscribe(param => {
			this.serialId = param.get("serialId"); if (!this.serialId) { this.serialId = "pa"; }
			let param_season: any = param.get("season");
			param_season = parseInt(param_season); if (isNaN(param_season)) { param_season = null; } else { param_season = param_season - 1; }
			if (param_season < 0) { param_season = 0; }
			this.param_season = param_season;
			this.param_episodeId = param.get("episode");
			this.param_autoOpen = param.get("open");
		});

		this.purrchase.checkF2P();
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (this.headerTitleLarge) {
			if (!this.scrollableChecked) {
				this.scrollableChecked = true;
				await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
			}
			if (this.scrollable) {
				let height = 50 - event.detail.scrollTop;
				let showSmallTitle = false;
				if (height <= 0) { height = 0; showSmallTitle = true; } 
				else if (height > 50) { height = 50; }
				this.domController.write(() => {
					this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
					if (showSmallTitle) {
						this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
						this.headerCollapsed = true;
					} else {
						this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
						this.headerCollapsed = false;
					}
				})
			}
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }	

  	async ngOnInit() {
	}

	ngAfterViewInit() {
		this.varias.getVariable("conf_f2pCovered");
		this.varias.getVariable("conf_adsImplemented");
		setTimeout(async () => { 
			await this.processSerial();
			if (this.param_autoOpen) {
				for (let season of this.seasons) {
					for (let episode of season) {
						if (episode.i === this.param_autoOpen) {
							this.pushEpisode(episode);
						}
					}
				}
			}
		}, 100);
		this.events.subscribe("purchaseMade", (productId) => { this.processSerial(); });
	}

	async processPurchase(productId) {
	}

	async processSerial() {
		this.lastActiveSeason = this.activeSeason;
		this.seasons = [];
		this.seasonPacks = [];

		let hasMembership = false; await this.varias.getVariable("sys_member").then(result => hasMembership = result);

		await this.meta.getEpisodes().then(async serials => {
			let serialTitle = "";
			for await (let serial of serials) {
				if (serial.i === this.serialId) {
					serialTitle = serial.t;
					for await (let episode of serial.ep) {
						if (!this.seasons[episode.s-1]) { this.seasons[episode.s-1] = []; }
						this.seasons[episode.s-1].push(episode);
					}
				}
			}
			this.serialTitle = serialTitle;
		});
		
		let seasonByParam: boolean = false;
		for (let season of this.seasons) {
			for (let episode of season) {
				if (episode.i === this.param_episodeId) {
					if (episode.s) {
						this.activeSeason = episode.s-1;
						this.lastActiveSeason = episode.s-1;
						seasonByParam = true;
					}
				}				
			}
		}
		if (!seasonByParam) {
			if (this.param_season) {
				if (this.param_season <= this.seasons.length) {
					this.activeSeason = this.param_season;
					this.lastActiveSeason = this.param_season;
					seasonByParam = true;
				}
			}
		}
		if (!seasonByParam) {
			let highestRunningSeason = 1;
			for (let storyboard of this.engine.storyboardsRegistry) {
				for (let season of this.seasons) {
					for (let episode of season) {
						if (episode.i === storyboard.i) {
							if (episode.s > highestRunningSeason) { highestRunningSeason = episode.s; }
						}
					}
				}
			}			
			if (this.lastActiveSeason) { this.activeSeason = this.lastActiveSeason; }
			else { this.activeSeason = highestRunningSeason-1; }
		}

		let i = 1;
		for await (let season of this.seasons) {
			season.hasEpisodesToUnlock = false;
			for await (let episode of season) {
				episode.inUse = false;
				await this.varias.getVariable(episode.i+"_cmp").then(result => {
					if (result) { episode.completed = true; }
				})
				if (!episode.completed) {
					await this.varias.getVariable(episode.i+"_unl").then(result => {
						if (result) { episode.unlocked = true; }
					})
				}
				if (!episode.unlocked && !episode.completed) {
					await this.varias.getVariable(episode.i+"_std").then(result => {
						if (result) { episode.started = true; }
					})
				}
				if (episode.completed || episode.unlocked || episode.started) { episode.inUse = true; }
				if (!episode.inUse) { 
					season.hasEpisodesToUnlock = true;

					let adsImplemented = false; await this.ads.checkImplementation().then(result => adsImplemented = result);
					if (adsImplemented) {
						episode.hasAds = true;
						this.ads.getWatchCount("episode", episode.i);
					}
				}
			}
			if (this.serialId === "pa") { season.i = "par"+i; }
			else if (this.serialId === "ga") { season.i = "gam"+i; }
			i++;

			if (season.hasEpisodesToUnlock) {
				let hasSeasonPack = false;
				if (!hasMembership) {
					await this.purrchase.findSeasonPackProduct(season.i).then(result => {
						if (result) { if (result.product && !result.seasonFull) {
							this.seasonPacks[season.i] = result.product;
							if (result.product.id.startsWith("se_reduced_") || result.product.id.startsWith("se_single_")) { this.seasonPacks[season.i].reducedSeason = true; }
							hasSeasonPack = true;
						} }
					})
				}
				if (!hasSeasonPack) {
					for (let episode of season) {
						episode.singlePurchase = true;
					}
				}
			}

		}

		if (this.seasonPacks[this.seasons[this.activeSeason].i]) {
			this.activeSeasonPack = this.seasonPacks[this.seasons[this.activeSeason].i];
		} else { this.activeSeasonPack = undefined; }

		this.loaded = true;
	}
	

	segmentChanged(event) {
		if (event.detail.value != this.activeSeason) {
			this.activeSeason = event.detail.value;

			if (this.seasonPacks[this.seasons[this.activeSeason].i]) {
				this.activeSeasonPack = this.seasonPacks[this.seasons[this.activeSeason].i];
			} else { this.activeSeasonPack = undefined; }
			this.ionContent.scrollToTop();
		}
	}

	purchaseModeChanged(event) {
		if (event.detail.value != this.purchaseMode) {
			this.purchaseMode = event.detail.value;
		}
	}

	async pushEpisode(episode) {
		let modal = await this.modalController.create({
			component: EpisodePage,
			componentProps: {
				mode: "episode", 
				constellation: "season",
				serialId: this.serialId,
				seasonNr: episode.s,
				episodeNr: episode.e,
				seasonPack: this.activeSeasonPack,
				membershipPack: this.purchase.membershipProduct
			}
		}).then(async (modal) => {
			setTimeout(async () => { await modal.present(); }, 100);
			this.events.subscribe("STB_loaded", () => {
				modal.dismiss();
				this.pop();
			})
			modal.onDidDismiss().then(async result => { this.events.unsubscribe("STB_loaded"); })
		})
	}

	presentMembershipModal() {
		this.modals.presentMembership();	
	}

	presentPurchaseOption() {
		if (this.instance.liberty) {
			this.scrollToTop();
		} else if (this.purchaseMode === "season") {
			if (this.activeSeasonPack) {
				this.purchase.order(this.activeSeasonPack.id, 'serialHeaderSeason');
			}
		} else if (this.purchaseMode === "membership") {
			this.modals.presentMembership();
		} else if (this.purchaseMode === "fair2play") {
			this.modals.presentFair2play();
		}
	}

	requestAd(episode) {
		if (this.varias.vars["sys_tickets"] + this.ads.watchCountsPercentage[episode.i] < 100) {
			this.ads.requestAd("episode", episode.i);
		} else {
			episode.willBeStarting = true;
			this.engine.STB_start(episode.i).then(result => {});
		}
	}

	getTickets() {
		this.navigation.pushShop();
	}

}