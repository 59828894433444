import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { ChatCardComponent } from './chat-card.component'
import { ButtonModule } from '../button/button.component.module'
import { ImageModule } from '../image/image.component.module'

@NgModule({
    declarations: [ChatCardComponent],
    exports: [ChatCardComponent],
    imports: [
        CommonModule,
        IonicModule,
        ButtonModule,
        ImageModule
    ]
})
export class ChatCardModule{}