import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth'

import { UserService } from './user.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

	projects: Array<any> = [];
	sync_projects: any = null;
	oneProjectHasCover: boolean = false;
	archive: Array<any> = [];
	sync_archive: any = null;
	collections: Array<any> = [];
	sync_collections: any = null;
	challenges: Array<any> = [];
	sync_challenges: any = null;
	friends: Array<any> = [];
	sync_friends: any = null;
	friendrequests: Array<any> = [];
	sync_friendrequests: any = null;
	incomingFriendrequests: Array<any> = [];
	hadIncomingFriendrequests: boolean = false;
	outgoingFriendrequests: Array<any> = [];
	hadOutgoingFriendrequests: boolean = false;
	profile: any = {};
	sync_profile: any = null;
	invites: Array<any> = [];
	sync_invites: any = null;
	cards: Array<any> = [];
	sync_cards: any = null;

	uid: string = "";

  	constructor(
		private fireStore: AngularFirestore,
		private fireAuth: AngularFireAuth,
		private alertController: AlertController,
		private user: UserService
	) {
		this.syncProfile();
	}

	syncProfile() {
		this.fireAuth.onAuthStateChanged(user => {
			if (user) { if (user.uid) { this.uid = user.uid; } else { this.uid = ""; } } else { this.uid = ""; }
			if (!this.sync_profile) {
				this.sync_profile = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).ref.onSnapshot(doc => {
					if (doc.exists) {
						this.profile = { ...doc.data(), id: doc.id };
					}
				})
			}
		})
	}

	syncFriends() {
		this.fireAuth.onAuthStateChanged((user) => {
			if (!this.sync_friends) {
				this.sync_friends = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friends").ref.onSnapshot(snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id }
						let index = this.friends.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							if (index > -1) { this.friends[index] = docData; }
							else { this.friends.push(docData); }
						} else if (change.type === "removed") {
							if (index > -1) { this.friends.splice(index,1); }
						}
					})
				})
				if (this.pendingNaming) { 
					setTimeout(() => {
						this.willNamingFriends();
					}, 1000)
				}
			}			
		});
	}

	syncFriendrequests() {
		this.fireAuth.onAuthStateChanged((user) => {
			if (!this.sync_friendrequests) {
				this.sync_friendrequests = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friendrequests").ref.onSnapshot(snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id }
						let index = this.friendrequests.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							if (index > -1) { this.friendrequests[index] = docData; } 
							else { this.friendrequests.push(docData); }
						} else if (change.type === "removed") {
							if (index > -1) { this.friendrequests.splice(index,1); }
						}
						this.printFriendrequests();
					})
				})
			}			
		});		
	}

	syncInvites() {
		this.fireAuth.onAuthStateChanged((user) => {
			if (!this.sync_invites) {
				this.sync_invites = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("invites").ref.onSnapshot( snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id }
						let index = this.invites.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							if (index > -1) {
								this.fireStore.collection("cugc").doc("projects").collection("projects").doc(docData.id).ref.get().then(inviteDoc => {
									let inviteData = { ...inviteDoc.data(), id: inviteDoc.id };
									this.invites[index] = inviteData;
								}).catch(error => {})
							} else {
								this.fireStore.collection("cugc").doc("projects").collection("projects").doc(docData.id).ref.get().then(inviteDoc => {
									let inviteData = { ...inviteDoc.data(), id: inviteDoc.id };
									this.invites.push(inviteData);
								}).catch(error => {})
							}
						} else if (change.type === "removed") {
							if (index > -1) { this.invites.splice(index,1); }
						}
					})
				})
			}			
		});	
	}

	syncProjects() {
		this.fireAuth.onAuthStateChanged((user) => {
			if (!this.sync_projects) {
				this.projectsLoading();
				this.sync_projects = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).collection("projects").ref.onSnapshot( snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id };
						let index = this.projects.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							this.projectsLoading();
							if (docData.im) { this.oneProjectHasCover = true; }
							this.fireStore.collection("cugc").doc("projects").collection("projects").doc(docData.id).ref.get().then(projectDoc => {
								let projectData = { ...projectDoc.data(), id: projectDoc.id };
								if (index > -1) {
									this.projects[index] = projectData;
								} else {
									this.projects.push(projectData);
								}
							}).catch(error => { console.log("ERROR ", docData, error); })
						} else if (change.type === "removed") {
							if (index > -1) { this.projects.splice(index,1); }
						}
					})
				})
			}			
		});
	}

	projectsLoaded: boolean = false;
	timeout_projectsLoading: any = null;
	projectsLoading() {
		if (!this.projectsLoaded) {
			clearTimeout(this.timeout_projectsLoading);
			this.timeout_projectsLoading = setTimeout(() => { this.projectsLoaded = true; }, 400);
		}
	}

	syncCards() {
		this.fireAuth.onAuthStateChanged(user => {
			if (user.uid) {
				if (!this.sync_cards) {
					this.cardsLoading();
					this.sync_cards = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).collection("cards").ref.onSnapshot( snapshot => {
						snapshot.docChanges().forEach(async change => {
							let docData: any = { ...change.doc.data(), id: change.doc.id };
							let index = this.cards.findIndex(element => element.id === docData.id);
							if (change.type === "added" || change.type === "modified") {
								this.cardsLoading();
								if (index > -1) { this.cards[index] = docData; }
								else { this.cards.push(docData); }
							} else if (change.type === "removed") {
								if (index > -1) { this.cards.splice(index,1); }
							}
						})
					})
				}
			} 
		})
	}

	cardsLoaded: boolean = false;
	timeout_cardsLoading: any = null;
	cardsLoading() {
		if (!this.cardsLoaded) {
			clearTimeout(this.timeout_cardsLoading);
			this.timeout_cardsLoading = setTimeout(() => { this.cardsLoaded = true; }, 400);
		}
	}

	syncCollections() {
		this.fireAuth.onAuthStateChanged(user => {
			if (!this.sync_collections) {
				this.collectionsLoading();
				this.sync_collections = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).collection("collections").ref.onSnapshot(snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id };
						let index = this.collections.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							this.collectionsLoading();
							this.fireStore.collection("cugc").doc("collections").collection("collections").doc(docData.id).ref.onSnapshot(collectionDoc => {
								let index2 = this.collections.findIndex(element => element.id === docData.id);
								let collectionData = { ...collectionDoc.data(), id: collectionDoc.id };
								if (index2 > -1) {
									this.collections[index2] = collectionData;
								} else {
									this.collections.push(collectionData);
								}
							})
						} else if (change.type === "removed") {
							if (index > -1) { this.collections.splice(index, 1); }
						}
					})
				})
			}
		})
	}

	collectionsLoaded: boolean = false;
	timeout_collectionsLoading: any = null;
	collectionsLoading() {
		if (!this.collectionsLoaded) {
			clearTimeout(this.timeout_collectionsLoading);
			this.timeout_collectionsLoading = setTimeout(() => { this.collectionsLoaded = true; }, 400)
		}
	}	

	syncChallenges() {
		this.fireAuth.onAuthStateChanged(user => {
			if (!this.sync_challenges) {
				this.challengesLoading();
				this.sync_challenges = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).collection("challenges").ref.onSnapshot(snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id };
						let index = this.challenges.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							this.challengesLoading();
							if (index > -1) {
								this.fireStore.collection("cugc").doc("challenges").collection("challenges").doc(docData.id).ref.get().then(collectionDoc => {
									let collectionData = { ...collectionDoc.data(), id: collectionDoc.id };
									this.challenges[index] = collectionData;
								})
							} else {
								this.fireStore.collection("cugc").doc("challenges").collection("challenges").doc(docData.id).ref.get().then(collectionDoc => {
									let collectionData = { ...collectionDoc.data(), id: collectionDoc.id };
									this.challenges.push(collectionData);
								})
							}
						} else if (change.type === "removed") {
							if (index > -1) { this.challenges.splice(index, 1); }
						}
					})
				})
			}
		})
	}

	challengesLoaded: boolean = false;
	timeout_challengesLoading: any = null;
	challengesLoading() {
		if (!this.challengesLoaded) {
			clearTimeout(this.timeout_challengesLoading);
			this.timeout_challengesLoading = setTimeout(() => { this.challengesLoaded = true; }, 400)
		}
	}	

	syncArchive() {
		this.fireAuth.onAuthStateChanged((user) => {
			if (!this.sync_archive) {
				this.archiveLoading();
				this.sync_archive = this.fireStore.collection("cugc").doc("authors").collection("authors").doc(user.uid).collection("archive").ref.onSnapshot( snapshot => {
					snapshot.docChanges().forEach(async change => {
						let docData: any = { ...change.doc.data(), id: change.doc.id }
						let index = this.archive.findIndex(element => element.id === docData.id);
						if (change.type === "added" || change.type === "modified") {
							this.archiveLoading();
							if (index > -1) { this.archive[index] = docData; } 
							else { this.archive.push(docData); }
						} else if (change.type === "removed") {
							if (index > -1) { this.archive.splice(index,1); }
						}
					})
				})
			}
		});
	}

	archiveLoaded: boolean = false;
	timeout_archiveLoading: any = null;
	archiveLoading() {
		if (!this.archiveLoaded) {
			clearTimeout(this.timeout_archiveLoading);
			this.timeout_archiveLoading = setTimeout(() => {
				this.archiveLoaded = true;
			}, 400)
		}
	}

	pendingNaming: boolean = false;
	funnel_namingFriends: any = null;
	willNamingFriends() {
		clearTimeout(this.funnel_namingFriends);
		this.funnel_namingFriends = setTimeout(() => {
			this.namingFriends();
		}, 500);
	}
	namingFriends() {
		if (this.sync_friends) {
			for (let friend of this.friends) {
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(friend.id).collection("public").doc("info").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						friend.n = docData.n;
					}
				})
			}
			this.pendingNaming = false;
		} else {
			this.pendingNaming = true;
		}
	}

	printFriendrequests() {
		let incomingFriendrequests = [];
		let outgoingFriendrequests = [];
		for (let request of this.friendrequests) {
			if (!request.acc) {
				if (request.in) { 
					incomingFriendrequests.push(request);
					this.hadIncomingFriendrequests = true;
				}
				else if (!request.in) { 
					outgoingFriendrequests.push(request);
					this.hadOutgoingFriendrequests = true;
				}
			}
		}
		this.incomingFriendrequests = incomingFriendrequests;
		this.outgoingFriendrequests = outgoingFriendrequests;
	}	

	updateName() {
		if (this.uid) {
			this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).update({
				n: this.profile.n
			})
			this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("public").doc("info").update({
				n: this.profile.n
			}).catch(error => {
				if (error.code === "not-found") {
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("public").doc("info").set({
						n: this.profile.n
					})
				}
			})
		}
		this.updateProfile();
	}
	
	updateProfile() {
		this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).update(this.profile)
		.catch(error => {
			if (error.code === "not-found") {
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).set(this.profile)
			}
		})

		this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("public").doc("info").update(this.profile)
		.catch(error => {
			if (error.code === "not-found") {
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("public").doc("info").set(this.profile)
			}
		});
	}

	async createFriendRequest(requestUserId, requestMessage) {
		if (requestUserId && requestUserId != "") {

			let myName = "Unknwon";
			if (this.profile) { if (this.profile.n) { myName = this.profile.n; } }

			this.fireStore.collection("cugc").doc("authors").collection("authors").doc(requestUserId).collection("public").doc("info").ref.get().then(async friendDoc => {
				if (friendDoc.exists) {
					let nameOfOther = friendDoc.data().n;

					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(requestUserId).collection("friends").doc(this.uid).ref.get().then(doc => {
						if (!doc.exists) {
							this.fireStore.collection("cugc").doc("authors").collection("authors").doc(requestUserId).collection("friendrequests").doc(this.uid).set({
								acc: false,
								dec: false,
								in: true,
								n: myName,
								m: requestMessage
							})
						}
					})
		
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friends").doc(requestUserId).ref.get().then(doc => {
						if (!doc.exists) {
							this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friendrequests").doc(requestUserId).set({
								acc: false,
								dec: false,
								in: false,
								n: nameOfOther,
								m: requestMessage
							})
						}
					})

					const alert = await this.alertController.create({
						header: "Neue",
						subHeader: "Freundschaftsanfrage",
						cssClass: 'eon__alert',
						message: "Eine Anfrage wurde verschickt.",
						buttons: [
							{ 
								text: 'Ok', handler: () => {}
							}
						]
					})
					await alert.present();	

				} else {
					const alert = await this.alertController.create({
						header: "Anfrage",
						subHeader: "Fehlgeschlagen",
						cssClass: 'eon__alert',
						message: "Bitte gib eine korrekte Author-ID ein.",
						buttons: [
							{ 
								text: 'Ok', handler: () => {}
							}
						]
					})
					await alert.present();						
				}
			})			
		}		
	}

	deleteFriendrequest(request) {
		this.fireStore.collection("cugc").doc("authors").collection("authors").doc(request.id).collection("friendrequests").doc(this.uid).delete().then(() => {}).catch(() => {});
		this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friendrequests").doc(request.id).delete().then(() => {}).catch(() => {});
	}

	async acceptFriendrequest(request) {
		this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.profile.id).collection("friends").doc(request.id).set({
			id: request.id
		}).then(() => {
			this.fireStore.collection("cugc").doc("authors").collection("authors").doc(request.id).collection("friends").doc(this.profile.id).set({
				id: this.profile.id
			}).then(async () => {
				const alert = await this.alertController.create({
					header: "Neue",
					subHeader: "Freundschaftsanfrage",
					cssClass: 'eon__alert',
					message: "Anfrage angenommen.",
					buttons: [
						{ 
							text: 'Ok', handler: () => {}
						}
					]
				})
				await alert.present();
				
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.profile.id).collection("friendrequests").doc(request.id).delete();
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(request.id).collection("friendrequests").doc(this.profile.id).delete();
			})
		})	
	}

	async removeFriend(friend) {
		let name = "Friend";
		if (friend.n) { name = friend.n; }

		const alert = await this.alertController.create({
			header: "Entfernen?",
			subHeader: name,
			cssClass: "eon__alert",
			message: "Möchtest du die Freundschaft wirklich beenden?",
			buttons: [
				{ text: "Abbrechen", handler: () => {}},
				{ text: "Freundschaft beenden", handler: () => {
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friends").doc(friend.id).delete().then(() => {}).catch(() => {});
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(friend.id).collection("friends").doc(this.uid).delete().then(() => {}).catch(() => {});
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.uid).collection("friendrequests").doc(friend.id).delete().then(() => {}).catch(() => {});
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(friend.id).collection("friendrequests").doc(this.uid).delete().then(() => {}).catch(() => {});
				} }
			]
		})
		alert.present();
	}

	async deleteCollection(collectionId) {
		console.log("DELETE COLLECTION " + collectionId);

		this.fireStore.collection("cugc").doc("collections").collection("collections").doc(collectionId).collection("projects").ref.get().then(snapshot => {
			snapshot.forEach(doc => {
				console.log("PROJECT", doc.id);
				this.fireStore.collection("cugc").doc("projects").collection("projects").doc(doc.id).collection("collections").doc(collectionId).delete();
				this.fireStore.collection("cugc").doc("catalogue").collection("catalogue").doc(doc.id).collection("collections").doc(collectionId).delete();
			})
		}).then(() => {
			this.fireStore.collection("cugc").doc("collections").collection("collections").doc(collectionId).collection("admins").ref.get().then(snapshot => {
				snapshot.forEach(doc => {
					this.fireStore.collection("cugc").doc("authors").collection("authors").doc(doc.id).collection("collections").doc(collectionId).delete();
				})
			}).then(() => {
				this.fireStore.collection("cugc").doc("collections").collection("collections").doc(collectionId).delete().then(() => {

				}).catch((error3) => { console.log("ERROR 3", error3); })
			}).catch((error2) => { console.log("ERROR 2", error2); })
		}).catch((error1) => { console.log("ERROR 1", error1); })


	}

}