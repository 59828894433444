import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine/ngx';
import { DeviceService } from './device.service';

import { DataService } from '../system/data.service'
import { AudioService } from './audio.service';

@Injectable({
  providedIn: 'root'
})
export class VibrationService {

	constructor(
		private data: DataService,
		private platform: Platform,
		private vibration: Vibration,
		private taptic: TapticEngine,
		private audio: AudioService,
		private device: DeviceService
	) {}
	  
	async vibrate(ms) {
		await this.data.getVariable("stt_vibration");
		if (this.data.vars["stt_vibration"]) {
			if (this.platform.is("capacitor") || this.platform.is("cordova") || this.platform.is("android") || this.platform.is("ios")) {
				if (!this.data.simstats_simulationOn) {
					this.vibration.vibrate(ms);
				}
			} else {
				if (window.navigator.vibrate) { window.navigator.vibrate(ms); }
			}
		}
	}

	notification(type) { // success | warning | error
		this.taptic.notification({ type: type });
	}

	async impact(style) { // light | medium | heavy
		/*
		if (this.platform.is("capacitor") || this.platform.is("cordova") || this.platform.is("android") || this.platform.is("ios")) {
			this.taptic.impact({ style: style }); 
		} else { 
			if (window.navigator.vibrate) { window.navigator.vibrate(100); }
		}
		*/
	}

	gestureSelection(mode) { // start | changed | end
		if (mode === "start") {
			this.taptic.gestureSelectionStart();
		} else if (mode === "changed") {
			this.taptic.gestureSelectionChanged();
		} else {
			this.taptic.gestureSelectionEnd();
		}
	}

	touchstart() {
		if (this.device.platform === "ios") {
			this.impact("heavy");
		} else {
			this.vibrate(100);
		}		
	}

	touchend() {
		if (this.device.platform === "ios") {
			this.impact("light");
		} else {
			this.vibrate(100);
		}			
	}
}
