import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VibrationService } from 'src/app/system/vibration.service';

@Component({
  	selector: 'eon-lasercut-button',
  	templateUrl: './lasercut-button.component.html',
  	styleUrls: ['./lasercut-button.component.scss'],
})
export class LasercutButtonComponent implements OnInit {
	@Input('title') title: string;
	@Input('icon') icon: string;
	@Input('pic') pic: string;
	@Input('chevron') chevron: boolean;
	@Input('size') size: string;
	@Input('small') small: boolean = false;
	@Input('space') space: boolean = false;
	@Input('left') left: boolean = false;
	@Input('avatar') avatar: boolean = false;
	@Input('logo') logo: boolean = false;
	@Input('autoHeight') autoHeight: boolean = false;
	@Input('flat') flat: boolean = false;

	@Output() eonClick = new EventEmitter<any>();

	titleSize: number = 7;

  	constructor(
		public vibration: VibrationService
	) {}

  	ngOnInit() {
		if (!this.icon) { this.titleSize = this.titleSize + 3; }
		if (!this.chevron) { this.titleSize = this.titleSize + 2; }		  
	}

}