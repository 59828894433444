import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  	selector: 'app-radio',
  	templateUrl: './radio.page.html',
  	styleUrls: ['./radio.page.scss'],
})
export class RadioPage implements OnInit {
	@Input("heading") heading: string = "";
	@Input("pauseFunction") pauseFunction: any = null;
	@Input("playing") playing: any = null;
	@Input("playFunction") playFunction: any = null;

  	constructor(
		private modalController: ModalController
	) {}

  	ngOnInit() {}

	async toggle() {
		if (await this.playing()) {
			this.pauseFunction();
		} else {
			this.playFunction();
		}
	}

	close() {
		this.pauseFunction();
		this.modalController.dismiss();
	}

}
