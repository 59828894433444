import { ModalController, IonContent, DomController, NavController } from '@ionic/angular'
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import { LocalizationService } from '../system/localization.service'
import { MetaService } from '../cloud/meta.service'
import { FilterService } from '../system/filter.service'
import { NavigationService } from '../system/navigation.service'
import { EngineService } from '../system/engine.service'
import { DataService } from '../system/data.service'
import { ModalsService } from '../system/modals.service';
import { BootService } from '../system/boot.service';
import { InstanceService } from '../system/instance.service';
import { StorageService } from '../system/storage.service';
import { VariasService } from '../system/varias.service';

@Component({
  	selector: 'app-search',
  	templateUrl: './search.page.html',
  	styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	@Input("asModal") asModal: boolean = false;

	allEchoes: Array<any> = []; filteredEchoes: Array<any> = []; searchResultButtonsEchoes: Array<any> = []; searchEchoesLoading: boolean = false;
	allEpisodes: Array<any> = []; filteredEpisodes: Array<any> = []; searchResultButtonsEpisodes: Array<any> = []; searchEpisodesLoading: boolean = false;
	allContacts: Array<any> = []; filteredContacts: Array<any> = []; searchResultButtonsContacts: Array<any> = []; searchContactsLoading: boolean = false;
	allLinks: Array<any> = []; filteredLinks: Array<any> = []; searchResultButtonsLinks: Array<any> = [];
	allSets: Array<any> = []; filteredSets: Array<any> = []; searchResultButtonsSets: Array<any> = []; searchSetsLoading: boolean = false;
	searchTerm: string = "";

	hasCreateMode: boolean = false;

  	constructor(
		private boot: BootService,
		public localization: LocalizationService,
		private meta: MetaService,
		private filter: FilterService,
		private storage: Storage,
		private storageSrv: StorageService,
		private navigation: NavigationService,
		private engine: EngineService,
		private modalController: ModalController,
		private domController: DomController,
		private renderer: Renderer2,
		public data: DataService,
		private navController: NavController,
		private modals: ModalsService,
		public instance: InstanceService,
		public varias: VariasService
	) { }

	ngOnInit() {
		this.initPage();
	}

	async initPage() {
		this.hasCreateMode = await this.varias.getVariable("conf_hasCreateMode");
	}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.scrollableChecked) {
			this.scrollableChecked = true;
			await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
		}
		if (this.scrollable) {
			let height = 50 - event.detail.scrollTop;
			let showSmallTitle = false;
			if (height <= 0) { height = 0; showSmallTitle = true; } 
			else if (height > 50) { height = 50; }
			this.domController.write(() => {
				this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
				if (showSmallTitle) {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
					this.headerCollapsed = true;
				} else {
					this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
					this.headerCollapsed = false;
				}
			})
		}
	}	

	searching: boolean = false;
	focusSearchbar() { 
		this.searching = true;
	}
	blurSearchbar() {
		this.searching = false;
	}

	async searchEchoes(event) {
		if (this.allEchoes.length < 1 && !this.searchEchoesLoading) {
			this.searchEchoesLoading = true;
			await this.meta.getSets().then(sets => {
				for (let set of sets) {
					for (let echo of set.e) { 
						if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
							this.allEchoes.push(echo);
						}
					}
				}
			})

			this.searchEchoesLoading = false;
		}
		if (event.length > 2) {
			let filteredEchoes = await this.filter.filterName(this.allEchoes, event);
			if (filteredEchoes.length > 0 && filteredEchoes.length < 10) { this.filteredEchoes = filteredEchoes; } else { this.filteredEchoes = []; }
		} else { this.filteredEchoes = []; }
		let searchResultButtonsEchoes = [];
		for (let echo of this.filteredEchoes) {
			searchResultButtonsEchoes.push(echo)
		}
		this.searchResultButtonsEchoes = searchResultButtonsEchoes;
	}

	async searchEpisodes(event) {
		if (this.allEpisodes.length < 1 && !this.searchEpisodesLoading) {
			this.searchEpisodesLoading = true;
			await this.meta.getEpisodes().then(serials => {
				this.allEpisodes = [];
				for (let serial of serials) {
					for (let episode of serial.ep) { 
						episode.serialId = serial.i;
						if (this.instance.instance === "voidapp") {
							this.allEpisodes.push(episode);
						} else if (this.instance.instance === "prllxapp") {
							if (episode.serialId === "pa") {
								this.allEpisodes.push(episode);
							}
						} else if (this.instance.instance === "gamerapp") {
							if (episode.serialId === "ga") {
								this.allEpisodes.push(episode);
							}
						}
					}
				}
			})
			this.searchEpisodesLoading = false;
		}
		if (this.searchTerm.length > 2) {
			let filteredEpisodes = await this.filter.filterTitle(this.allEpisodes, this.searchTerm);
			if (filteredEpisodes.length > 0 && filteredEpisodes.length < 10) { this.filteredEpisodes = filteredEpisodes; } else { this.filteredEpisodes = []; }
		} else { this.filteredEpisodes = []; }
		let searchResultButtonsEpisodes = [];
		for (let episode of this.filteredEpisodes) {
			let seriesTitle = "";
			if (episode.serialId === "pa") { seriesTitle = "(David)"; }
			else if (episode.serialId === "ga") { seriesTitle = "(Gamer)"; }
			searchResultButtonsEpisodes.push({ ...episode, seriesTitle: seriesTitle })
		}
		this.searchResultButtonsEpisodes = searchResultButtonsEpisodes;
	}

	async searchContacts(event) {
		if (this.allContacts.length < 1 && !this.searchContactsLoading) {
			this.searchContactsLoading = true;
			let keys = []; await this.storage.keys().then(storedKeys => { keys = storedKeys; })
			let contactsStored = [];
			for await (let key of keys) {
				if (key.indexOf("var_cnt_") > -1) {
					let contactId = key.replace("var_cnt_", "");
					if (
						contactId != "avaalt" &&
						contactId != "obialt" &&
						contactId != "raidioalt" &&
						contactId != "shenalt" &&
						contactId != "slayalt" &&
						contactId != "wombatalt" &&
						contactId != "avapix" &&
						contactId != "obipix" &&
						contactId != "raidiopix" &&
						contactId != "shenpix" &&
						contactId != "slaypix" &&
						contactId != "wombatpix" &&					
						contactId.indexOf("_alt") < 0 &&
						contactId.indexOf("_pix") < 0
					) {
						contactsStored.push({i: contactId});
					}
				}
			}
			let contactsDirectory; await this.meta.getContacts().then(contacts => contactsDirectory = contacts);
			for await (let contact of contactsStored) {
				for await (let contactInDirectory of contactsDirectory) {
					if (contact.i === contactInDirectory.i) {
						contact.n = contactInDirectory.n;
						contact.im = contactInDirectory.im;
						contact.matched = true;
						this.allContacts.push(contact);
					}
				}
			}
			this.searchContactsLoading = false;
		}
		if (this.searchTerm.length > 2) {
			let filteredContacts = await this.filter.filterName(this.allContacts, this.searchTerm);
			if (filteredContacts.length > 0 && filteredContacts.length < 10) { this.filteredContacts = filteredContacts } else { this.filteredContacts = []; }
		} else { this.filteredContacts = []; }
		let searchResultButtonsContacts = [];
		for (let contact of this.filteredContacts) {
			searchResultButtonsContacts.push(contact)
		}
		this.searchResultButtonsContacts = searchResultButtonsContacts;
	}

	async searchLinks(event) {
		if (this.allLinks.length < 1) {
			this.allLinks = [
				{ id: "contacts", t: this.localization.menu_contacts, icon: "address-card" },
				{ id: "glossary", t: this.localization.menu_glossary, icon: "lightbulb" },
				{ id: "backstage", t: "Blog", icon: "newspaper" },
				{ id: "helpi", t: "Support", icon: "paw" },
				{ id: "helpi", t: this.localization.menu_help, icon: "paw" },
				{ id: "helpi", t: this.localization.menu_contact, icon: "paw" },
				{ id: "settings", t: this.localization.menu_settings, icon: "cog" }
			]
		}
		if (this.searchTerm.length > 2) {
			let filteredLinks = await this.filter.filterTitle(this.allLinks, this.searchTerm);
			if (filteredLinks.length > 0 && filteredLinks.length < 10) { this.filteredLinks = filteredLinks } else { this.filteredLinks = []; }
		} else { this.filteredLinks = []; }
		let searchResultButtonsLinks = [];
		for (let link of this.filteredLinks) {
			searchResultButtonsLinks.push(link)
		}
		this.searchResultButtonsLinks = searchResultButtonsLinks;
	}

	async searchSets(event) {
		if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
			if (this.allSets.length < 1 && !this.searchSetsLoading) {
				this.searchSetsLoading = true;
				await this.meta.getSets().then(sets => {
					this.allSets = sets;
				})
			}
			this.searchSetsLoading = false;
			if (this.searchTerm.length > 2) {
				let filteredSets = await this.filter.filterName(this.allSets, this.searchTerm);
				if (filteredSets.length > 0 && filteredSets.length < 10) { this.filteredSets = filteredSets; } else { this.filteredSets = []; }
			} else { this.filteredSets = []; }
			let searchResultButtonsSets = [];
			for (let set of this.filteredSets) {
				searchResultButtonsSets.push(set);
			}
			this.searchResultButtonsSets = searchResultButtonsSets;
		}
	}	
	  
	async searchChanged(event) {
		this.searchTerm = event;
		this.searchEpisodes(event);
		this.searchEchoes(event);
		this.searchContacts(event);
		this.searchLinks(event);
		this.searchSets(event);
	}

	handleSearchResultButtonClick(event) {
		if (event.id === "set") {
			this.modals.pushSet(event.data.setId);
		} else if (event.id === "echo") {
			this.modals.pushSetByEchoId(event.data.contentId);
		} else if (event.id === "contact") {
			this.navigation.pushContact(event.data.contact);
		}
	}

	pushEpisode(episode) { this.modals.pushSeason(episode.serialId, episode.s, episode.e); }
	pushSet(set) { this.modals.pushSet(set.i); }
	pushEcho(echo) { this.modals.pushSetByEchoId(echo.i); }
	pushContact(contact) { this.navigation.pushContact(contact); }
	pushLink(link) {
		if (link.id === "contacts") { this.navigation.pushContacts(); }
		else if ( link.id === "glossary") { this.navigation.pushGlossary(); }
		else if ( link.id === "backstage") { this.navigation.pushBlog(); }
		else if ( link.id === "helpi") { this.navigation.pushHelpi(); }
		else if ( link.id === "settings") { this.navigation.pushSettings(); }
	}	
	pushCatalogue() { this.navigation.pushCUGC_catalogue("all"); }

	close() {
		if (this.asModal) { this.modalController.dismiss(false); }
		else { this.navigation.pop(); }
	}

	scrollToTop() { this.ionContent.scrollToTop(400); }

	pushCUGC_search() { this.navigation.pushCUGC_catalogue(); }

}