import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { DeviceService } from './device.service';
import { UtilityService } from '../system/utility.service'
import { Events } from '../system/events.service'
import { LocalizationService } from './localization.service';
import { DataService } from '../system/data.service'
import { InstanceService } from './instance.service';
import { MolertsService } from './molerts.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
	muted: boolean = true;

	currentTrack: string = "";
	sounds: Array<MediaObject> = [];
	backgroundMusic: MediaObject;
	sound_receiveMessage: MediaObject;
	sound: MediaObject;

	musicPlaying: boolean = false;
	forceStop: boolean = false;
	funnel_forceStop: any;
	musicObject: HTMLAudioElement = null;
	musicObject2: HTMLAudioElement = null;
	soundObject_receiveMessage: HTMLAudioElement = null;
	hasPrompted: number = 0;
	isPrompting: boolean = false;
	canPromptAgain: boolean = true;
	promptAfterBackground: boolean = false;
	inBackground: boolean = false;

	radio: HTMLAudioElement = null;
	radioMessages: Array<any> = [];
	currentRadioMessageId: string = "null";
	radioPaused: boolean = false;
	radioPlaying: boolean = false;

	pausedAt: number = 0;

	musicPausedByRadio: boolean = false;

	constructor(
		private data: DataService,
		private media: Media,
		private platform: Platform,
		private utility: UtilityService,
		private device: DeviceService,
		private events: Events,
		private toastController: ToastController,
		private localization: LocalizationService,
		private http: HttpClient,
		private file: File,
		private instance: InstanceService,
		private molerts: MolertsService
	) {
		this.autostartAudio();
		this.addPauseResumeListener();
	}	

	// ################################################################################
	// #### S E T U P #################################################################
	// ################################################################################	

	async autostartAudio() {
		if (this.instance.nativeAudio) {
			this.muted = false;
			this.setupSounds();
			await this.data.getVariable("stt_music");
			let track = "wasteland1"; await this.data.getVariable("stt_track").then(result => { if (result) { track = result; } });
			if (this.data.vars["stt_music"]) {
				this.playMusic(this.data.vars["stt_track"]);
			}
		} else {
			this.muted = true;
			setTimeout(() => {
				this.promptMusicAndSound("autostartAudio");
			}, 2000);
		}
	}

	async promptMusicAndSound(trigger: string = "unknown") {

		if (this.instance.instance === "prevapp" && this.instance.variant === "prllx") { return; }

		if (((this.hasPrompted < 3 && this.canPromptAgain) || this.promptAfterBackground) && !this.isPrompting) {

			let duration = 10000;
			if (this.instance.instance === "demoapp") { duration = 300000; }

			/*
			this.molerts.toast_music(duration, () => {
				this.hasPrompted = 3;
				this.permitMusicAndSound();
			}, () => {
				this.isPrompting = false;
				setTimeout(() => {
					this.canPromptAgain = true;
				}, 300000);
			});

			this.isPrompting = true;
			this.canPromptAgain = false;
			*/
			
			const toast = await this.toastController.create({
				animated: true,
				cssClass: 'EON__TOAST__MUSIC',
				header: this.localization.bestWithMusic,
				position: 'top',
				duration: duration,
				buttons: [
					{
						side: "start",
						icon: "close-outline",
						handler: () => { this.hasPrompted = 3; }
					},{
						side: 'end',
						text: this.localization.turnOn,
						handler: () => { 
							this.hasPrompted = 3;
							this.permitMusicAndSound();
						}
				  	}
				]
			});
			await toast.present();
			this.isPrompting = true;
			this.canPromptAgain = false;

			toast.onDidDismiss().then(() => { 
				this.isPrompting = false;
				setTimeout(() => { 
					this.canPromptAgain = true;
				}, 300000);
			})

			this.hasPrompted++;
		}
	}

	async permitMusicAndSound() {
		this.muted = false;
		this.data.setVariable("stt_music", true);
		let track = "signature"; await this.data.getVariable("stt_track").then(result => track = result);
		if (this.musicObject) {
			this.musicObject.pause();
			this.musicObject = null;
		}
		this.musicObject = new Audio("https://extraordinerdy.b-cdn.net/music/"+track+".mp3");
		if (!this.inBackground) {
			this.musicObject.play().catch(err => { this.handleError(err); });
			this.musicPlaying = true;
		}
		this.musicObject.addEventListener("ended", ev => { 
			this.musicObject.play().catch(err => { this.handleError(err); }) 
			this.musicPlaying = true;
		})
	}

	async addPauseResumeListener() {
		if (this.instance.nativeAudio) {
			document.addEventListener("pause", () => { this.pauseAudio(); }, false);
			document.addEventListener("resume", () => { this.resumeAudio(); }, false);
		} else {
			document.addEventListener("visibilitychange", (ev) => {
				if (document.hidden) { this.inBackground = true; this.pauseAudio(); } 
				else { this.inBackground = false; this.resumeAudio(); }
			})
		}
	}

	async pauseAudio() {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			if (this.backgroundMusic) { 
				this.backgroundMusic.pause();
				this.musicPlaying = false;
			}
		} else {
			if (this.musicObject) { 
				this.musicObject.pause();
				this.musicPlaying = false;
			}
		}
		if (this.radio) { this.radio.pause(); }

		this.pausedAt = Date.now();
		this.promptAfterBackground = true;
	}

	async resumeAudio() {
		let now = Date.now();
		let diff = now - this.pausedAt;
		if (diff > 30000) {
			this.muted = true;
			await this.promptMusicAndSound("resume");
		} else {
			await this.data.getVariable("stt_music");
			if (this.data.vars["stt_music"]) {
				await this.device.getPlatform();
				if (this.instance.nativeAudio) {
					if (this.backgroundMusic) { 
						this.backgroundMusic.play({ numberOfLoops: 10, playAudioWhenScreenIsLocked : false }); 
						this.musicPlaying = true;
					}
				} else {
					if (this.musicObject && !this.muted) { 
						this.musicObject.play().catch(err => { this.handleError(err); }) 
						this.musicPlaying = true;
					}
				}
			}
			if (this.radio && !this.radioPaused) { this.radio.play(); }
		}

		this.promptAfterBackground = false;
	}

	async unmuteMusicAndOrSound() {
		await this.data.getVariable("stt_music");
		await this.data.getVariable("stt_sound");
		if (!this.data.vars["stt_music"] && !this.data.vars["stt_sound"]) {
			await this.data.setVariable("stt_music", true);
			await this.data.setVariable("stt_sound", true);
		}
		this.muted = false;
		this.startMusic();
	}

	async muteMusicAndSound() {
		this.muted = true;
		this.stopMusic();
	}

	async activateMusic() {
		await this.data.setVariable("stt_music", true);
		this.startMusic();
	}

	// ################################################################################
	// #### M U S I C #################################################################
	// ################################################################################

	async playMusic(track) {
		
		this.setCurrentMusicTrack(track);
		this.musicPlaying = true;

		await this.device.getPlatform();
		if (this.instance.nativeAudio) {

			await this.tuneDownMusic();
			this.backgroundMusic = this.media.create("https://extraordinerdy.b-cdn.net/music/"+track+".mp3");
			this.backgroundMusic.setVolume(0);
			this.tuneUpMusic();
			
		} else {
			if (this.muted) { return; }
			if (this.musicObject) {
				await this.tuneDownMusic();
				await this.musicObject.pause();
				await this.utility.sleep(400);
				this.musicObject.src = "https://extraordinerdy.b-cdn.net/music/"+track+".mp3";
				if (!this.inBackground && !this.musicPausedByRadio) {
					this.musicObject.play().catch(err => { this.handleError(err); })
				}
				this.tuneUpMusic();
			} else {
				this.musicObject = new Audio("https://extraordinerdy.b-cdn.net/music/"+track+".mp3");
				if (!this.inBackground && !this.musicPausedByRadio) {
					this.musicObject.play().catch(err => { this.handleError(err); }) 
				}
				this.musicObject.addEventListener("ended", ev => {
					this.musicObject.play().catch(err => { this.handleError(err); }) 
				})
			}
		}

	}

	async tuneDownMusic() {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			if (this.backgroundMusic) {
				if (!this.radioPlaying) {
					this.backgroundMusic.setVolume(1); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.8); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.6); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.4); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.2); await this.utility.sleep(100);
				}
				this.backgroundMusic.setVolume(0);
			}			
		} else {
			if (this.musicObject) {
				if (!this.radioPlaying) {
					this.musicObject.volume = 1; await this.utility.sleep(100);
					this.musicObject.volume = 0.8; await this.utility.sleep(100);
					this.musicObject.volume = 0.6; await this.utility.sleep(100);
					this.musicObject.volume = 0.4; await this.utility.sleep(100);
					this.musicObject.volume = 0.2; await this.utility.sleep(100);
				}
				this.musicObject.volume = 0; await this.utility.sleep(100);
			}
		}
		return;
	}

	statusUpdateListener: any = null;
	async tuneUpMusic() {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			if (this.backgroundMusic) {
				this.backgroundMusic.setVolume(0); await this.utility.sleep(100);
				if (!this.inBackground) {
					this.backgroundMusic.play({ numberOfLoops: 10, playAudioWhenScreenIsLocked : false });
				}
				this.statusUpdateListener = this.backgroundMusic.onStatusUpdate.subscribe(status => {
					if (status === 4) {
						if (!this.inBackground) {
							if (!this.forceStop) {
								this.backgroundMusic.play({ numberOfLoops: 10, playAudioWhenScreenIsLocked : false });
							}
						}
					}
				});
				this.backgroundMusic.setVolume(0.2); await this.utility.sleep(100);
				if (!this.radioPlaying) {
					this.backgroundMusic.setVolume(0.4); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.6); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(0.8); await this.utility.sleep(100);
					this.backgroundMusic.setVolume(1);
				}
			}
		} else {
			if (this.musicObject) {
				this.musicObject.volume = 0; await this.utility.sleep(100);
				this.musicObject.volume = 0.2; await this.utility.sleep(100);
				if (!this.radioPlaying) {
					this.musicObject.volume = 0.4; await this.utility.sleep(100);
					this.musicObject.volume = 0.6; await this.utility.sleep(100);
					this.musicObject.volume = 0.8; await this.utility.sleep(100);
					this.musicObject.volume = 1; await this.utility.sleep(100);
				}
			}			
		}
		return;
	}

	dimmMusicValue: number = 1;
	async dimmMusic(value) {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			if (this.backgroundMusic) {
				this.backgroundMusic.setVolume(value);
			}
		} else {
			if (this.musicObject) {
				if (this.device.platform === "ios") {
					if (value >= 1) {
						if (this.musicPlaying) { this.musicObject.play(); }
						this.musicPausedByRadio = false;
					} else {
						this.musicObject.pause();
						this.musicPausedByRadio = true;
					}
				} else {
					this.musicObject.volume = value;
				}
			}
		}
		return;
	}

	setCurrentMusicTrack(track) {
		this.currentTrack = track;
		this.data.setVariable("stt_track", track);
	}

	async switchMusic(track) {
		await this.data.getVariable("stt_music");
		if (this.data.vars["stt_music"]) {
			if (track != this.currentTrack) {
				this.playMusic(track);
			}
		}
		return;
	}

	async startMusic() {
		await this.data.getVariable("stt_music");
		if (this.data.vars["stt_music"]) {
			if (this.currentTrack === "" || this.currentTrack == null || this.currentTrack == undefined) { this.currentTrack = "wasteland1"; }
			this.playMusic(this.currentTrack);
		}
		return;
	}	

	async stopMusic() {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			this.forceStop = true;
			if (this.backgroundMusic) { 
				this.backgroundMusic.stop();
				this.backgroundMusic.release();
				this.musicPlaying = false;
			}
			this.resolveForceStop();
		} else {
			if (this.musicObject) { 
				this.musicObject.pause(); 
				this.musicPlaying = false;
			}
		}
	}
	resolveForceStop() {
		var that = this;
		clearTimeout(this.funnel_forceStop);
		this.funnel_forceStop = setTimeout(function() {
			that.forceStop = false;
		}, 3000);
	}

	async handleError(error) {
		this.promptMusicAndSound("error");
	}

	// ################################################################################
	// #### E D I T O R ###############################################################
	// ################################################################################

	tryEditorTrack(track) {
		if (this.musicObject2) {
			this.musicObject2.pause();
			this.musicObject2.src = "https://extraordinerdy.b-cdn.net/music/"+track+".mp3";
		} else {
			this.musicObject2 = new Audio("https://extraordinerdy.b-cdn.net/music/"+track+".mp3");
		}
		this.musicObject2.play();
	}

	stopEditorTrack() {
		this.musicObject2.pause();
	}

	// ################################################################################
	// #### S O U N D #################################################################
	// ################################################################################

	async setupSounds() {
		await this.device.getPlatform();
		if (this.instance.nativeAudio) {
			if (this.device.platform === "iOS") {
				this.sound_receiveMessage = this.media.create("assets/sounds/receiveMessage.mp3");
			} else {
				this.sound_receiveMessage = this.media.create("/android_asset/www/assets/sounds/receiveMessage.mp3");
			}
			this.sound_receiveMessage.setVolume(1);
		}
	}

	async playSound(thisSound) {
		if (!this.inBackground) {
			await this.data.getVariable("stt_sound");
			if (this.data.vars["stt_sound"]) {
				await this.device.getPlatform();
				if (this.instance.nativeAudio) {
					if (this.device.platform != "ios" || !this.musicPlaying) {
						if (thisSound === "receiveMessage") {
							if (this.sound_receiveMessage) {
								this.sound_receiveMessage.setVolume(1);
								this.sound_receiveMessage.play({ numberOfLoops: 1, playAudioWhenScreenIsLocked: false });
							} else {
								this.sound = this.media.create("x");
								this.sound.setVolume(1);
								this.sound.play({ numberOfLoops: 10, playAudioWhenScreenIsLocked: false });
							}
						} else {
						}
					}
				} else {
					if (this.muted) { return; }
					if (thisSound === "receiveMessage") {
						if (!this.soundObject_receiveMessage) {
							this.soundObject_receiveMessage = new Audio("https://extraordinerdy.b-cdn.net/sounds/"+thisSound+".mp3");
							if (!this.inBackground) {
								this.soundObject_receiveMessage.play().catch(err => {
									this.promptMusicAndSound("playSound");
								});
							}
						} else {
							if (!this.inBackground) {
								this.soundObject_receiveMessage.play().catch(err => { 
									this.promptMusicAndSound("receiveMessage");
								});
							}
						}
					} else {
						let soundObject = new Audio("https://extraordinerdy.b-cdn.net/sounds/"+thisSound+".mp3");
						if (!this.inBackground) {
							soundObject.play().catch(err => {});
						}
					}
				}
			}
		}
		return;
	}

	// ################################################################################
	// #### R A D I O #################################################################
	// ################################################################################

	radioLangCodes = ["DE","EN","FR","ES","PT"];
	getRadioLangCode() {
		// check if this.localization.lang is in radioLangCodes
		if (this.radioLangCodes.indexOf(this.localization.lang) > -1) {
			return this.localization.lang;
		} else {
			return "EN";
		}
	}

	onRadioLoaded(messageId) {
		if (!this.radioMessages[messageId]) { this.radioMessages[messageId] = {}; }
		if (this.radioMessages[messageId]) {
			this.radioMessages[messageId].loaded = true;
			this.radioMessages[messageId].loading = false;
			if (this.radioMessages[messageId].willPlay && !this.radioMessages[messageId].paused) { 
				this.radio.play();
			}
		}
	}

	onRadioPlay(messageId) {
		if (!this.radioMessages[messageId]) { this.radioMessages[messageId] = {}; }
		if (this.radioMessages[messageId]) {
			this.radioMessages[messageId].playing = true;
			this.radioMessages[messageId].paused = false;
			this.radioMessages[messageId].willPlay = false;
			//this.dimmMusic(0.2);
			this.dimmMusic(0);
			this.dimmUpAfterSwitch = false;
			this.radioPlaying = true;
		}
	}

	onRadioPaused(messageId) {
		if (!this.radioMessages[messageId]) { this.radioMessages[messageId] = {}; }
		if (this.radioMessages[messageId]) {
			this.radioMessages[messageId].playing = false;
			this.radioMessages[messageId].paused = true;
			this.radioMessages[messageId].willPlay = false;
			if (!this.blockDimmUpAtPause) {
				this.dimmMusic(1);
			}
			this.radioPlaying = false;
		}
	}

	onRadioTimeUpdate(messageId) {
		if (!this.radioMessages[messageId]) { this.radioMessages[messageId] = {}; }
		if (this.radioMessages[messageId]) {
			if (this.radio.duration > 0 && this.radio.currentTime > 0) {
				this.radioMessages[messageId].progress = Math.ceil((this.radio.currentTime / this.radio.duration) * 100);
			} else {
				this.radioMessages[messageId].progress = 0;
			}
		}
	}

	onRadioEnded(messageId) {
		if (!this.radioMessages[messageId]) { this.radioMessages[messageId] = {}; }
		if (this.radioMessages[messageId]) {
			this.radioMessages[messageId].playing = false;
			this.radioMessages[messageId].paused = false;
			this.radioMessages[messageId].willPlay = false;
			this.radioMessages[messageId].ended = true;
			this.dimmMusic(1);
			this.molerts.dismiss_radio(messageId);
			this.radioPlaying = false;
		}
	}

	blockDimmUpAtPause: boolean = false;
	dimmUpAfterSwitch: boolean = false;
	async playRadio(message) {
		
		this.radioPaused = false;

		let radioLangCode = this.getRadioLangCode();
		if (this.radio && this.radio.currentSrc === "https://extraordinerdy.b-cdn.net/audio/"+message.audId+"_"+radioLangCode+".mp3") {
			this.toastRadio(message);
			this.radio.play();
			this.data.setVariable("aud_"+message.audId, true);
			return;
		}

		if (!this.radioMessages[message.i]) { this.radioMessages[message.i] = {}; }

		if (this.radio && this.radio.currentSrc != "https://extraordinerdy.b-cdn.net/audio/"+message.audId+"_"+radioLangCode+".mp3") {
			this.blockDimmUpAtPause = true;
			setTimeout(() => { this.blockDimmUpAtPause = false; }, 1000);
			this.dimmUpAfterSwitch = true;
			setTimeout(() => {
				if (this.dimmUpAfterSwitch) {
					this.dimmMusic(1);
					this.dimmUpAfterSwitch = false;
				}
			}, 2000);

			this.radio.pause();
			this.radio = null;

			this.radioMessages[this.currentRadioMessageId].loaded = false;
			this.radioMessages[this.currentRadioMessageId].loading = false;
			this.radioMessages[this.currentRadioMessageId].willPlay = false;
			this.radioMessages[this.currentRadioMessageId].playing = false;
			this.radioMessages[this.currentRadioMessageId].paused = false;
			this.radioMessages[this.currentRadioMessageId].ended = false;
			this.radioMessages[this.currentRadioMessageId].stopped = true;
		}

		this.radio = new Audio("https://extraordinerdy.b-cdn.net/audio/"+message.audId+"_"+radioLangCode+".mp3");

		this.radio.addEventListener("canplaythrough", this.onRadioLoaded.bind(this, message.i));
		this.radio.addEventListener("play", this.onRadioPlay.bind(this, message.i));
		this.radio.addEventListener("pause", this.onRadioPaused.bind(this, message.i));
		this.radio.addEventListener("timeupdate", this.onRadioTimeUpdate.bind(this, message.i));
		this.radio.addEventListener("ended", this.onRadioEnded.bind(this, message.i));

		this.radio.play();

		this.radioMessages[message.i].loaded = false;
		setTimeout(() => {
			if (!this.radioMessages[message.i].loaded) { this.radioMessages[message.i].loading = true; }
		}, 400)
		this.radioMessages[message.i].willPlay = true;
		this.radioMessages[message.i].playing = false;
		this.radioMessages[message.i].paused = false;
		this.radioMessages[message.i].ended = false;
		this.radioMessages[message.i].stopped = false;

		this.currentRadioMessageId = message.i;

		this.toastRadio(message);

		this.data.setVariable("aud_"+message.audId, true);
	}

	toastRadio(message) {
		this.molerts.toast_radio(message.i, message.audH, 
			() => { this.pauseRadio(false); }, 
			() => { return this.radioMessages[message.i].playing; },
			() => { this.playRadio(message); }
		);
	}

	pauseRadio(dismissToast) {
		if (this.radio) {
			this.blockDimmUpAtPause = false;
			this.radio.pause();
			this.radioPaused = true;
			if (dismissToast) { this.molerts.dismiss_radio("any"); }
		}
	}

	seekRadio(message, value) {
		let radioLangCode = this.getRadioLangCode();
		if (this.radio && this.radio.currentSrc === "https://extraordinerdy.b-cdn.net/audio/"+message.audId+"_"+radioLangCode+".mp3") {
			if (this.radio.duration > 0) {
				let newTime = (value / 100) * this.radio.duration;
				console.log("SEEKING TO: "+newTime+" ("+value+"% of "+this.radio.duration+"");
				this.radio.currentTime = (value / 100) * this.radio.duration;
			} else {
				this.radio.currentTime = 0;
			}
		}
	}

	restartRadio(message) {
		if (this.radio) {
			let radioLangCode = this.getRadioLangCode();
			if (this.radio.currentSrc === "https://extraordinerdy.b-cdn.net/audio/"+message.audId+"_"+radioLangCode+".mp3") {
				this.radio.currentTime = 0;
				return;
			}
		}
		this.playRadio(message);
	}
}