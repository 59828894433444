import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError  } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { setupConfig } from '@ionic/core';

import { DeviceService } from './device.service';
import { ThemeService } from './theme.service';
import { MetaService } from '../cloud/meta.service';
import { VariasService } from './varias.service';
import { Events } from '../system/events.service'
import { MenuService } from '../system/menu.service'
import { InstanceService } from '../system/instance.service'

@Injectable({
  providedIn: 'root'
})
export class DataService {

	currency: any = [{"tickets": 0, "nutrition": 0}];
	s: any = [{"mode": "day"}];
	sceneInView: string = "none";
	unreadMessages: number = 0;
	page_main: string = "";
	page_tabs: boolean = false;
	page_parameter: string = "";
	page_sceneId: string = "";
	page_slide: string = "";
	simstats_log: Array<string> = [];
	simstats_vars: Array<any> = [];
	simstats_simulationOn: boolean = false;

	settings_vibrate: boolean = true;
	settings_sound: boolean = true;

	ads_videosReg: Array<any> = [];
	ads_videosAvailable: number = 5;
	ads_nextVideoAt: number = undefined;

	beta_show_echoes: boolean = false;
	beta_show_simulation: boolean = false;
	beta_show_verticalFarming: boolean = false;
	beta_new_layout: boolean = false;

	iap_entitlements: any = "";
	iap_error: any = "";

	theme: string = "day";

	vars: Array<any> = [];
	userPic: any = null;

	config_ads_coolOff: number = 31;
	config_ads_limit: number = 5;
	config_multiplicator: number = 1.1;

	config_adsImplemented: boolean = true;
	config_f2pCovered: boolean = true;
	config_adsCovered: boolean = true;

	config_pauseFactor: number = 1;

	config_videoCircuitsFactor: number = 0;

	config_purrSpecial2: string = "hlp_purr_special2";
	config_purrSpecial3: string = "hlp_purr_special3";
	config_purrSpecial4: string = "hlp_purr_special4";

	innerWidth: number = 0;
	innerHeight: number = 0;

	foreground: boolean = true;

	footerTop: any = undefined;
	iconStates: Array<any> = ["passive","passive","passive","passive","passive"]

	primaryTabSwitch: any = null;
	subscribe_forceTabChange: boolean = false;

	swapCover: string = "ga1.jpg";
	swapMockup: string = "urbanGardening.png";

	activeEffectAllowed: boolean = true;
	recommendedEchoes: any = false;

	headerHeightSearchbar: number = 184;
	headerHeightNavbar: number = 186;
	headerHeightBlank: number = 184;
	headerHeightContacts: number = 177;
	headerHeightCard: number = 225;
	headerHeightModal: number = 50;
	headerHeightNone: number = 50;
	headerHeightStart: number = 184;

	sunday: boolean = true;
	freeplayAllowed: boolean = true;

	restoredPurchases: Array<any> = [];

	totalNumberOfEpisodes: number = 19;

	navigationEndCount: number = 0;

	embeddedAsDemo: boolean = false;

  	constructor(
		private events: Events,
		public router: Router,
		private storage: Storage,
		private theming: ThemeService,
		private meta: MetaService,
		private varias: VariasService,
		private platform: Platform,
		private device: DeviceService,
		private menu: MenuService,
		private instance: InstanceService
	) {
		this.s.mode = "day";
		this.theming.setTheme("day");

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd ) { 
				this.navigationEndCount++;
				this.setUrl(event.url);
			}
		});

		/*
		let resizeObserver = new ResizeObserver(() => { this.castMeasures(); });
		const body = document.getElementsByTagName("body")[0];
		resizeObserver.observe(body);
		*/
		this.castMeasures();
		window.onresize = () => { this.castMeasures };

		var that = this;
		document.addEventListener("pause", function() { that.foreground = false; }, false);
		document.addEventListener("resume", function() { that.foreground = true; }, false);

		this.platform.ready().then(async () => {
			await this.device.getPlatform();
			if (this.device.operatingSystem === "ios") {
				this.headerHeightSearchbar = this.headerHeightSearchbar + 27;
				this.headerHeightNavbar = this.headerHeightNavbar + 27;
				this.headerHeightBlank = this.headerHeightBlank + 27;
				this.headerHeightContacts = this.headerHeightContacts + 27;
				this.headerHeightCard = this.headerHeightCard + 27;
				this.headerHeightModal = this.headerHeightModal + 27;
				this.headerHeightNone = this.headerHeightNone + 27;
				this.headerHeightStart = this.headerHeightStart + 40;
			}
		});
	}

	castMeasures() {
		if (window.innerWidth < 640) { this.innerWidth = window.innerWidth; } 
		else { this.innerWidth = document.getElementById("main").offsetWidth; }
		var that = this;
		if (this.innerWidth <= 0) { setTimeout(() => { this.castMeasures(); }, 700); }
		this.innerHeight = window.innerHeight;
	}

	timeout_disableMenu: any = null;
	setUrl(url) {
		let oldPageMain = this.page_main;
		this.page_main = undefined;
		this.page_tabs = false;
		this.page_parameter = undefined;
		let urlSplitted = url.split("?")[0].split("/");
		if (urlSplitted[1] == "") {
			this.page_main = "start";
			this.page_tabs = true;
			this.page_parameter = undefined;
		}
		else if (urlSplitted[1] == "tabs") {
			this.page_main = urlSplitted[3];
			this.page_tabs = true;
		} else {
			this.page_main = urlSplitted[1];
			this.page_parameter = urlSplitted[2];
		}

		if (oldPageMain !== "list" && this.page_main == "list") {
			this.events.publish("goToList");
		}
		if (oldPageMain == "list" && this.page_main !== "list") {
			this.events.publish("leaveList");
		}
		if (this.page_main === "echo") {
			this.events.publish("goToEcho");
		}

		if (this.platform.is("mobile")) {
			clearTimeout(this.timeout_disableMenu);
			if (this.instance.instance === "demoapp") {
				this.menu.enabled = false;
			} if (this.instance.instance === "redfirapp") {
				this.menu.enabled = true;
			} else {
				if (this.page_main === "start") { this.menu.enabled = true; } 
				else { this.timeout_disableMenu = setTimeout(() => { this.menu.enabled = false; }, 1000) }
			}
		} else {
			if (this.page_main === "quest_editor") { 
				this.menu.enabled = false;
				this.menu.isFullscreen = true;
			} else {
				this.menu.enabled = true;
				this.menu.isFullscreen = false;
			}
		}

	}

	async toggleSetting(property, value) {
		await this.setVariable("stt_"+property, value);
		return;
	}

	async setVariable(key, value) {
		this.varias.setVariable(key, value);
		return;
	}

	async getVariable(key) {
		await this.varias.getVariable(key).then(result => this.vars[key] = result);
		return this.vars[key];
	}

	async checkConditions(conditions) {
		//let allGood = true; await this.varias.checkConditions(conditions).then(result => allGood = result);
		//return allGood;
		return this.varias.checkConditions(conditions);
	}

	async incrementCurrency(currency, amount) { return this.varias.incrementCurrency(currency, amount); }
	async decrementCurrency(currency, amount) { return this.varias.decrementCurrency(currency, amount); }
	async checkCurrency(currency, amount) { return this.varias.checkCurrency(currency, amount); }
	async incrementXP(amount) { return this.varias.incrementXP(amount); }

	getSwapCover() {
		if (this.swapCover === "ga1.jpg") { this.swapCover = "pa7.jpg"; }
		else if (this.swapCover === "pa7.jpg") { this.swapCover = "pa15.jpg"; }
		else if (this.swapCover === "pa15.jpg") { this.swapCover = "ga1.jpg"; }

		return this.swapCover;
	}

	getSwapMockup() {
		if (this.swapMockup === "urbanGardening.png") { this.swapMockup = "farmlands.png"; }
		else if (this.swapMockup === "farmlands.png") { this.swapMockup = "urbanGardening.png"; }
		return this.swapMockup;
	}
}
