import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular'

import { Browser } from '@capacitor/browser';
import { AppLauncher } from '@capacitor/app-launcher';

import { AngularFireAuth } from '@angular/fire/compat/auth'

import { DeviceService } from './device.service';
import { BrowserService } from '../system/browser.service';

import { LocalizationService } from '../system/localization.service';
import { InstanceService } from './instance.service';

@Injectable({
  	providedIn: 'root'
})
export class MailService {

	constructor(
		private platform: Platform,
		private fireAuth: AngularFireAuth,
		private localization: LocalizationService,
		private device: DeviceService,
		private instance: InstanceService,
		private iab: BrowserService,
	) { }
	  
	async support(hasMembership, preset) {
		let system = "App";
		await this.device.getPlatform();
		if (this.device.platform === "ios") { system = "iOS"; }
		else if (this.device.platform === "android") { system = "Android"; }
		else if (this.device.platform === "web") {
			if (this.device.operatingSystem === "ios") { system = "iOS Web"; }
			else if (this.device.operatingSystem === "android") { system = "Android Web"; }
		}


		let config = "0";
		await this.fireAuth.currentUser.then(currentUser => {
			if (currentUser) {
				config = currentUser.uid;
			}
		})

		let title = "A%20Void%20Society";
		if (this.instance.instance === "prllxapp") { title = "PARALLAX";}
		else if (this.instance.instance === "gamerapp") { title = "The%20Stray"; }
		else if (this.instance.instance === "horrorapp") { title = "Mobile%20Horror%20Stories"; }

		let mailaddress = 'support@avoidsociety.app';
		if (this.instance.instance === "prllxapp") { mailaddress = "support@prllx.app"}
		else if (this.instance.instance === "gamerapp") {}
		else if (this.instance.instance === "horrorapp") {}

		let subject = title + ' ('+system+' | '+this.localization.lang+')';
		if (hasMembership) { subject = title+' ['+system+' | '+this.localization.lang+']'; }

		let body = "";
		body = '[Version%205.0.0%20-%20'+config+']%20Hallo%20Helpi,';

		if (preset === "restore") {
			body = body+'ich%20habe%20damals%20"The Parallax"%20gespielt%20und%20war%20an%20der%20Stelle,%20wo...<br><br>Wenn%20ich%20mich%20richtig%20erinnere,%20war%20ich%20also%20in%20Episode%20...<br><br>Ich%20hatte%20noch%20...%20Tickets%20und%20...%20Nutrition.<br><br>Danke!';
		} else if (preset === "feedback") {
			body = body + "%20ich%20würde%20euch%20gerne%20Feedback%20zu%20" + title + "%20geben."
		}


		//window.open('mailto:'+mailaddress+'?subject='+title+' ('+system+')&body='+body, '_system');

		//Browser.open({ url: 'mailto:'+mailaddress+'?subject='+title+'('+system+')&body='+body, windowName: '_blank' });
		//Browser.open({ url: 'mailto://'+mailaddress, windowName: '_blank' });
		await AppLauncher.openUrl({ url: 'mailto:'+mailaddress+'?subject='+title+'%20('+system+')&body='+body, });

	}
}