import { ModalController, IonTextarea, IonInput, AlertController } from '@ionic/angular'
import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { VariasService } from 'src/app/system/varias.service';
import { DataService } from 'src/app/system/data.service';
import { BootService } from 'src/app/system/boot.service';
import { InstanceService } from 'src/app/system/instance.service';

@Component({
  	selector: 'app-contact',
  	templateUrl: './contact.page.html',
  	styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {
	@Input() contact: any;
	@ViewChild(IonInput, { static: false } ) inputField: IonInput;

	asModal: boolean = true;
	contactpics: Array<any> = [];
	memberpics: Array<any> = [];

	expanded: string = "collapsed";

	context_character: string = "";
	context_style: string = "";

  	constructor(
		private boot: BootService,
		private modalController: ModalController,
		private alertController: AlertController,
		public varias: VariasService,
		private fireStore: AngularFirestore,
		public data: DataService,
		public instance: InstanceService
	) {}

  	ngOnInit() {
		this.varias.getVariable("sys_member");
	}

	async ionViewDidEnter() {
		if (this.contact.n === "") {
			let inputElement = await this.inputField.getInputElement();
			inputElement.focus();
		}

		this.fireStore.collection("meta").doc("contactpics").ref.get().then(doc => {
			let docData: any = doc.data();
			if (docData.contactpics) {
				for (let picNr of docData.contactpics) {
					this.contactpics.push("_cugc_cont"+picNr+".jpg");
				}
			}
			if (docData.memberpics) {
				for (let picNr of docData.memberpics) {
					this.memberpics.push("_cugc_cont"+picNr+".jpg");
				}
			}	
		})

		if (!this.contact.d) { this.contact.d = ""; }
		this.contact.d += " ";
		setTimeout(() => {
			this.contact.d = this.contact.d.trim();
		}, 100)
	}

	close() {
		this.modalController.dismiss(this.contact);
	}

	async selectPic(pic, memberpic) {
		if (memberpic) {
			if (this.varias.vars["sys_member"]) {
				this.contact.im = pic;
			} else {
				const alert = await this.alertController.create({
					header: "Exklusiv",
					subHeader: "Membership",
					cssClass: 'eon__alert',
					message: "Dieses Profilbild kannst du exklusiv mit einer Membership verwenden.",
					buttons: [
						{ 
							text: 'Ok', handler: () => {}
						}
					]
				})
				await alert.present();				
			}
		} else {
			this.contact.im = pic;
		}
	}

	toggleContext(value) {
		let el: any = document.getElementById("acc_context");
		if (el) {
			if (el.value === value) { 
				el.value = "collapsed";
				this.expanded = "collapsed";
			} else {
				if (value === "character") { 
					if (!this.contact.c_c) { this.contact.c_c = " "; }
					else { this.contact.c_c += " "; }
				}
				else if (value === "style") { 
					if (!this.contact.c_s) { this.contact.c_s = " "; }
					else { this.contact.c_s += " "; }
				}
				el.value = value;
				this.expanded = value;
				setTimeout(() => {
					if (value === "character") { this.contact.c_c = this.contact.c_c.trim(); }
					else if (value === "style") { this.contact.c_s = this.contact.c_s.trim(); }
				}, 100);
			}
		}
	}

}