import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

	instance: string = "prllxapp";
	// voidapp, gamerapp, prllxapp, horrorapp, redfirapp, spaceapp, prevapp, demoapp
	variant: string = "main";

	liberty: boolean = false;
	cugc: boolean = true;
	iapPrefix: string = "";
	special2: boolean = true;

	tomaiUrl: string = "https://tomai.herokuapp.com/api/test";
	
	botaiUrl: string = "https://tomai.herokuapp.com/api";
	//botaiUrl: string = "http://localhost:3000/api";

	hasTom: boolean = true; // AI
	hasBarb: boolean = true; // Translation
	hasDomi: boolean = true; // special Formations
	chrisaiToken: string = '711c019f55a6cc29fe72076d17403690050f36daea3';
	hasChris: boolean = true; // Speach Recognition

	nativeAudio: boolean = false;

  	constructor() {}
}
