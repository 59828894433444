import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { Events } from '../system/events.service'

import { DataService } from '../system/data.service'
import { NotificationsService } from '../system/notifications.service'
import { TelemetryService } from '../cloud/telemetry.service'
import { MetaService } from '../cloud/meta.service';
import { SignalService } from '../cloud/signal.service';
import { VariasService } from '../system/varias.service';
import { LocalizationService } from '../system/localization.service';
import { Fair2playService } from '../system/fair2play.service';
import { PurchaseService } from '../cloud/purchase.service'
import { NavigationService } from '../system/navigation.service';
import { InstanceService } from '../system/instance.service';
import { AdsService } from '../cloud/ads.service';
import { MenuService } from './menu.service';
import { UserService } from '../cloud/user.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class EngineService {

	continueResult: Array<any> = [];
	echoesRegistry: any = [];
	funnel_storeCachedScene: any = [];
	funnel_storeCachedStoryboard: any = [];
	funnel_storeEchoesRegistry: any = [];
	funnel_storeStoryboardsRegistry: any = [];
	funnel_storeQueue: any = [];
	globalQueue: Array<any> = [];
	interval_QUE_work: any = {};
	loadingElement: any;
	scenesCache: Array<any> = [];
	storyboardsCache: Array<any> = [];
	storyboardsRegistry: any = [];
	typingSpeeds: Array<any> = [];
	lastNow: number = 0;
	tutoringsThisSession: Array<any> = [];
	loading: any = null;
	   
	constructor(
		private alertController: AlertController, 
		private data: DataService,
		private events: Events,
		private fireStore: AngularFirestore,
		private loadingController: LoadingController,
		private storage: Storage, 
		private storageSrv: StorageService,
		private notifications: NotificationsService,
		private telemetry: TelemetryService,
		private meta: MetaService,
		private signal: SignalService,
		private varias: VariasService,
		private localization: LocalizationService,
		private modalController: ModalController,
		private navController: NavController,
		private fireAuth: AngularFireAuth,
		private fair2play: Fair2playService,
		private purchase: PurchaseService,
		private navigation: NavigationService,
		private instance: InstanceService,
		private ads: AdsService,
		private menu: MenuService,
		private user: UserService
	) {
		this.load();

		var that = this;
		this.fireStore.collection("parameters").doc("parameters").collection("typingSpeeds").ref.get().then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					let item = doc.data();
					that.typingSpeeds[item.c] = item.s;
				});
		});

		this.events.subscribe("STB_start", (params) => {	
			this.STB_start(params.episodeId)
		})

		this.events.subscribe("ECH_downloadAndStart", (params) => {	
			if (!that.data.simstats_simulationOn) {
				that.ECH_downloadAndStart(params.sceneId);
			} else { console.log("BLOCKED DOWNLOAD DURING SIMULATION"); }
		})
	
		this.events.subscribe("ECH_restart", (params) => {
			that.ECH_restart(params.sceneId);
		})
	
		this.events.subscribe("ECH_downloadAndStartTutoring", (params) => {
			if (!that.data.simstats_simulationOn) {
				that.ECH_downloadAndStartTutoring(params.sceneId, params.forceRestart, params.openOnStart);
			} else { console.log("BLOCKED DOWNLOAD DURING SIMULATION"); }
		})

		this.events.subscribe("languageChanged", () => {
			this.switchLanguage();
		})

		this.events.subscribe("SYNC_findRunningStoryboards", () => {
			this.SYNC_findRunningStoryboards();
		})

		this.events.subscribe("executeSkip", (params) => {
			this.skip({ i: params.sceneId });
		})

		setTimeout(function() { that.ECH_loadUnloadedTutorings(); }, 60000);
		setInterval(function() { that.ECH_loadUnloadedTutorings(); }, 300000);
	}

	async load() {
		var that = this;
		await this.loadEchoesRegistry();
		await this.loadStoryboardsRegistry();
		await this.loadQueue();
		this.events.publish('updateStoryboardsOverview', false);
		this.events.publish('updateEchoesOverview');
		this.interval_QUE_work = setInterval(function() { that.QUE_work(); }, 1000)
	}

	// Scenes-Cache
	async getCachedScene(sceneId) {
		if (!this.scenesCache[sceneId]) { 
			await this.storage.get("scn_"+sceneId).then((sceneString) => { this.scenesCache[sceneId] = JSON.parse(sceneString); }) 
		}
		return this.scenesCache[sceneId];
	}
	async setCachedScene(scene) { this.scenesCache[scene.info.i] = scene; return; }
	async removeSceneFromCache(sceneId) { this.scenesCache[sceneId] = null; return; }
	storeCachedScene(sceneId) {
		if (!sceneId.startsWith("stk_")) {
			let that = this;
			clearTimeout(this.funnel_storeCachedScene[sceneId]); 
			this.funnel_storeCachedScene[sceneId] = setTimeout(async () => { 
				await that.storage.set("scn_"+sceneId, JSON.stringify(that.scenesCache[sceneId])); 
			}, 100); 
		}
	}

	// Storyboards-Cache
	async getCachedStoryboard(storyboardId) {
		if (!this.storyboardsCache[storyboardId]) {
			let storyboardString = null; 
			await this.storage.get("stb_"+storyboardId).then((result) => storyboardString = result);
			if (storyboardString != null) {
				this.storyboardsCache[storyboardId] = JSON.parse(storyboardString);
			} else {
				let doc; await this.fireStore.collection(this.localization.DB_episodes).doc(storyboardId).ref.get().then((result) => { doc = result })
				if (doc) { if (doc.exists) {
					this.storyboardsCache[storyboardId] = { info: doc.data() };
				}}
			}
		}
		return this.storyboardsCache[storyboardId];
	}	
	async setCachedStoryboard(storyboard) { this.storyboardsCache[storyboard.info.i] = storyboard; return; }
	async removeStoryboardFromCache(storyboardId) { this.storyboardsCache[storyboardId] = null; return; }
	storeCachedStoryboard(storyboardId) { let that = this;  clearTimeout(this.funnel_storeCachedStoryboard[storyboardId]); this.funnel_storeCachedStoryboard[storyboardId] = setTimeout(function() { that.storage.set("stb_"+storyboardId, JSON.stringify(that.storyboardsCache[storyboardId])); }, 10); }
	async immediatelyStoreCachedStoryboard(storyboardId) {
		await this.storage.set("stb_"+storyboardId, JSON.stringify(this.storyboardsCache[storyboardId]));
	}

	async loadStoryboardsRegistry() {
		await this.storage.get("stbReg").then((value) => {
			if (value == null) { this.storyboardsRegistry = []; } else { this.storyboardsRegistry = JSON.parse(value); }
		})
		return;
	}
	async loadStoryboardsRegistryIfNotYet() {
		if (this.storyboardsRegistry.length == 0) {
			return this.loadStoryboardsRegistry();
		}
		return;
	}
	storeStoryboardsRegistry() { 
		let that = this; clearTimeout(this.funnel_storeStoryboardsRegistry); this.funnel_storeStoryboardsRegistry = setTimeout(function() { that.storage.set("stbReg", JSON.stringify(that.storyboardsRegistry)); }, 500);
	}
	async immediatelyStoreStoryboardsRegistry() {
		await this.storage.set("stbReg", JSON.stringify(this.storyboardsRegistry));
	}
	logStoryboardsRegistry() {}

	async loadEchoesRegistry() {
		await this.storage.get("echReg").then((value) => {
			if (value == null) { this.echoesRegistry = []; } else { this.echoesRegistry = JSON.parse(value); }
		})
		return;
	}
	storeEchoesRegistry() { let that = this; clearTimeout(this.funnel_storeEchoesRegistry); this.funnel_storeEchoesRegistry = setTimeout(function() { that.storage.set("echReg", JSON.stringify(that.echoesRegistry)); }, 500); }
	logEchoesRegistry() { console.log("ECHOES REGISTRY", this.echoesRegistry )}

	storeQueue() {
		let that = this;
		clearTimeout(this.funnel_storeQueue);
		this.funnel_storeQueue = setTimeout(function() {
			let globalQueue = [];
		   	Object.keys(that.globalQueue).forEach(function(key, index) {
			  	globalQueue.push(that.globalQueue[key]);
		   	}, that.globalQueue);
		   	that.storage.set("globalQueue", JSON.stringify(globalQueue));
		}, 300);
	}
	async loadQueue() {
		let that = this;
		await this.storage.get("globalQueue").then((globalQueueString) => {
			let globalQueue = JSON.parse(globalQueueString);
			if (globalQueue) {
			   	globalQueue.forEach(function(message) {
					that.QUE_addSingleMessage(message.scn, message);
			   	})
			}
		})
		return;
	}
	logQueue() { console.log(this.globalQueue); }

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/* Echo aus der Cloud laden & im Storage speichern */
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	
	async ECH_unlock(echoId, tickets, echoTitle: any = null, blocked: boolean = false) {
		let started = false; await this.varias.getVariable("ech_"+echoId+"_std").then(result => { if (result) { started = true; }})
		let unlocked = false; await this.varias.getVariable("ech_"+echoId+"_unl").then(result => { if (result) { unlocked = true; }})
		if ((started || unlocked) && !blocked) {
			return true;
		} else {
			let enoughTickets = false; await this.varias.decrementCurrency("tickets", tickets).then(result => { if (result) { enoughTickets = true; } })
			if (enoughTickets) {
				this.varias.setVariable("ech_"+echoId+"_std", true);
				this.varias.setVariable("ech_"+echoId+"_sub", false);
				this.telemetry.logEvent("echo_unlock", { echo: echoId });
				return true;
			} else {
				if (await this.varias.getVariable("sys_member")) {
					this.varias.setVariable("ech_"+echoId+"_sub", true);
					return true;
				} else {
					return false;
				}
			}
		}
	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/* Echo aus der Cloud laden & im Storage speichern */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async ECH_download(echoId, showLoader) {
		if (this.signal.connected) {

			let that = this;
			this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEcho});
			if (showLoader) { await this.loadingElement.present(); }
	
			let scene:any = {};
			let dbConnection = this.fireStore.collection(this.localization.DB_echoes).doc(echoId.toString());
			await dbConnection.ref.get().then((doc) => {
				if (doc.exists) {
					scene = doc.data();
				} else {
					if (showLoader) { this.loadingElement.dismiss(); }
					return false;
				}
			})
			// im Storage speichern
			if (scene) {
				if (scene.info) {
					await this.setCachedScene(scene);
					this.storeCachedScene(scene.info.i);
					if (showLoader) { this.loadingElement.dismiss(); }
					this.varias.removeFromArray("arr_recech", echoId);
				} else {
					if (showLoader) { this.loadingElement.dismiss(); }
					this.varias.removeFromArray("arr_recech", echoId);
					return false;
				}
			} else {
				if (showLoader) { this.loadingElement.dismiss(); }
				this.varias.removeFromArray("arr_recech", echoId);
				return false;
			}
		} else {
			this.signal.alertNoConnection(this.localization.signal_blank);
		}
		return true ;
	}

	async ECH_requestForFeed(projectId, echoId, showLoader) {
		this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.oneMomentPlease});
		if (showLoader) { await this.loadingElement.present(); }
		await this.loadEchoesRegistry();
		let running: boolean = false;
		for await (let echo of this.echoesRegistry) {
			if (echo.i === echoId) {
				running = true;
			}
		}
		if (running) {
			this.navigation.pushChat(echoId, false, false, null);
			this.loadingElement.dismiss();
		} else {
			this.loadingElement.dismiss();
			this.ECH_requestFromCUGC(projectId, echoId, showLoader);
		}
	}

	async ECH_requestFromCUGC(projectId, echoId, showLoader) {
		this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEcho});
		if (showLoader) { await this.loadingElement.present(); }
		await this.fireStore.collection("cugc").doc("echoes").collection("echoes").doc(projectId).collection("echoes").doc(echoId).ref.get().then(async doc => {
			if (doc.exists) {
				let scene = doc.data();
				await this.ECH_importFromCUGC(scene, echoId, false);
				await this.SCN_start(scene.info.i, true, true);
				this.loadingElement.dismiss();
				this.navigation.pushChat(scene.info.i, false, false, null);
				this.telemetry.cugc_start(projectId);
			} else { this.loadingElement.dismiss(); }
		}).catch(() => { this.loadingElement.dismiss(); })
	}

	async ECH_loadFromCUGC(projectId, echoId, showLoader) {

		await this.fireStore.collection("cugc").doc("echoes").collection("echoes").doc(projectId).collection("echoes").doc(echoId).ref.get().then(doc => {

		}).catch(() => { this.loadingElement.dismiss(); })
	}

	async ECH_importFromCUGC(scene, echoId, showLoader) {
		if (showLoader) { 
			this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEcho});
			await this.loadingElement.present();
		}

		if (scene) {
			if (scene.info) {
				await this.setCachedScene(scene);
				this.storeCachedScene(scene.info.i);
				if (showLoader) { this.loadingElement.dismiss(); }
				this.varias.removeFromArray("arr_recech", echoId);
			} else {
				if (showLoader) { this.loadingElement.dismiss(); }
				this.varias.removeFromArray("arr_recech", echoId);
				return false;
			}
		} else {
			if (showLoader) { this.loadingElement.dismiss(); }
			this.varias.removeFromArray("arr_recech", echoId);
			return false;
		}

		return true;
	}

	async ECH_downloadAndStart(echoId) {
		if (this.signal.connected) {
			await this.ECH_download(echoId, false);
			await this.SCN_start(echoId, false, true);
		}
		return;
	}

	async ECH_restart(echoId) {
		await this.SCN_start(echoId, true, true);
	}

	async ECH_downloadAndStartTutoring(sceneId, forceRestart: boolean = false, openOnStart: boolean = false) {
		let started = false; await this.varias.getVariable("ech_"+sceneId+"_std").then(result => started = result);
		if (!started || forceRestart) {
			if (this.signal.connected) {
				let downloadSuccess = false; await this.ECH_download(sceneId, false).then(async result => downloadSuccess = result);
				if (downloadSuccess) {
					await this.SCN_start(sceneId, false, true);
					if (openOnStart) {
						if (sceneId.startsWith("ads_")) {
							this.events.publish("presentChat", { sceneId: sceneId, progNext: false, viaHistory: false, purrInfo: null });
						} else {
							this.navigation.pushChat(sceneId, false, false, null);
						}
					}
				}
				await this.storage.remove("tto_pending_"+sceneId);
			} else {
				await this.storage.set("tto_pending_"+sceneId, sceneId);
			}
		}
		return;
	}

	async ECH_loadUnloadedTutorings() {
		let keys; await this.storage.keys().then((storedKeys) => { keys = storedKeys; })
		for await (let key of keys) {
			if (key.indexOf("tto_pending_") > -1) {
				let sceneId; await this.storage.get(key).then(result => sceneId = result);
				if (sceneId) { await this.ECH_downloadAndStartTutoring(sceneId); }
			}
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Scene starten */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_start(sceneId: string, forceRestart: boolean, receiveFirstMessage: boolean) {
		let that = this;
		let now = Date.now();

		// Scene aufrufen
		let scene: any; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })

		// Start abbrechen, wenn Scene nicht gefunden oder bereits gestartet
		let cancelStart = (!scene || scene.info.started && !forceRestart) ? true : false; // ToDo --> forceRestart funktioniert nicht
		if (cancelStart) {
			return scene;
		}

		// Änderungen durchführen
		scene.info.started = true;
		scene.info.running = true;
		scene.info.touched = now;
		await this.setCachedScene(scene);
		this.storeCachedScene(scene.info.i);

		// erste Nachricht suchen und folgende in Queue legen
		if (receiveFirstMessage) {
			for (const message of scene.messages) {
				if (message.s) {
				   	that.continueResult[scene.info.i] = [];
				   	let echoMode = scene.stb == "echo" ? true : false;
				   	let firstMessageAt = await this.SCN_addToContinueResult(scene, null, message, Date.now(), echoMode);
				   	await this.SCN_continueWith(scene, message, message.n, firstMessageAt, echoMode, false);
				   	this.QUE_addMultipleMessages(scene.info, scene.info.i, that.continueResult[scene.info.i]);
				}
		 	}
		}

		// falls Scene == einzelnes Echo: Scene in echoesRegistry markieren
		if (scene.info.stb == "echo") {
	   		let foundInRegistry = false;
	   		this.echoesRegistry.forEach(function(echoInRegistry) {
		  		if (echoInRegistry.i == scene.info.i) {
			 		foundInRegistry = true;
			 		echoInRegistry.started = true;
			 		echoInRegistry.running = true;
			 		echoInRegistry.touched = now;
		  		}
	   		})
	   		if (!foundInRegistry) { this.echoesRegistry.push({...scene.info, touched: now}); }
			this.storeEchoesRegistry();
			this.events.publish('updateEchoesOverview');
			if (scene.info.tut) { this.events.publish("updateTutorings"); }

			this.varias.setVariable("ech_"+sceneId+"_std", true);
			   
		// falls Scene == Teil eines Storyboards: Scene in Storyboard markieren
		} else if (scene.info.stb === "stk") {
		} else {
			let storyboard; await this.getCachedStoryboard(scene.info.stb).then((cachedStoryboard) => { storyboard = cachedStoryboard });
	   		if (storyboard) {
				if (storyboard.scenes) {
					storyboard.scenes.forEach(function(sceneInStoryboard) {
						if (sceneInStoryboard.i == sceneId) { 
						   sceneInStoryboard.started = true; 
						   sceneInStoryboard.running = true; 
						   sceneInStoryboard.touched = now;
						}
				  	})
				}
			}
			await this.setCachedStoryboard(storyboard);
			await this.storeCachedStoryboard(storyboard.info.i);
			this.events.publish('updateStoryboardsOverview', false);
			this.events.publish("updateStoryboard", storyboard.info.i);
			this.STB_sendToMenu(storyboard.info.i, storyboard.info.n, storyboard.info.ugc, storyboard.info.im);
		}

		this.varias.setVariable("scn_"+sceneId+"_std", true);

		this.telemetry.logEvent("start_scene", { id: sceneId });

		return scene;
	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Scene fortsetzen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_continue(scene, prevMessage, nexts, echoMode, streakMode) {
		this.logGlobalQueue();
		this.continueResult[scene.info.i] = [];
		// folgender Messages suchen
		await this.SCN_continueWith(scene, prevMessage, nexts, Date.now(), echoMode, streakMode)
		this.logGlobalQueue();
		// folgende Message in Queue ablegen
		let adaptedMessages = []; await this.QUE_addMultipleMessages(scene.info, scene.info.i, this.continueResult[scene.info.i]).then(result => adaptedMessages = result);
		// folgende Messages zurückgeben
		return (adaptedMessages);
	}	

   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   /* Forsetzen mit */
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   async SCN_continueWith(scene, prevMessage, nexts, at, echoMode, streakMode) {
		if (nexts) {
	   		for (const nextObject of nexts) {
		  		// Conditions prüfen
		  		let allConditionsFulfilled = false;
		  		await this.VAR_checkConditions(nextObject.conds).then((result) => { allConditionsFulfilled = result; })
		  		if (allConditionsFulfilled) {
					if (nextObject.n == "end") {
						this.continueResult[scene.info.i].push({ end: true, at: at+1000, echoId: scene.id })
					} else {
						// Folgende Message heraussuchen
						let nextMessage;
						let sceneInCache;
						if (streakMode) { sceneInCache = scene; }
						else { await this.getCachedScene(scene.info.i).then((cachedScene) => { sceneInCache = cachedScene; }) }
						await sceneInCache.messages.forEach(function(message) {
							if (message.i == nextObject.n) { 
								nextMessage = message;
							}
						})
						let newAt = at;
						if (nextMessage) {
							let nextMessageNew = JSON.parse(JSON.stringify(nextMessage));
							await this.SCN_addToContinueResult(scene, prevMessage, nextMessageNew, at, echoMode).then((atReturned) => {
								newAt = atReturned;
							})
							if (!echoMode) {
								await this.SCN_continueWith(scene, nextMessage, nextMessage.n, newAt+1, echoMode, streakMode);
							}
						} else {
							this.continueResult[scene.info.i].push({ end: true, at: at+1000, echoId: scene.id })	
						}
					}
		  		}
	   		}
		}
		return;
 	}	
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* eine Message zu den ermittelten kommenden Nachrichten hinzufügen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_addToContinueResult(scene, prevMessage, message, at, echoMode) {

		if (message) {
			let delay = 0;
			let lag = 0;
			let pause = 0;

			if (!message.d) {

				pause = message.p*60000;
				pause = Math.round((message.p / this.data.config_pauseFactor) * 60000);
				if (isNaN(pause)) { pause = 1000; }
				
				if (prevMessage) {
					if (prevMessage.t) {
						if (prevMessage.c.i == message.c.i) {
						} else {
							let readingPause = prevMessage.t.length / 35 * 1000;
							if (pause < readingPause) { pause = readingPause; }
						}
					}
				}
				if (pause < 1000) { pause = 1000; }

				let typingSpeed = 50;
				if (this.typingSpeeds[message.c.i]) { typingSpeed = this.typingSpeeds[message.c.i]; }

				lag = message.t.length/typingSpeed*1000;
				if (lag < 1000) { lag = 1000; }
				if (message.sys) { lag = 0; }
				else if (message.nar) { lag = 0; }
				else if (message.sta) { lag = 0; }
				else if (message.stg) { lag = 0; }
				else if (message.c.i === "mmorpg") { lag = 0; }

				if (scene.info.i === "hlp_consent") { lag = 1000; }

				delay = pause + lag;
			}	

			message.at = Math.floor(at+delay);
			message.startTyping = message.at - lag;

			/*
	   		// ist die Message bereist in der Queue?
	   		if (this.globalQueue[scene.id+"_"+message.id]) {
		  		// ist der Eintrittszeitpunkt in der Queue früher?
		  		if (this.globalQueue[scene.id+"_"+message.id].at < message.at) {
			 		// Eintrittszeitpunkt aus Queue übernehmen
			 		message.at = this.globalQueue[scene.id+"_"+message.id].at;
		  		}
			}
			*/

	   		this.continueResult[scene.info.i].push(message);
	   		return message.at;
		}
	}
	
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* touch Scene */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_touch(sceneId, storyboardId) {
		let that = this;
		let now = Date.now();

		if (storyboardId == "echo") {
	   		this.echoesRegistry.forEach(function(echo) {
		  		if (echo.i == sceneId) {
			 		echo.touched = now;
			 		that.storeEchoesRegistry();
		  		}
			})
		} else if (storyboardId === "stk") {
		} else {
			//this.varias.setVariable("usr_recentEpisodeId", storyboardId);
			//this.telemetry.setUserProperty("recentEpisode", storyboardId);
	   		let storyboard; await this.getCachedStoryboard(storyboardId).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
			storyboard.touched = now;
			if (storyboard) {
				if (storyboard.scenes) {
					for (var scene of storyboard.scenes) {
					   if (scene.i == sceneId) { scene.touched = now; }
					}
				}
			}   
	   		await this.storeCachedStoryboard(storyboardId);
	   		this.storyboardsRegistry.forEach(function(storyboard) {
		  		if (storyboard.i == storyboardId) {
			 		storyboard.touched = now;
			 		that.storeStoryboardsRegistry();
		  		}
			})
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Messages als gelesen markieren */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_markMessagesRead(sceneId, messageIds) {
		// Scene aufrufen
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })
		scene.messages.forEach(function(message) {
	   		messageIds.forEach(function(messageId) {
		  		if (message.i == messageId) { message.read = true; }
	   		})
		})
		// Scene speichern
		this.storeCachedScene(sceneId);
		// Chatübersicht neu rendern
		if (scene.info.stb == "echo") {
			this.events.publish('updateEchoesOverview');
			if (scene.info.tut) { this.events.publish("updateTutorings"); }
		} else if (scene.info.stk) {
		} else {
			this.events.publish("updateStoryboardsOverview", false);
			this.events.publish("updateStoryboard", scene.info.stb);
		}
 	}	 
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Scene abschließen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async SCN_complete(sceneId) {
		// Scene aufrufen
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })

		// Änderungen durchführen
		scene.running = false;
		scene.completed = true;
		this.storeCachedScene(sceneId);

		// Änderungen in storyboard.scenes spiegeln
		let storyboard; await this.getCachedStoryboard(scene.info.stb).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
		if (storyboard) {
			if (storyboard.scenes) {
				storyboard.scenes.forEach(function(sceneInStoryboard) {
					if (sceneInStoryboard.i == sceneId) {
					   sceneInStoryboard.running = false;
					   sceneInStoryboard.completed = true;
					}
				})
			}
		}
		this.storeCachedStoryboard(scene.info.stb);

		// Chatübersicht neu rendern
		if (scene.info.stb == "echo") {
			this.events.publish('updateEchoesOverview');
			if (scene.info.tut) { this.events.publish("updateTutorings"); }
		} else {
			this.events.publish("updateStoryboardsOverview", false);
			this.events.publish("updateStoryboard", scene.info.stb);
		}

		// Folgende Szenen finden
		let followingScenes = [];
		let end = true;
		// Mögliche folgende Szenen durchgehen
		for await (let nextObject of scene.info.n) {
	   		// Sind alle Bedingungen für die folgende Szene erfüllt?
	   		let conditionsFulfilled = false; await this.VAR_checkConditions(nextObject.conds).then((result) => { conditionsFulfilled = result; })
	   		if (conditionsFulfilled) {
		  		if (nextObject.n == "end") {
			 		await this.STB_complete(scene.info.stb);
		  		} else {
			 		await this.SCN_start(nextObject.n, false, true).then((followingScene) => {
						followingScenes.push(followingScene);
						end = false;
			 		})
		  		}
	   		}         
		}

		this.varias.setVariable("scn_"+sceneId+"_cmp", true);

		return {followingScenes, end};
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Echo abschließen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
   	async ECH_complete(sceneId) {
		// Scene aufrufen
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })

		// Änderungen durchführen
		scene.running = false;
		scene.completed = true;
		this.storeCachedScene(sceneId);

		// Änderungen in echoesRegistry spiegeln
		this.echoesRegistry.forEach(function(echo) {
	   		if (echo.i == scene.info.i) {
		  		echo.running = false;
		  		echo.completed = true;
	   		}
		})
		this.storeEchoesRegistry();

		if (scene) {
			if (scene.info) {
				if (scene.info.ugc) {
					await this.varias.getVariable("ech_"+sceneId+"_cmp").then(result => {
						if (!result) {
							this.telemetry.cugc_complete(sceneId);
						}
					})
				}
			}
		}

		this.varias.setVariable("ech_"+sceneId+"_std", true);
		this.varias.setVariable("ech_"+sceneId+"_cmp", true);

		this.telemetry.logEvent("echo_complete", { echo: sceneId });
		this.telemetry.logEvent("echo_complete_"+sceneId, { echo: sceneId });	
 	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Message empfangen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async MSG_receive(sceneId, messageId, at, markAsRead: boolean = false) {
		let that = this;
		let now = Date.now();

		// Scene aufrufen
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })
		if (!scene) {
			this.QUE_removeMessage(sceneId, messageId, 0);
			return;
		}

		// Message in Scene suchen
		let found = false;
		let foundMessage = undefined;
		let pause = 0;
		if (scene.messages) {
	   		scene.messages.forEach(function(message) {
		  		if (message.i == messageId) {
			 		if (!message.sent) {
						// Änderungen durchführen
						message.sent = true;
						message.at = at;
						pause = message.p;
						if (markAsRead) { message.read = true; }

						if (message.t) {
							if (message.t.indexOf("[tickets]") > -1) {
								message.t.replace("[tickets]", that.varias.vars["sys_tickets"]);
							}
						}

						foundMessage = message;
						found = true;
						// ggf. Spielvariablen setzen
						if (message.vars) {
							if (!message.lnk) {
								message.vars.forEach(function(variable) { that.VAR_setVariable(variable.key, variable.val); })
							}
						}
						// Toast einblenden
						that.notifications.toast(scene, message);
						// Kontakt als bekannt markieren
						if (message.c) { that.CNT_meet(message.c.i); }
			 		}
		  		}
	   		})
		}

		this.QUE_removeMessage(sceneId, messageId, pause);

		if (found) {
	   		scene.touched = now;
	   		this.storeCachedScene(sceneId);
	   		this.SCN_touch(sceneId, scene.info.stb);

			// Chatübersicht neu rendern
			if (scene.info.stb == "echo") { 
				this.events.publish('updateEchoesOverview');
				if (scene.info.tut) { this.events.publish("updateTutorings"); }
			} else { 
				this.events.publish('updateStoryboardsOverview', false);
				this.events.publish("updateStoryboard", scene.info.stb);
			}

			if (foundMessage) {
				this.telemetry.logEvent("message_received", { message: foundMessage })
				if (foundMessage.g) {
					if (foundMessage.g.length > 0) {
						for (let glossarItem of foundMessage.g) {
							let key = "gls_"+foundMessage.i;
							this.varias.setVariable("gls_"+foundMessage.i, glossarItem.k);
						}
					}
				}
			}
		}
		return foundMessage;
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/*  Nachricht verschicken */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async MSG_send(sceneId, decisionId, possible, posIndex, vanish = false, streakMode, givenScene) {
		let that = this;
		let now = Date.now();

		// Scene aurufen
		let scene; 
		if (streakMode) { scene = givenScene; }
		else { await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; }) }

		let found = false;
		let foundMessage = undefined;
		scene.messages.forEach(function(message) {
			if (message.i == decisionId) {
				if (!message.used) {

					message.sent = true;
					message.used = true;
					message.t = possible.t;
					message.n = possible.n;
					message.st = possible.st;
					message.c = possible.c;
					message.cro = possible.cro;
					message.nar = possible.nar;
					message.posI = posIndex;
					message.at = now;
					message.last = "last";
					if (vanish) { message.e_va = true; }

					foundMessage = {...message};
					found = true;

					that.CNT_meet(message.c.i);

					// ggf. Spielvariablen setzen
					if (possible.vars) { possible.vars.forEach(function(variable) { that.VAR_setVariable(variable.key, variable.val); }) }

					// ggf. Modifications durchführen
					if (possible.mods) {
						for (const mod of possible.mods) {
							if (mod.i == message.i) {
								if (mod.t) { message.t = mod.t; }
								message.hidden = mod.h;
							}
						}
					}

				}
			}
		})

		if (found) {
	   		scene.touched = now;
			await this.storeCachedScene(sceneId);
	   		await this.SCN_touch(scene.info.i, scene.info.stb);
			// Chatübersicht neu rendern
			if (scene.info.stb == "echo") { this.events.publish('updateEchoesOverview', null); } 
			else { 
				this.events.publish('updateStoryboardsOverview', false);
				this.events.publish("updateStoryboard", scene.info.stb);
			}

			if (foundMessage) { this.telemetry.logEvent("message_sent", { messageId: foundMessage.i }); }
		}

		let moment = new Date;
		let weekday = moment.getDay();
		let hour = moment.getHours();
		let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
		let storyboardId = ""; if (scene) { if (scene.info) { storyboardId = scene.info.stb; } };
		this.telemetry.logEvent("activity_sendMessage_"+weekday, { day: day, weekday: weekday, hour: hour, ep: storyboardId });
		
		return foundMessage;
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* mehrere Messages zur Queue hinzufügen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async QUE_addMultipleMessages(sceneInfo, sceneId, messages) {
		let allMessagesAlreadyInQueue = true;
		let existingStartTyping = [];
		let existingAt = [];

		for (let message of messages) {
			if (!message.d) {
				let alreadyInQueue = false;
				if (this.globalQueue[sceneId+"_"+message.i] != null) {
					alreadyInQueue = true;
					existingAt[message.i] = this.globalQueue[sceneId+"_"+message.i].at;
					//existingStartTyping[message.i] = message.startTyping;
					existingStartTyping[message.i] = this.globalQueue[sceneId+"_"+message.i].startTyping;
				}
				if (!alreadyInQueue) { allMessagesAlreadyInQueue = false;}
			}
		}

		if (allMessagesAlreadyInQueue) {
			for await (let message of messages) {
				message.at = existingAt[message.i];
				message.startTyping = existingStartTyping[message.i];
			}
		} else {

			for (let message of messages) {
				await this.QUE_removeMessage(sceneId, message.i, message.p);
			}

			let now = Date.now();
			let highestDelay = 0;
			let queueKeys = Object.keys(this.globalQueue);
			for (let key of queueKeys) {
				if (this.globalQueue[key].at-now > highestDelay) {
					highestDelay = this.globalQueue[key].at-now;
				}
			}
			for (let message of messages) {
				if (!message.d) {
					if (message.at-now > 300000) {
						message.at = message.at + highestDelay;
						message.startTyping = message.startTyping + highestDelay; // EDITED
					}
				}
				this.QUE_addSingleMessage(sceneId, message);
			}
		}

		this.notifications.addMultiple(sceneInfo, messages, sceneId, this.globalQueue);

		return messages;
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* eine einzelne Message zur Queue hinzufügen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async QUE_addSingleMessage(sceneId, message) {
		if (sceneId) {
			// Ist die Message KEINE Entscheidung?
			if (message) {
				if (!message.d && !message.end) {
					// falls Message bereits in Queue
					if (this.globalQueue[sceneId+"_"+message.i]) {
						// ist der in der Queue verzeichnete Eintrittszeitpunkt später?
						if (this.globalQueue[sceneId+"_"+message.i].at > message.at) {
							// Eintrittszeitpunkt in der Queue anpassen
							this.globalQueue[sceneId+"_"+message.i].at = message.at;
							this.globalQueue[sceneId+"_"+message.i].startTyping = message.startTyping;
							//this.notifications.update(message, this.globalQueue);
						}
					// falls Message noch nicht in Queue
					} else {
						let contentTitle = "";
						if (message.scn.indexOf("_") > -1) {
							message.stbId = message.scn.split("_")[0];
							let storyboard = null; await this.getCachedStoryboard(message.stbId).then(cachedStoryboard => storyboard = cachedStoryboard);
							if (storyboard) { if (storyboard.info) { contentTitle = storyboard.info.n }}
						} else {
							message.stbId = message.scn;
							let scene = null; await this.getCachedScene(message.stbId).then( cachedScene => scene = cachedScene );
							if (scene) { if (scene.info) { contentTitle = scene.info.t; }}
						}
						message.sceneId = message.scn;
						this.globalQueue[sceneId+"_"+message.i] = message;
						//this.notifications.add(message, this.globalQueue, contentTitle);
					}
					this.storeQueue();
				}
			}
		}
	}	

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* eine Message aus der Queue entfernen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async QUE_removeMessage(sceneId, messageId, pause = 0) {
		delete this.globalQueue[sceneId+"_"+messageId];
		this.notifications.cancel(sceneId, messageId, this.globalQueue, pause);
		this.storeQueue();
		return
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Message in Queue abrufen und zurückgeben */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	QUE_getMessage(sceneId, messageId) {
		return this.globalQueue[sceneId+"_"+messageId];
 	}

 	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 	/* Queue abarbeiten */
 	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 	QUE_work() {
		let that = this;
		let now = Date.now();
		let logs = [];
		Object.keys(this.globalQueue).forEach(function(key, index) {
			let message = that.globalQueue[key];

			let typingIn = Math.floor((message.startTyping-now)/60000)
			let receiveIn = Math.floor((message.at-now)/60000);
			logs.push({id: message.i, typing: typingIn, receive: receiveIn})

			if ((that.globalQueue[key].at - now) < -3000) { // auf 30 Sekunden erhöhen
				that.MSG_receive(message.scn, message.i, that.globalQueue[key].at);
	   		}
		}, that.globalQueue);
	 }
	 
	async logGlobalQueue() {
		var that = this;
		let now = Date.now();
		let logs = [];
		Object.keys(this.globalQueue).forEach(function(key, index) {
			let message = that.globalQueue[key];
			let typingIn = Math.floor((message.startTyping-now)/60000)
			let receiveIn = Math.floor((message.at-now)/60000);
			logs.push({id: message.i, at: message.at, typing: typingIn, receive: receiveIn})			
		});
		//console.table(logs);
		return
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Bedingungen prüfen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async VAR_checkConditions(conditions) {
		let allGood = false;
		await this.varias.checkConditions(conditions).then(result => allGood = result);
		if (allGood) { return true; } else { return false; }
		/*
		let allGood = true;
		for (const condition of conditions) {
			await this.VAR_checkVariable(condition.key, condition.val, condition.equ).then((checkGood: boolean) => {
				if (!checkGood) { allGood = false; }
			})
		}
		if (allGood) { return true; } else { return false; }
		*/
	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* erlaubte Possibles aus allen Possibles einer Decision filtern */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async VAR_checkPossibles(possibles) {
		let approvedPossibles = [];
		if (possibles) {
			for (const possible of possibles) {
				if (possible.conds) {
				 await this.VAR_checkConditions(possible.conds).then((checkGood) => {
					 if (checkGood) {
						 approvedPossibles.push(possible);
					 }
				 })
				} else { approvedPossibles.push(possible); }
		 	}
		}
		return(approvedPossibles);
 	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Spielvariable abfragen und prüfen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	VAR_checkVariable(key, expectedValue, expectedToBeEqual) {
		return new Promise(resolve => {
	   		// aus dem Storage holen
	   		this.storage.get("var_"+key).then((value) => {
				//let currenctValue = JSON.parse(value);
				let currentValue = value;
				if (key === "tickets") { currentValue = this.varias.vars["sys_tickets"]; }
				if (key === "nutrition") { currentValue = this.varias.vars["sys_nutrition"]; }
		  		// Startwert "initial" annehmen, falls Variable nicht in Storage
				if (currentValue == null) { currentValue = "initial"; }

				if (expectedValue.indexOf) {
		  			if (expectedToBeEqual) {
						if (expectedValue.indexOf(">") > -1) { if (parseInt(currentValue) > parseInt(expectedValue)) { resolve(true); } else { resolve(false); } }
						else if (expectedValue.indexOf(">=") > -1) { if (parseInt(currentValue) >= parseInt(expectedValue)) { resolve(true); } else { resolve(false); } } 
						else if (expectedValue.indexOf("<") > -1) { if (parseInt(currentValue) < parseInt(expectedValue)) { resolve(true); } else { resolve(false); } } 
						else if (expectedValue.indexOf("<=") > -1) { if (parseInt(currentValue) <= parseInt(expectedValue)) { resolve(true); } else { resolve(false); } }
		  			} else {
						if (expectedValue.indexOf(">") > -1) { if (parseInt(currentValue) > parseInt(expectedValue)) { resolve(false); } else { resolve(true); } }
						else if (expectedValue.indexOf(">=") > -1) { if (parseInt(currentValue) >= parseInt(expectedValue)) { resolve(false); } else { resolve(true); } } 
						else if (expectedValue.indexOf("<") > -1) { if (parseInt(currentValue) < parseInt(expectedValue)) { resolve(false); } else { resolve(true); } } 
						else if (expectedValue.indexOf("<=") > -1) { if (parseInt(currentValue) <= parseInt(expectedValue)) { resolve(false); } else { resolve(true); } }
					}
				}
				  
				if (expectedToBeEqual) { if (currentValue.toString() == expectedValue.toString()) {  resolve(true); } else { resolve(false); } }
				else { if (currentValue.toString() !== expectedValue.toString()) { resolve(true); } else { resolve(false); } }
	   		})
		})
	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Spielvariable setzen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async VAR_setVariable(key, value) {
		//await this.data.setVariable(key, value);
		await this.varias.setVariable(key, value);

		//this.data.vars[key] = value;
		if (this.data.simstats_simulationOn) {
			this.data.simstats_log.unshift("Set var '" + key + "' to '" + value + "'");
			this.data.simstats_vars[key] = { key: key, value: value };
		}
		//this.telemetry.logEvent("variable_set", { key: key, value: value });
		/*
		if (key === "scene") {
			await this.ECH_download(value, false);
			await this.SCN_start(value, false, true);
		} else if (key === "ending") {
			await this.data.setVariable("end_"+value, true);
		} else if (key === "username") {
			let username; await this.data.getVariable("usr_name").then(result => username = result);
			if (username === null || username === "ask") {
				const alert = await this.alertController.create({
					subHeader: "Dein Name:",
					cssClass: 'eon__alert',
					inputs: [ { name: 'name', type: 'text', placeholder: 'Name' } ],
					buttons: [
						{ text: 'Cancel', role: 'cancel', handler: () => {} },
						{ text: 'Ok', handler: (data) => { this.varias.setVariable("usr_name", data.name); } }
					]
				})
				alert.present();
			}
		}
		*/
		//else if (key === "usergender") { this.varias.setVariable("usr_gender", value); }
		//else if (key === "rating") { this.events.publish("askForRating"); }
	   	return;
 	}	
	 
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard abschließen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_complete(storyboardId) {
		// Storyboard aufrufen
		let storyboard; await this.getCachedStoryboard(storyboardId).then((retrieved) => { storyboard = retrieved; })

		// Änderungen durchführen
		if (storyboard) {
			storyboard.info.running = false;
			storyboard.info.completed = true;

			if (storyboard.info.ugc) {
				this.telemetry.cugc_complete(storyboardId);
			}
		}
		this.setCachedStoryboard(storyboard);
		this.storeCachedStoryboard(storyboardId);

		// Änderungen in Storyboard-Registry spiegeln
		this.storyboardsRegistry.forEach(function(storyboardInRegistry) {
	   		if (storyboardInRegistry.i == storyboard.info.i) {
		  		storyboardInRegistry.running = false;
		  		storyboardInRegistry.completed = true;
	   		}
		})
		this.storeStoryboardsRegistry();

		this.varias.setVariable(storyboardId+"_std", true);
		this.varias.setVariable(storyboardId+"_rnn", false);
		this.varias.setVariable(storyboardId+"_cmp", true);

		// Chatübersicht neu rendern
		this.events.publish('updateStoryboardsOverview', false);
		this.events.publish("updateStoryboard", storyboardId);

		this.telemetry.setUserProperty("episode_"+storyboardId, "true");
		let mature: boolean = false; await this.varias.getVariable("usr_mature").then(result => mature = result);
		let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
		if (!storyboard.ugc && !storyboard.cugc) {
			this.telemetry.logEvent("episode_complete", { ep: storyboardId, day: day });
			this.telemetry.logEvent("episode_complete_"+storyboardId, { ep: storyboardId, day: day });
			if (!mature) { this.telemetry.logEvent("72h_episode_complete_"+storyboardId, {}); }
		}
		return;
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard starten */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_start(storyboardId, blocked: boolean = false) {
		if (this.signal.connected) {
			return this.STB_searchInStorage(storyboardId, blocked);
		} else {
			this.signal.alertNoConnection(this.localization.signal_blank)
		}
		return { start: false };
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard in Storage suchen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_searchInStorage(storyboardId, blocked: boolean = false) {
		// Storyboard aufrufen
		let storyboardInStorage; await this.getCachedStoryboard(storyboardId).then((result) => { storyboardInStorage = result; })
		// bereits im Storage?
		if (storyboardInStorage && !blocked) {
			if (storyboardInStorage.scenes) {
				// nach Neustart fragen
				const confirm = await this.alertController.create({
					subHeader: this.localization.content_restart_1, 
					cssClass: 'eon__alert',
					message: this.localization.content_restart_2,
					buttons: [
						{ 
							text: this.localization.cancel,
							handler: () => {}
						},{
							text: this.localization.restart,
							handler: () => { 
								this.STB_checkUpdates(storyboardInStorage);
							}
						}
					]
				}); 
				confirm.onDidDismiss().then(() => {
					return { start: true, method: "update" };
				})
				await confirm.present();
				return { start: true, method: "update" };
			} else {
				return this.STB_checkIfUnlocked(storyboardId);	
			}
		} else {
	   		return this.STB_checkIfUnlocked(storyboardId);
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard auf Updates prüfen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_checkUpdates(storyboardInStorage) {

		let dbConnection = this.fireStore.collection(this.localization.DB_episodes).doc(storyboardInStorage.info.i.toString());
		let storyboardInCloudInfo: any = [];
		await dbConnection.ref.get().then((doc) => {
			if (doc.exists) {
				storyboardInCloudInfo = doc.data();
			}
		})

		let askForUpdate = false;

		if (storyboardInCloudInfo.v > parseInt(storyboardInStorage.info.v)) { askForUpdate = true; }
		if (storyboardInStorage.info.u) {
			if (storyboardInCloudInfo.u > parseInt(storyboardInStorage.info.u)) { askForUpdate = true; }
		}

		if (askForUpdate) {
			// nach Update fragen
			const confirm = await this.alertController.create({
				   subHeader: this.localization.content_update_1,
				   cssClass: 'eon__alert',
				   message: this.localization.content_update_2,
				   buttons: [
					  {
						text: this.localization.noThanks,
						handler: () => { 
							// Storyboard neustarten
							this.STB_restart(storyboardInStorage.info.i, true);
						}
					  },{
						text: this.localization.yes,
						handler: () => {
							// Update laden und Storyboard starten
							this.STB_doUpdateAndStart(storyboardInStorage, storyboardInCloudInfo);
						}
					  }
				   ]
			});
			await confirm.present();
		} else {
			this.STB_restart(storyboardInStorage.info.i, true);
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Update von Storyboard herunterladen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_doUpdateAndStart(storyboardInStorage, storyboardInCloudInfo) {
		console.warn('%c STB_doUpdateAndStart ', 'background: #ff0000; color: #bada55');
		let loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_updatingEpisode });
		await loadingElement.present();
		await this.STB_remove(storyboardInStorage.info.i);
		let SCN_startId; await this.STB_download(storyboardInCloudInfo).then((returnedSCN_startId) => { SCN_startId = returnedSCN_startId })
		await this.SCN_start(SCN_startId, true, true);
		await loadingElement.dismiss();
		this.events.publish("STB_loaded", storyboardInStorage.i);
		this.navController.navigateRoot("/start", { queryParams: { episode: storyboardInStorage.i } });
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard löschen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	   async STB_remove(storyboardId) {
		console.warn('%c STB_remove ', 'background: #ff0000; color: #bada55');
		var that = this;

		Object.keys(that.globalQueue).forEach(function(key, index) {
			if (that.globalQueue[key].stbId == storyboardId) {
				that.QUE_removeMessage(that.globalQueue[key].sceneId, that.globalQueue[key].i, that.globalQueue[key].p);				
			}
		}, that.globalQueue);

		let storyboard; await this.getCachedStoryboard(storyboardId).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
		if (storyboard) {
			if (storyboard.scenes) {
				for await (let scene of storyboard.scenes) {
					await this.removeSceneFromCache(scene.i);
					await this.storeCachedScene(scene.i);
				}
			}
		}
		await this.removeStoryboardFromCache(storyboardId);
		await this.storeCachedStoryboard(storyboardId);

		return;
	}

	async STB_vote(storyboardId, voteUp) {
		for (let storyboardInRegistry of this.storyboardsRegistry) {
			if (storyboardInRegistry.i === storyboardId) {
				storyboardInRegistry.voteUp = true;
				storyboardInRegistry.voted = true;
			}
		}
		this.storeStoryboardsRegistry();
	}
		 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard neustarten */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_restart(storyboardId, startFirstScene: boolean) {
		console.warn('%c STB_restart ', 'background: #ff0000; color: #bada55');
		var that = this;

		Object.keys(that.globalQueue).forEach(function(key, index) {
			if (that.globalQueue[key].stbId == storyboardId) {
				that.QUE_removeMessage(that.globalQueue[key].sceneId, that.globalQueue[key].i, that.globalQueue[key].p);				
			}
		}, that.globalQueue);

		let startSceneId = undefined;

		let storyboard; await this.getCachedStoryboard(storyboardId).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
		storyboard.info.started = true;
		storyboard.info.running = true;
		storyboard.info.completed = false;
		storyboard.touched = 0;
		await this.setCachedStoryboard(storyboard);

		for await (let sceneInStoryboard of storyboard.scenes) {
			sceneInStoryboard.started = false;
			sceneInStoryboard.running = false;
			sceneInStoryboard.completed = false;
			sceneInStoryboard.hidden = false;
			sceneInStoryboard.touched = 0;

			let sceneInStorage; await that.getCachedScene(sceneInStoryboard.i).then((cachedScene) => { sceneInStorage = cachedScene; })
			if (sceneInStorage) {
				sceneInStorage.info.started = false;
				sceneInStorage.info.running = false;
				sceneInStorage.info.completed = false;
				sceneInStorage.info.touched = 0;
				sceneInStorage.messages.forEach((message) => {
					message.sent = false;
					message.read = false;
					message.used = false;
					message.wasThere = false;
					message.at = 0;
					if (message.d) { message.n = null; }
				})
				await that.setCachedScene(sceneInStorage);
				this.storeCachedScene(sceneInStorage.info.i);
			}

			if (sceneInStoryboard.s) {
				startSceneId = sceneInStoryboard.i;
			}
		}

		this.storyboardsRegistry.forEach((storyboardInRegistry) => {
			if (storyboardInRegistry.i == storyboard.info.i) {
				storyboardInRegistry.started = true;
				storyboardInRegistry.running = true;
				storyboardInRegistry.completed = false;
				storyboardInRegistry.archived = false;
			}
		})

		this.events.publish('updateStoryboardsOverview', true);
		this.events.publish("updateStoryboard", storyboardId);
		this.STB_sendToMenu(storyboard.info.i, storyboard.info.n, storyboard.info.ugc, storyboard.info.im);

		await this.sleep(2000);

		if (startFirstScene) {
			if (startSceneId) {
				let startScene; await this.getCachedScene(startSceneId).then((cachedScene) => { startScene = cachedScene; })
				await this.sleep(2000);
				this.SCN_start(startSceneId, true, true).then(() => {});
			}
		}

		return;
	}

	async SCN_restart(sceneId, stbId) {
		// Nachrichten aus Queue entfernen
		let that = this;
		Object.keys(that.globalQueue).forEach(function(key, index) {
			if (that.globalQueue[key].scn == sceneId) {
				that.QUE_removeMessage(that.globalQueue[key].sceneId, that.globalQueue[key].i, that.globalQueue[key].p);
			}
		}, that.globalQueue);

		let scene; await that.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; });
		scene.hidden = false;
		for (let message of scene.messages) {
			message.sent = false;
			message.read = false;
			message.used = false;
			message.wasThere = false;
			message.at = 0;
			if (message.d) { message.n = null; }
		}
		await this.setCachedScene(scene);
		this.storeCachedScene(sceneId);
		this.events.publish('updateStoryboardsOverview', true);
		this.events.publish("updateStoryboard", scene.info.stb);
		await this.sleep(2000);
		await this.SCN_start(sceneId, true, true).then(() => {});

		return;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard zurücksetzen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	async STB_reset(storyboardId) {
		console.warn('%c STB_reset ', 'background: #ff0000; color: #bada55');
		var that = this;

		Object.keys(that.globalQueue).forEach(function(key, index) {
			if (that.globalQueue[key].stbId == storyboardId) {
				that.QUE_removeMessage(that.globalQueue[key].sceneId, that.globalQueue[key].i, that.globalQueue[key].p);
			}
		}, that.globalQueue);

		let storyboard; await this.getCachedStoryboard(storyboardId).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* prüfen, ob Storyboard bereits freigeschalten */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_checkIfUnlocked(storyboardId) {
		this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEpisode });
		await this.loadingElement.present();

		let proceedWithStart: boolean = false;
		let unlocked = false; await this.varias.getVariable(storyboardId+"_unl").then(result => { if (result) { unlocked = true; } })
		let method = "unknown";
		if (unlocked) {
			proceedWithStart = true;
			method = "unlocked";
		} else {
			let started = false; await this.varias.getVariable(storyboardId+"_std").then(result => { if (result) { started = true; } })
			if (started) {
				let viaMembership = false; await this.varias.getVariable(storyboardId+"_sub").then(result => { if (result) { viaMembership = true; } })
				if (viaMembership) {
					let hasMembership = false; await this.varias.getVariable("sys_member").then( result => { if (result) { hasMembership = true; } })
					if (hasMembership) {
						proceedWithStart = true;
						method = "membership";
					}
				} else {
					proceedWithStart = true;
					method = "started";
				}
			}
		}

		if (proceedWithStart) {
			let dbConnection = this.fireStore.collection(this.localization.DB_episodes).doc(storyboardId);
			let storyboardInCloudInfo: any = [];
			await dbConnection.ref.get().then((doc) => {
				if (doc.exists) { storyboardInCloudInfo = doc.data(); }
			})
			this.STB_downloadNewAndStart(storyboardInCloudInfo, method);
			this.loadingElement.dismiss();
			return { start: true, method: "unlocked" }
		} else {
	   		return this.STB_checkConditions(storyboardId);
		}
 	}

   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard herunterladen und starten */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_downloadNewAndStart(storyboardInCloudInfo, method) {
		let that = this;
		//this.loadingElement.setContent("Episode laden...");

		let startSceneId; await this.STB_download(storyboardInCloudInfo).then((result) => { startSceneId = result; })

	   	// erste Scene beginnen
		await this.SCN_start(startSceneId, true, true).then(() => { 
			this.events.publish("STB_loaded", storyboardInCloudInfo.i);
			this.events.publish("STB_unlocked", storyboardInCloudInfo.i);
			this.navController.navigateRoot("/start", { queryParams: { episode: storyboardInCloudInfo.i } });
		})
		if (this.loadingElement) { this.loadingElement.dismiss(); }

		let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
		if (!storyboardInCloudInfo.ugc && !storyboardInCloudInfo.cugc) {
			this.telemetry.logEvent("episode_start", { ep: storyboardInCloudInfo.i, day: day, method: method });
			this.telemetry.logEvent("episode_start_"+storyboardInCloudInfo.i, { ep: storyboardInCloudInfo.i, day: day, method: method });
		}

		return;
	}

	downloadingFirst: boolean = false;
	async STB_downloadFirst(givenFirstEpisodeId = "pa1") {
		if (!this.downloadingFirst) {
			this.downloadingFirst = true;
			this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_connecting});
			this.loadingElement.present();

			let firstEpisodeId = "pa1";
			if (this.instance.instance === "gamerapp") { firstEpisodeId = "ga1"; } 
			else if (this.instance.instance === "horrorapp") { firstEpisodeId = "ma1"; }
			else if (this.instance.instance === "prevapp") { firstEpisodeId = givenFirstEpisodeId; }
	
			let storyboardInCloudInfo = null; await this.fireStore.collection(this.localization.DB_episodes).doc(firstEpisodeId).ref.get()
			.then((doc) => { storyboardInCloudInfo = doc.data(); })
	
			if (storyboardInCloudInfo) {
				let startSceneId = null; await this.STB_download(storyboardInCloudInfo).then((result) => { startSceneId = result; })
				await this.SCN_start(startSceneId, true, true).then(() => {})
				this.downloadingFirst = false;
				this.loadingElement.dismiss();
				return true;
			} else {
				this.downloadingFirst = false;
				this.loadingElement.dismiss();
				return false;
			}
		}
	}

	async STB_downloadSyncedAndStart(storyboardInCloudInfo, scenesToStart, method) {
		await this.STB_download(storyboardInCloudInfo);

		for await (let scene of scenesToStart) {
			await this.SCN_start(scene.replace("scn_",""), true, true);
		}
		if (this.loadingElement) { this.loadingElement.dismiss(); }

		let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
		if (!storyboardInCloudInfo.ugc && !storyboardInCloudInfo.cugc) {
			this.telemetry.logEvent("episode_start", { ep: storyboardInCloudInfo.i, day: day, method: method });
			this.telemetry.logEvent("episode_start_"+storyboardInCloudInfo.i, { ep: storyboardInCloudInfo.i, day: day, method: method });
		}

		return;
	}

	funnel_findRunningStoryboards: any = null;
	async SYNC_findRunningStoryboards() {
		clearTimeout(this.funnel_findRunningStoryboards);
		this.funnel_findRunningStoryboards = setTimeout(() => { this.SYNC_doFindRunningStoryboards(); }, 3000);
	}

	syncInProgress: boolean = false;
	async SYNC_doFindRunningStoryboards() {
		if (this.syncInProgress) { return; }
		if (this.instance.liberty) { return; }

		await this.loadStoryboardsRegistry();

		let storyboardsToStart = [];
		await this.meta.getEpisodes().then(async serials => {
			for await (let serial of serials) {
				for await (let episode of serial.ep) {
					let started = false; await this.varias.getVariable(episode.i+"_std").then(result => started = result);
					if (started) {
						let completed = false; await this.varias.getVariable(episode.i+"_cmp").then(result => completed = result);
						if (!completed) {
							let ignored = false; await this.varias.getVariable(episode.i+"_ign").then(result => ignored = result);
							if (!ignored) {
								let alreadyThere = false;
								for await (let storyboardInRegistry of this.storyboardsRegistry) {
									if (storyboardInRegistry.i === episode.i) { alreadyThere = true; }
								}
								if (!alreadyThere) { storyboardsToStart.push(episode); }
							}
						}
					}
				}
			}
		})

		if (!this.syncInProgress) {
			this.syncInProgress = true;
			this.SYNC_askForSyncOfRunningStoryboard(storyboardsToStart,0);
		}
	}

	syncLoadingElement: any = null;
	async SYNC_askForSyncOfRunningStoryboard(storyboardsToStart, index) {
		if (storyboardsToStart[index]) {
			let storyboard = storyboardsToStart[index];
			let startedScenes = [];
			let completedScenes = [];
			let currentUserUID = "none"; await this.fireAuth.currentUser.then( currentUser => { 
				if (currentUser) { currentUserUID = currentUser.uid  }
			});
			await this.fireStore.collection("sync").doc(currentUserUID).collection(storyboard.i).ref.get().then(async snapshot => {
				snapshot.forEach(async doc => {
					if (doc.id.indexOf("_std") > -1) { startedScenes.push(doc.id.replace("_std","")); }
					if (doc.id.indexOf("_cmp") > -1) { completedScenes.push(doc.id.replace("_cmp","")); }
				})
			})
			let scenesToStart = [];
			for await (let startedScene of startedScenes) {
				let alreadyCompleted = false;
				for await (let completedScene of completedScenes) {
					if (completedScene === startedScene) {
						alreadyCompleted = true;
					}
				}
				if (!alreadyCompleted) {
					scenesToStart.push(startedScene);
				}
			}
	
			let storyboardInCloudInfo = {}; await this.fireStore.collection(this.localization.DB_episodes).doc(storyboard.i).ref.get().then(doc => { storyboardInCloudInfo = doc.data(); })

			const alert = await this.alertController.create({
				header: "Laufende",
				subHeader: "Episode",
				cssClass: 'eon__alert',
				message: 'Episode "'+storyboard.t+'" jetzt synchronisieren?',
				buttons: [
					{ text: this.localization.cancel, handler: () => {
						this.varias.setVariable(storyboard.i+"_ign", true);
						this.SYNC_askForSyncOfRunningStoryboard(storyboardsToStart, index+1);
					} },
					{ text: 'Ok', handler: async () => {
						this.syncLoadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEpisode});
						this.syncLoadingElement.present();
						if (scenesToStart.length > 0) {
							await this.STB_downloadSyncedAndStart(storyboardInCloudInfo, scenesToStart, "restored");
						} else {
							await this.STB_downloadNewAndStart(storyboardInCloudInfo, "restored");
						}
						setTimeout(() => {
							this.syncLoadingElement.dismiss();
							this.SYNC_askForSyncOfRunningStoryboard(storyboardsToStart, index+1);
						}, 10000);
					} }
				]				
			});
			await alert.present();			
		} else {
			this.syncInProgress = false;
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Conditions für Storyboard prüfen */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_checkConditions(storyboardId) {
		// Storyboard-Info aus Cloud abrufen
		let dbConnection = this.fireStore.collection(this.localization.DB_episodes).doc(storyboardId);
		let storyboardInCloudInfo; await dbConnection.ref.get().then((doc) => { storyboardInCloudInfo = doc.data(); })

		let allConditionsFulfilled = true;
		let textOfUnfulfilledConditions = "";
		let numberOfUnfulfilledConditions = 0;
		// alle Conditions prüfen
		if (storyboardInCloudInfo) {
			if (storyboardInCloudInfo.conds) {
				for await (const condition of storyboardInCloudInfo.conds) {
				   await this.VAR_checkVariable(condition.key, condition.val, condition.equ).then((checkGood) => {
					  if (!checkGood) {
						 allConditionsFulfilled = false;
						 textOfUnfulfilledConditions += "<div>"+condition.t+"</div>"
						 numberOfUnfulfilledConditions += 1;
					  }
				   })
				}
		 }
		}

		return this.STB_checkMember(storyboardInCloudInfo);
	}

	async STB_checkMember(storyboardInCloudInfo) {
		let hasMembership = false; await this.varias.getVariable("sys_member").then(result => { if (result) { hasMembership = true; } })
		if (hasMembership) {
			this.varias.setVariable(storyboardInCloudInfo.i+"_sub", true);
			this.STB_downloadNewAndStart(storyboardInCloudInfo, "membership");
			return { start: true, method: "member" };
		} else {
			return this.STB_checkTicketsAndAds(storyboardInCloudInfo);
		}
	}

	async STB_checkTicketsAndAds(storyboardInCloudInfo) {
		let watchCountPercentage = 0; await this.ads.getWatchCount("episode", storyboardInCloudInfo.i).then(result => {
			if (result) { watchCountPercentage = Math.round(result.watchCountPercentage); }
		})
		let tickets = 0; await this.varias.getVariable("sys_tickets").then(result => {
			if (result) { tickets = result; }
		})
		let method = "tickets";
		if (watchCountPercentage > 0) { method = "ads"; }
		if (watchCountPercentage + tickets >= storyboardInCloudInfo.pr) {
			this.varias.decrementCurrency("tickets", storyboardInCloudInfo.pr-watchCountPercentage);
			this.varias.setVariable(storyboardInCloudInfo.i+"_sub", false);
			this.STB_downloadNewAndStart(storyboardInCloudInfo, method);
			return { start: true, method: method }
		} else {
			this.STB_requestPurchase(storyboardInCloudInfo);
			this.loadingElement.dismiss();
			return { start: false }
		}
	}

	async STB_checkTickets(storyboardInCloudInfo) {
		let hasEnoughTickets = false; await this.varias.checkCurrency("tickets", storyboardInCloudInfo.p).then( result => { if (result) { hasEnoughTickets = true; } })
		if (hasEnoughTickets) {
			this.varias.decrementCurrency("tickets", storyboardInCloudInfo.pr);
	   		this.STB_downloadNewAndStart(storyboardInCloudInfo, "tickets");
			return { start: true, method: "tickets" };
		} else {
			return this.STB_checkAds(storyboardInCloudInfo);
		}
	}
	
	async STB_checkAds(storyboardInCloudInfo) {
		let adsLegitForEpisode = false; await this.ads.checkLegibility("episode", storyboardInCloudInfo.i).then(result => adsLegitForEpisode = result);
		let watchCountPercentage = 0; await this.ads.getWatchCount("episode", storyboardInCloudInfo.i).then(result => {
			if (result) { watchCountPercentage = result.watchCountPercentage; }
		})
		await this.varias.getVariable("sys_tickets").then(tickets => {
			if (tickets) { watchCountPercentage = watchCountPercentage + tickets; }
		})

		if (adsLegitForEpisode && watchCountPercentage >= 100) {
			this.STB_downloadNewAndStart(storyboardInCloudInfo, "ads");
			return { start: true, method: "ads" };
		} else {
			this.STB_requestPurchase(storyboardInCloudInfo);
			this.loadingElement.dismiss();
			return { start: false }
		}
	}

	async STB_requestPurchase(storyboardInCloudInfo) {
		//this.events.publish("purrchaseEpisode", { storyboardInfo: storyboardInCloudInfo, storyboardsRegistry: this.storyboardsRegistry});
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboard (inkl. Scenes) herunterladen und in Storage speichern */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async STB_download(storyboardInCloudInfo) {
		let that = this;
		let now = Date.now();
		
		let latestVersion = storyboardInCloudInfo.v.toString();
		if (storyboardInCloudInfo.i === "pa1" || storyboardInCloudInfo.i === "pa2" || storyboardInCloudInfo.i === "pa3" || storyboardInCloudInfo.i === "pa4") {
			if (parseInt(storyboardInCloudInfo.v) < 20) { latestVersion = "20"; }
		}
		if (this.instance.instance === "prllxapp" || this.instance.instance === "voidapp") {
			if (latestVersion === "20") {
				if (storyboardInCloudInfo.i === "pa1" || storyboardInCloudInfo.i === "pa2" || storyboardInCloudInfo.i === "pa3") {
					latestVersion = "17"; // TBD remove later
				}
			}
		}

		let dbConnection = this.fireStore.collection(this.localization.DB_episodes).doc(storyboardInCloudInfo.i).collection("versions").doc(latestVersion).collection("scenes");
		let fullScenes = [];
		// neues Storyboard-Object anlegen
		let storyboard = {info: storyboardInCloudInfo, scenes: []}
		await dbConnection.ref.get().then((querySnapshot) => {
	   		querySnapshot.forEach(function(doc) {
				let scene = doc.data();
				storyboard.scenes.push(scene.info);
				fullScenes.push(scene);
	   		})
		})

		this.setCachedStoryboard(storyboard);
		this.storeCachedStoryboard(storyboard.info.i);

		let alreadyInRegistry = false;
		this.storyboardsRegistry.forEach(function(storyboardInfoInRegistry) {
	   		if (storyboardInfoInRegistry.i == storyboard.info.i) {
		  		// aktuelle Infos in Registry einsetzen
		  		storyboardInfoInRegistry = {...storyboard.info, started: true, running: true, touched: now};
				alreadyInRegistry = true;
	   		}
		})
		// falls nicht vorhanden, Eintrag in Registry ergänzen
		if (!alreadyInRegistry) {
			this.storyboardsRegistry.push({...storyboard.info, started: true, running: true, touched: now});
		}
		this.storeStoryboardsRegistry();

		this.varias.setVariable(storyboard.info.i+"_std", true);
		this.varias.setVariable(storyboard.info.i+"_rnn", true);

		let SCN_startId = null;
		for await (const scene of fullScenes) {
			await that.setCachedScene(scene);
			that.storeCachedScene(scene.info.i);
			if (scene.info.s) { SCN_startId = scene.info.i; }
		}

		// ID der ersten Scene zurückgeben
		return (SCN_startId);
	}

	async STB_requestFromFeed(storyboardId) {
		this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEpisode});
		this.loadingElement.present();
		
		await this.loadStoryboardsRegistry();
	}

	async STB_requestFromCUGC(storyboardId) {
		this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEpisode});
		this.loadingElement.present();

		let storyboardInfo = null; await this.fireStore.collection("cugc").doc("episodes").collection("episodes").doc(storyboardId).ref.get().then(doc => {
			if (doc.exists) {
				storyboardInfo = doc.data();
			}
		})

		await this.loadStoryboardsRegistry();
		let started = false; await this.varias.getVariable(storyboardId+"_std").then(result => { if (result) { started = true; } })
		let update = true;
		if (started) {
			for await (let stb of this.storyboardsRegistry) {
				if (stb.i === storyboardInfo.i) {
					if (parseInt(stb.v) >= parseInt(storyboardInfo.v)) {
						update = false;
					}
				}
			}
		}

		if (started) {
			const alert = await this.alertController.create({
				subHeader: "Neustart?",
				cssClass: 'eon__alert',
				message: "Neustart?",
				buttons: [
					{ text: this.localization.cancel, handler: () => {} },
					{ text: 'Ok', handler: async () => {
							this.loadingElement = await this.loadingController.create({ spinner: "bubbles", message: this.localization.content_loadingEpisode});
							this.loadingElement.present();
							this.STB_startFromCUGC(storyboardInfo, update);
					} }
				]				
			});
			this.loadingElement.dismiss();
			await alert.present();
		} else {
			this.STB_startFromCUGC(storyboardInfo, update);
		}

	}

	async STB_startFromCUGC(storyboardInfo, update) {
		if (update) {
			await this.STB_loadFromCUGC(storyboardInfo).then(async result => {
				await this.STB_importFromCUGC(result.storyboard, result.fullScenes).then(async (startSceneId) => {
					await this.STB_restart(result.storyboard.info.i, false);
					await this.SCN_start(startSceneId, true, true);
					this.loadingElement.dismiss();
					await this.navController.navigateRoot("/start", { queryParams: { episode: result.storyboard.info.i } });
					await this.navigation.pushChat(startSceneId, true, false, null);
					this.telemetry.cugc_start(storyboardInfo.i);
				})
			})
		} else {
			await this.STB_restart(storyboardInfo.i, true)
			this.loadingElement.dismiss();
			await this.navController.navigateRoot("/start", { queryParams: { episode: storyboardInfo.i } });
		}		
	}

	async STB_loadFromCUGC(storyboardInfo) {

		let fullScenes = [];
		let storyboardScenes = [];

		if (storyboardInfo) {
			await this.fireStore.collection("cugc").doc("episodes").collection("episodes").doc(storyboardInfo.i).collection("versions").doc(storyboardInfo.v).collection("scenes").ref.get().then(snapshot => {
				snapshot.forEach(function(doc) {
					let docData: any = doc.data();
					fullScenes.push(docData);
					storyboardScenes.push(docData.info);
				});
			})
		}

		let storyboard = { info: storyboardInfo, scenes: storyboardScenes };

		return { storyboard: storyboard, fullScenes: fullScenes }
	}

	async STB_importFromCUGC(storyboard, fullScenes) {
		let now = Date.now();

		this.setCachedStoryboard(storyboard);
		this.storeCachedStoryboard(storyboard.info.i);

		await this.loadStoryboardsRegistry();
		let index = this.storyboardsRegistry.findIndex(el => el.i === storyboard.info.i);
		if (index > -1) { this.storyboardsRegistry.splice(index, 1); }
		this.storyboardsRegistry.push({...storyboard.info, ugc: true, started: true, running: true, touched: now});
		this.storeStoryboardsRegistry();

		this.varias.setVariable(storyboard.info.i+"_std", true);
		this.varias.setVariable(storyboard.info.i+"_rnn", true);

		let SCN_startId = null;
		for await (let scene of fullScenes) {
			await this.setCachedScene(scene);
			this.storeCachedScene(scene.info.i);
			if (scene.info.s) { SCN_startId = scene.info.i; }
		}

		// ID der ersten Scene zurückgeben
		return (SCN_startId);
	}

	async STB_archive(storyboardId) {
		if (this.storyboardsRegistry.length == 0) { await this.loadStoryboardsRegistry(); }
		for await (let storyboardInfo of this.storyboardsRegistry) {
			if (storyboardInfo.i === storyboardId) {
				storyboardInfo.archived = true;
			}
		}
		await this.storeStoryboardsRegistry();
		this.events.publish('updateStoryboardsOverview', true);
		this.events.publish("updateStoryboard", storyboardId);
		return;
	}

	async STB_unarchive(storyboardId) {
		if (this.storyboardsRegistry.length == 0) { await this.loadStoryboardsRegistry(); }
		for await (let storyboardInfo of this.storyboardsRegistry) {
			if (storyboardInfo.i === storyboardId) {
				storyboardInfo.archived = false;
				this.STB_sendToMenu(storyboardInfo.i, storyboardInfo.n, storyboardInfo.ugc, storyboardInfo.im);
			}
		}
		await this.storeStoryboardsRegistry();
		this.events.publish('updateStoryboardsOverview', true);
		this.events.publish("updateStoryboard", storyboardId);
		return;
	}

	async STB_sendToMenu(id, name, ugc, cover) {
		let alreadyThere = false;
		if (ugc) {
			for (let episode of this.menu.userEpisodes) {
				if (episode.i === id) { alreadyThere = true; }
			}
			if (!alreadyThere) {
				this.menu.userEpisodes.push({ i: id, n: name, ugc: ugc, im: cover });
				this.menu.userEpisodes.sort(function(a,b){return a.n.charCodeAt(0)-b.n.charCodeAt(0)});
			}
		} else {
			for (let episode of this.menu.episodes) {
				if (episode.i === id) { alreadyThere = true; }
			}
			if (!alreadyThere) {
				this.menu.episodes.push({ i: id, n: name, ugc: ugc, im: cover });
				this.menu.episodes.sort(function(a,b){return a.n.charCodeAt(0)-b.n.charCodeAt(0)});
			}
		}
	}
	 
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Storyboards-Overview rendern */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	async createStoryboardsOverview(forcedEpisodeId, all: boolean = true, singleStoryboardId: string = "", simple: boolean = false) {
		let start = Date.now()
		let that = this;
		let list = [];
		let now = Date.now();

		if (this.storyboardsRegistry.length == 0) { await this.loadStoryboardsRegistry(); }

		let hasOnlyArchiveds: boolean = true;
		for await (const storyboardInfo of this.storyboardsRegistry) {
			if (!storyboardInfo.archived) { hasOnlyArchiveds = false; }
		}

		// Storyboard-Registry abarbeiten
		for await (const storyboardInfo of this.storyboardsRegistry) {
			if (all || singleStoryboardId === storyboardInfo.i) {
				// ist das Storyboard eine Episode?
				if (storyboardInfo.epi) {

					// soll das Storyboard in die Chatübersicht?
					if (storyboardInfo.completed) {
						let isLatestOne = true;
						for (const storyboardInfo2 of this.storyboardsRegistry) {
							if (storyboardInfo2.touched > storyboardInfo.touched) {
									isLatestOne = false;
							}
						}
						// Storyboard überspringen falls abgeschlossen und nicht jüngstes
						if (!isLatestOne && storyboardInfo.i != forcedEpisodeId) {
							continue;
						}
					}
				
					if (storyboardInfo.archived) {
						let isLatestOne = true;
						for (const storyboardInfo2 of this.storyboardsRegistry) {
							if (storyboardInfo2.touched > storyboardInfo.touched) {
								isLatestOne = false;
							}
						}
						if ((!hasOnlyArchiveds || !isLatestOne) && storyboardInfo.i != forcedEpisodeId) {
							continue;
						}
					}

					if (simple) {
						list.push(storyboardInfo);
						continue;
					}

					// neues Episoden-Object für Chatliste anlegen
					let episode = {...storyboardInfo};
					episode.chats = [];
					episode.touched = 0;

					// vollständiges Storyboard (inkl. aller Scenes) aufrufen
					let fullStoryboard; await this.getCachedStoryboard(storyboardInfo.i).then((cachedStoryboard) => { fullStoryboard = cachedStoryboard; })
					if (fullStoryboard) {
						if (fullStoryboard.scenes) {
							for await (const scene of fullStoryboard.scenes) {
								if (scene.started && !scene.hidden) {
	
									let chatAlreadyThere = false;
									for await (const chat of episode.chats) {
										// ist dies eine Szene desselben Chats?
										if (chat.i == scene.sc.i) {
											// ist diese Szene aktueller?
											if (scene.touched > chat.touched) {
												// Scene als aktuellste Szene merken
												chat.lastScene = scene;
												chat.touched = scene.touched;
												if (scene.touched > episode.touched) { episode.touched = scene.touched; }
											}
											// ggf. Scene als letzte abgeschlossene Scene des Chats merken
											if (scene.completed && scene.touched > chat.lastCompletedScene.touched) {
												chat.lastCompletedScene = scene;
											}
											chatAlreadyThere = true;
										}
									}
	
									// ggf. Chat anlegen falls noch nicht vorhanden
									if (!chatAlreadyThere) {
										let newChat = {...scene.sc, lastScene: {...scene}, lastCompletedScene: {...scene}, touched: scene.touched};
										if (scene.touched > episode.touched) {
											episode.touched = scene.touched;
										}
										episode.chats.push(newChat);
									}
	
								}
							}
						}
					}
					list.push(episode);
					
				}
			}
		}

		if (simple) { return list; }

		// ungelesene Nachrichten ermitteln
		let unreadMessages = 0;
		// Liste nach Zeit sortieren
		list = list.sort(function(a,b){return b.touched-a.touched});
		// für alle Episoden der Liste...
		for await (let episode of list) {
	   		// Chats der Episode nach Zeit sortieren
			episode.chats = episode.chats.sort(function(a, b){return b.touched - a.touched});
	   		// für alle Chats einer Episode der Liste...
	   		for await (let chat of episode.chats) {
		  		// letzte Scene aufrufen
		  		let latestScene; await this.getCachedScene(chat.lastScene.i).then((cachedScene) => { latestScene = cachedScene; })
		  		chat.latestMessage = {at:0};
		  		chat.unreadMessages = 0;
		  		// jünste Message der jüngsten Scene ermitteln
		  		// + ungelesene Messages ermitteln
		  		for await (const message of latestScene.messages) {
			 		if (message.sent) {
						if (message.at > chat.latestMessage.at) {
							chat.latestMessage = message;
						}
						if (!message.read && !message.d) {
				   			chat.unreadMessages += 1;
				   			unreadMessages += 1;
						}
			 		}
		  		}

		  		// Zeitanzeige für jüngste Nachricht ermitteln
		  		let timePassed = now - chat.latestMessage.at;
		  		if (timePassed < 1000*60*60*24) {
		  		} else if (timePassed < 1000*60*60*48) {
			 		chat.latestMessage.moment = this.localization.date_yesterday;
		  		} else {
		  		}

		  		if (!chat.latestMessage.t) {
			 		let fullCompletedScene; await this.getCachedScene(chat.lastCompletedScene.i).then((cachedScene) => { fullCompletedScene = cachedScene; })
			 		chat.latestMessage = {at: 0};
			 		for await (const message of fullCompletedScene.messages) {
						if (message.sent) {
				   			if (message.at > chat.latestMessage.at) {
								chat.latestMessage = message;
				   			}
						}
			 		}
				}  

		  		// anhand Queue ermitteln, welche Chats auf eine Message warten
		  		Object.keys(this.globalQueue).forEach(function(key, index) {
					let keySplitted = key.split("_");
					let echoIdOfQueueItem = keySplitted[0]+"_"+keySplitted[1];
			 		if (echoIdOfQueueItem == chat.lastScene.i) {

						let queueItem = that.globalQueue[key];
						if (queueItem.at > now) { 
							chat.waiting = true;
						}
						if (queueItem.startTyping < now) {
							chat.typing = true; 
						} else {
							if (chat.startTyping) { if (queueItem.startTyping < chat.startTyping) { chat.startTyping = queueItem.startTyping; } } 
							else { chat.startTyping = queueItem.startTyping; }
						}

			 		}
				})
				  
				chat.hasSecondaryContact = false;
				chat.secondaryContactId = "";
				chat.secondaryContactName = "";
				chat.secondaryContactImage = "";

				if (chat.latestMessage.c && chat.lastScene.sc) {
					if (chat.latestMessage.c.i != chat.lastScene.sc.i) {
						chat.hasSecondaryContact = true;
						chat.secondaryContactId = chat.latestMessage.c.i;
						chat.secondaryContactName = chat.latestMessage.c.n;
						chat.secondaryContactImage = chat.latestMessage.c.im;
					}
				}
			}
			await this.varias.getVariable(episode.i+"_skp").then(result => { if (result) { episode.skippable = true; } })
		}

		if (all) {
			this.data.unreadMessages = unreadMessages;
			this.notifications.setBadge(unreadMessages, this.globalQueue);
		}

		let meta_episodes = [];

		for await (let episode of list) {

			if (episode.completed) {
				await this.meta.getEpisodes().then(result => meta_episodes = result);
				let episodeMeta: any = {};
				for (let serial of meta_episodes) {
					for (let episodeInSerial of serial.ep) {
						if (episodeInSerial.i === episode.i) {
							episodeMeta = episodeInSerial;
							episodeMeta.serial = serial.i;
							episodeMeta.serialTitle = serial.t;
						}
					}
				}
				if (episodeMeta) {
					episode.season = episodeMeta.s;
					episode.episode = episodeMeta.e;
					episode.serialTitle = episodeMeta.serialTitle;
					for (let serial of meta_episodes) {
						if (serial.i === episodeMeta.serial) {
							for (let episodeInSerial of serial.ep) {
								if (episodeInSerial.o === episodeMeta.o+1) {

									let nextEpisodeAlreadyRunning = false;
									for (let storyboardInRegistry of this.storyboardsRegistry) {
										if (storyboardInRegistry.i === episodeInSerial.i) {
											if (storyboardInRegistry.running) { nextEpisodeAlreadyRunning = true; }
										}
									}
									if (!nextEpisodeAlreadyRunning) {

										episode.nextEpisode = episodeInSerial;
										episode.nextEpisode.reasers = [];
										let fullNextStoryboard = null; await this.getCachedStoryboard(episode.nextEpisode.i).then((result) => { fullNextStoryboard = result; })
										if (fullNextStoryboard) {
											if (fullNextStoryboard.info) {
												if (fullNextStoryboard.info.r) {
													for (let reaser of fullNextStoryboard.info.r) {
														let legit = true;
														for (let condition of reaser.conds) {
															if (condition.equ || condition.key != fullNextStoryboard.info.i+"_cmp" || condition.val != "true") { legit = false; }
														}
														if (legit) { episode.nextEpisode.reasers.push(reaser); }
													}
												}
											}
										
											episode.nextEpisode.releaseInfo = fullNextStoryboard.info.rl;
											episode.nextEpisode.live = fullNextStoryboard.info.l;

											episode.hasNextEpisode = true;
											episode.nextEpisode.serialTitle = serial.t;
											episode.nextEpisode.price = fullNextStoryboard.info.pr;
											if (episode.nextEpisode) {
												let free = false;
												await this.varias.getVariable(fullNextStoryboard.info.i+"_std").then(result => {if (result) { free = true; }})
												await this.varias.getVariable(fullNextStoryboard.info.i+"_cmp").then(result => {if (result) { free = true; }})
												if (free) {
													episode.nextEpisode.price = 0;
												}
											}
											episode.nextEpisode.fullNextStoryboard = fullNextStoryboard;

											episode.nextEpisodeCompleted = false;
											if (episode.nextEpisode.fullNextStoryboard.info.completed) {
												episode.nextEpisodeCompleted = true;
											}

											episode.nextEpisodeInList = false;
											if (episode.nextEpisode) {
												for await (let episodeInList of list) {
													if (episodeInList === episode.nextEpisode.i) {
														episode.nextEpisodeInList = true;
													}
												}
											}										
										}
										
									}

								}
							}
						}
					}
				}
				if (!episode.hasNextEpisode) {
				}
			}
		}

		let end = Date.now();
		return list;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   	/* Echoes-Overview rendern */
   	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	async createEchoesOverview() {
		let scenes = [];
		let sets = [];
		let now = Date.now();

		if (this.echoesRegistry.length == 0) { await this.loadEchoesRegistry(); }

		let completedEchoes = [];
		for await (let sceneInfo of this.echoesRegistry) { if (sceneInfo.completed) { completedEchoes.push({ ...sceneInfo }); } }
		completedEchoes = completedEchoes.sort(function(a,b){return b.touched-a.touched});
		let lastEchoes = [];
		if (completedEchoes[0]) { lastEchoes[completedEchoes[0].i] = true; }
		if (completedEchoes[1]) { lastEchoes[completedEchoes[1].i] = true; }
		if (completedEchoes[2]) { lastEchoes[completedEchoes[2].i] = true; }

		for await (const sceneInfo of this.echoesRegistry) {
			let legit = false; if ((sceneInfo.started && !sceneInfo.completed) || (sceneInfo.started && sceneInfo.completed && lastEchoes[sceneInfo.i])) { legit = true; }
			if (legit) {
				let newSceneEntry = {...sceneInfo}

				let scene; await this.getCachedScene(sceneInfo.i).then((cachedScene) => { scene = cachedScene; })

				let latestMessage = undefined;
				let foundLatestMessage = false;
				let unreadMessages = 0;
				for await (const message of scene.messages) {
					if (message.sent) {
						if (!message.read && !message.d) { unreadMessages++; }
						if (latestMessage) {
							if (message.at > latestMessage.at) {
								latestMessage = {...message};
								foundLatestMessage = true;
							}
						} else {
							latestMessage = {...message};
							foundLatestMessage = true;
						}
					}
				}
				newSceneEntry.latestMessage = latestMessage;
				newSceneEntry.hasLatestMessage = foundLatestMessage;
				
				newSceneEntry.latestMessageContactImage = "";
				newSceneEntry.latestMessageContactName = "";
				newSceneEntry.latestMessageText = "";
				newSceneEntry.latestMessageContactDiffers = false;
				newSceneEntry.latestMessageAt = 9999999999999;
				newSceneEntry.typing = false;
				newSceneEntry.unread = unreadMessages;
				newSceneEntry.waiting = false;

				if (latestMessage) {
					newSceneEntry.latestMessageText = latestMessage.t;
					newSceneEntry.latestMessageAt = latestMessage.at;
					if (latestMessage.c.i === sceneInfo.sc.i) {
						newSceneEntry.latestMessageContactName = sceneInfo.sc.n;
						if (sceneInfo.sc.im) {
							newSceneEntry.latestMessageContactImage = sceneInfo.sc.im;
						} else {
							if (latestMessage) {
								if (latestMessage.c) {
									newSceneEntry.latestMessageContactImage = latestMessage.c.im;
								}
							}
						}
						newSceneEntry.latestMessageContactDiffers = false;
					} else {
						newSceneEntry.latestMessageContactName = latestMessage.c.n;
						newSceneEntry.latestMessageContactImage = latestMessage.c.im;
						newSceneEntry.latestMessageContactDiffers = true;
					}
				}

				var that = this;
				Object.keys(this.globalQueue).forEach(function(key, index) {
					let keySplitted = key.split("_");
					let echoIdOfQueueItem = keySplitted[0];
			 		if (echoIdOfQueueItem == newSceneEntry.i) {

						let queueItem = that.globalQueue[key];
						if (queueItem.at > now) { newSceneEntry.waiting = true; }
						if (queueItem.startTyping < now) { newSceneEntry.typing = true; }

			 		}
				})

				if (newSceneEntry.set) {
					newSceneEntry.set.forEach(function(set) {
						let setAlreadyExists = false;
						sets.forEach(function(existingSet) {
							if (existingSet.i == set) {
								existingSet.scenes.push(newSceneEntry);
								setAlreadyExists = true;
							}
						})
						if (!setAlreadyExists) {
							let newSet = {
								i: set,
								scenes: []
							};
							newSet.scenes.push(newSceneEntry);
							sets.push(newSet);
						}
					})
				} else {
					let isCompletedTutoring = false;
					if (scene.completed) {
						if (scene.info.sc.i === "helpBot") {
							isCompletedTutoring = true;
						}
					}
					let isPurrchaseTutoring = false; if (scene.info.i.indexOf("hlp_purr_") > -1) { isPurrchaseTutoring = true; }
					let isAd = false; if (scene.info.ad) { isAd = true; }

					if ((!isCompletedTutoring || this.tutoringsThisSession[newSceneEntry.i]) && !isPurrchaseTutoring && !isAd) {
						scenes.push(newSceneEntry);
						this.tutoringsThisSession[newSceneEntry.i] = true;
					}
				}

			}
		}

		sets.forEach(function(set) {
			set.scenes = set.scenes.sort(function(a,b){return b.latestMessageAt-a.latestMessageAt});
			set.latestMessageAt = set.scenes[0].latestMessageAt;
		})

		let list = [];

		scenes.forEach(function(scene) {
			let newListItem = {
				...scene,
				type: "scene",
				at: scene.latestMessageAt
			}
			list.push(newListItem);
		})

		sets.forEach(function(set) {
			let newListItem = {
				...set,
				type: "set",
				at: set.latestMessageAt
			}
			list.push(newListItem);
		})

		list = list.sort(function(a,b){return b.latestMessageAt-a.latestMessageAt});

		return list;
	}

	sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	
	async skip(sceneInfo) {
		var that = this;
		let now = Date.now();

		let skippedItem = undefined;

		Object.keys(this.globalQueue).forEach(function(key, index) {
			if (that.globalQueue[key].sceneId == sceneInfo.i) {
				if (skippedItem) {
					if (that.globalQueue[key].at < skippedItem.at) {
						skippedItem = that.globalQueue[key];
					}
				} else {
					skippedItem = that.globalQueue[key];
				}
			}
		}, this.globalQueue);

		let timeGained = skippedItem.at - now;
		if (timeGained < 0) { timeGained = 0; }

		let minutesGained = Math.floor(timeGained / 60000);
		let minutesWaited = skippedItem.p - minutesGained;
		this.telemetry.logEvent("pause_skipped", {messageId: skippedItem.i, pause: skippedItem.p, minutesWaited: minutesWaited, minutesGained: minutesGained})

		let messagesToAdjustInCurrentScene = [];

		Object.keys(this.globalQueue).forEach(function(key, index) {
			/*
			if (that.globalQueue[key].at-now >= timeGained || that.globalQueue[key].sceneId === sceneInfo.i) {
				that.globalQueue[key].at = that.globalQueue[key].at - timeGained;
				that.globalQueue[key].startTyping = that.globalQueue[key].startTyping - timeGained;
				that.notifications.update(that.globalQueue[key], that.globalQueue);
				if (that.globalQueue[key].sceneId == sceneInfo.i) {
					messagesToAdjustInCurrentScene.push(that.globalQueue[key]);
				}
			}
			*/
			if (that.globalQueue[key].sceneId == sceneInfo.i) {
				that.globalQueue[key].at = that.globalQueue[key].at - timeGained;
				that.globalQueue[key].startTyping = that.globalQueue[key].startTyping - timeGained;				
				messagesToAdjustInCurrentScene.push(that.globalQueue[key]);
			}			
		}, this.globalQueue);

		this.storeQueue();

		return messagesToAdjustInCurrentScene;
	}

	async resetApp() {
		this.storyboardsCache = [];
		this.storyboardsRegistry = [];
		this.echoesRegistry = [];
		this.scenesCache = [];
		await this.storage.clear();
		this.createEchoesOverview();
		this.createStoryboardsOverview("none");
		this.events.publish('updateStoryboardsOverview', true);
		this.events.publish("updateStoryboard", "all");
		this.events.publish('updateEchoesOverview', true);
		window.location.href = "index.html";
	}

	async CNT_meet(contactId) {
		this.varias.setVariable("cnt_"+contactId, true);
	}	

	///////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////// DEV ////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////

	logQueueInfo() {
		this.doLogQueueInfo();
	}

	doLogQueueInfo() {
		var that = this;
		let now = Date.now();
		let logs = []
		Object.keys(this.globalQueue).forEach(function(key, index) {
			let item = that.globalQueue[key];
			let restTime = Math.floor((item.at-now)/60000);
			let message = item.t.substring(0, 30);
			logs.push({ "restTime": restTime, "message": message});
		});
		console.table(logs);
	}

	async setPauseInAllOfGamer() {
		let storyboard:any = {}; await this.getCachedStoryboard("ga1").then(result => storyboard = result)
		for (let sceneOfStoryboard of storyboard.scenes) {
			let scene: any = {}; await this.getCachedScene(sceneOfStoryboard.i).then(result => scene = result);
			for await (let message of scene.messages) {
				if (message.in) { 
					message.p_regular = JSON.parse(JSON.stringify(message.p));
					message.p = 1;
				}
			}
			await this.setCachedScene(scene);
			this.storeCachedScene(scene.i);
		}
	}

	async unsetPauseInAllOfGamer() {
		let storyboard:any = {}; await this.getCachedStoryboard("ga1").then(result => storyboard = result)
		for (let sceneOfStoryboard of storyboard.scenes) {
			let scene: any = {}; await this.getCachedScene(sceneOfStoryboard.i).then(result => scene = result);
			for await (let message of scene.messages) {
				if (message.in) { 
					message.p = JSON.parse(JSON.stringify(message.p_regular))
				}
			}
			await this.setCachedScene(scene);
			this.storeCachedScene(scene.i);
		}
	}

	async logStuff() {
		if (this.storyboardsRegistry.length == 0) { await this.loadStoryboardsRegistry(); }
	}

	missingLocalizations = [];
	run = 0;
	async switchLanguage() {
		let then = Date.now();
		this.run = then;

		let loading = await this.loadingController.create({ spinner: "bubbles", message: this.localization.oneMomentPlease});
		loading.present();

		if (this.storyboardsRegistry.length == 0) { await this.loadStoryboardsRegistry(); }

		this.missingLocalizations = [];

		for await (let storyboardInfo of this.storyboardsRegistry) {
			if (storyboardInfo.lang != this.localization.lang) {
				await this.localizeStoryboard(storyboardInfo);
			}
		}
		await this.immediatelyStoreStoryboardsRegistry();

		let now = Date.now();
		let seconds = Math.round((now-then)/1000);

		loading.dismiss();

		this.events.publish("updateStoryboardsOverview", false);
		this.events.publish("updateStoryboard", "all");
		this.events.publish("updateEchoesOverview");
		this.events.publish("updateTutorings");

		if (this.missingLocalizations.length > 0) {
			let missingLocalizationsMessage = "";
			for (let missing of this.missingLocalizations) {
				missingLocalizationsMessage = missingLocalizationsMessage + missing.n + ", ";
			}
			const alert = await this.alertController.create({
				subHeader: this.localization.language,
				cssClass: 'eon__alert',
				message: this.localization.localization_missingLocalizations_b + " " + missingLocalizationsMessage,
				buttons: [
					{ 
						text: 'Ok', handler: () => {
							this.restartApp();
						}
					}
				]				
			});
			await alert.present();
		} else {
			this.restartApp();
		}
	}

	async restartApp() {
		let loading = await this.loadingController.create({ spinner: "bubbles", message: this.localization.oneMomentPlease});
		loading.present();
		setTimeout(() => { window.location.href = "index.html"; }, 10000);
	}

	async localizeStoryboard(storyboardInfo) {
		let storyboard; await this.getCachedStoryboard(storyboardInfo.i).then(stb => storyboard = stb);

		let info_ALT;
		var that = this;
		await this.fireStore.collection(this.localization.DB_episodes).doc(storyboardInfo.i).ref.get().then(doc => {
			if (doc.exists) {
				info_ALT = doc.data();
				if (this.instance.instance === "prllxapp" || this.instance.instance === "voidapp") {
					if (info_ALT.v === "20") {
						if (storyboardInfo.i === "pa1" || storyboardInfo.i === "pa2" || storyboardInfo.i === "pa3") {
							info_ALT.v = "17"; // TBD remove later
						}
					}
				}
			}
		})

		if (info_ALT) {
			if (info_ALT.l) {

				let scenes_ALT = [];
				await this.fireStore.collection(this.localization.DB_episodes).doc(storyboardInfo.i).collection("versions").doc(""+info_ALT.v).collection("scenes").ref.get().then(async function(querySnapshot) {
					await querySnapshot.forEach(function(doc) {
						let scene = doc.data();
						scenes_ALT.push(scene);
					})
				})

				for await (let sceneInfo of storyboard.scenes) {
					let scene; await this.getCachedScene(sceneInfo.i).then(scn => scene = scn);
					if (scene) {
						let scene_ALT = scenes_ALT.find(scn => scn.info.i === scene.info.i);
						if (scene_ALT) {
		
							for await (let message of scene.messages) {
								let message_ALT = scene_ALT.messages.find(msg => msg.i === message.i);
								if (message_ALT) {
									if (message.d) {
										message.p = message_ALT.p;
										if (message.posI != undefined && message.posI != null) {
											message.t = message_ALT.p[message.posI].t;
											message.n = message_ALT.p[message.posI].n;
											message.st = message_ALT.p[message.posI].st;
											message.c = message_ALT.p[message.posI].c;
											message.cro = message_ALT.p[message.posI].cro;
											message.nar = message_ALT.p[message.posI].nar;
											message.c = message_ALT.p[message.posI].c;
										}
									} else {
										message.t = message_ALT.t;
										message.stg = message_ALT.stg;
										message.sta = message_ALT.sta;
										message.g = message_ALT.g;
										message.c = message_ALT.c;
									}
								}
							}
		
							scene.info.t = scene_ALT.info.t;
							scene.info.d = scene_ALT.info.d;

							sceneInfo.t = scene_ALT.info.t;
							sceneInfo.d = scene_ALT.info.d;
		
						}
						this.storeCachedScene(scene.info.i);
					}
				}

				if (!info_ALT.lang) { info_ALT.lang = "DE"; }

				storyboard.info.d = info_ALT.d;
				storyboard.info.r = info_ALT.r;
				storyboard.info.lang = info_ALT.lang;

				storyboardInfo.d = info_ALT.d;
				storyboardInfo.r = info_ALT.r;
				storyboardInfo.lang = info_ALT.lang;

				await this.setCachedStoryboard(storyboard);
				await this.immediatelyStoreCachedStoryboard(storyboard.info.i);

			} else { 
				this.missingLocalizations.push(storyboardInfo);
			}
		} else {
			this.missingLocalizations.push(storyboardInfo);
		}
		return
	}

	async EFF_modifyMessage(sceneId, messageId, newText) {
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })
		if (scene) {
			if (scene.messages) {
				scene.messages.forEach(message => { if (message.i === messageId) { message.t = newText; } })
				this.setCachedScene(scene);
				await this.storeCachedScene(sceneId);
			}
		}
	}

	async EFF_hideMessage(sceneId, messageId) {
		let scene; await this.getCachedScene(sceneId).then((cachedScene) => { scene = cachedScene; })
		if (scene) {
			if (scene.messages) {
				scene.messages.forEach(message => { if (message.i === messageId) { message.e_va = true; } })
				this.setCachedScene(scene);
				await this.storeCachedScene(sceneId);
			}
		}
	}

	async EFF_hideScene(storyboardId, sceneId) {
		let storyboard; await this.getCachedStoryboard(storyboardId).then((cachedStoryboard) => { storyboard = cachedStoryboard; })
		if (storyboard) {
			if (storyboard.scenes) {
				for (let scene of storyboard.scenes) {
					if (scene.i === sceneId) {
						scene.hidden = true;
						this.events.publish("updateStoryboardsOverview", true);
						this.events.publish("updateStoryboard", storyboardId);
					}
				}
				this.setCachedStoryboard(storyboard);
				await this.storeCachedStoryboard(storyboardId);
			}
		}
	}

}