import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { IonContent, DomController, Platform } from '@ionic/angular';
import { DataService } from 'src/app/system/data.service';
import { VibrationService } from 'src/app/system/vibration.service';
import { LocalizationService } from 'src/app/system/localization.service';
import { VariasService } from 'src/app/system/varias.service'
import { DateformatService } from 'src/app/system/dateformat.service'

import { Events } from '../../system/events.service'

@Component({
  	selector: 'eon-chat-card',
  	templateUrl: './chat-card.component.html',
  	styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {
	@Input('title') title: any;
	@Input('subtitle') subtitle: any;
	@Input('message') message: any;
	@Input('pic') pic: any;
	@Input('minipic') minipic: any;
	@Input('fullText') fullText: boolean = false;
	@Input('pricetag') pricetag: boolean = false;
	@Input('recap') recap: boolean = false;
	@Input('member') member: boolean = false;
	@Input('tickets') tickets: number = 0;
	@Input('skippable') skippable: boolean = false;
	@Input('at') at: any = undefined;
	@Input('unlock') unlock: boolean = false;
	@Input('inList') inList: boolean = false;

	@Input('typing') typing: any = false;
	@Input('unread') unread: any = 0;
	@Input('waiting') waiting: any = false;
	@Input('completed') completed: any = false;
	@Input('flag') flag: boolean = false;
	@Input('hasPic') hasPic: boolean = true;
	@Input('lessShadow') lessShadow: boolean = false;
	@Input('lessMargin') lessMargin: boolean = false;
	@Input('noMargin') noMargin: boolean = false;
	@Input('bottomMargin') bottomMargin: boolean = false;
	@Input('frosted') frosted: boolean = false;
	@Input('hasBox') hasBox: boolean = true;

	@ViewChild("boxLasercut", { static: false } ) boxLasercut: ElementRef;

	@Output() eonClick = new EventEmitter<any>();

	showMinipic: boolean = false;
	scrolling: boolean = false;
	sliding: boolean = false;
	active: boolean = false;
	clickInitiated: boolean = false;
	showPrice: boolean = false;

	pic2: string = "";

	touchDelayOn: boolean = true;
	formatedDate = "";

  	constructor(
		private renderer: Renderer2,
		private events: Events,
		private domCtrl: DomController,
		private platform: Platform,
		public data: DataService,
		public vibration: VibrationService,
		private localization: LocalizationService,
		private varias: VariasService,
		private dateformat: DateformatService
	) {
		this.pic2 = "mockups/"+this.data.getSwapMockup();
	}

  	ngOnInit() {
		if (this.tickets > 0 && !this.member) { this.showPrice = true; }

		if (this.pic === this.minipic) { this.showMinipic = false; } else { 
			if (this.minipic != "contacts/") {
				this.showMinipic = true;
			}
		}

		if (this.message === "" || this.message === null || this.message === undefined) {
			if (!this.unlock) {
				this.message = "["+this.localization.chat_startChat+"]";
			}
		}

		if (this.member) { this.skippable = true; }

		this.formatDate();
	}

	ngOnChanges(changes: SimpleChanges) {

		if (changes) {
			if (changes.minipic) {
				if (changes.minipic.currentValue) {
					this.minipic = changes.minipic.currentValue;
					if (this.pic === this.minipic) {
						this.showMinipic = false;
					} else {
						if (this.minipic === "contacts/") {
							this.showMinipic = false;
						} else {
							this.showMinipic = true;
						}
					}
				} else {
					this.showMinipic = false;
				}
			}
			if (changes.tickets) {
				this.tickets = changes.tickets.currentValue;
				if (this.tickets > 0 && !this.member) { this.showPrice = true; } else { this.showPrice = false; }
			}
			if (changes.member) {
				this.member = changes.member.currentValue;
				if (this.tickets > 0 && !this.member) { this.showPrice = true; } else { this.showPrice = false; }
			}
			if (changes.at) {
				this.at = changes.at.currentValue;
				this.formatDate();
			}
		}
	}

	formatDate() { 
		if (this.at > 0) { this.formatedDate = this.dateformat.format(this.at); }
		else { this.formatedDate = ""; }
	}

}
