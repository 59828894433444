import { ErrorHandler, NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonVirtualScroll } from '@ionic/angular'

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireRemoteConfig, AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config'
import { AngularFireAnalytics, AngularFireAnalyticsModule, APP_NAME, APP_VERSION, COLLECTION_ENABLED, CONFIG, DEBUG_MODE, ScreenTrackingService } from '@angular/fire/compat/analytics'
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/compat/messaging'

//import { FirestoreModule } from '@angular/fire/firestore'

import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ThemeDetection } from '@awesome-cordova-plugins/theme-detection/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CloudSettings } from '@awesome-cordova-plugins/cloud-settings/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { firebaseConfig } from './credentials';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicModule, IonicRouteStrategy, ModalController } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BranchModule } from '../app/components/branch/branch.component.module';

import { Storage } from '@ionic/storage-angular';

import { PrivacyPageModule } from './privacy/privacy.module'
import { EpisodePageModule } from './episode/episode.module'
import { SearchPageModule } from './search/search.module'
import { MembershipPageModule } from './membership/membership.module';
import { Fair2playPageModule } from './fair2play/fair2play.module';
import { SerialPageModule } from './serial/serial.module';
import { ContactsPageModule } from './quest/contacts/contacts.module';
import { ContactPageModule } from './quest/contact/contact.module';
import { ChatPageModule } from './quest/chat/chat.module';
import { MetadataPageModule } from './quest/metadata/metadata.module';
import { CanoncontactsPageModule } from './quest/canoncontacts/canoncontacts.module'
import { ScenesarchivePageModule } from './quest/scenesarchive/scenesarchive.module'
import { MusicPageModule } from './quest/music/music.module';
import { FriendslistPage } from './quest/friendslist/friendslist.page';
import { SelectprojectPageModule } from './quest/selectproject/selectproject.module';

@NgModule({
	declarations: [AppComponent],
  	entryComponents: [],
	imports: [
    	BrowserModule,
    	IonicModule.forRoot({
			scrollAssist: false,
			scrollPadding: false,
			mode: 'ios'
		}),
    	IonicStorageModule.forRoot(),
    	AppRoutingModule,
    	AngularFireModule.initializeApp(firebaseConfig),
		AngularFireAuthModule,
		AngularFireRemoteConfigModule,
		AngularFireAnalyticsModule,
		AngularFireMessagingModule,
		AngularFirestoreModule,
		HttpClientModule,
		PrivacyPageModule,
		BrowserAnimationsModule,
		EpisodePageModule,
		MembershipPageModule,
		Fair2playPageModule,
		SearchPageModule,
		SerialPageModule,
		ContactsPageModule,
		ContactPageModule,
		ChatPageModule,
		CanoncontactsPageModule,
		ScenesarchivePageModule,
		MusicPageModule,
		MetadataPageModule,
		BranchModule,
		SelectprojectPageModule
  	],
  	providers: [
		{ provide: CONFIG, useValue: {
			send_page_view: true,
			allow_ad_personalization_signals: false,
			anonymize_ip: true
		} },
		{ provide: APP_NAME, useValue: "A Void Society" },
		{ provide: DEBUG_MODE, useValue: false },
		{ provide: APP_VERSION, useValue: "5.0.0" },
		{ provide: COLLECTION_ENABLED, useValue: true },
    	AppRate,
		Badge,
		Vibration,
		TapticEngine,
		InAppPurchase2,
		Media,
		File,
		ThemeDetection,
		SocialSharing,
		CloudSettings,
		Storage,
		IonicStorageModule,
		ModalController,
		AngularFirestore,
		AngularFireAuth,
		AngularFireRemoteConfig,
		AngularFireAnalytics,
		ScreenTrackingService,
		AngularFireMessaging,
		HttpClient,
		Injectable,
		IonVirtualScroll,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  	],
  	bootstrap: [AppComponent]
})
export class AppModule {}