import { ModalController } from '@ionic/angular'
import { Component, OnInit } from '@angular/core';
import { AuthorService } from 'src/app/cloud/author.service';
import { DataService } from 'src/app/system/data.service';
import { BootService } from 'src/app/system/boot.service';
import { NavigationService } from 'src/app/system/navigation.service';

@Component({
  	selector: 'app-selectproject',
  	templateUrl: './selectproject.page.html',
  	styleUrls: ['./selectproject.page.scss'],
})
export class SelectprojectPage implements OnInit {

	asModal: boolean = true;

  	constructor(
		private boot: BootService,
		private modalController: ModalController,
		public author: AuthorService,
		public data: DataService,
		private navigation: NavigationService
	) {}

  	ngOnInit() {
		this.author.syncProjects();
	}

  	close() { this.modalController.dismiss(null); }

	selectProject(project) { this.modalController.dismiss(project); }

	pushDesk() {
		this.modalController.dismiss();
		this.navigation.pushCUGC_overview();
	}

}