import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { FirerrService } from './firerr.service';
import { LocalizationService } from '../system/localization.service';
import { InstanceService } from '../system/instance.service'
import { Events } from '../system/events.service'
import { StorageService } from '../system/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

	achievements: Array<any> = [];
	contacts: Array<any> = [];
	namedContacts: Array<any> = [];
	episodes: Array<any> = [];
	sets: Array<any> = [];
	discoveries: any = [];
	storyboardInfo: Array<any> = [];
	echoInfos: Array<any> = [];
	echoes: Array<any> = [];
	rewbs: Array<any> = [];
	namedAchievements: Array<any> = [];
	glossary: Array<any> = [];
	progression: Array<any> = [];
	blog: any = [];
	introposts: any = [];
	seasonpacks: any = [];
	adLimits: any = [];
	adSettings: any = [];
	notifications: any = [];
	cosmos: any = [];
	tags: any = [];
	tools: any = [];
	tracks: any = [];
	welcome: any = [];
	live: any = [];
	notification: any = [];
	cloudMeta: any = undefined;
	storedMeta: any = undefined;
	discovery: Array<any> = [];
	shop: any = false;
	addresses: Array<any> = [];

  	constructor(
		private fireStore: AngularFirestore,
		private storage: Storage,
		private firerr: FirerrService,
		private localization: LocalizationService,
		private events: Events,
		private instance: InstanceService,
		private storageSrv: StorageService
	) {
		this.events.subscribe("languageChanged", () => { this.reset(); })
	}

	async reset() {
		this.achievements = [];
		this.contacts = [];
		this.namedContacts = [];
		this.episodes = [];
		this.sets = [];
		this.discoveries = [];
		this.storyboardInfo = [];
		this.echoInfos = [];
		this.echoes = [];
		this.rewbs = [];
		this.namedAchievements = [];
		this.glossary = [];
		this.progression = [];
		this.blog = [];
		this.notification = [];
		this.cloudMeta = [];
		this.storedMeta = [];
		this.discovery = [];
	}

	async checkMeta(type) {
		if (this.instance.instance === "demoapp") { return false; }

		if (this.cloudMeta === undefined) {
			await this.fireStore.collection(this.localization.DB_meta).doc("meta").ref.get().then(doc => {
				if (doc.exists) { this.cloudMeta = doc.data(); }
			}).catch(err => {});
		}
		if (this.storedMeta === undefined) {
			await this.storage.get(this.localization.lang+"_meta").then(resultString => {
				if (resultString) { this.storedMeta = JSON.parse(resultString); }
			})
		}
		if (this.storedMeta === undefined) {
			if (this.cloudMeta) {
				this.storedMeta = this.cloudMeta;
				this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
			}
		}
		let update = false;
		if (this.cloudMeta && this.storedMeta) {
			if (type === "contacts") { 
				if (!this.storedMeta.c) { this.storedMeta.c = 0; }
				if (this.cloudMeta.c > this.storedMeta.c) { update = true; }
			}
			else if (type === "achievements") {
				if (!this.storedMeta.a) { this.storedMeta.a = 0; }
				if (this.cloudMeta.a > this.storedMeta.a) { update = true; }
			}
			else if (type === "episodes") { 
				if (!this.storedMeta.e) { this.storedMeta.e = 0; }
				if (this.cloudMeta.e > this.storedMeta.e) { update = true; }
			}
			else if (type === "rewbs") {
				if (!this.storedMeta.r) { this.storedMeta.r = 0; }
				if (this.cloudMeta.r > this.storedMeta.r) { update = true; }
			}
			else if (type === "progression") { 
				if (!this.storedMeta.p) { this.storedMeta.p = 0; }
				if (this.cloudMeta.p > this.storedMeta.p) { update = true; }
			}
			else if (type === "sets") { 
				if (!this.storedMeta.s) { this.storedMeta.s = 0; }
				if (this.cloudMeta.s > this.storedMeta.s) { update = true; }
			}
			else if (type === "discoveries") { 
				if (!this.storedMeta.d) { this.storedMeta.d = 0; }
				if (this.cloudMeta.d > this.storedMeta.d) { update = true; } 
			}
			else if (type === "blog") { 
				if (!this.storedMeta.b) { this.storedMeta.b = 0; }
				if (this.cloudMeta.b > this.storedMeta.b) { update = true; }
			}
			else if (type === "welcome") { 
				if (!this.storedMeta.w) { this.storedMeta.w = 0; }
				if (this.cloudMeta.w > this.storedMeta.w) { update = true; }
			}			
			else if (type === "shop") { 
				if (!this.storedMeta.sh) { this.storedMeta.sh = 0; }
				if (this.cloudMeta.sh > this.storedMeta.sh) { update = true; }
			}			
			else if (type === "notification_general") {
				if (!this.storedMeta.u_g) { this.storedMeta.u_g = 0; }
				if (this.cloudMeta.u_g > this.storedMeta.u_g) { update = true; }
			}
			else if (type === "notification_ios") { 
				if (!this.storedMeta.u_i) { this.storedMeta.u_i = 0; }
				if (this.cloudMeta.u_i > this.storedMeta.u_i) { update = true; }
			}
			else if (type === "notification_android") {
				if (!this.storedMeta.u_a) { this.storedMeta.u_a = 0; }
				if (this.cloudMeta.u_a > this.storedMeta.u_a) { update = true; }
			}
			else if (type === "addresses") {
				if (!this.storedMeta.adr) { this.storedMeta.adr = 0; }
				if (this.cloudMeta.adr > this.storedMeta.adr) { update = true; }
			} else if (type === "notifications") {
				if (!this.storedMeta.n) { this.storedMeta.n = 0; }
				if (this.cloudMeta.n > this.storedMeta.n) { update = true; }			
			} else if (type === "live") {
				if (!this.storedMeta.l) { this.storedMeta.l = 0; }
				if (this.cloudMeta.l > this.storedMeta.l) { update = true; }
			} else if (type === "introposts") {
				if (!this.storedMeta.in) { this.storedMeta.in = 0; }
				if (this.cloudMeta.in > this.storedMeta.in) { update = true; }				
			} else if (type === "seasonpacks") {
				if (!this.storedMeta.sp) { this.storedMeta.sp = 0; }
				if (this.cloudMeta.sp > this.storedMeta.sp ) { update = true; }
			} else if (type === "adSettings") {
				if (!this.storedMeta.ads) { this.storedMeta.ads = 0; }
				if (this.cloudMeta.ads > this.storedMeta.ads) { update = true; }
			} else if (type === "cosmos") {
				if (!this.storedMeta.cos) { this.storedMeta.cos = 0; }
				if (this.cloudMeta.cos > this.storedMeta.cos) { update = true; }
			} else if (type === "tags") {
				if (!this.storedMeta.tgs) { this.storedMeta.tgs = 0; }
				if (this.cloudMeta.tgs > this.storedMeta.tgs) { update = true; }
			} else if (type === "tools") {
				if (!this.storedMeta.tls) { this.storedMeta.tls = 0; }
				if (this.cloudMeta.tls > this.storedMeta.tls) { update = true; }
			} else if (type === "tracks") {
				if (!this.storedMeta.trk) { this.storedMeta.trk = 0; }
				if (this.cloudMeta.trk > this.storedMeta.trk) { update = true; }
			}
		}
		return update;
	}

	async getContact(contactId) {
		if (!this.namedContacts[contactId]) {
			let cachedContact = null;
			await this.storage.get(this.localization.lang+"_con_"+contactId).then(resultString => { if (resultString) { cachedContact = JSON.parse(resultString); } })
			let update = false;
			if (cachedContact) {
				this.namedContacts[contactId] = cachedContact;
				await this.getContacts();
				let contactBasicInfo = this.contacts.find(item => { return item.i === contactId });
				if (contactBasicInfo.v > cachedContact.v) { update = true; }
			} else { update = true; }
			if (update) {
				await this.fireStore.collection(this.localization.DB_contacts).doc(contactId).ref.get().then(doc => { 
					if (doc.exists) { this.namedContacts[contactId] = doc.data(); } 
				}).catch(err => this.firerr.error(err, this.localization.signal_contact))
				this.storage.set(this.localization.lang+"_con_"+contactId, JSON.stringify(this.namedContacts[contactId]));
			}
		}
		return this.namedContacts[contactId];
	}

	async getContacts() {
		if (this.contacts.length < 1) {
			let update = await this.checkMeta("contacts");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("contacts").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.contacts = docData.contacts;
						this.storage.set(this.localization.lang+"_meta_contacts", JSON.stringify(docData.contacts));
						this.storedMeta.c = this.cloudMeta.c;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_contacts").then(resultString => {
					if (resultString) { this.contacts = JSON.parse(resultString); } 
					else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("contacts").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.contacts = docData.contacts;
							this.storage.set(this.localization.lang+"_meta_contacts", JSON.stringify(docData.contacts));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_contacts))
				}
			}

		}
		return this.contacts;
	}

	async getBlog() {
		if (this.blog.length < 1) {
			let update = await this.checkMeta("blog");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("blog").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.blog = docData.blog;
						this.storage.set(this.localization.lang+"_meta_blog", JSON.stringify(docData.blog));
						this.storedMeta.b = this.cloudMeta.b;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_blog").then(resultString => {
					if (resultString) { this.blog = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("blog").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.blog = docData.blog;
							this.storage.set(this.localization.lang+"_meta_blog", JSON.stringify(docData.blog));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.blog;
	}

	async getIntroposts() {
		if (this.introposts.length < 1) {
			let update = await this.checkMeta("introposts");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("introposts").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.introposts = docData.introposts;
						this.storage.set(this.localization.lang+"_meta_introposts", JSON.stringify(docData.introposts));
						this.storedMeta.in = this.cloudMeta.in;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_introposts").then(resultString => {
					if (resultString) { this.introposts = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("introposts").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.introposts = docData.introposts;
							this.storage.set(this.localization.lang+"_meta_introposts", JSON.stringify(docData.introposts));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.introposts;
	}	

	async getSeasonpacks() {
		if (this.seasonpacks.length < 1) {
			let update = await this.checkMeta("seasonpacks");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("seasonpacks").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.seasonpacks = docData.seasonpacks;
						this.storage.set(this.localization.lang+"_meta_seasonpacks", JSON.stringify(docData.seasonpacks));
						this.storedMeta.sp = this.cloudMeta.sp;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_seasonpacks").then(resultString => {
					if (resultString) { this.seasonpacks = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("seasonpacks").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.seasonpacks = docData.seasonpacks;
							this.storage.set(this.localization.lang+"_meta_seasonpacks", JSON.stringify(docData.seasonpacks));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.seasonpacks;
	}

	async getAdSettings() {
		if (this.adSettings.length < 1) {
			let update = await this.checkMeta("adSettings");
			update = true;
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("adSettings").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.adSettings = docData["adSettings_"+this.instance.instance];
						this.storage.set(this.localization.lang+"_meta_adSettings", JSON.stringify(docData.adSettings));
						this.storedMeta.ads = this.cloudMeta.ads;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_adSettings").then(resultString => {
					if (resultString) { this.adSettings = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("adSettings").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.adSettings = docData["adSettings_"+this.instance.instance];
							this.storage.set(this.localization.lang+"_meta_adSettings", JSON.stringify(docData.adSettings));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.adSettings;
	}

	async getNotifications() {
		if (this.notifications.length < 1) {
			let update = await this.checkMeta("notifications");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("notifications").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.notifications = docData["notifications_"+this.instance.instance];
						this.storage.set(this.localization.lang+"_meta_notifications", JSON.stringify(docData.notifications));
						this.storedMeta.n = this.cloudMeta.n;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_notifications").then(resultString => {
					if (resultString) { this.notifications = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("notifications").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.notifications = docData["notifications_"+this.instance.instance];
							this.storage.set(this.localization.lang+"_meta_notifications", JSON.stringify(docData.notifications));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.notifications;
	}
	
	async getCosmos() {
		if (this.cosmos.length < 1) {
			let update = await this.checkMeta("cosmos");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("cosmos").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.cosmos = docData.cosmos;
						this.storage.set(this.localization.lang+"_meta_cosmos", JSON.stringify(docData.cosmos));
						this.storedMeta.cos = this.cloudMeta.cos;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_cosmos").then(resultString => {
					if (resultString) { this.cosmos = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("cosmos").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.cosmos = docData.cosmos;
							this.storage.set(this.localization.lang+"_meta_cosmos", JSON.stringify(docData.cosmos));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.cosmos;
	}	

	async getTags() {
		if (this.tags.length < 1) {
			let update = await this.checkMeta("tags");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("tags").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.tags = docData.tags;
						this.storage.set(this.localization.lang+"_meta_tags", JSON.stringify(docData.tags));
						this.storedMeta.tgs = this.cloudMeta.tgs;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_tags").then(resultString => {
					if (resultString) { this.tags = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("tags").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.tags = docData.tags;
							this.storage.set(this.localization.lang+"_meta_tags", JSON.stringify(docData.tags));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.tags;
	}

	async getTools() {
		if (this.tools.length < 1) {
			let update = await this.checkMeta("tools");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("tools").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.tools = docData.tools;
						this.storage.set(this.localization.lang+"_meta_tools", JSON.stringify(docData.tools));
						this.storedMeta.tls = this.cloudMeta.tls;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_tools").then(resultString => {
					if (resultString) { this.tools = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("tools").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.tools = docData.tools;
							this.storage.set(this.localization.lang+"_meta_tools", JSON.stringify(docData.tools));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.tools;
	}	

	async getTracks() {
		if (this.tracks.length < 1) {
			let update = await this.checkMeta("tracks");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("tracks").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.tracks = docData.tracks;
						this.storage.set(this.localization.lang+"_meta_tracks", JSON.stringify(docData.tracks));
						this.storedMeta.trk = this.cloudMeta.trk;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_tracks").then(resultString => {
					if (resultString) { this.tracks = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("tracks").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.tracks = docData.tracks;
							this.storage.set(this.localization.lang+"_meta_tracks", JSON.stringify(docData.tracks));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.tracks;
	}		

	async getWelcome() {
		if (this.welcome.length < 1) {
			let update = await this.checkMeta("welcome");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("welcome").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.welcome = docData.welcome;
						this.storage.set(this.localization.lang+"_meta_welcome", JSON.stringify(docData.welcome));
						this.storedMeta.w = this.cloudMeta.w;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_welcome").then(resultString => {
					if (resultString) { this.welcome = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("welcome").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.welcome = docData.welcome;
							this.storage.set(this.localization.lang+"_meta_welcome", JSON.stringify(docData.welcome));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_connection));
				}
			}
		}
		return this.welcome;
	}	

	async getLive() {
		if (this.live.length < 1) {
			let update = await this.checkMeta("live");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("live").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.live = docData.live;
						this.storage.set(this.localization.lang+"_meta_live", JSON.stringify(docData.live));
						this.storedMeta.l = this.cloudMeta.l;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_live").then(resultString => {
					if (resultString) { this.live = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("live").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.live = docData.live;
							this.storage.set(this.localization.lang+"_meta_live", JSON.stringify(docData.live));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.live;
	}

	async getAddresses() {
		if (this.addresses.length < 1) {
			let update = await this.checkMeta("addresses");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("addresses").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.addresses = docData["addresses_"+this.instance.instance];
						this.storage.set(this.instance.instance+"_"+this.localization.lang+"_meta_addresses", JSON.stringify(docData.addresses));
						this.storedMeta.adr = this.cloudMeta.adr;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.instance.instance+"_"+this.localization.lang+"_meta_addresses").then(resultString => {
					if (resultString) { this.addresses = JSON.parse(resultString); }
					else { missing = true; }
				}).catch(() => missing = true )
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("addresses").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.addresses = docData["addresses_"+this.instance.instance];
							this.storage.set(this.instance.instance+"_"+this.localization.lang+"_meta_addresses", JSON.stringify(docData.addresses));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank));
				}
			}
		}
		return this.addresses;
	}

	async getAchievements() {
		if (this.achievements.length < 1) {
			let update = await this.checkMeta("achievements");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("achievements").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.achievements = docData.achievements;
						this.storage.set(this.localization.lang+"_meta_achievements", JSON.stringify(docData.achievements));
						this.storedMeta.a = this.cloudMeta.a;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {});
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_achievements").then(resultString => {
					if (resultString) { this.achievements = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("achievements").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.achievements = docData.achievements;
							this.storage.set(this.localization.lang+"_meta_achievements", JSON.stringify(docData.achievements));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.achievements;
	}

	async getEpisodes() {
		if (this.episodes.length < 1) {
			let update = await this.checkMeta("episodes");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("episodes").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.episodes = docData.episodes;
						this.storage.set(this.localization.lang+"_meta_episodes", JSON.stringify(docData.episodes));
						this.storedMeta.e = this.cloudMeta.e;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_episodes").then(resultString => {
					if (resultString) { this.episodes = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("episodes").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.episodes = docData.episodes;
							this.storage.set(this.localization.lang+"_meta_episodes", JSON.stringify(docData.episodes));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.episodes;
	}

	async getSets() {
		if (this.sets.length < 1) {
			let update = await this.checkMeta("sets");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("sets").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.sets = docData.sets;
						this.storage.set(this.localization.lang+"_meta_sets", JSON.stringify(docData.sets));
						this.storedMeta.s = this.cloudMeta.s;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_sets").then(resultString => {
					if (resultString) { this.sets = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("sets").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.sets = docData.sets;
							this.storage.set(this.localization.lang+"_meta_sets", JSON.stringify(docData.sets));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.sets;
	}

	async getShop() {
		if (!this.shop) {
			let update = await this.checkMeta("shop");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("shop").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.shop = docData.shop;
						this.storage.set(this.localization.lang+"_meta_shop", JSON.stringify(docData.shop));
						this.storedMeta.sh = this.cloudMeta.sh;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_shop").then(resultString => {
					if (resultString) { this.shop = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("shop").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.shop = docData.shop;
							this.storage.set(this.localization.lang+"_meta_shop", JSON.stringify(docData.shop));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.shop;
	}

	async getDiscoveries() {
		if (this.discoveries.length < 1) {
			let update = await this.checkMeta("discoveries");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("discoveries").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.discoveries = docData["discoveries_"+this.instance.instance];
						this.storage.set(this.localization.lang+"_meta_discoveries", JSON.stringify(docData.discoveries));
						this.storedMeta.d = this.cloudMeta.d;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_discoveries").then(resultString => {
					if (resultString) { this.discoveries = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("discoveries").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.discoveries = docData["discoveries_"+this.instance.instance];
							this.storage.set(this.localization.lang+"_meta_discoveries", JSON.stringify(docData.discoveries));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.discoveries;
	}	

	async getRewbs() {
		if (this.rewbs.length < 1) {
			let update = await this.checkMeta("rewbs");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("rewbs").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.rewbs = docData.rewbs;
						this.storage.set(this.localization.lang+"_meta_rewbs", JSON.stringify(docData.rewbs));
						this.storedMeta.r = this.cloudMeta.r;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_rewbs").then(resultString => {
					if (resultString) { this.rewbs = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("rewbs").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.rewbs = docData.rewbs;
							this.storage.set(this.localization.lang+"_meta_rewbs", JSON.stringify(docData.rewbs));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.rewbs;
	}

	async getProgression() {
		if (this.progression.length < 1) {
			let update = await this.checkMeta("progression");
			if (update) {
				await this.fireStore.collection(this.localization.DB_meta).doc("progression").ref.get().then(doc => {
					if (doc.exists) {
						let docData: any = doc.data();
						this.progression = docData.progression;
						this.storage.set(this.localization.lang+"_meta_progression", JSON.stringify(docData.progression));
						this.storedMeta.p = this.cloudMeta.p;
						this.storage.set(this.localization.lang+"_meta", JSON.stringify(this.storedMeta));
					}
				}).catch(err => {})
			} else {
				let missing = false;
				await this.storage.get(this.localization.lang+"_meta_progression").then(resultString => {
					if (resultString) { this.progression = JSON.parse(resultString);
					} else { missing = true; }
				}).catch(() => missing = true)
				if (missing) {
					await this.fireStore.collection(this.localization.DB_meta).doc("progression").ref.get().then(doc => {
						if (doc.exists) {
							let docData: any = doc.data();
							this.progression = docData.progression;
							this.storage.set(this.localization.lang+"_meta_progression", JSON.stringify(docData.progression));
						}
					}).catch(err => this.firerr.error(err, this.localization.signal_blank))
				}
			}

		}
		return this.progression;
	}		
	
	async getStoryboardInfo(storyboardId) {
		storyboardId = storyboardId+"";
		if (!this.storyboardInfo[storyboardId]) {
			this.storyboardInfo[storyboardId] = {};
			await this.fireStore.collection(this.localization.DB_episodes).doc(storyboardId).ref.get().then(doc => {
				if (doc.exists) { this.storyboardInfo[storyboardId] = doc.data(); }
			}).catch(err => this.firerr.error(err, this.localization.signal_blank))
		}
		return this.storyboardInfo[storyboardId];
	}

	async getEchoInfo(echoId) {
		if (!this.echoInfos[echoId]) {
			this.echoInfos[echoId] = {};
			await this.fireStore.collection(this.localization.DB_echoesInfo).doc(echoId).ref.get().then(doc => {
				if (doc.exists) { this.echoInfos[echoId] = doc.data(); }
			}).catch(err => this.firerr.error(err, this.localization.signal_blank))
		}
		return this.echoInfos[echoId];
	}

	async getEcho(echoId) {
		if (!this.echoes[echoId]) {
			this.echoes[echoId] = {};
			await this.fireStore.collection(this.localization.DB_echoes).doc(echoId).ref.get().then(doc => {
				if (doc.exists) { this.echoes[echoId] = doc.data(); }
			}).catch(err => this.firerr.error(err, this.localization.signal_blank))
		}
		return this.echoes[echoId];
	}	

	async getAchievement(id) {
		if (!this.namedAchievements[id]) {
			await this.fireStore.collection("achievements").doc(id).ref.get().then(doc => {
				if (doc.exists) { this.namedAchievements[id] = doc.data(); }
			}).catch(err => this.firerr.error(err, this.localization.signal_blank))
		}
		return this.namedAchievements[id];
	}

	async getGlossaryItem(id) {
		if (!this.glossary[id]) {
			await this.fireStore.collection("glossary").doc(id).ref.get().then(doc => {
				if (doc.exists) { this.glossary[id] = doc.data(); }
			}).catch(err => {})
		}
		return this.glossary[id];
	}

	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

	async getEchoes_Structure() {
		let structure = [
			{ 
				t: "recommended", 
				l: "large",
				h: "Empfohlen",
				conds: []
			},
			//{  t: "contacts", h: "Nach Kontakten", f: false, conds: [] },
			{ t: "set", h: "Redfir Reserve Special", i: "redfir", conds: [] },
			{ t: "set", h: "Halloweek Special 2019", i: "hw19", conds: [] },
			{
				t: "single",
				l: "small",
				h: "Anne I",
				e: { i: "flunami", n: "Flunami", im: "flunami.jpg", d: "Das Virus entwickelt sich besorgniserregend, doch Mr. Parker sieht dennoch keinen Grund, den ChipMart zu schließen. Während Anne bereits Fluchtpläne schmiedet, schiebt Laura noch Überstunden." },
				conds: []
			},	
			{
				t: "single",
				l: "small",
				h: "Anne II",
				e: { i: "doom", n: "Doom", im: "doom.jpg", d: "Toby hat den perfekten Fluchtplan und drängt darauf, die Stadt schnellstmöglich zu verlassen. Nur wo bleibt Laura?" },
				conds: []
			},					
			{
				t: "single",
				l: "small",
				h: "Anne III",
				e: { i: "creep", n: "Creep", im: "creep.jpg", d: "Jemandem scheint es nicht zu gefallen, dass Anne sich allein durch die Straßen Buddingtons bewegt. Sind die Konservendosen vor der Tür ein Friedensangebot... oder vielleicht doch eine Falle?" },
				conds: []
			}		
			/*
			{  t: "contacts", h: "Nach Kontakten", f: false, conds: [] },
			{ 
				t: "recommended", 
				l: "large",
				h: "Empfohlen",
				conds: []
			},
			{
				t: "single",
				l: "small",
				h: "Redfir Reserve II",
				e: { i: "redfir2", n: "Tenexon Agrilane", im: "redfir2.jpg", d: "Kapitel 2 des REDFIR RESERVE SPECIAL - Eine Chance tut sich auf für die Bewohner eines unscheinbaren Dorfes." },
				conds: []
			},
			{
				t: "single",
				l: "small",
				h: "Redfir Reserve III",
				e: { i: "redfir3", n: "Lodge", im: "redfir3.jpg", d: "Kapitel 3 des REDFIR RESERVE SPECIAL - Tori bemerkt in der Lodge etwas, das interessanter ist als eine Reservierung." },
				conds: []
			},			
			{
				t: "latestComplete"
			},
			{ 
				t: "single", 
				l: "large",
				h: "Für dich",
				e: { i: "flunami", n: "Flunami", im: "flunami.jpg", d: "Teaser-Text zu Flunami." },
				conds: []
			},			
			{
				t: "contact",
				i: "david",
				h: "Echos mit David",
				d: "Alle Echos mit David",
				f: false,
				conds: []
			},
			{ t: "essentials", h: "Schlüsselszenen" },	
			{ t: "new", h: "Neu", l: "small", conds: [] },
			{ 
				t: "single", 
				l: "large",
				h: "Für dich",
				e: { i: "creep", n: "Creep", im: "creep.jpg", d: "Teaser-Text zu Creep." },
				conds: []
			},			
			{  t: "contacts", h: "Nach Kontakten", f: true, conds: [] },
			*/
		];
		return structure;
	}

	async getEchoes_Set(setId) {
		let sets = [];

		sets["redfir"] = {
			i: "redfir",
			n: "Redfir Reserve Special",
			e: [
				{ i: "redfir1", n: "EdenWatch", im: "mockup1.png", d: "Überraschend kann EdenWatch das Budget für eine großangelegte Kampagne aufbringen. Ein neuer Mitarbeiter soll dabei das bestehende Team von Routineaufgaben entlasten." },
				{ i: "redfir2", n: "Tenexon Agrilane", im: "mockup2.png", d: "Kapitel 2 des REDFIR RESERVE SPECIAL - Eine Chance tut sich auf für die Bewohner eines unscheinbaren Dorfes." },
				{ i: "redfir3", n: "Lodge", im: "mockup1.png", d: "Kapitel 3 des REDFIR RESERVE SPECIAL - Tori bemerkt in der Lodge etwas, das interessanter ist als eine Reservierung." },
				{ i: "redfir4", n: "Bauarbeiten", im: "mockup2.png", d: "Kapitel 4 des REDFIR RESERVE SPECIAL - Drei Teenager aus Redfir Hills begegnem in ihrem Ferienjob einem Rätsel." },
				{ i: "redfir5", n: "Kurztrip", im: "redfir5.jpg", d: "David erlebt seine ersten Tage bei EdenWatch und natürlich ist Mr. Souveränität ganz und gar nicht verwirrt. Nicht einmal, als er seinen ersten großen Auftrag bekommt: Die Kontrolle der Messwerte in einem Nationalpark." },
				{ i: "redfir6", n: "Wochenmarkt", im: "redfir6.jpg", d: "Kapitel 6 des REDFIR RESERVE SPECIAL - Auf dem Wochenmarkt sieht es so leer aus wie auf den Feldern der Farmer von Redfir Hills. Kann Coleman etwas dagegen unternehmen?" },
				{ i: "redfir7", n: "Halloweek", im: "redfir7.jpg", d: "Kapitel 7 des REDFIR RESERVE SPECIAL - Die Kürbisse aus Redwich sind der Funken, der einen Scheiterhaufen entflammen ließen." }
			],
			d: "Das Redfir Reserve Special behandelt die Vorfälle kurz vor dem fatalen Campingausflug der Clique."
		}

		sets["hw19"] = {
			i: "hw19",
			n: "Halloweek Special 2019",
			e: [
				{ i: "hw191", n: "Haltbar", im: "mockup2.png", d: "Gemeinsam mit ihrem Freund Thomas macht Steph drei Tage Urlaub in einem verschlafenen Nest namens Redfir Hills. Dort besuchen sie die jährlich stattfindende Halloweek, ein mit Kürbisen überladenes Touristenspektakel, das leider stets darunter leider, dass die Zahl der Kürbisse die Zahl der Touristen um ein Vielfaches übersteigt. Möglicherweise auch, weil das Spektakel gar nicht so spektakulär ist."},
				{ i: "hw192", n: "Laktosefrei", im: "mockup1.png", d: ""},
				{ i: "hw193", n: "Ultrahocherhitzt", im: "mockup2.png", d: ""}
			]
		}
		return sets[setId];
	}

	async getEchoes_AllOfSet(setId) {
		let echoes = [
			{ i: "redfir1", n: "Redfir 1", im: "redfir1.jpg" },
			{ i: "redfir2", n: "Redfir 2", im: "redfir2.jpg" },
			{ i: "redfir3", n: "Redfir 3", im: "redfir3.jpg" },
			{ i: "redfir4", n: "Redfir 4", im: "redfir4.jpg" },
			{ i: "redfir5", n: "Redfir 5", im: "redfir5.jpg" },
			{ i: "redfir6", n: "Redfir 6", im: "redfir6.jpg" },
			{ i: "redfir7", n: "Redfir 7", im: "redfir7.jpg" }
		]
		return echoes;		
	}

	async getEchoes_New() {
		let echo = { i: "boat", n: "Boat", im: "creep.jpg", d: "Das ist ein Teaser zum empfohlenen Echo. Anne macht irgendwas, währen Laura irgendwas anderes macht. Und dann gibt es ein Ende." }
		return echo;
	}

	async getEchoes_Contacts() {
		let contacts = [
			{ i: "david", n: "David", im: "david.jpg" },
			{ i: "chris", n: "Chris", im: "chris.jpg" },
			{ i: "laura", n: "Laura", im: "laura.jpg" },
			{ i: "tom", n: "Tom", im: "voice1.jpg" },
			{ i: "anne", n: "Anne", im: "anne.jpg" },
			{ i: "david", n: "David", im: "david.jpg" },
			{ i: "laura", n: "Laura", im: "laura.jpg" },
			{ i: "tom", n: "Tom", im: "voice1.jpg" },
			{ i: "anne", n: "Anne", im: "anne.jpg" }			
		]
		return contacts;		
	}

	async getEchoes_Contact(contactId) {
		let echoes = [];
		if (contactId === "david") {
			echoes = [
				{ i: "kinoabend", n: "Kinoabend", im: "clique1.jpg" },
				{ i: "creep", n: "Creep", im: "creep.jpg", d: "Teaser-Text zu Creep." },				
				{ i: "boat2", n: "Boat 2", im: "boat2.jpg" },
				{ i: "boat3", n: "Boat 3", im: "boat3.jpg", d: "Teaser zu Boat 3." },
				{ i: "boat4", n: "Boat 4", im: "boat4.jpg" }
			]
		} else if (contactId === "laura") {
			echoes = [
				{ i: "laura1", n: "Creep", im: "creep.jpg", d: "Teaser-Text zu Creep." },
				{ i: "laura2", n: "Flunami", im: "flunami.jpg", d: "Teaser-Text zu Flunami." },
				{ i: "laura3", n: "Chipmart of doom", im: "doom.jpg", d: "Teaser-Text zu ChipMart of Doom." }
			]			
		} else {
			echoes = [
				{ i: "boat1", n: "Boat 1", im: "boat1.jpg" },
				{ i: "boat2", n: "Boat 2", im: "boat2.jpg" },
				{ i: "boat3", n: "Boat 3", im: "boat3.jpg" },
				{ i: "boat4", n: "Boat 4", im: "boat4.jpg" }				
			]
		}
		return echoes;
	}

	async getEchoes_Episode(episodeId) {
		let echoes = [];
		if (episodeId === "pa15") {
			echoes = [
				{ i: "boat1", n: "Boat 1", im: "boat1.jpg" },
				{ i: "boat2", n: "Boat 2", im: "boat2.jpg" },
				{ i: "boat3", n: "Boat 3", im: "boat3.jpg" },
				{ i: "boat4", n: "Boat 4", im: "boat4.jpg" }				
			]
		} else {
			echoes = [
				{ i: "laura1", n: "Creep", im: "creep.jpg", d: "Teaser-Text zu Creep." },
				{ i: "laura2", n: "Flunami", im: "flunami.jpg", d: "Teaser-Text zu Flunami." },
				{ i: "laura3", n: "Chipmart of doom", im: "doom.jpg", d: "Teaser-Text zu ChipMart of Doom." }
			]
		}
		return echoes;
	}

	async getEchoes_Essentials() {
		let essentials = [
			{ t: "ech", i: "flunami", n: "Flunami", im: "flunami.jpg", d: "Ein Teaser zu Flunami." },
			{ t: "ech", i: "creep", n: "Creep", im: "creep.jpg", d: "Ein Teaser zu Creep." },
			{ t: "ech", i: "doom", n: "ChipMart of Doom", im: "doom.jpg" },
			{ t: "ech", i: "seaweed", n: "Seaweed", im: "island1.jpg" },
			{ t: "ech", i: "bootCamp", n: "Boot Camp", im: "tom2.jpg" }
		]
		return essentials;
	}

	async getEchoes_All() {
		let all: any = []; await this.fireStore.collection(this.localization.DB_meta).doc("echoes").ref.get().then(doc => {
			if (doc.exists) {
				let docData: any = doc.data();
				all = docData.echoes;
			}
		});
		return all;
	}

	async getPath() {
		let path = [
			{ t: "ep", i: "pa1", n: "A New Friend", im: "pa1.png", d: "Das ist die erste Episode."},
			{ t: "ech", i: "creep", n: "Creep", im: "pa3.png" },
			{ t: "ep", i: "pa2", n: "Urban Gardening", im: "pa4.png", d: "Das ist die zweite Episode." },
			{ t: "set", i: "redfir", n: "Redfir Reserve Special", im: "pa5.png" , e: [
				{ i: "redfir1", n: "Creep", im: "pa6.png" },
				{ i: "flunami", n: "Flunami", im: "pa7.png" },
				{ i: "redfir3", n: "ChipMart of Doom", im: "pa8.png" },
				{ i: "redfir4", n: "ChipMart of Doom", im: "pa9.png" },
				{ i: "redfir5", n: "ChipMart of Doom", im: "pa10.png" },
				{ i: "redfir6", n: "ChipMart of Doom", im: "pa11.png" },
				{ i: "redfir7", n: "ChipMart of Doom", im: "pa12.png" },
			]},			
			{ t: "ep", i: "ga1", n: "Gamer 1", im: "pa13.png", d: "Das ist die erste Folge des SpinOffs." },
			{ t: "ech", i: "doom", n: "ChipMart of Doom", im: "pa14.png" },
			{ t: "ech", i: "seaweed", n: "Seaweed", im: "pa15.png" },
			{ t: "ech", i: "bootCamp", n: "Boot Camp", im: "pa1.png" },
			{ t: "set", i: "redfir", n: "Redfir Reserve Special", im: "pa2.png" , e: [
				{ i: "redfir1", n: "Creep", im: "pa3.png" },
				{ i: "redfir2", n: "Flunami", im: "pa4.png" },
				{ i: "redfir3", n: "ChipMart of Doom", im: "pa5.png" }
			]},
			{ t: "ep", i: "pa15", n: "Vertical Farming", im: "pa6.png", d: "Das ist die fünfzehnte Episode." }
		]		
		return path;		
	}

	async getSeason(episodeId) {
		await this.getEpisodes();

		let matchingEpisode = { d: "", e: 0, i: "", o: 0, s: 0, t: "Episode", partOf: false };

		for (let serial of this.episodes) {
			if (episodeId.startsWith(serial.i)) {
				for (let episode of serial.ep) {
					if (episode.i === episodeId) {
						matchingEpisode = { ...episode, st: serial.t, partOf: true };
						break;
					}
				}
			}
		}
		return matchingEpisode;
	}

	async getSeasonId(episodeId) {
		let seasonId = "null";
		await this.getSeason(episodeId).then(seasonInfo => {
			let seasonShort = "null";
			if (episodeId.startsWith("pa")) { seasonShort = "par"; }
			else if (episodeId.startsWith("ga")) { seasonShort = "gam"; }
			seasonId = seasonShort + seasonInfo.s;
		})
		return seasonId;
	}

	async getSet (setId) {
		await this.getSets();
		let set = this.sets.find(set => set.i === setId);
		return set;
	}

	async findSetOfEcho (echoId) {
		await this.getSets();
		for (let set of this.sets) {
			for (let echo of set.e) {
				if (echo.i === echoId) {
					return set;
				}
			}
		}
		return null;
	}

}