import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

	constructor() { }
	  
	filterName(items, searchTerm) {
		return items.filter(item => {
			if (item.n) {
				return item.n.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
			}
		});
	}

	filterTitle(items, searchTerm) {
		return items.filter(item => {
			if (item.t) {
				return item.t.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
			}
		});
	}	
	
	filterGlossar(items, searchTerm) {
		return items.filter(item => {
			if (item.heading) {
				return item.heading.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
			}
		});		
	}

	filterTitleOrDescription(items, searchTerm) {
		return items.filter(item => {
			if (item.t && item.d) {
				return (item.t.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) || (item.d.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
			}
		})
	}

	filterSets(items, searchTerm) {
		let sets = JSON.parse(JSON.stringify(items));

		searchTerm = searchTerm.toLowerCase();
		let filteredSets = [];
		for (let set of sets) {
			let setMatching = false;
			if (set.h.toLowerCase().indexOf(searchTerm) > -1) {
				setMatching = true;
			} else {
				let filteredEchoes = [];
				for (let echo of set.s) {
					if (echo.t.toLowerCase().indexOf(searchTerm) > -1) {
						filteredEchoes.push(echo);
					}
				}
				if (filteredEchoes.length > 0) {
					set.s = filteredEchoes;
					setMatching = true;
				}
			}
			if (setMatching) {
				filteredSets.push(set);
			}
		}
		return filteredSets;
	}

	filterSingeltons(items, searchTerm) {
		let singletons = JSON.parse(JSON.stringify(items));

		searchTerm = searchTerm.toLowerCase();
		let filteredSingletons = [];
		for (let singleton of singletons) {
			let matching = false;
			if (singleton.h.toLowerCase().indexOf(searchTerm) > -1) { matching = true; }
			else if (singleton.d.toLowerCase().indexOf(searchTerm) > -1) { matching = true; }
			if (matching) {
				filteredSingletons.push(singleton);
			}
		}
		return filteredSingletons;
	}

	filterRecap(items, searchTerm) {
		let filteredItems = [];
		for (let item of items) {
			let nameMatching = false;
			let recapMatching = false;			
			if (item.n.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) { nameMatching = true; }
			if (item.recaps) {
				for (let recap of item.recaps) {
					if (recap.t.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) { recapMatching = true; }
				}
			}
			if (nameMatching || recapMatching) { filteredItems.push(item); }
		}
		return filteredItems;
	}
}
