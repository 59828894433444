import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'start', pathMatch: 'full' },
  	{ path: 'start', loadChildren: () => import('./main/start/start.module').then( m => m.StartPageModule) },
  	{ path: 'nothing', loadChildren: () => import('./quest/overview/overview.module').then( m => m.OverviewPageModule) },
  	{ path: 'chat/:sceneId', loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule) },
  	{ path: 'echoes', loadChildren: () => import('./echoes/echoes.module').then( m => m.EchoesPageModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule) },
  { path: 'list', loadChildren: () => import('./list/list.module').then( m => m.ListPageModule) },
  { path: 'simulation', loadChildren: () => import('./simulation/simulation.module').then( m => m.SimulationPageModule) },
  { path: 'history', loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule) },
  { path: 'contact/:contactId', loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule) },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then( m => m.ContactsPageModule) },
  { path: 'serial', loadChildren: () => import('./serial/serial.module').then( m => m.SerialPageModule) },
  { path: 'episode', loadChildren: () => import('./episode/episode.module').then( m => m.EpisodePageModule) },
  { path: 'echo', loadChildren: () => import('./episode/episode.module').then( m => m.EpisodePageModule) },
  { path: 'glossary', loadChildren: () => import('./glossary/glossary.module').then( m => m.GlossaryPageModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule) },
  { path: 'search', loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule) },
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then( m => m.BlogPageModule) },
  { path: 'signin', loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule) },
  { path: 'membership', loadChildren: () => import('./membership/membership.module').then( m => m.MembershipPageModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule) },
  { path: 'quest_editor', loadChildren: () => import('./quest/editor/editor.module').then( m => m.EditorPageModule) },
  { path: 'quest_projects', loadChildren: () => import('./quest/projects/projects.module').then( m => m.ProjectsPageModule) },
  { path: 'quest_contacts', loadChildren: () => import('./quest/contacts/contacts.module').then( m => m.ContactsPageModule) },
  { path: 'quest_project', loadChildren: () => import('./quest/project/project.module').then( m => m.ProjectPageModule) },
  { path: 'quest_scenes', loadChildren: () => import('./quest/scenes/scenes.module').then( m => m.ScenesPageModule) },
  { path: 'quest_contacts-opro', loadChildren: () => import('./quest/contacts-opro/contacts-opro.module').then( m => m.ContactsOproPageModule) },
  { path: 'contactpics', loadChildren: () => import('./quest/contactpics/contactpics.module').then( m => m.ContactpicsPageModule) },
  { path: 'quest_contact', loadChildren: () => import('./quest/contact/contact.module').then( m => m.ContactPageModule) },
  { path: 'quest_chat', loadChildren: () => import('./quest/chat/chat.module').then( m => m.ChatPageModule) },
  { path: 'blank', loadChildren: () => import('./quest/blank/blank.module').then( m => m.BlankPageModule) },
  { path: 'meta', loadChildren: () => import('./quest/meta/meta.module').then( m => m.MetaPageModule) },
  { path: 'quest_metadata', loadChildren: () => import('./quest/metadata/metadata.module').then( m => m.MetadataPageModule) },
  { path: 'quest_drafting', loadChildren: () => import('./quest/drafting/drafting.module').then( m => m.DraftingPageModule) },
  { path: 'quest_canoncontacts', loadChildren: () => import('./quest/canoncontacts/canoncontacts.module').then( m => m.CanoncontactsPageModule) },
  { path: 'quest_scenesarchive', loadChildren: () => import('./quest/scenesarchive/scenesarchive.module').then( m => m.ScenesarchivePageModule) },
  { path: 'quest_music', loadChildren: () => import('./quest/music/music.module').then( m => m.MusicPageModule) },
  { path: 'quest_overview', loadChildren: () => import('./quest/overview/overview.module').then( m => m.OverviewPageModule) },
  { path: 'quest_applies', loadChildren: () => import('./quest/applies/applies.module').then( m => m.AppliesPageModule) },
  { path: 'quest_invites', loadChildren: () => import('./quest/invites/invites.module').then( m => m.InvitesPageModule) },
  { path: 'quest_friends', loadChildren: () => import('./quest/friends/friends.module').then( m => m.FriendsPageModule) },
  { path: 'quest_friendslist', loadChildren: () => import('./quest/friendslist/friendslist.module').then( m => m.FriendslistPageModule) },
  { path: 'quest_collabs', loadChildren: () => import('./quest/collabs/collabs.module').then( m => m.CollabsPageModule) },
  { path: 'quest_rights', loadChildren: () => import('./quest/rights/rights.module').then( m => m.RightsPageModule) },
  { path: 'quest_export', loadChildren: () => import('./quest/export/export.module').then( m => m.ExportPageModule) },
  { path: 'quest_covers', loadChildren: () => import('./quest/covers/covers.module').then( m => m.CoversPageModule) },
  { path: 'quest_author', loadChildren: () => import('./quest/author/author.module').then( m => m.AuthorPageModule) },
  { path: 'quest_profile', loadChildren: () => import('./quest/profile/profile.module').then( m => m.ProfilePageModule) },
  { path: 'quest_content', loadChildren: () => import('./quest/content/content.module').then( m => m.ContentPageModule) },
  { path: 'quest_comments', loadChildren: () => import('./quest/comments/comments.module').then( m => m.CommentsPageModule) },
  { path: 'quest_catalogue', loadChildren: () => import('./quest/catalogue/catalogue.module').then( m => m.CataloguePageModule) },
  { path: 'quest_collections', loadChildren: () => import('./quest/collections/collections.module').then( m => m.CollectionsPageModule) },
  { path: 'contact2/:contactId', loadChildren: () => import('./contact2/contact2.module').then( m => m.Contact2PageModule) },
  { path: 'scheme', loadChildren: () => import('./scheme/scheme.module').then( m => m.SchemePageModule) },
  { path: 'episode2', loadChildren: () => import('./episode2/episode2.module').then( m => m.Episode2PageModule) },
  { path: 'quest_collectionadmin', loadChildren: () => import('./quest/collectionadmin/collectionadmin.module').then( m => m.CollectionadminPageModule) },
  { path: 'quest_selectproject', loadChildren: () => import('./quest/selectproject/selectproject.module').then( m => m.SelectprojectPageModule) },
  { path: 'quest_collection', loadChildren: () => import('./quest/collection/collection.module').then( m => m.CollectionPageModule) },
  { path: 'quest_challenges', loadChildren: () => import('./quest/challenges/challenges.module').then( m => m.ChallengesPageModule) },
  { path: 'quest_challenge', loadChildren: () => import('./quest/challenge/challenge.module').then( m => m.ChallengePageModule) },
  { path: 'test', loadChildren: () => import('./main/test/test.module').then( m => m.TestPageModule) },
  { path: 'minigame', loadChildren: () => import('./beta/minigame/minigame.module').then( m => m.MinigamePageModule) },
  { path: 'mhs/play', loadChildren: () => import('./boxes/play/play.module').then( m => m.PlayPageModule) },
  { path: 'mhs/main', loadChildren: () => import('./boxes/main/main.module').then( m => m.MainPageModule) },
  { path: 'mhs/truck', loadChildren: () => import('./boxes/loop/loop.module').then( m => m.LoopPageModule) },
  { path: 'feed', loadChildren: () => import('./rfh/feed/feed.module').then( m => m.FeedPageModule) },
  { path: 'feed/chall', loadChildren: () => import('./rfh/chall/chall.module').then( m => m.ChallPageModule) },
  {
    path: 'seasonpass',
    loadChildren: () => import('./rfh/seasonpass/seasonpass.module').then( m => m.SeasonpassPageModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then( m => m.DemoPageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'iaps',
    loadChildren: () => import('./molerts/iaps/iaps.module').then( m => m.IapsPageModule)
  },
  {
    path: 'toasts',
    loadChildren: () => import('./molerts/toasts/toasts.module').then( m => m.ToastsPageModule)
  },
  {
    path: 'episode',
    loadChildren: () => import('./molerts/episode/episode.module').then( m => m.EpisodePageModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./molerts/alerts/alerts.module').then( m => m.AlertsPageModule)
  },
  { path: 'sterlings/main', loadChildren: () => import('./sterlings/main/main.module').then( m => m.MainPageModule) },
  { path: 'sterlings/focus', loadChildren: () => import('./sterlings/focus/focus.module').then( m => m.FocusPageModule) },
  { path: 'sterlings/distraction', loadChildren: () => import('./sterlings/distraction/distraction.module').then( m => m.DistractionPageModule) },
  { path: 'sterlings/creativity', loadChildren: () => import('./sterlings/creativity/creativity.module').then( m => m.CreativityPageModule) },
  {
    path: 'instructions',
    loadChildren: () => import('./quest/instructions/instructions.module').then( m => m.InstructionsPageModule)
  },
  {
    path: 'briefing',
    loadChildren: () => import('./quest/briefing/briefing.module').then( m => m.BriefingPageModule)
  },
  {
    path: 'card',
    loadChildren: () => import('./quest/card/card.module').then( m => m.CardPageModule)
  },
  {
    path: 'digest',
    loadChildren: () => import('./quest/digest/digest.module').then( m => m.DigestPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./molerts/authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'sync',
    loadChildren: () => import('./molerts/sync/sync.module').then( m => m.SyncPageModule)
  },
  {
    path: 'radio',
    loadChildren: () => import('./molerts/radio/radio.module').then( m => m.RadioPageModule)
  },
  {
    path: 'music',
    loadChildren: () => import('./molerts/music/music.module').then( m => m.MusicPageModule)
  },
  {
    path: 'context',
    loadChildren: () => import('./quest/context/context.module').then( m => m.ContextPageModule)
  },
  {
    path: 'sequencer',
    loadChildren: () => import('./quest/ai/sequencer/sequencer.module').then( m => m.SequencerPageModule)
  },
  {
    path: 'bot',
    loadChildren: () => import('./bot/bot/bot.module').then( m => m.BotPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then( m => m.LanguagePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
