import { ActionSheetController, AlertController, LoadingController, NavController } from '@ionic/angular'
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { HttpClient } from '@angular/common/http';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserService } from '../cloud/user.service';
import { AuthorService } from '../cloud/author.service'
import { LocalizationService } from '../system/localization.service'
import { NavigationService } from '../system/navigation.service'

import { Events } from '../system/events.service'
import { VariasService } from '../system/varias.service';

@Injectable({
  	providedIn: 'root'
})
export class ProjectService {

	projects: Array<any> = [];
	sync: Array<any> = [];

  	constructor(
		private fireStore: AngularFirestore,
		private fireAuth: AngularFireAuth,
		private user: UserService,
		private author: AuthorService,
		private loadingController: LoadingController,
		private actionSheetController: ActionSheetController,
		private alertController: AlertController,
		private localization: LocalizationService,
		private http: HttpClient,
		private navigation: NavigationService,
		private navController: NavController,
		private varias: VariasService,
		private events: Events
	) {}

	syncProject(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.projects[projectId].authors) { this.projects[projectId].authors = []; }
		if (!this.projects[projectId].contacts) { this.projects[projectId].contacts = []; }
		if (!this.projects[projectId].contactsById) { this.projects[projectId].contactsById = []; }
		if (!this.projects[projectId].scenes) { this.projects[projectId].scenes = []; }
		if (!this.projects[projectId].draftings) { this.projects[projectId].draftings = []; }
		if (!this.projects[projectId].chat) { this.projects[projectId].chat = []; }
		if (!this.projects[projectId].role) { this.projects[projectId].role = "none"; }
		if (!this.projects[projectId].doc) { this.projects[projectId].doc = { d: "", n: "" }; }

		if (!this.sync[projectId]) { this.sync[projectId] = {}; }

		if (!this.sync[projectId].doc || !this.projects[projectId]) {
			this.sync[projectId].doc = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).ref.onSnapshot(doc => {
				if (doc.exists) {
					let docData: any = doc.data();
					if (!docData.n) { docData.n = ""; }
					if (!docData.d) { docData.d = ""; }
					this.projects[projectId].doc = { ...docData, id: doc.id };
					this.projects[projectId].id = doc.id;
					for (let projectOfAuthor of this.author.projects) {
						if (projectOfAuthor.id === docData.id) {
							projectOfAuthor.d = docData.d;
							projectOfAuthor.im = docData.im;
							projectOfAuthor.n = docData.n;
						}
					}
				} else {
					this.sync[projectId].doc.unlisten();
					this.sync[projectId].doc = null;
				}
			})
		}

		this.fireAuth.onAuthStateChanged(user => {
			if (!this.sync[projectId].role) {
				this.sync[projectId].role = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("admins").doc(user.uid).ref.onSnapshot(adminDoc => {
					if (adminDoc.exists) {
						this.projects[projectId].role = "admin";
						this.varias.setVariable(projectId+"_unl", true);
						this.varias.setVariable(projectId+"_skp", true);
						this.publishSyncRole(projectId);
					} else {
						this.sync[projectId].role = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("collabs").doc(user.uid).ref.onSnapshot(collabDoc => {
							if (collabDoc.exists) {
								this.projects[projectId].role = "collab";
								this.varias.setVariable(projectId+"_unl", true);
								this.varias.setVariable(projectId+"_skp", true);
								this.publishSyncRole(projectId);
							} else {
								this.sync[projectId].role = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("fellows").doc(user.uid).ref.onSnapshot(fellowDoc => {
									if (fellowDoc.exists) {
										this.projects[projectId].role = "fellow";
										this.varias.setVariable(projectId+"_unl", true);
										this.varias.setVariable(projectId+"_skp", true);
										this.publishSyncRole(projectId);
									}
								});
							}
						});
					}
				})
			}
		})
	}

	funnel_publishSyncRole: any = null;
	publishSyncRole(projectId) {
		clearTimeout(this.funnel_publishSyncRole);
		this.funnel_publishSyncRole = setTimeout(() => { this.doSyncRole(projectId); }, 400);
	}
	doSyncRole(projectId) { this.events.publish(projectId+"_role"); }

	syncAuthors(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.projects[projectId].authors) { this.projects[projectId].authors = []; }
		if (!this.projects[projectId].authorsById) { this.projects[projectId].authorsById = []; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }

		if (!this.sync[projectId].admins) {
			this.sync[projectId].admins = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("admins").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					this.updateAuthor(projectId, change, "admin");
				})
			})
		}		
		if (!this.sync[projectId].collabs) {
			this.sync[projectId].collabs = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("collabs").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					this.updateAuthor(projectId, change, "collab");
				})
			})
		}
		if (!this.sync[projectId].fellows) {
			this.sync[projectId].fellows = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("fellows").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					this.updateAuthor(projectId, change, "fellow");
				})
			})
		}		
	}

	syncCredits(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.projects[projectId].credits) { this.projects[projectId].credits = []; }

		if (!this.sync[projectId].credits) {
			this.sync[projectId].credits = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("credits").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					let index = this.projects[projectId].credits.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { 
							this.projects[projectId].credits[index] = docData; 
						} else { 
							this.projects[projectId].credits.push(docData);
						}
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].credits.splice(index,1); }
					}					
				})
			})
		}	
	}

	syncContexts(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.projects[projectId].contexts) { this.projects[projectId].contexts = []; }

		if (!this.sync[projectId].contexts) {
			this.sync[projectId].contexts = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("contexts").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					let index = this.projects[projectId].contexts.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) {
							this.projects[projectId].contexts[index] = docData;
						} else { 
							this.projects[projectId].contexts.push(docData);
						}
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].contexts.splice(index,1); }
					}					
				})
			})
		}
	}

	updateAuthor(projectId, change, role) {
		let docData: any = { ...change.doc.data(), id: change.doc.id }
		let index = this.projects[projectId].authors.findIndex(element => element.id === docData.id);
		if (change.type === "added" || change.type === "modified") {
			if (index > -1) {
				this.projects[projectId].authors[index].role = role;
				this.projects[projectId].authorsById[docData.id].role = role;
			} else { 
				this.fireStore.collection("cugc").doc("authors").collection("authors").doc(docData.id).collection("public").doc("info").ref.get().then(authorDoc => {
					let authorData = { ...authorDoc.data(), id: change.doc.id, role: role }
					this.projects[projectId].authors.push(authorData);
					this.projects[projectId].authorsById[authorData.id] = authorData;
				})
			}
		} else if (change.type === "removed") {
			if (index > -1) { 
				if (this.projects[projectId].authors[index].role === role) {
					this.projects[projectId].authors.splice(index,1);
				}
			}
		}
	}
	
	syncContacts(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.projects[projectId].contactsById) { this.projects[projectId].contactsById = []; }

		if (!this.sync[projectId].contacts) {
			if (!this.projects[projectId].contacts) { this.projects[projectId].contacts = []; }
			this.sync[projectId].contacts = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("contacts").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					let index = this.projects[projectId].contacts.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { 
							this.projects[projectId].contacts[index] = docData; 
							this.projects[projectId].contactsById[docData.id] = docData;
						} else { 
							this.projects[projectId].contacts.push(docData);
							this.projects[projectId].contactsById[docData.id] = docData;
						}
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].contacts.splice(index,1); }
					}
					this.publishSyncContacts(projectId);
				})
			})
		}
	}

	funnel_publishSyncContacts: any = null;
	publishSyncContacts(projectId) {
		clearTimeout(this.funnel_publishSyncContacts);
		this.funnel_publishSyncContacts = setTimeout(() => { this.doSyncContacts(projectId); }, 400);
	}
	doSyncContacts(projectId) { this.events.publish(projectId+"_contacts"); }

	syncScenes(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].scenes) {
			if (!this.projects[projectId].scenes) { this.projects[projectId].scenes = []; }
			this.sync[projectId].scenes = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					let index = this.projects[projectId].scenes.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { this.projects[projectId].scenes[index] = docData; } 
						else { this.projects[projectId].scenes.push(docData); }
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].scenes.splice(index,1); }
					}
					this.publishSyncScenes(projectId);
				})
			})
		}
	}

	syncChallenges(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.projects[projectId].challenges) { this.projects[projectId].challenges = []; }
		if (!this.sync[projectId].challenges) {
			this.sync[projectId].challenges = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("challenges").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let challengeId = change.doc.id;
					let index = this.projects[projectId].challenges.findIndex(element => element.id === challengeId);
					if (change.type === "added" || change.type === "modified") {
						this.fireStore.collection("cugc").doc("challenges").collection("challenges").doc(challengeId).ref.onSnapshot(challengeDoc => {
							if (challengeDoc.exists) {
								let challengeData = { ... challengeDoc.data(), id: challengeDoc.id };
								if (index > -1) { this.projects[projectId].challenges[index] = challengeData; }
								else { this.projects[projectId].challenges.push(challengeData); }
							} else {
								if (index > -1) { this.projects[projectId].challenges.splice(index,1); }		
							}					
						})
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].challenges.splice(index,1); }
					}
				})
			})
		}
	}

	syncCollections(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.projects[projectId].collections) { this.projects[projectId].collections = []; }
		if (!this.sync[projectId].collections) {
			this.sync[projectId].collections = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("collections").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let collectionId = change.doc.id;
					let index = this.projects[projectId].collections.findIndex(element => element.id === collectionId);
					if (change.type === "added" || change.type === "modified") {
						this.fireStore.collection("cugc").doc("collections").collection("collections").doc(collectionId).ref.onSnapshot(collectionDoc => {
							if (collectionDoc.exists) {
								let collectionData = { ... collectionDoc.data(), id: collectionDoc.id };
								if (index > -1) { this.projects[projectId].collections[index] = collectionData; }
								else { this.projects[projectId].collections.push(collectionData); }
							} else {
								if (index > -1) { this.projects[projectId].collections.splice(index,1); }		
							}					
						})
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].collections.splice(index,1); }
					}
				})
			})
		}
	}	

	funnel_publishSyncScenes: any = null;
	publishSyncScenes(projectId) {
		clearTimeout(this.funnel_publishSyncScenes);
		this.funnel_publishSyncScenes = setTimeout(() => { this.doSyncScenes(projectId); }, 400);
	}
	doSyncScenes(projectId) { this.events.publish(projectId+"_scenes"); }

	syncDraftings(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].draftings) {
			if (!this.projects[projectId].draftings) { this.projects[projectId].draftings = []; }
			this.sync[projectId].draftings = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("draftings").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id }
					let index = this.projects[projectId].draftings.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { this.projects[projectId].draftings[index] = docData; } 
						else { this.projects[projectId].draftings.push(docData); }
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].draftings.splice(index,1); }
					}
				})
			})
		}
	}

	syncChat(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].chat) {
			if (!this.projects[projectId].chat) { this.projects[projectId].chat = []; }
			this.sync[projectId].chat = this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("chat").ref.orderBy("at", "asc").limit(30).onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id };
					if (docData.t.startsWith("https://") || docData.t.startsWith("www.")) { docData.l = true; }
					let index = this.projects[projectId].chat.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						if (index > -1) { this.projects[projectId].chat[index] = docData; } 
						else { this.projects[projectId].chat.push(docData); }
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].chat.splice(index,1); }
					}
				})
			})
		}
	}

	syncEpisode(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].episode) {
			this.sync[projectId].episode = this.fireStore.collection("cugc").doc("episodes").collection("episodes").doc(projectId).ref.onSnapshot(doc => {
				if (doc.exists) {
					let docData: any =  { ...doc.data(), id: doc.id };
					let formattedDate = "---"
					if (docData.crat) {
						let date = docData.crat.toDate();
						let time = date.getTime();
						if (this.localization.lang === "EN") { formattedDate = new Intl.DateTimeFormat("en-EN").format(time); } 
						else { formattedDate = new Intl.DateTimeFormat("de-DE").format(time); }
					}
					docData.formattedDate = formattedDate;
					this.projects[projectId].episode = docData;
				} else {
					this.projects[projectId].episode = null;
				}
			})
		}	
	}

	syncCatalogue(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].catalogue) {
			this.sync[projectId].catalogue = this.fireStore.collection("cugc").doc("catalogue").collection("catalogue").doc(projectId).ref.onSnapshot(doc => {
				if (doc.exists) {
					let docData: any = { ...doc.data(), id: doc.id };
					let formattedDate = "---";
					if (docData.crat) {
						let langCode = "en-EN"; if (this.localization.lang === "DE") { langCode = "de-DE"; }
						formattedDate = new Intl.DateTimeFormat(langCode).format(docData.crat.toDate().getTime());
					}
					docData.formattedDate = formattedDate;

					this.projects[projectId].catalogue = docData;

					if (!this.projects[projectId].awards) { this.projects[projectId].awards = {}; }
					if (docData.bdg_edt) { this.projects[projectId].awards.bdg_edt = true; } else { this.projects[projectId].awards.bdg_edt = false; }
					if (docData.bdg_can) { this.projects[projectId].awards.bdg_can = true; } else { this.projects[projectId].awards.bdg_can = false; }
					if (docData.bdg_fav || docData.a_bdg_fav) { this.projects[projectId].awards.bdg_fav = true; } else { this.projects[projectId].awards.bdg_fav = false; }
					if (docData.bdg_pop || docData.a_bdg_pop) { this.projects[projectId].awards.bdg_pop = true; } else { this.projects[projectId].awards.bdg_pop = false; }
					if (docData.bdg_fin || docData.a_bdg_fin) { this.projects[projectId].awards.bdg_fin = true; } else { this.projects[projectId].awards.bdg_fin = false; }
					if (docData.bdg_ptu || docData.a_bdg_ptu) { this.projects[projectId].awards.bdg_ptu = true; } else { this.projects[projectId].awards.bdg_ptu = false; }
					if (docData.bdg_cmt) { this.projects[projectId].awards.bdg_cmt = true; } else { this.projects[projectId].awards.bdg_cmt = false; }
				} else {
					this.projects[projectId].catalogue = null;
				}
			})
		}
	}

	syncEchoes(projectId) {
		if (!this.projects[projectId]) { this.projects[projectId] = {}; }
		if (!this.sync[projectId]) { this.sync[projectId] = {}; }
		if (!this.sync[projectId].echoes) {
			if (!this.projects[projectId].echoes) { this.projects[projectId].echoes = []; }
			this.sync[projectId].echoes = this.fireStore.collection("cugc").doc("echoes").collection("echoes").doc(projectId).collection("echoes").ref.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(async change => {
					let docData: any = { ...change.doc.data(), id: change.doc.id };
					let index = this.projects[projectId].echoes.findIndex(element => element.id === docData.id);
					if (change.type === "added" || change.type === "modified") {
						let formattedDate = "---"
						if (docData.crat) {
							let date = docData.crat.toDate();
							let time = date.getTime();
							if (this.localization.lang === "EN") { formattedDate = new Intl.DateTimeFormat("en-EN").format(time); } 
							else { formattedDate = new Intl.DateTimeFormat("de-DE").format(time); }						
						}
						docData.formattedDate = formattedDate;						
						if (index > -1) { this.projects[projectId].echoes[index] = docData; } 
						else { this.projects[projectId].echoes.push(docData); }
					} else if (change.type === "removed") {
						if (index > -1) { this.projects[projectId].echoes.splice(index,1); }
					}
				})
			})
		}
	}

	unsyncProject(projectId) {
		if (this.sync[projectId]) {
			if (this.sync[projectId].doc) { this.sync[projectId].doc.unlisten(); this.sync[projectId].doc = null; }
			if (this.sync[projectId].authors) { this.sync[projectId].authors.unlisten(); this.sync[projectId].authors = null; }
			if (this.sync[projectId].contacts) { this.sync[projectId].contacts.unlisten(); this.sync[projectId].contacts = null; }
			if (this.sync[projectId].scenes) { this.sync[projectId].scenes.unlisten(); this.sync[projectId].scenes = null; }
			if (this.sync[projectId].admins) { this.sync[projectId].collabs.unlisten(); this.sync[projectId].admins = null; }
			if (this.sync[projectId].collabs) { this.sync[projectId].collabs.unlisten(); this.sync[projectId].collabs = null; }
			if (this.sync[projectId].fellows) { this.sync[projectId].fellows.unlisten(); this.sync[projectId].fellows = null; }
			if (this.sync[projectId].draftings) { this.sync[projectId].draftings.unlisten(); this.sync[projectId].draftings = null; }
			if (this.sync[projectId].chat) { this.sync[projectId].chat.unlisten(); this.sync[projectId].chat = null; }
		}
	}

	async togglePrivate(projectId) {
		let priv = false;
		if (this.projects[projectId].catalogue) {
			priv = false; if (this.projects[projectId].catalogue.priv) { priv = true; }
			let crat = this.projects[projectId].catalogue.crat;
			if (!priv) { crat = new Date(); }
			this.fireStore.collection("cugc").doc("episodes").collection("episodes").doc(projectId).ref.update({ priv: !this.projects[projectId].catalogue.priv, crat: crat })
			.then(() => {
				this.fireStore.collection("cugc").doc("catalogue").collection("catalogue").doc(projectId).ref.update({ priv: !this.projects[projectId].catalogue.priv, crat: crat })
				.then(async () => {
					for (let author of this.projects[projectId].authors ) {
						await this.fireStore.collection("cugc").doc("authors").collection("authors").doc(author.id).collection("public").doc("exports").collection("exports").doc(projectId).update({ priv: !priv })
						.catch((err3) => { console.log("ERROR 3", err3); });
					}					
				}).catch((err1) => { console.log("ERROR 1", err1); })
			}).catch((err2) => { console.log("ERROR 2", err2); });
		}
		return priv;
	}

	async clone(projectId) {
		const loading = await this.loadingController.create({
			cssClass: "eon__alert",
			message: this.localization.oneMomentPlease,
			duration: 30000
		})
		await loading.present();

		this.http.get('https://avoidsociety.app/cugc_createProject?author='+this.author.uid).subscribe(async (response: any) => {
			if (response) {

				let projectDoc = JSON.parse(JSON.stringify(this.projects[projectId].doc));
				projectDoc.id = response.id;
				projectDoc.n = projectDoc.n + " (Clone)";

				this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectDoc.id).update(projectDoc);

				await this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.author.uid).collection("projects").doc(projectDoc.id).set({ id: projectDoc.id });
				
				for await (let contact of this.projects[projectId].contacts) {
					await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectDoc.id).collection("contacts").doc(contact.id).set(contact);
				}

				for await (let scene of this.projects[projectId].scenes) {
					await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectDoc.id).collection("scenes").doc(scene.id).set(scene);
					let items = [];
					await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").doc(scene.id).collection("items").ref.get().then(async query => {
						query.forEach(doc => { items.push({ ...doc.data(), id: doc.id }); });
					})
					for (let item of items) {
						await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectDoc.id).collection("scenes").doc(scene.id).collection("items").doc(item.id).set(item);
					}
				}				

				await loading.dismiss();
				await this.navController.pop();
				this.navigation.pushCUGC_project(projectDoc.id);

			} else {
				loading.dismiss();
			}
		})		
	}

	async cloneScene(projectId, sceneId) {
		for await (let scene of this.projects[projectId].scenes) {
			if (scene.id === sceneId) {
				let sceneDoc = JSON.parse(JSON.stringify(scene));
				sceneDoc.id = "";
				sceneDoc.n = "";
				sceneDoc.t = sceneDoc.t + " (Copy)";
				sceneDoc.s = false;
				await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").add(sceneDoc).then(async newDoc => {

					sceneDoc.id = newDoc.id;

					let items = [];
					await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").doc(scene.id).collection("items").ref.get().then(async query => {
						query.forEach(doc => { items.push({ ...doc.data(), id: doc.id }); });
					})
					for (let item of items) {
						await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").doc(newDoc.id).collection("items").doc(item.id).set(item);
					}

					const alert = await this.alertController.create({
						header: "Szene",
						subHeader: "geklont",
						cssClass: 'eon__alert',
						message: "Eine Kopie der Szene wurde im Archiv abgelegt. Du kannst von dort aus in das Storyboard einfügen.",
						buttons: [ { text: "Ok", handler: () => {} } ]
					});
					alert.present();

				}).catch(error => console.log("ERROR", error) )
			}
		}
	}

	async leave(projectId) {
		const alert = await this.alertController.create({
			header: "Projekt",
			subHeader: "verlassen?",
			cssClass: 'eon__alert',
			message: "Möchtest du das Projekt wirklich verlassen? Du verlierst damit jeden Zugriff darauf.",
			buttons: [
				{
					text: "Abbrechen", role: "cancel", handler: () => {}
				},{ 
					text: 'Ja, Projekt verlassen', handler: () => {
						this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.author.uid).collection("projects").doc(projectId).delete().catch(error => {});
						this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("collabs").doc(this.author.uid).delete().catch(error => {});
						this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("fellows").doc(this.author.uid).delete().catch(error => {});
						this.fireStore.collection("cugc").doc("authors").collection("authors").doc(this.author.uid).collection("invites").doc(projectId).delete().catch(error => {});
						this.navController.pop();
					}
				}
			]
		})
		await alert.present();
	}

	async toggleEpisode(projectId) {
		let live = false;
		if (this.projects[projectId].catalogue) {
			this.fireStore.collection("cugc").doc("episodes").collection("episodes").doc(projectId).ref.update({ l: !this.projects[projectId].catalogue.l })
			.then(() => {
				this.fireStore.collection("cugc").doc("catalogue").collection("catalogue").doc(projectId).ref.update({ l: !this.projects[projectId].catalogue.l })
				.then(() => {
				}).catch((err1) => { console.log("ERROR 1", err1); })
			}).catch((err2) => { console.log("ERROR 2", err2); });
			this.toggleExportOnAuthorsPage(projectId);
			live = !this.projects[projectId].catalogue.l;
		}
		return live;
	}

	async toggleEcho(projectId, echoId) {
		let live = false;
		if (this.projects[projectId].catalogue) {
			this.fireStore.collection("cugc").doc("echoes").collection("echoes").doc(projectId).collection("echoes").doc(echoId).ref.get().then((echoDoc) => {
				if (echoDoc.exists) {
					let echoDocData = echoDoc.data();
					if (echoDocData.info) {
						echoDocData.info.l = !echoDocData.info.l;
						live = echoDocData.info.l;
						this.fireStore.collection("cugc").doc("echoes").collection("echoes").doc(projectId).collection("echoes").doc(echoId).ref.set(echoDocData).then(() => {
							this.fireStore.collection("cugc").doc("catalogue").collection("catalogue").doc(projectId).ref.update({ l: echoDocData.info.l }).catch((err) => {})
						}).catch(() => {});
					}
				}
			}).catch(() => {})
			this.toggleExportOnAuthorsPage(projectId);
		}
		return live;
	}

	async toggleExportOnAuthorsPage(projectId) {
		if (this.projects[projectId].catalogue) {
			for (let author of this.projects[projectId].authors ) {
				await this.fireStore.collection("cugc").doc("authors").collection("authors").doc(author.id).collection("public").doc("exports").collection("exports").doc(projectId).update({ l: !this.projects[projectId].catalogue.l }).catch(() => {});
			}
		}
	}

	async importContacts_chooseProject(targetProjectId, tries) {
		if (tries >= 3) { return; }
		if (this.author.projects.length > 0) {
			if (this.loading_importScenes) { this.loading_importScenes.dismiss(); }
			let buttons = [];
			for (let project of this.author.projects) {
				buttons.push({
					text: project.n,
					cssClass: "eon__actionSheet__button1",
					handler: () => { this.importContacts_import(targetProjectId, project.id) }
				})
			}
			let sheet = await this.actionSheetController.create({
				header: "Projekte", cssClass: "eon__actionSheet", buttons: buttons
			})
			await sheet.present();
		} else {
			this.loading_importScenes = await this.loadingController.create({
				spinner: "bubbles", cssClass: "eon__alert",
				message: this.localization.oneMomentPlease,
				translucent: true, duration: 4000, showBackdrop: true
			});
			await this.loading_importScenes.present();
			this.author.syncProjects();
			setTimeout(() => { this.importContacts_chooseProject(targetProjectId, tries+1); }, 3000);
		}
	}
	async importContacts_import(targetProjectId, projectId) {
		console.log("IMPORT CONTACTS FROM " + projectId);

		if (this.projects[targetProjectId]) {
			let contacts = [];
			await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("contacts").ref.get().then(snapshot => {
				snapshot.forEach(doc => {
					let contact = doc.data(); 
					let idExists = false;
					for (let existingContact of this.projects[targetProjectId].contacts) {
						if (existingContact.id === contact.id) { idExists = true; }
					}
					if (!idExists) { contacts.push(contact); }
				})
			})
			for (let contact of contacts) {
				if (contact.id) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("contacts").doc(contact.id).set(contact); } 
				else if (contact.i) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("contacts").doc(contact.i).set(contact); }
			}
		}

	}

	loading_importScenes: any = null;
	async importScene_chooseProject(targetProjectId, tries) {
		if (tries >= 3) { return; }
		if (this.author.projects.length > 0) {
			if (this.loading_importScenes) { this.loading_importScenes.dismiss(); }
			let buttons = [];
			for (let project of this.author.projects) {
				buttons.push({
					text: project.n,
					cssClass: "eon__actionSheet__button1",
					handler: () => { this.importScene_chooseScene(targetProjectId, project.id) }
				})
			}
			let sheet = await this.actionSheetController.create({
				header: "Projekte", cssClass: "eon__actionSheet", buttons: buttons
			})
			await sheet.present();
		} else {
			this.loading_importScenes = await this.loadingController.create({
				spinner: "bubbles", cssClass: "eon__alert",
				message: this.localization.oneMomentPlease,
				translucent: true, duration: 4000, showBackdrop: true
			});
			await this.loading_importScenes.present();
			this.author.syncProjects();
			setTimeout(() => { this.importScene_chooseProject(targetProjectId, tries+1); }, 3000);
		}
	}
	async importScene_chooseScene(targetProjectId, projectId) {
		let buttons = [];
		await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").ref.get().then(snapshot => {
			snapshot.forEach(doc => {
				let docData: any = doc.data();
				buttons.push({
					text: docData.t,
					cssClass: "eon__actionSheet__button1",
					handler: () => { this.importScene_import(targetProjectId, projectId, docData); }
				})
			})
		}).catch(() => {})
		if (buttons.length > 0) {
			let sheet = await this.actionSheetController.create({
				header: "Szenen",
				cssClass: "eon__actionSheet",
				buttons: buttons
			})
			sheet.present();
		}
	}
	async importScene_import(targetProjectId, projectId, sceneData) {

		const loading = await this.loadingController.create({
			cssClass: "eon__alert",
			message: this.localization.oneMomentPlease,
			duration: 30000
		})
		await loading.present();

		if (this.projects[targetProjectId]) {
			for (let existingScene of this.projects[targetProjectId].scenes) {
				if (existingScene.id === sceneData.id) { return; }
			}
			sceneData.s = false;
	
			let items = [];
			await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("scenes").doc(sceneData.id).collection("items").ref.get().then(snapshot => {
				snapshot.forEach(doc => { items.push(doc.data()); })
			})
			let allContacts = [];
			await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(projectId).collection("contacts").ref.get().then(snapshot => {
				snapshot.forEach(doc => { allContacts.push(doc.data()) })
			})
			let contacts = [];
			for (let contact of allContacts) {
				for (let existingContact of this.projects[targetProjectId].contacts) {
					if (existingContact.id === contact.id) { break; }
				}
				for (let item of items) {
					if (item.c === contact.id) {
						contacts.push(contact);
						break;
					}
				}
			}
	
			for (let contact of contacts) {
				if (contact.id) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("contacts").doc(contact.id).set(contact).then(() => {}).catch((error) => {}); } 
				else if (contact.i) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("contacts").doc(contact.i).set(contact).then(() => {}).catch((error) => {}); }
			}
			if (sceneData.id) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("scenes").doc(sceneData.id).set(sceneData).then(() => {}).catch((error) => {}); } 
			else if (sceneData.i) { this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("scenes").doc(sceneData.i).set(sceneData).then(() => {}).catch((error) => {}); }
			for (let item of items) {
				if (item.id) { await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("scenes").doc(sceneData.id).collection("items").doc(item.id).set(item).then(() => {}).catch((error) => {}); } 
				else if (item.i) { await this.fireStore.collection("cugc").doc("projects").collection("projects").doc(targetProjectId).collection("scenes").doc(sceneData.id).collection("items").doc(item.i).set(item).then(() => {}).catch((error) => {}); }
			}

			loading.dismiss();

			const alert = await this.alertController.create({
				cssClass: "eon__alert",
				subHeader: "Szene",
				header: "importiert",
				message: "Die importierte Szene wird im Archiv dieses Projekts abgelegt. Du kannst sie von dort aus in deine Story einbinden.",
				buttons: [ { text: "Ok", handler: () => {} } ]
			});
			alert.present();
		} else {
			loading.dismiss();
		}
	}	
}