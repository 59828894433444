import { Component, OnInit, Input } from '@angular/core';
import { LocalizationService } from 'src/app/system/localization.service';

@Component({
  	selector: 'app-sync',
  	templateUrl: './sync.page.html',
  	styleUrls: ['./sync.page.scss'],
})
export class SyncPage implements OnInit {

	icon: string = "hourglass-start";

	anim1: boolean = true;
	anim2: boolean = false;
	anim3: boolean = false;
	animBeat: boolean = false;
	animFlip: boolean = true;

  	constructor(
		public localization: LocalizationService
	) { }

  	ngOnInit() {
		setTimeout(() => {
			this.switchIcon();
		}, 500);
	}

	switchIcon() {
		if (this.anim1) { this.anim1 = false; this.anim2 = true; }
		else if (this.anim2) { this.anim2 = false; this.anim3 = true; }
		else if (this.anim3) { this.anim3 = false; this.anim1 = true; }
		setTimeout(() => {
			this.switchIcon();
		}, 1000);
	}

}
