import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { ModalController, IonContent } from '@ionic/angular'

import { SplashScreen } from '@capacitor/splash-screen';

import { BrowserService } from '../system/browser.service';

import { PurchaseService } from '../cloud/purchase.service'
import { LocalizationService } from '../system/localization.service'
import { VariasService } from '../system/varias.service';
import { NavigationService } from '../system/navigation.service';
import { BootService } from '../system/boot.service';

@Component({
  	selector: 'app-membership',
  	templateUrl: './membership.page.html',
  	styleUrls: ['./membership.page.scss'],
})
export class MembershipPage implements OnInit {
	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@Input("asModal") asModal: boolean = false;

  	constructor(
		private modalController: ModalController,
		public purchase: PurchaseService,
		private iab: BrowserService,
		public localization: LocalizationService,
		public varias: VariasService,
		private navigation: NavigationService
	) {
		SplashScreen.hide();
	}

  	ngOnInit() {}

  	close(data: any = {}) { 
		if (this.asModal) { this.modalController.dismiss(data); } 
		else { this.navigation.pop(); }
	}

	openTermsAndConitions() {
		let link = "https://www.iubenda.com/terms-and-conditions/60316547";
		if (this.localization.lang === "DE") { link = "https://www.iubenda.com/nutzungsbedingungen/95578647" }
		this.iab.create(link, '_blank', 'location=yes')
	}

	openPrivacyPolicy() {
		let link = "https://www.iubenda.com/privacy-policy/60316547";
		if (this.localization.lang === "DE") { link = "https://www.iubenda.com/privacy-policy/95578647"; }
		this.iab.create(link, '_blank', 'location=yes')
	}

	orderMembership() {
		this.modalController.dismiss();
		this.purchase.orderMembership('membershipPage');
	}

	clickCard() {
		this.ionContent.scrollToBottom(400);
	}
}