import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore'

import { LocalizationService } from './localization.service';
import { VariasService } from './varias.service';

@Injectable({
  providedIn: 'root'
})
export class Fair2playService {

	sundayOnServer: boolean = false;
	sundayOnDevice: boolean = false;
	hasRunningEpisodes: boolean = false;
	tickets: number = 0;
	episodes: Array<any> = [];
	legit: boolean = false;
	alreadyUsedToday: boolean = false;

  	constructor(
		private fireStore: AngularFirestore,
		private localization: LocalizationService,
		private varias: VariasService
	) { }

	async checkIfAllowed(tickets, storyboardsRegistry, episodeId) {

		this.legit = false;

		if (tickets >= 100) { return "has-tickets";  }

		let runningEpisodesString = "";
		for await (let storyboard of storyboardsRegistry) {
			if (storyboard.started && !storyboard.completed) {
				this.hasRunningEpisodes = true;
				runningEpisodesString = runningEpisodesString + storyboard.n;
				if (storyboard.archived) { runningEpisodesString = runningEpisodesString + " (archiviert), "}
				else { runningEpisodesString = runningEpisodesString + ", "}
			}
		}
		runningEpisodesString = runningEpisodesString.slice(0,-2);
		this.hasRunningEpisodes = false;
		if (this.hasRunningEpisodes) { return "has-running"; }

		await this.fireStore.collection(this.localization.DB_meta).doc("sunday").ref.get().then(doc => {
			let docData: any = doc.data();
			if (docData.sunday) { this.sundayOnServer = true; }
			this.episodes = docData.ep;
		})
		let now = new Date; if (now.getDay() === 0) { this.sundayOnDevice = true; }

		if (!this.sundayOnServer || !this.sundayOnDevice) {
			return "not-sunday";
		}

		let dateString = "y"+now.getFullYear()+"m"+now.getMonth()+"d"+now.getDate();
		let lastUsed = ""; await this.varias.getVariable("sys_fair2PlayLastUsed").then(result => lastUsed = result);
		if (lastUsed === dateString) { return "done-today"; }

		let amongLegitEpisodes: boolean = false;
		for await (let legitEpisodeId of this.episodes) {
			if (legitEpisodeId === episodeId) {
				amongLegitEpisodes = true;
			}
		}
		if (!amongLegitEpisodes) { return "not-episode"; }

		this.legit = true;
		return "legit";

	}

	useToday() {
		let now = new Date;
		let dateString = "y"+now.getFullYear()+"m"+now.getMonth()+"d"+now.getDate();
		this.varias.setVariable("sys_fair2PlayLastUsed", dateString, false);
	}
}
