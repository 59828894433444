import { Injectable } from '@angular/core';
import { AlertController, Platform, LoadingController } from "@ionic/angular";
import { Storage } from '@ionic/storage-angular';

import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config'

import { VariasService } from '../system/varias.service';
import { LocalizationService } from '../system/localization.service';
import { DataService } from '../system/data.service';
import { MetaService } from '../cloud/meta.service'

import { Events } from '../system/events.service'
import { TelemetryService } from './telemetry.service';
import { DeviceService } from '../system/device.service';
import { InstanceService } from '../system/instance.service';
import { MolertsService } from '../system/molerts.service';
import { StorageService } from '../system/storage.service';
import { AssetsService } from '../system/assets.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

	loading: any;
	products: Array<any> = [];
	prices: Array<any> = [];
	productList: Array<any> = []
	sets: Array<any> = [];

	membershipProduct: any = { id: "membership", type: this.store.PAID_SUBSCRIPTION, title: "Membership", description: "...", price: "4,99 €" };
	membershipTitle: string = "Membirshep"
	membershipPrice: string = "4'99 €";
	membershipRegular: string = "4'99 €";
	membershipOffer: boolean = false;
	membershipDiscountPriceMicro: number = 9990000;

	constructor(
		public platform: Platform,
		private store: InAppPurchase2,
		private varias: VariasService,
		private loadingController: LoadingController,
		private localization: LocalizationService,
		private events: Events,
		private config: AngularFireRemoteConfig,
		private data: DataService,
		private meta: MetaService,
		private storage: Storage,
		private telemetry: TelemetryService,
		private device: DeviceService,
		private instance: InstanceService,
		private storageSrv: StorageService,
		private assets: AssetsService
	) {}

	async prepare() {
		//this.store.verbosity = this.store.DEBUG;
		this.store.verbosity = this.store.QUIET;
		this.store.validator = "https://validator.fovea.cc/v1/validate?appName=me.cloudtells.avoidsociety&apiKey=6a839073-e7cd-42f9-9939-a60a8fdca78f";

		this.productList = [
			{ id: "membership", type: this.store.PAID_SUBSCRIPTION, title: "Membership", description: "...", price: "4,99 €" },
			{ id: "ep_unlock_pa1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa6", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa7", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_pa8", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa9", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa10", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa11", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa12", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa13", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa14", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_pa15", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa16", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa17", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa18", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa19", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa20", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa21", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_pa22", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa23", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa24", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa25", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa26", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa27", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_pa28", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
	
			{ id: "ep_unlock_ga1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga6", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga7", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_ga8", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga9", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga10", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga11", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga12", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga13", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ga14", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_ha1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha6", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_ha7", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "ep_unlock_xa1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa6", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "ep_unlock_xa7", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },

			{ id: "boxset1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "boxset2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "boxset3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "boxset4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "boxset5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" },
	
			{ id: "7shards", type: this.store.CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: "10shards", type: this.store.CONSUMABLE, title: "...", description: "...", price: "---" },
			{ id: this.instance.iapPrefix+"10nutrition", type: this.store.CONSUMABLE, title: "10 Nutrition", description: "...", price: "0'99 €" },
			{ id: this.instance.iapPrefix+"125nutrition", type: this.store.CONSUMABLE, title: "125 Nutrition", description: "...", price: "6'99 €" },

			{ id: this.instance.iapPrefix+"special2", type: this.store.CONSUMABLE, title: "20 Nutrition", description: "20 Nutrition", price: "0'99 €" },
			{ id: "special3", type: this.store.NON_CONSUMABLE, title: "Episoden 2-4", description: "Episode 2-4", price: "2'99 €" },
			{ id: "special5", type: this.store.NON_CONSUMABLE, title: "Season 1 & 2", description: "Season 1 & 2", price: "9'99 €" },
			{ id: this.instance.iapPrefix+"special6", type: this.store.CONSUMABLE, title: "200 Tickets & 50 Nutrition", price: "3'99 €"},
			{ id: "special7", type: this.store.CONSUMABLE, title: "50 Nutrition", price: "1'99 €"},
			{ id: "membership2", type: this.store.PAID_SUBSCRIPTION, title: "Membership (50% off)", description: "...", price: "4,99 €", introPrice: "2,99 €" },

			{ id: this.instance.iapPrefix+"box_single", type: this.store.CONSUMABLE, title: "100 Tickets & 25 Nutrition", description: "...", price: "2,99 €" },
			{ id: this.instance.iapPrefix+"box_season", type: this.store.CONSUMABLE, title: "500 Tickets & 125 Nutrition", description: "...", price: "9,99 €" },
			
			{ id: "100tickets", type: this.store.CONSUMABLE, title: "100 Tickets", description: "...", price: "1,99 €" },

			{ id: this.instance.iapPrefix+"100circuits", type: this.store.CONSUMABLE, title: "100 Circuits", description: "...", price: "0,99 €" }
		]

		let legitSeasonPacks = []; await this.meta.getSeasonpacks().then(result => { legitSeasonPacks = result });
		for (let pack of legitSeasonPacks) {
			if (pack.l) {
				if (pack.s === "par1") {
					this.productList.push({ id: "se_unlock_par1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_par1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_par1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "par2") {
					this.productList.push({ id: "se_unlock_par2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_par2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_par2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "par3") {
					this.productList.push({ id: "se_unlock_par3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_par3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_par3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "par4") {
					this.productList.push({ id: "se_unlock_par4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_par4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_par4", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "par5") {
					this.productList.push({ id: "se_unlock_par5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_par5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_par5", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "gam1") {
					this.productList.push({ id: "se_unlock_gam1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_gam1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_gam1", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "gam2") {
					this.productList.push({ id: "se_unlock_gam2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_gam2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_gam2", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				} else if (pack.s === "gam3") {
					this.productList.push({ id: "se_unlock_gam3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_reduced_gam3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
					this.productList.push({ id: "se_single_gam3", type: this.store.NON_CONSUMABLE, title: "...", description: "...", price: "---" });
				}
			}
		}

		await this.meta.getSets().then(result => this.sets = result);
		if (this.sets) {
			for (let set of this.sets) {
				this.productList.push({ id: "st_unlock_"+set.i, type: this.store.NON_CONSUMABLE, title: "---", description: "...", price: "---" })
			}
		}
		
		for (let product of this.productList) { this.products[product.id] = product; }

		this.events.subscribe("purchaseIAP", (productId) => {
			this.order(productId, "event");
		})
	}

	async registerAll() {
		await this.prepare();
		if (this.device.platform === "ios" || this.device.platform === "android") {
			for (let product of this.productList) { this.subscribeProduct(product.id); }
			this.store.register(this.productList);
			this.store.refresh();
		}
	}

	async requestProduct(productId) {
		let owned = false;
		let existingStoreProduct: any = false; if (this.store.products) { existingStoreProduct = this.store.products.find(storeProduct => storeProduct.id === productId); }
		if (existingStoreProduct) {
			if (existingStoreProduct.owned) {
				owned = true;
			}
		}
		return owned;
	}

	async registerProduct(product) {}

	async onMembershipUpdate(product) {
		if (product.owned) {
			await this.varias.setVariable("sys_member", true);
			await this.varias.setVariable("sys_wasMember", true);
		} else {

			if (!this.products["membership"].owned && !this.products["membership2"].owned) {
				await this.varias.setVariable("sys_member", false);
				await this.varias.setVariable("sys_hasBeenMember", false);
			}

			if (product.id === "membership2") {
				let offerLegit = false;
				let isMember = await this.varias.getVariable("sys_member");
				if (!isMember) {
					let wasMember = await this.varias.getVariable("sys_wasMember");
					if (!wasMember) {
						let hasSpecial4 = await this.varias.getVariable("off_special4");
						if (hasSpecial4) {
							let special4Withdrawn = await this.varias.getVariable("off_special4_wtd");
							if (!special4Withdrawn) {
								if (!product.ineligibleForIntroPrice) {
									if (product.introPrice != product.price && product.introPrice != "") {
										this.membershipProduct = product;
										this.membershipPrice = product.introPrice;
										this.membershipRegular = product.price;
										this.membershipTitle = product.title;
										this.membershipOffer = true;
										offerLegit = true;										
									}									
								}
							}
						}
					}
				}
				if (!offerLegit) {
					this.membershipProduct = this.products["membership"];
					this.membershipPrice = this.products["membership"].price;
					this.membershipRegular = this.products["membership"].price;
					this.membershipTitle = this.products["membership"].title;
					this.membershipOffer = false;
				}
			} else if (product.id === "membership") {
				if (!this.membershipOffer) {
					this.membershipProduct = this.products["membership"];
					this.membershipPrice = this.products["membership"].price;
					this.membershipRegular = this.products["membership"].price;
					this.membershipTitle = this.products["membership"].title;					
				}
			}

		}
	}

	subscribeProduct(id) {
		this.store.when(id).registered	((product) => { this.onProductRegistered(product); })
		this.store.when(id).updated		((product) => { this.onProductUpdated(product); })
		this.store.when(id).cancelled	((product) => { this.onProductCancelled(product); });
		this.store.when(id).approved	((product) => { this.onProductApproved(product); });
		this.store.when(id).verified	((product) => { this.onProductVerified(product); })
		this.store.when(id).finished	((product) => { this.onProductFinished(product); })
		this.store.when(id).owned		((product) => { this.onProductOwned(product); })
	}
	onProductRegistered(product) {
		console.log("REGISTERED (SIMPLE) " + product.id);
		console.log("REGISTERED " + product.id + " " + JSON.stringify(product));
		this.products[product.id] = product;
	}
	onProductUpdated(product) {
		this.products[product.id] = product;
		if (product.id === "membership" || product.id === "membership2") {
			this.onMembershipUpdate(product);		
		}
	}
	onProductCancelled(product) {}
	onProductApproved(product) { 
		if (this.instance.instance === "voidapp") {
			product.verify();
		} else {
			this.onProductVerified(product);
		}
	}
	async onProductVerified(product) {
		this.events.publish("purchaseMade", product.id);
		this.events.publish("productOwned_"+product.id);
		if (product.id === "membership") {
			await this.varias.setVariable("sys_member", true);
			await this.varias.setVariable("sys_wasMember", true);
			this.events.publish("unlockMembership");
			await this.varias.setVariable("off_special4_wtd", true);
		} else if (product.id === "membership2") {
			await this.varias.setVariable("sys_member", true);
			await this.varias.setVariable("sys_wasMember", true);
			this.events.publish("unlockMembership");
			await this.varias.setVariable("off_special4_wtd", true);
		} else if (product.id.indexOf("ep_unlock_") > -1) {
			let episodeId = product.id.replace("ep_unlock_", "");
			await this.varias.setVariable(episodeId+"_unl", true);
			await this.varias.setVariable(episodeId+"_skp", true);
			this.events.publish("unlockEpisode_"+episodeId);
		} else if (product.id.startsWith("se_unlock_")) {
			let seasonId = product.id.replace("se_unlock_", "");
			await this.unlockSeason(seasonId);
		} else if (product.id.startsWith("se_reduced_")) {
			let seasonId = product.id.replace("se_reduced_", "");
			await this.unlockSeason(seasonId);			
		} else if (product.id.startsWith("se_single_")) {
			let seasonId = product.id.replace("se_single_", "");
			await this.unlockSeason(seasonId);
		} else if (product.id.startsWith("st_unlock_")) {
			let echoSetId = product.id.replace("st_unlock_", "");
			await this.unlockEchoPack(echoSetId);
		} else if (product.id === "7shards") {
			await this.varias.incrementCurrency("shards", 7);
			this.events.publish("purrchaseShards");
		} else if (product.id === "10shards") {
			await this.varias.incrementCurrency("shards", 10);
			this.events.publish("purrchaseShards");
		} else if (product.id === this.instance.iapPrefix+"10nutrition") {
			await this.varias.incrementCurrency("nutrition", 10);
			this.events.publish("purrchaseNutrition");
		} else if (product.id === this.instance.iapPrefix+"125nutrition") {
			await this.varias.incrementCurrency("nutrition", 125);
			this.events.publish("purrchaseNutrition");
		} else if (product.id === this.instance.iapPrefix+"100circuits") {
			await this.varias.incrementCurrency("circuits", 100);
		} else if (product.id === "boxset1") {
			this.unlockSeason("par1");
			this.unlockSeason("par2");
			this.unlockSeason("par3");
		} else if (product.id === "boxset2") {
			this.unlockSeason("par1");
			this.unlockSeason("par2");
			this.unlockSeason("par3");
			this.unlockSeason("par4");
		} else if (product.id === "boxset3") {
			this.unlockSeason("par1");
			this.unlockSeason("par2");
			this.unlockSeason("par3");
			this.unlockSeason("par4");
			this.unlockSeason("gam1");
			this.unlockSeason("gam2");
		} else if (product.id === "boxset4") {
			this.unlockSeason("gam1");
			this.unlockSeason("gam2");
		} else if (product.id === "boxset5") {
			this.unlockSeason("par1");
			this.unlockSeason("par2");
			this.unlockSeason("par3");
			this.unlockSeason("gam1");
		} else if (product.id === this.instance.iapPrefix+"special2") {
			await this.varias.incrementCurrency("nutrition", 20);
			this.events.publish("purrchaseNutrition");
			this.varias.setVariable("off_special2_wtd", true);
		} else if (product.id === "special3") {
			if (this.instance.instance === "voidapp" || this.instance.instance === "prllxapp") {
				await this.varias.setVariable("pa2_unl", true);
				await this.varias.setVariable("pa2_skp", true);
				this.events.publish("unlockEpisode_pa2");
				await this.varias.setVariable("pa3_unl", true);
				await this.varias.setVariable("pa3_skp", true);
				this.events.publish("unlockEpisode_pa3");
				await this.varias.setVariable("pa4_unl", true);
				await this.varias.setVariable("pa4_skp", true);
				this.events.publish("unlockEpisode_pa4");
			} else if (this.instance.instance === "gamerapp") {
				await this.varias.setVariable("ga2_unl", true);
				await this.varias.setVariable("ga2_skp", true);
				this.events.publish("unlockEpisode_ga2");
				await this.varias.setVariable("ga3_unl", true);
				await this.varias.setVariable("ga3_skp", true);
				this.events.publish("unlockEpisode_ga3");
				await this.varias.setVariable("ga4_unl", true);
				await this.varias.setVariable("ga4_skp", true);
				this.events.publish("unlockEpisode_ga4");
			}
			await this.varias.setVariable("off_special3_wtd", true);
		} else if (product.id === "special5") {
			this.unlockSeason("gam1");
			this.unlockSeason("gam2");
			this.varias.setVariable("off_special5_wtd", true);
		} else if (product.id === this.instance.iapPrefix+"special6") {
			await this.varias.incrementCurrency("tickets", 200);
			await this.varias.incrementCurrency("nutrition", 50);
			this.events.publish("purrchaseNutrition");
			this.varias.setVariable("off_special6_wtd", true);
		} else if (product.id === this.instance.iapPrefix+"box_single") {
			await this.varias.incrementCurrency("tickets", 100);
			await this.varias.incrementCurrency("nutrition", 25);
			this.events.publish("purrchaseNutrition");
		} else if (product.id === this.instance.iapPrefix+"box_season") {
			await this.varias.incrementCurrency("tickets", 500);
			await this.varias.incrementCurrency("nutrition", 125);
			this.events.publish("purrchaseNutrition");			
		}

		this.assets.unlockAssets();

		product.finish();

		if (product.id === "membership") {
			this.varias.setVariable("purr_winback1", false);
			this.varias.setVariable("purr_winback2", false);
			this.varias.setVariable("purr_winback3", false);
			this.storage.get("stbReg").then(async (value) => {
				if (value != null) {
					this.varias.getVariable("sys_hasBeenMember").then(async hasBeenMember => {
						if (!hasBeenMember) {

								this.varias.setVariable("sys_hasBeenMember", true);

								this.telemetry.logEvent("get_membership", {});

								let storyboardsRegistry = JSON.parse(value)
								let lastTouchedStb = { i: "none", touched: 0 };
								for await (let storyboard of storyboardsRegistry) {
									if (storyboard.touched > lastTouchedStb.touched) {
										lastTouchedStb = storyboard;
									}
								}
								let day = 1000; await this.varias.getVariable("usr_day").then(result => day = result);
								this.telemetry.logEvent("get_membership_ep_"+lastTouchedStb.i, { ep: lastTouchedStb.i, day: day });
								if (day <= 1) { this.telemetry.logEvent("get_membership_firstDay", { ep: lastTouchedStb.i, day: day }); }
								if (day <= 3) { this.telemetry.logEvent("get_membership_first3Days", { ep: lastTouchedStb.i, day: day }); }
								if (day <= 7) { this.telemetry.logEvent("get_membership_first7Days", { ep: lastTouchedStb.i, day: day }); }
								if (day <= 14) { this.telemetry.logEvent("get_membership_first14Days", { ep: lastTouchedStb.i, day: day }); }
								if (day <= 30) { this.telemetry.logEvent("get_membership_first30Days", { ep: lastTouchedStb.i, day: day }); }
								if (day <= 30) { this.telemetry.logEvent("get_membership_day_"+day, { ep: lastTouchedStb.i, day: day }); }
								if (day > 30) { this.telemetry.logEvent("get_membership_moreDays", { ep: lastTouchedStb.i, day: day }); }
			
								let hasTickets = false;
								let has100Tickets = false;
								if ( this.varias.vars['sys_tickets'] > 0) {
									hasTickets = true;
									if ( this.varias.vars['sys_tickets'] > 100) { has100Tickets = true; }
								}
								let hasNutrition = false;
								let has50Nutrition = false;
								if ( this.varias.vars['sys_nutrition'] > 0) {
									hasNutrition = true;
									if ( this.varias.vars['sys_nutrition'] > 50) { has50Nutrition = true; }
								}
								if (has100Tickets) { this.telemetry.logEvent("get_membership_has100Tickets", {}); }
								if (has50Nutrition) { this.telemetry.logEvent("get_membership_has50Nutri", {}); }								
								if (hasTickets) {
									if (hasNutrition) { this.telemetry.logEvent("get_membership_hasNutriTick", {}); }
									else { this.telemetry.logEvent("get_membership_hasTick", {}); }
								} else {
									if (hasNutrition) { this.telemetry.logEvent("get_membership_hasNutri", {}); }
									else { this.telemetry.logEvent("get_membership_hasNoCurrency", {}); }
								}

						}
					})					
				}
			})
		} else {
			this.varias.getVariable("usr_day").then(day => {
				this.telemetry.logEvent("iap_"+product.id, { day: day });
			}).catch(() => { this.telemetry.logEvent("iap_error", {}) });
		}
	}
	onProductFinished(product) {}
	onProductOwned(product) {
		console.log("OWNED " + product.id);
	}

	async order(productId, source = "unknown") {
		
		this.varias.setVariable("mightSeeOffSpecial2", false);
		
		if (productId === "membership" || productId === "membership2") {
			this.varias.setVariable("memberMolertAfterOrder", true);
		}

		const loading = await this.loadingController.create({
			spinner: "bubbles",
			message: this.localization.oneMomentPlease,
			translucent: true,
			duration: 3000,
			showBackdrop: true
		});
		loading.present();
		this.store.order(productId);

		this.telemetry.logEvent("IAPsrc_"+source, { productId: productId });
	}
	orderMembership(source = "unknown") { 
		this.varias.setVariable("memberMolertAfterOrder", true);
		this.order(this.membershipProduct.id, source);
	}

	logStoreProducts() {
		for (let product of this.store.products) {
			console.log(product.id + " - " + product.title + ": " + product.description + " (" + product.price + ")");
		}
	}

	restorePurchases() {
		this.data.restoredPurchases = [];

		/*
		this.meta.getEpisodes().then(serials => {
			for (let serial of serials) {
				for (let episode of serial.ep) {
					this.restoreProduct("ep_unlock_"+episode.i);
				}
			}
		})
		*/
	}

	manageSubscriptions() {
		this.store.manageSubscriptions();
	}

	async logAllStoreProducts() {
		let things = [];
		this.store.products.forEach(product => {
			things.push({ id: product.id, title: product.title, price: product.price, owned: product.owned });
			console.table(things);
		})
	}

	async logAllMirroredProducts() {
		let things = [];
		let keys = Object.keys(this.products);
		for (let key of keys) {
			let product = this.products[key];
			things.push({ id: product.id, title: product.title, price: product.price, owned: product.owned });
			console.table(things);
		}
	}

	async unlockSeason(seasonId) {
		let episodes = [];
		if (seasonId === "par1") {
			episodes = [ "pa1", "pa2", "pa3", "pa4", "pa5", "pa6", "pa7" ];
		} else if (seasonId === "par2") {
			episodes = [ "pa8", "pa9", "pa10", "pa11", "pa12", "pa13", "pa14" ];
		} else if (seasonId === "par3") {
			episodes = [ "pa15", "pa16", "pa17", "pa18", "pa19", "pa20", "pa21" ];
		} else if (seasonId === "par4") {
			episodes = [ "pa22", "pa23", "pa24", "pa25", "pa26", "pa27", "pa28" ];
		} else if (seasonId === "gam1") {
			episodes = [ "ga1", "ga2", "ga3", "ga4", "ga5", "ga6", "ga7" ];
		} else if (seasonId === "gam2") {
			episodes = [ "ga8", "ga9", "ga10", "ga11", "ga12", "ga13", "ga14" ];
		} else if (seasonId === "gam3") {
			episodes = [ "ga15", "ga16", "ga17", "ga18", "ga19", "ga20", "ga21" ];
		}

		for (let episodeId of episodes) {
			await this.varias.setVariable(episodeId+"_unl", true);
			await this.varias.setVariable(episodeId+"_skp", true);
			this.events.publish("unlockEpisode_"+episodeId);
		}

		await this.varias.setVariable(seasonId+"_pur", true);
		this.events.publish("unlockSeason_"+seasonId);
	}

	async unlockEchoPack(setId) {
		this.events.publish("unlockSet_"+setId);
		for (let set of this.sets) {
			if (set.i === setId) {
				for (let echo of set.e) {
					this.varias.setVariable("ech_"+echo.i+"_unl", true);
					this.varias.setVariable("ech_"+echo.i+"_skp", true);
					this.varias.setVariable("st_"+setId+"_unl", true);
					this.varias.setVariable("st_"+setId+"_skp", true);
				}
			}
		}
	}

}