import { IonContent, DomController, AlertController, ModalController } from '@ionic/angular'
import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from 'src/app/system/navigation.service';
import { DataService } from 'src/app/system/data.service';
import { LocalizationService } from 'src/app/system/localization.service';
import { ProjectService } from '../project.service';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Events } from '../../system/events.service'

import { ContactPage } from '../contact/contact.page';
import { CanoncontactsPage } from '../canoncontacts/canoncontacts.page';
import { UserService } from 'src/app/cloud/user.service';
import { AuthorService } from 'src/app/cloud/author.service'
import { BootService } from 'src/app/system/boot.service';

@Component({
  	selector: 'app-contacts',
  	templateUrl: './contacts.page.html',
  	styleUrls: ['./contacts.page.scss'],
})
export class ContactsPage implements OnInit {
	@Input() projectId: string = "null";
	@Input() sceneId: string = "null";
	@Input() asModal: any;
	@Input() sceneMode: boolean = false;
	@Input() elligibleContacts: Array<any> = [];
	@Input() noOwnContacts: boolean = false;

	@ViewChild("ionContent", { static: false } ) ionContent: IonContent;
	@ViewChild("headerTitleLarge", { static: false } ) headerTitleLarge: ElementRef;
	@ViewChild("headerTitleSmall", { static: false } ) headerTitleSmall: ElementRef;
	@ViewChild("content", { static: false } ) content: ElementRef;

	checkedContacts: Array<any> = [];
	hadNoOwn: boolean = false;
	contactsOfFellow: Array<any> = [];

  	constructor(
		private boot: BootService,
		private domController: DomController,
		private renderer: Renderer2,
		private navigation: NavigationService,
		public data: DataService,
		public localization: LocalizationService,
		private fireStore: AngularFirestore,
		private route: ActivatedRoute,
		private alertController: AlertController,
		private user: UserService,
		private modalController: ModalController,
		private events: Events,
		private author: AuthorService,
		public project: ProjectService
	) {}

  	ngOnInit() {
		this.route.queryParamMap.forEach(map => {
			this.projectId = map.get("projectId");
			if (!this.projectId) { this.projectId = "CCD9sQXTf8OxkOO8Tmn6"; }
			this.project.syncProject(this.projectId);
			this.project.syncContacts(this.projectId);
		})

		if (this.noOwnContacts) { this.hadNoOwn = true; }

		if (this.elligibleContacts.length <= 0) {
			let i = 1;
			for (let contact of this.project.projects[this.projectId].contacts) {
				this.checkedContacts[contact.id] = true;
				i++;
				if (i >= 3) { break; }
			}
		} else {
			for (let contact of this.elligibleContacts) {
				this.checkedContacts[contact.id] = true;
			}
		}

		this.events.subscribe(this.projectId+"_contacts", () => { this.processContactsOfFellow(); })
		this.processContactsOfFellow();
	}

	ionViewDidEnter() {}

	scrollable: boolean = false;
	scrollableChecked: boolean = false;
	headerCollapsed: boolean = false;
	async scroll(event) {
		if (!this.asModal) {
			if (!this.scrollableChecked) {
				this.scrollableChecked = true;
				await this.ionContent.getScrollElement().then(scrollElement => { if (scrollElement.scrollHeight > scrollElement.offsetHeight + 10) { this.scrollable = true; } })
			}
			if (this.scrollable) {
				let height = 50 - event.detail.scrollTop;
				let showSmallTitle = false;
				if (height <= 0) { height = 0; showSmallTitle = true; } 
				else if (height > 50) { height = 50; }
				this.domController.write(() => {
					this.renderer.setStyle(this.headerTitleLarge.nativeElement, "height", height+"px");
					if (showSmallTitle) {
						this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "1");
						this.headerCollapsed = true;
					} else {
						this.renderer.setStyle(this.headerTitleSmall.nativeElement, "opacity", "0");
						this.headerCollapsed = false;
					}
				})
			}
		}
	}
	scrollToTop() { this.ionContent.scrollToTop(400); }
	pop() { this.navigation.pop(); }
	close() {
		if (this.asModal) { this.modalController.dismiss(); } else { this.pop(); }
	}

	processContactsOfFellow() {
		for (let contact of this.project.projects[this.projectId].contacts) {
			let isFellow = false;
			if (contact.flw) {
				for (let fellow of contact.flw) {
					if (fellow.aid === this.author.uid) {
						isFellow = true;
					}
				}
			}
			if (isFellow) { 
				this.contactsOfFellow[contact.id] = true;
			} else { 
				this.contactsOfFellow[contact.id] = false;
			}
		}
	}

	async getID() {}

	async addCanonContact() {
		if (this.project.projects[this.projectId].role === 'admin' || this.project.projects[this.projectId].role === 'collab') {
			const modal = await this.modalController.create({
				component: CanoncontactsPage,
				componentProps: {
					"asModal": true
				}
			}).then(async modal => {
				setTimeout(async () => { await modal.present(); }, 100)
				modal.onDidDismiss().then(async result => {
					if (result.data) {
						let newContact = {
							id: result.data.i,
							im: result.data.i+".jpg",
							n: result.data.n
						}
						this.updateContact(newContact);
					}
				})
			})
		}
	}

	async createContact() {
		if (this.project.projects[this.projectId].role === 'admin' || this.project.projects[this.projectId].role === 'collab') {
			let newContact = {
				n: "",
				im: "trudy.jpg"
			}
			this.editContact(newContact);			
		}
	}

	updateContact(contact) {
		console.log("CONTACT", contact);
		if (contact.id) {
			if (!contact.scs) { contact.scs = []; }
			if (!contact.d) { contact.d = ""; }
			this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("contacts").doc(contact.id).set(contact);
		} else {
			this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("contacts").add({
				im: contact.im,
				n: contact.n,
				d: ""
			})
		}
	}

	async editContact(contact) {
		if (this.project.projects[this.projectId].role === 'admin' || this.project.projects[this.projectId].role === 'collab' || this.contactsOfFellow[contact.id]) {
			let modal = await this.modalController.create({
				component: ContactPage,
				componentProps: {
					contact: contact
				}
			}).then(async (modal) => {
				setTimeout(async () => { await modal.present(); }, 100);
				modal.onDidDismiss().then(async result => {
					contact = result.data;
					if (contact.n != "") {
						this.updateContact(contact);
					}
				})
			})			
		}
	}

	deleteContact(contact) {
		if (contact.id) {
			this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("contacts").doc(contact.id).delete();			
		}		
	}

	changeToggle(contact) {
		if (this.user.currentUser) {
			if (this.checkedContacts[contact.id]) {
				this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("authors").doc(this.user.currentUser.uid).collection("contacts").doc(contact.id).delete();
				this.checkedContacts[contact.id] = false;
			} else {
				this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("authors").doc(this.user.currentUser.uid).collection("contacts").doc(contact.id).set({ id: contact.id });
				this.checkedContacts[contact.id] = true;
			}
		}
		if (this.noOwnContacts) {
			for (let contactOfProject of this.project.projects[this.projectId].contacts) {
				if (this.checkedContacts[contactOfProject.id]) {
					if (contactOfProject.id != contact.id) {
						this.fireStore.collection("cugc").doc("projects").collection("projects").doc(this.projectId).collection("scenes").doc(this.sceneId).collection("authors").doc(this.user.currentUser.uid).collection("contacts").doc(contactOfProject.id).set({ id: contactOfProject.id });
					}
				}
			}
			this.noOwnContacts = false;
		}
	}

	importContacts() {
		this.project.importContacts_chooseProject(this.projectId, 0);
	}

}