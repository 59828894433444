import { Component, NgZone } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { App } from '@capacitor/app'
import { Router } from '@angular/router';

import { MenuService } from './system/menu.service';
import { LocalizationService } from './system/localization.service';
import { InstanceService } from './system/instance.service';
import { Events } from './system/events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

	isMobile: boolean = true;

	contentSections: Array<any> = [];

  	constructor(
    	private platform: Platform,
		public menu: MenuService,
		public localization: LocalizationService,
		public instance: InstanceService,
		private navController: NavController,
		private events: Events,
		private zone: NgZone,
		private router: Router
  	) {
		console.clear();
    	this.initializeApp();

		if (this.platform.is("cordova") || this.platform.is("android") || this.platform.is("ios") || this.platform.is("mobile") || this.platform.is("mobileweb")) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
  	}

  	initializeApp() {
    	//this.platform.ready().then(() => { this.statusBar.styleDefault(); });
		App.addListener('appUrlOpen', (data: any) => {
			this.zone.run(() => {
				// Example url: https://beerswift.app/tabs/tab2
				// slug = /tabs/tab2
				const slug = data.url.split(".app").pop();
				if (slug) {
					this.router.navigateByUrl(slug);
				}
				// If no match, do nothing - let regular routing
				// logic take over
			});
		});
  	}

	pushStart() { this.navController.navigateRoot("/start"); }
}