import { Component, OnInit, Input } from '@angular/core';

import { BranchService } from 'src/app/quest/branch.service';

@Component({
  	selector: 'eon-branch',
  	templateUrl: './branch.component.html',
  	styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {

	@Input('seed') seed: number = 0;
	@Input("itemId") itemId: string = "";

	item: any = false;

	textOne: string = "";
	nextOne: string = "";
	textTwo: string = "";
	nextTwo: string = "";
	isSplit: boolean = false;

  	constructor(
		public branch: BranchService
	) {}

  	ngOnInit() {
		if (this.itemId) {
			this.item = this.branch.getItemById(this.itemId);
			if (this.item) {
				if (this.item.spl) {
					this.textOne = "SPLIT";
					console.log("HERE ITEM", this.item);
					if (this.item.o[0] && this.item.o[1]) {
						this.textOne = this.item.o[0].m;
						this.textTwo = this.item.o[1].m;
						this.isSplit = true;
						this.nextOne = this.item.o[0].n;
						this.nextTwo = this.item.o[1].n;
					}
				} else {
					this.textOne = this.item.m;
					this.nextOne = this.item.n;
					this.textTwo = "";
					this.isSplit = false;
				}
			}
		} else {
			setInterval(() => {
				this.item = this.branch.getRootItem();
				if (this.item) {
					if (this.item.spl) {
	
					} else {
						this.textOne = this.item.m;
						this.nextOne = this.item.n;
						this.textTwo = "";
						this.isSplit = false;
					}
				}
			}, 10000);
		}
	}

}