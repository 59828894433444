import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore'

import { Events } from '../system/events.service'

import { MetaService } from './meta.service';
import { VariasService } from '../system/varias.service';
import { LocalizationService } from '../system/localization.service';
import { InstanceService } from '../system/instance.service';

@Injectable({
  	providedIn: 'root'
})
export class LiveService {
	
	unsubscribeChanges: any;

	constructor(
		private meta: MetaService,
		private varias: VariasService,
		private events: Events,
		private fireStore: AngularFirestore,
		private localization: LocalizationService,
		private instance: InstanceService
	) {}
	  
	async checkAddresses() {
		if (this.instance.instance != "prevapp") {
			let initTime = null; await this.varias.getVariable("usr_init").then(async result => { if (result) { initTime = result; } });
			if (initTime === null || initTime === undefined) { return; }

			let addresses = []; await this.meta.getAddresses().then(result => addresses = result);
			let now = Date.now();
			for (let address of addresses) {
				let legit = false; await this.varias.checkConditions(address.conds).then(result => { if (result) { legit = true; } })
				if (address.f) {
					if (address.f.seconds) {
						if (now/1000 < address.f.seconds) {
							legit = false;
						}
						if (initTime/1000 > address.f.seconds) {
							legit = false;
						}
					}
				}
				if (address.t) {
					if (address.t.seconds) {
						if (now/1000 > address.t.seconds) {
							legit = false;
						}
					}
				}				
				if (legit) {
					this.events.publish("ECH_downloadAndStartTutoring", {sceneId: address.i});
				}
			}			
		}
		return;
	}

	async startWatchingAddresses() {
		if (this.instance.instance != "prevapp") {
			this.unsubscribeChanges = this.fireStore.collection(this.localization.DB_meta).doc("meta").ref.onSnapshot(snapshot => {
				this.checkAddresses();
			})
		}
	}
}
