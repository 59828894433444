import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { DeviceService } from './device.service';

import { SplashScreen } from '@capacitor/splash-screen';

import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'

import { DataService } from './data.service'
import { LocalizationService } from './localization.service'
import { VariasService } from './varias.service';
import { LiveService } from '../cloud/live.service';
import { EngineService } from '../system/engine.service'
import { TelemetryService } from '../cloud/telemetry.service'
import { UserService } from '../cloud/user.service'
import { PushService } from '../cloud/push.service'
import { PurchaseService } from '../cloud/purchase.service'
import { NotificationsService } from '../system/notifications.service'
import { ConfigService } from '../cloud/config.service';

import { Events } from '../system/events.service'
import { MetaService } from '../cloud/meta.service';
import { Router } from '@angular/router';
import { InstanceService } from './instance.service';
import { MenuService } from './menu.service';
import { AssetsService } from './assets.service';

@Injectable({
  	providedIn: 'root'
})
export class BootService {

	willExit: boolean = false;
	done_checkIfAnonymousSyncToBeStarted: boolean = false;
	booted: boolean = false;

	constructor(
		private platform: Platform,
		private fireAuth: AngularFireAuth,
		private toastController: ToastController,
		private navController: NavController,
		private data: DataService,
		private localization: LocalizationService,
		private device: DeviceService,
		private varias: VariasService,
		private alertController: AlertController,
		private live: LiveService,
		private events: Events,
		private engine: EngineService,
		private telemetry: TelemetryService,
		private user: UserService,
		private push: PushService,
		private purchase: PurchaseService,
		private modalController: ModalController,
		private notifications: NotificationsService,
		private meta: MetaService,
		private config: ConfigService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		private instance: InstanceService,
		private menu: MenuService,
		private storage: Storage,
		private assets: AssetsService,
		
	) {
		this.boot();
	}
	  
	async boot() {
		

		await this.detectLanguage();

		if (this.instance.instance === "demoapp") {
			this.menu.enabled = false;
		} else if (this.instance.instance === "prevapp" && this.instance.variant === "prllx") {
		} else {
			this.analytics.setAnalyticsCollectionEnabled(true);
		}

		await this.platform.ready().then(() => {
			if (this.router.routerState.snapshot.url != "/start") {
				SplashScreen.hide();
			}
		})
		
		if (this.device.operatingSystem === "android") {
			this.platform.backButton.subscribe(() => {
				this.handleBackButton();
			});
		}

		setTimeout(() => { 
			this.purchase.registerAll();
			this.checkBootCount();
			this.setTotalNumberOfEpisodes();
		}, 1000);
		setTimeout(() => {
			this.checkInitialization();
		}, 2000);
		setTimeout(() => { this.push.checkNotifications(); }, 4000);
		setTimeout(async () => { this.live.startWatchingAddresses(); }, 4500);
		setTimeout(() => { this.trackRunningStoryboardsAndCheckPermission(); }, 5000);
		//setTimeout(() => { this.checkIfAssetsToBePermittedDueToLongTimeOrCurrency(); }, 5500);
		setTimeout(() => { this.engine.SYNC_findRunningStoryboards(); }, 7000);
		setTimeout(() => { this.checkIfPurchasesToBeRestored(); }, 60000)
		setTimeout(() => { this.config.getConfiguration(); }, 10000);
		
	}

	async handleBackButton() {
		let isList = false; if (this.data.page_main === "list") { isList = true; }

		let dismissed = false;
		await this.modalController.dismiss().then(result => { dismissed = result; }).catch(() => { dismissed = false; })

		if (!dismissed) {
			if (isList) {
				if (this.willExit) {
					navigator['app'].exitApp();
				} else {
					this.willExit = true;
					const toast = await this.toastController.create({
						message: this.localization.backButton_instruction,
						position: "bottom",
						duration: 2000
					})
					toast.present();
					setTimeout(() => { this.willExit = false; }, 2000);
				}
			} else {
				//this.navController.pop();
			}			
		}
	}

	async checkBootCount() {
		await this.varias.incrementCount("sys_boot", 1);
		/*
		await this.varias.getCount("sys_boot").then(async value => {
			await this.varias.incrementCount("sys_boot", 1);
			if (value < 3) { await this.presentDisclaimer(); }
		})
		*/
	}
	disclaimerBlocked: boolean = false;
	disclaimer: any = null;
	async presentDisclaimer() {
		if (!this.disclaimerBlocked) {
			this.disclaimer = await this.alertController.create({
				subHeader: this.localization.fictionDisclaimer_header,
				cssClass: 'eon__alert',
				message: this.localization.fictionDisclaimer,
				buttons: [{
					text: this.localization.fictionDisclaimer_button,
					handler: () => {}
				}]
			})
			await this.disclaimer.present();
		}
	}

	blockDisclaimer() {
		if (this.disclaimer) { this.disclaimer.dismiss(); }
		this.disclaimerBlocked = true;
	}

	async checkIfAssetsToBePermittedDueToLongTimeOrCurrency() {

		if (this.instance.instance === "voidapp" || this.instance.instance === "gamerapp") {
			let unlocked = await this.varias.getVariable("sys_asstUnl");
			if (unlocked) { return; }

			let day = 0; await this.varias.getVariable("usr_day").then(result => day = result);
			if (day > 200) {
				this.assets.unlockAssets();
				console.log("ASSET DUE TO TIME");
				return;
			}

			await this.varias.getVariable("sys_tickets");
			if (this.varias.vars["sys_tickets"] >= 200) {
				this.assets.unlockAssets();
				console.log("ASSET DUE TO TICKETS");
				return;
			}

			await this.varias.getVariable("sys_shards");
			if (this.varias.vars["sys_shards"] >= 25) {
				this.assets.unlockAssets();
				console.log("ASSET DUE TO SHARDS");
				return;
			}

			await this.varias.getVariable("sys_nutrition");
			if (this.varias.vars["sys_nutrition"] >= 25) {
				this.assets.unlockAssets();
				console.log("ASSET DUE TO NUTRITION");
				return;
			}
		}
	}

	async trackRunningStoryboardsAndCheckPermission() {
		let askForPermission = false;
		let day = 0; await this.varias.getVariable("usr_day").then(result => day = result);
		if (day >= 3) {
			askForPermission = true;
		}

		await this.engine.loadStoryboardsRegistry();
		let numberStartedStoryboards = 0;
		for (let storyboard of this.engine.storyboardsRegistry) {
			if (storyboard.started) {
				numberStartedStoryboards++;
				if (storyboard.i != "pa1" && storyboard.i != "ga1") { askForPermission = true; }
			}
			if (storyboard.running && !storyboard.archived) {
				this.telemetry.logEvent("episode_running", { ep: storyboard.i, day: day });
			}
		}

		if (askForPermission) {
			this.notifications.requestPermission();
		}
	}

	async checkInitialization() {
		await this.engine.loadStoryboardsRegistry();

		let day = 0;
		await this.varias.getVariable("usr_init").then(async initTime => {
			let now = Date.now();
			if (initTime === null) {
				await this.varias.setVariable("usr_init", now);
				this.varias.setVariable("usr_day", day);
				this.telemetry.setUserProperty("day", "0");
				this.telemetry.setUserProperty("first3days", "true");
			} else {
				day = Math.floor((now - initTime) / (1000*60*60*24));
				this.varias.setVariable("usr_day", day);
				this.telemetry.setUserProperty("day", day.toString());
				if (day <= 2) {
					this.telemetry.setUserProperty("first3days", "true");
				} else {
					this.varias.setVariable("usr_mature", true);
					this.telemetry.setUserProperty("first3days", "false");
				}
			}
		})
		return day;
	}

	async checkIfPurchasesToBeRestored() {
		await this.engine.loadStoryboardsRegistry();
		let needToRestorePurchases = true;
		for (let storyboard of this.engine.storyboardsRegistry) {
			if (storyboard.started && storyboard.i != "pa1") {
				needToRestorePurchases = false;
			}
		}
		if (needToRestorePurchases) {
			this.purchase.restorePurchases();
		}
	}

	async setTotalNumberOfEpisodes() {
		let totalNumber = 0;
		await this.meta.getEpisodes().then(result => {
			for (let serial of result) {
				if (serial.ep) {
					totalNumber = totalNumber + serial.ep.length;
				}
			}
			this.data.totalNumberOfEpisodes = totalNumber;
		})
	}

	async detectLanguage() {
		let storedLang = null; await this.varias.getVariable("stt_lang").then(async result => storedLang = result);
		if (storedLang) {
			let foundMatchingLanguage = false;
			for (let language of this.localization.languages) {
				if (storedLang === language.code) {
					await this.localization.setLanguage(language.code, false);
					foundMatchingLanguage = true;
					break;
				}
			}
			if (!foundMatchingLanguage) {
				await this.localization.setLanguage("en", false);
			}
		} else {
			let foundMatchingLanguage = false;
			let detectedLangCode = navigator.language.split("-")[0].toUpperCase();
			for (let language of this.localization.languages) {
				if (language.code === detectedLangCode) {
					this.varias.setVariable("stt_lang", language.code);
					await this.localization.setLanguage(language.code, false);
					foundMatchingLanguage = true;
					break;					
				}
			}
			if (!foundMatchingLanguage) {
				this.varias.setVariable("stt_lang", "EN");
				await this.localization.setLanguage("EN", false);
			}
		}
	}

}