import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { RateApp } from 'capacitor-rate-app';
import { LocalizationService } from './localization.service';
import { MailService } from './mail.service';
import { VariasService } from './varias.service';

import { Events } from '../system/events.service'
import { InstanceService } from './instance.service';
import { BrowserService } from './browser.service';
import { DeviceService } from './device.service';

@Injectable({
  	providedIn: 'root'
})
export class RateService {

  	constructor(
		private appRate: AppRate,
		private events: Events,
		private localization: LocalizationService,
		private platform: Platform,
		private mail: MailService,
		private varias: VariasService,
		private instance: InstanceService,
		private browser: BrowserService,
		private device: DeviceService
	) {
		this.events.subscribe("askForRating", () => {
			this.rate();
		})
	}

	async rate() {
		RateApp.requestReview();
	}
}